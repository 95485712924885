import {
  GetAttivitaSortBy,
  GetAttivitaSortDirection,
  GetAttivitaStatoAttivita
} from 'client/api/backend/schemas';
import { yup } from 'common/validation/initYup';
import moment from 'moment';
import { StringSchema } from 'yup';

function normalizeDate(date: Date) {
  if (!date) return undefined;
  return moment(date).format('YYYY-MM-DD HH:mm');
}

export const getAttivitaParamsValidator = yup
  .object({
    firstResult: yup.number().notRequired(),
    maxResults: yup.number().notRequired(),
    taskId: yup.string().notRequired(),
    processInstanceId: yup.string().notRequired(),
    idUfficioAvvio: yup
      .string()
      .notRequired()
      .transform(value => value || undefined),
    candidateGroups: yup.array().of(yup.string().required()).notRequired(),
    processInstanceIdIn: yup.array().of(yup.string().required()).notRequired(),
    processDefinitionId: yup
      .string()
      .notRequired()
      .nullable()
      .transform(value => (value == null ? undefined : value)) as StringSchema,
    numeroProcedimento: yup.string().notRequired(),
    oggettoProcedimento: yup.string().notRequired(),
    statoAttivita: yup
      .string()
      .oneOfEnum(GetAttivitaStatoAttivita)
      .notRequired()
      .nullable()
      .transform(value =>
        value == null ? undefined : value
      ) as StringSchema<GetAttivitaStatoAttivita>,
    idUtenteAssegnatarioTask: yup
      .string()
      .notRequired()
      .nullable()
      .transform(value => (value == null ? undefined : value)) as StringSchema,
    dataCreazioneTaskIn: yup
      .string()
      .transform(value => normalizeDate(value))
      .notRequired(),
    dataCreazioneTaskFn: yup
      .string()
      .transform(value => normalizeDate(value))
      .notRequired(),
    dataInizioLavorazioneTaskIn: yup
      .string()
      .transform(value => normalizeDate(value))
      .notRequired(),
    dataInizioLavorazioneTaskFn: yup
      .string()
      .transform(value => normalizeDate(value))
      .notRequired(),
    dataAvvioProcedimentoIn: yup
      .string()
      .transform(value => normalizeDate(value))
      .notRequired(),
    dataAvvioProcedimentoFn: yup
      .string()
      .transform(value => normalizeDate(value))
      .notRequired(),
    sortBy: yup.string().oneOfEnum(GetAttivitaSortBy).notRequired(),
    sortDirection: yup.string().oneOfEnum(GetAttivitaSortDirection),
    strutturaAssegnazioneTask: yup.string().notRequired(),
    ricercaInSottostrutture: yup.boolean().notRequired(),
    nomeTask: yup.string().notRequired()
  })
  .required()
  .noUnknown();
