import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps, PopconfirmProps, Space, Typography } from 'antd';
const { Title, Paragraph } = Typography;
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { HiPlus } from 'react-icons/hi';
import { RiAuctionFill, RiPenNibFill, RiFileWarningFill } from 'react-icons/ri';
import { DefaultProcedureInfo } from 'client/components/schema/variables/colors/colorNames';
import {
  ButtonConfirm,
  ButtonConfirmProps
} from 'client/ui/button/ButtonConfirm';

const BoxWrapper = styled.div<{ color?: string }>`
  color: #fff;
  background: ${props => props.color ?? props.theme.procedureFallbackColor};
  border: 4px solid
    ${props => props.color ?? props.theme.procedureFallbackColor};
  border-radius: 8px;
`;
const SpaceMaxWidth = styled(Space)`
  max-width: 100%;
  width: 100%;
`;
const HeadWrapper = styled.div`
  display: flex;
`;
const Icon = styled(IconWrapper)`
  color: #fff;
  font-size: 48px;
  margin: 12px;
  flex: 0 0 48px;
`;
const Head = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;

const WhiteTitle = styled(Title)`
  &.ant-typography {
    color: #fff;
    margin-bottom: 0;
    padding-top: 20px;

    /* overflow: unset;
    white-space: unset; */
  }
`;
const WhiteParagraph = styled(Paragraph)`
  &.ant-typography {
    color: #fff;
    max-width: 100%;
    margin-right: 12px;
    margin-bottom: 6px;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;

    /* overflow: unset;
    white-space: unset; */
    .ant-typography-expand {
      color: #fff;
      opacity: 0.7;
      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }
  }
`;
const StyledButton = styled(Button)<{ color?: string }>`
  border-color: ${props => props.color ?? props.theme.procedureFallbackColor};

  &:hover,
  &:active,
  &:focus {
    border-color: #fff;
  }
`;

const ButtonText = styled.span<{ color?: string }>`
  color: ${props => props.color ?? props.theme.procedureFallbackColor};
  opacity: 1;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
`;

export interface ProcedureButtonBoxProps
  extends Omit<ButtonConfirmProps, 'title' | 'children' | 'type'> {
  title: React.ReactNode;
  description?: string; // Previsto nell'interfaccia e non in DTO
  color?: string;
  icon: string | React.ReactNode;
  buttonText?: string;
  confirmMessage?: React.ReactNode;
}

/**
 * Box per le procedure
 */
export function ProcedureButtonBox(props: ProcedureButtonBoxProps) {
  const {
    title,
    description,
    color,
    icon,
    buttonText,
    confirmMessage,
    ...confirmProps
  } = props;
  const procedureColor = color ?? DefaultProcedureInfo.Color;

  return (
    <BoxWrapper color={color}>
      <SpaceMaxWidth direction="vertical">
        <HeadWrapper>
          <Icon icon={icon} />
          <Head>
            <WhiteTitle
              level={3}
              ellipsis={{
                rows: 1
              }}
            >
              {title}
            </WhiteTitle>
            <WhiteParagraph
              strong
              ellipsis={{
                rows: 3
              }}
            >
              {description}
            </WhiteParagraph>
          </Head>
        </HeadWrapper>
        <ButtonConfirm
          as={StyledButton}
          block
          buttonColor={procedureColor}
          okDanger={false}
          placement={'top'}
          title={confirmMessage}
          {...confirmProps}
        >
          <ButtonText color={procedureColor}>
            <IconWrapper icon={<HiPlus />} />{' '}
            {buttonText ?? 'Nuovo Procedimento'}
          </ButtonText>
        </ButtonConfirm>
      </SpaceMaxWidth>
    </BoxWrapper>
  );
}
