import React from 'react';
import { Col, Row, Layout } from 'antd';
const { Header } = Layout;
import { HeaderLogo } from './HeaderLogo';
import { RightMenu } from 'client/routes/menu/RightMenu';
import { MainMenu } from 'client/routes/menu/MainMenu';
import styled from 'styled-components';
import { MainMenuMobile } from 'client/routes/menu/MainMenuMobile';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';

// const defaultBreakpoints = {
//   xs: '0px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1400px',
// };

//  const StyledLoginCard = styled(Card)`
//    min-width: 550px;
//    ${down('md')} {
//      min-width: 450px;
//    }
//    ${down('sm')} {
//      min-width: unset;
//      width: calc(100vw - 40px);
//      > .ant-card-body {
//        padding: 24px 12px;
//      }
//    }
//  `;

const StyledHeader = styled(Header)`
  width: 100%;
  position: fixed;
  z-index: 11;
  height: ${props => props.theme.layoutHeaderHeight};
  box-shadow: 0 1px 1px 0 rgba(100, 116, 139, 0.06),
    0 1px 2px 0 rgba(100, 116, 139, 0.1);
`;

const RowNoWrap = styled(Row)`
  flex-wrap: nowrap;
  height: ${props => props.theme.layoutHeaderHeight};
`;
const ColFlex = styled(Col)`
  flex: 0 0 auto;
  min-width: 0;
`;
const ColOveflow = styled(Col)`
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
`;

export interface PageLayoutHeaderProps {}

/**
 * Fascia in alto della pagina che contiene il logo,
 * il menu principale, il menu delle notifiche e quello dell'utente
 */
export function PageLayoutHeader(props: PageLayoutHeaderProps) {
  const isMobile = useBreakpoint(down('md'));

  return (
    <StyledHeader>
      <RowNoWrap align="middle">
        <ColFlex>
          <HeaderLogo />
        </ColFlex>
        {!isMobile && (
          <ColFlex>
            <MainMenu />
          </ColFlex>
        )}
        <ColOveflow>
          <RightMenu isMobile={isMobile} />
        </ColOveflow>
      </RowNoWrap>
    </StyledHeader>
  );
}
