import * as React from 'react';
import { Anchor, AnchorProps } from 'antd';
import styled from 'styled-components';

const AnchorStyled = styled(Anchor)`
  width: 100%;
  margin-top: -4px;
  .ant-anchor-ink-ball {
    display: none;
  }
`;

export interface StateAnchorProps extends AnchorProps {}

export function StateAnchor(props: StateAnchorProps) {
  return <AnchorStyled {...props} />;
}
