import { AzioniDichiarazione, Dichiarazione } from 'client/api/backend/schemas';
import { DeclarationConvert } from 'client/components/schema/task/declaration/converter/DeclarationConverter';
import { DeclarationActionLogic } from '../../declaration/actions/DeclarationActionLogic';

export class DeclarationLogic {
  /**
   * Ritorna una key univoca per react
   */
  static getKey(declaration: Dichiarazione) {
    return `${declaration.idTipoDichiarazione!}-${declaration.progressivo}`;
  }

  /**
   * Ritorna il body per salvare una dichiarazione via ApI
   */
  static getBodyData(
    declarations: AzioniDichiarazione[],
    documentDeclarations: AzioniDichiarazione[],
    values: any
  ) {
    // Salvo la dichiarazione
    const data = [];
    const allDeclarations = [...declarations, ...documentDeclarations];

    for (const declaration of allDeclarations) {
      // Possiamo salvare soltanto le dichiarazioni editabili.
      if (!DeclarationActionLogic.isDeclarationEditable(declaration.azioni))
        continue;

      data.push({
        idTipoDichiarazione: declaration.dichiarazione?.idTipoDichiarazione,
        progressivo: declaration.dichiarazione?.progressivo,
        datiJson: DeclarationConvert.to(
          declaration.dichiarazione!,
          values[declaration.dichiarazione!.idTipoDichiarazione!]
        )
      });
    }
    return data;
  }
}
