import { BellOutlined, EyeOutlined } from '@ant-design/icons';
import { Switch, Tooltip } from 'antd';
import React from 'react';

export interface INotificationFilterSwitchProps {
  onChange: (value: boolean) => void;
  defaultChecked: boolean;
}

export function NotificationFilterSwitch(
  props: INotificationFilterSwitchProps
) {
  const { onChange, defaultChecked } = props;
  return (
    <Tooltip title={defaultChecked ? 'Da Leggere' : 'Tutte'}>
      <Switch
        checkedChildren={<BellOutlined />}
        unCheckedChildren={<EyeOutlined />}
        onChange={onChange}
        defaultChecked={defaultChecked}
      />
    </Tooltip>
  );
}
