import { Button, message, Space } from 'antd';
import { AzioniDocumento, AzioniSezione } from 'client/api/backend/schemas';
import React from 'react';
import { SectionActionButton } from './SectionActionButton';

export interface ISectionButtonProps {
  taskId: string;
  section: AzioniSezione;
  document: AzioniDocumento;
}

/**
 * Bottone azione su sezione, con testo dinamico in base all'azione.
 * Verrà collegato al servizio desktop per la modifica del file word.
 */
export function SectionButton(props: ISectionButtonProps) {
  const { section } = props;

  return (
    <>
      {section.azioni?.map((action, index) => (
        <SectionActionButton key={index} action={action} {...props} />
      ))}
    </>
  );
}
