import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Typography } from 'antd';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import React, { useImperativeHandle, useState } from 'react';

const { Paragraph, Text } = Typography;

export interface IStandardSignJnlpWindowManagerProps {}

export type IStandardSignJnlpWindowManagerHandle = {
  open: (objectUrl: string) => void;
};

/**
 * Manager modal per firma **massiva** "STANDARD"
 */
export const StandardSignJnlpWindowManager = React.forwardRef(
  (
    props: IStandardSignJnlpWindowManagerProps,
    ref: React.ForwardedRef<IStandardSignJnlpWindowManagerHandle>
  ) => {
    const [objectUrl, setObjectUrl] = useState('');
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
      open: (objectUrl: string) => {
        setObjectUrl(objectUrl);
        setVisible(true);
      }
    }));

    return (
      <ModalDialog
        title={'Firma Documenti'}
        visible={visible}
        size="small"
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          setVisible(false);
        }}
        cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <Paragraph>
          A breve verrà avviato il download del programma per la firma del
          documento.
        </Paragraph>
        <Paragraph>
          Non appena scaricato, avviare il programma con estensione{' '}
          <Text strong>.jnlp</Text>. Verrà aperto il proprio sistema di firma.
        </Paragraph>
        <Alert
          message="Se il download non parte automaticamente, è possibile avviare nuovamente il download."
          type="info"
          action={
            <a
              href={objectUrl}
              target="_blank"
              title="Avvia il download"
              download="firmaManager.jnlp"
            >
              <Button
                icon={<DownloadOutlined />}
                children="Avvia il download"
              />
            </a>
          }
        />
      </ModalDialog>
    );
  }
);
