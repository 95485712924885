import * as React from 'react';
import { Result } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { OnPageAlertContainer } from 'client/ui/alert/OnPageAlertContainer';

export interface IForbiddenPageProps {}

export function ForbiddenPage(props: IForbiddenPageProps) {
  return (
    <PageLayout>
      <OnPageAlertContainer>
        <Result
          status="error"
          title="Non Autorizzato"
          subTitle="Non si dispone dei permessi per visualizzare la pagina richiesta."
        />
      </OnPageAlertContainer>
    </PageLayout>
  );
}
