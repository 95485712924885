/**
 * Generato automaticamente.
 * Procedimenti
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  BEResponseAttivitaDTO,
  BEResponseRicercaProcedimentoResponseDTO,
  GetProcedimentiParams,
  BEResponseProcedimentoConOggettiCollegati,
  BEResponseProcedimentoConTask,
  BEResponseListTipologiaProcedimentoDTO,
  BEResponseLong,
  CountProcedimentiParams
} from '.././schemas';
import { backendApiInstance } from '../../../core/network/mutators/apiClient';
import type { ErrorType } from '../../../core/network/mutators/apiClient';

export const avviaProcedimento = (idTipoProcedimento: string) => {
  return backendApiInstance<BEResponseAttivitaDTO>({
    url: `/procedimenti/${idTipoProcedimento}/avvia`,
    method: 'put'
  });
};

export type AvviaProcedimentoMutationResult = NonNullable<
  Awaited<ReturnType<typeof avviaProcedimento>>
>;

export type AvviaProcedimentoMutationError = ErrorType<unknown>;

export const useAvviaProcedimento = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof avviaProcedimento>>,
    TError,
    { idTipoProcedimento: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof avviaProcedimento>>,
    { idTipoProcedimento: string }
  > = props => {
    const { idTipoProcedimento } = props ?? {};

    return avviaProcedimento(idTipoProcedimento);
  };

  return useMutation<
    Awaited<ReturnType<typeof avviaProcedimento>>,
    TError,
    { idTipoProcedimento: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getProcedimenti = (
  params?: GetProcedimentiParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseRicercaProcedimentoResponseDTO>({
    url: `/procedimenti`,
    method: 'get',
    params,
    signal
  });
};

export const getGetProcedimentiQueryKey = (params?: GetProcedimentiParams) => [
  `/procedimenti`,
  ...(params ? [params] : [])
];

export type GetProcedimentiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProcedimenti>>
>;
export type GetProcedimentiQueryError = ErrorType<unknown>;

export const useGetProcedimenti = <
  TData = Awaited<ReturnType<typeof getProcedimenti>>,
  TError = ErrorType<unknown>
>(
  params?: GetProcedimentiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProcedimenti>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcedimentiQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProcedimenti>>> = ({
    signal
  }) => getProcedimenti(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getProcedimenti>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const getOggettiCollegati = (
  idIstanzaProcedimento: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseProcedimentoConOggettiCollegati>({
    url: `/procedimenti/${idIstanzaProcedimento}/oggettiCollegati`,
    method: 'get',
    signal
  });
};

export const getGetOggettiCollegatiQueryKey = (
  idIstanzaProcedimento: string
) => [`/procedimenti/${idIstanzaProcedimento}/oggettiCollegati`];

export type GetOggettiCollegatiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOggettiCollegati>>
>;
export type GetOggettiCollegatiQueryError = ErrorType<unknown>;

export const useGetOggettiCollegati = <
  TData = Awaited<ReturnType<typeof getOggettiCollegati>>,
  TError = ErrorType<unknown>
>(
  idIstanzaProcedimento: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOggettiCollegati>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetOggettiCollegatiQueryKey(idIstanzaProcedimento);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOggettiCollegati>>
  > = ({ signal }) => getOggettiCollegati(idIstanzaProcedimento, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getOggettiCollegati>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idIstanzaProcedimento,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getInfoGenerali = (
  idIstanzaProcedimento: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseProcedimentoConTask>({
    url: `/procedimenti/${idIstanzaProcedimento}/infoGenerali`,
    method: 'get',
    signal
  });
};

export const getGetInfoGeneraliQueryKey = (idIstanzaProcedimento: string) => [
  `/procedimenti/${idIstanzaProcedimento}/infoGenerali`
];

export type GetInfoGeneraliQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInfoGenerali>>
>;
export type GetInfoGeneraliQueryError = ErrorType<unknown>;

export const useGetInfoGenerali = <
  TData = Awaited<ReturnType<typeof getInfoGenerali>>,
  TError = ErrorType<unknown>
>(
  idIstanzaProcedimento: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInfoGenerali>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInfoGeneraliQueryKey(idIstanzaProcedimento);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInfoGenerali>>> = ({
    signal
  }) => getInfoGenerali(idIstanzaProcedimento, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getInfoGenerali>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idIstanzaProcedimento,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getFileDocumento = (
  idIstanzaProcedimento: string,
  idFile: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<string[]>({
    url: `/procedimenti/${idIstanzaProcedimento}/fileDocumento/${idFile}`,
    method: 'get',
    signal
  });
};

export const getGetFileDocumentoQueryKey = (
  idIstanzaProcedimento: string,
  idFile: string
) => [`/procedimenti/${idIstanzaProcedimento}/fileDocumento/${idFile}`];

export type GetFileDocumentoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFileDocumento>>
>;
export type GetFileDocumentoQueryError = ErrorType<unknown>;

export const useGetFileDocumento = <
  TData = Awaited<ReturnType<typeof getFileDocumento>>,
  TError = ErrorType<unknown>
>(
  idIstanzaProcedimento: string,
  idFile: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFileDocumento>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetFileDocumentoQueryKey(idIstanzaProcedimento, idFile);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFileDocumento>>
  > = ({ signal }) => getFileDocumento(idIstanzaProcedimento, idFile, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getFileDocumento>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(idIstanzaProcedimento && idFile),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getFileAllegato = (
  idIstanzaProcedimento: string,
  idFile: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<string[]>({
    url: `/procedimenti/${idIstanzaProcedimento}/fileAllegato/${idFile}`,
    method: 'get',
    signal
  });
};

export const getGetFileAllegatoQueryKey = (
  idIstanzaProcedimento: string,
  idFile: string
) => [`/procedimenti/${idIstanzaProcedimento}/fileAllegato/${idFile}`];

export type GetFileAllegatoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFileAllegato>>
>;
export type GetFileAllegatoQueryError = ErrorType<unknown>;

export const useGetFileAllegato = <
  TData = Awaited<ReturnType<typeof getFileAllegato>>,
  TError = ErrorType<unknown>
>(
  idIstanzaProcedimento: string,
  idFile: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFileAllegato>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetFileAllegatoQueryKey(idIstanzaProcedimento, idFile);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFileAllegato>>> = ({
    signal
  }) => getFileAllegato(idIstanzaProcedimento, idFile, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getFileAllegato>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(idIstanzaProcedimento && idFile),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getTipiProcedimento = (signal?: AbortSignal) => {
  return backendApiInstance<BEResponseListTipologiaProcedimentoDTO>({
    url: `/procedimenti/tipi`,
    method: 'get',
    signal
  });
};

export const getGetTipiProcedimentoQueryKey = () => [`/procedimenti/tipi`];

export type GetTipiProcedimentoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTipiProcedimento>>
>;
export type GetTipiProcedimentoQueryError = ErrorType<unknown>;

export const useGetTipiProcedimento = <
  TData = Awaited<ReturnType<typeof getTipiProcedimento>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTipiProcedimento>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTipiProcedimentoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTipiProcedimento>>
  > = ({ signal }) => getTipiProcedimento(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getTipiProcedimento>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const getTipiProcedimentoPerUtente = (signal?: AbortSignal) => {
  return backendApiInstance<BEResponseListTipologiaProcedimentoDTO>({
    url: `/procedimenti/tipiAvviabili`,
    method: 'get',
    signal
  });
};

export const getGetTipiProcedimentoPerUtenteQueryKey = () => [
  `/procedimenti/tipiAvviabili`
];

export type GetTipiProcedimentoPerUtenteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTipiProcedimentoPerUtente>>
>;
export type GetTipiProcedimentoPerUtenteQueryError = ErrorType<unknown>;

export const useGetTipiProcedimentoPerUtente = <
  TData = Awaited<ReturnType<typeof getTipiProcedimentoPerUtente>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getTipiProcedimentoPerUtente>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTipiProcedimentoPerUtenteQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTipiProcedimentoPerUtente>>
  > = ({ signal }) => getTipiProcedimentoPerUtente(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getTipiProcedimentoPerUtente>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const countProcedimenti = (
  params?: CountProcedimentiParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseLong>({
    url: `/procedimenti/count`,
    method: 'get',
    params,
    signal
  });
};

export const getCountProcedimentiQueryKey = (
  params?: CountProcedimentiParams
) => [`/procedimenti/count`, ...(params ? [params] : [])];

export type CountProcedimentiQueryResult = NonNullable<
  Awaited<ReturnType<typeof countProcedimenti>>
>;
export type CountProcedimentiQueryError = ErrorType<unknown>;

export const useCountProcedimenti = <
  TData = Awaited<ReturnType<typeof countProcedimenti>>,
  TError = ErrorType<unknown>
>(
  params?: CountProcedimentiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof countProcedimenti>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCountProcedimentiQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof countProcedimenti>>
  > = ({ signal }) => countProcedimenti(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof countProcedimenti>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
