export enum TargetType {
  Declaration = 1, // Dichiarazione
  Document = 2, // Documento
  Attachments = 3, // Gruppi di allegati
  DocumentSection = 4 // Sezioni di documenti
}

export enum DeclarationAction {
  View = '1', // Accesso in sola consultazione a tutti i dati della dichiarazione
  Edit = '2', // Accesso in modifica alla dichiarazione secondo la configurazione definita in bdu
  CustomEdit = '3' // Accesso in modifica alla dichiarazione secondo la configurazione definita in bdu
}

// Lista di azioni su dichiarazioni da considerarsi per la modifica
export const EditDeclarationActions = [
  DeclarationAction.Edit,
  DeclarationAction.CustomEdit
];

export enum DocumentAction {
  ViewOmissis = '10', // Permette di scaricare in locale il documento che tiene conto degli omissis
  SignAct = '11', // Scarica in locale l'atto da firmare e lo apre con il componente Firma Manager se è prevista la firma on line. Se è presente questa azione deve essere anche possibile scaricare il file, firmarlo offline e ricaricarlo
  ViewText = '12', // Visualizza solo testo dell'atto  nel template intermedio aprendolo con il Document Manager
  ViewSigned = '13', // Permette di scaricare in locale il file firmato
  View = '9', // Visualizza l'atto completo
  Sign = '19', // Scarica in locale il file pdf contenente il solo testo da firmare e lo apre con il componente Firma Manager se è prevista la firma on line. Se è presente questa azione deve essere anche possibile scaricare il file, firmarlo offline e ricaricarlo
  OfflineSignAct = '21', // Aggiunta azione per gestire la firma offline del documento completo
  OfflineTextSign = '22', // Aggiunta azione per gestire la firma offline del testo
  ViewSignedText = '23', // Visualizza il testo firmato
  RemoteSignAct = '28', // Firma remota principale
  RemoteTextSign = '29' // Firma remota testo
}

// Lista di azioni "Firma" sui document da considerarsi per la modifica
export const DocumentSignActions = [
  DocumentAction.SignAct,
  DocumentAction.Sign,
  DocumentAction.OfflineSignAct,
  DocumentAction.OfflineTextSign,
  DocumentAction.RemoteSignAct,
  DocumentAction.RemoteTextSign
];

export enum AttachmentsAction {
  CustomEdit = '3', // Modifica Custom,
  View = '4', // Visualizza gli allegati associati al procedimenti senza possibilità di modificare descrizione o attributi, caricare altri file o rimuoverli
  DeleteOwn = '5', // Permette di cancellare allegati caricati dall'utente
  DeleteOffice = '6', // Permette di cancellare allegati caricati dall'ufficio di login dell'utente
  EditOwn = '7', // Permette di modificare gli attributi ( descrizione , flag parte integrante ) dei propri allegati
  EditOffice = '8', // Permette di modificare gli attributi ( descrizione , flag parte integrante ) degli allegati del proprio ufficio
  Upload = '20' // Permette di caricare allegati
}

export const EditAttachmentsActions = [
  AttachmentsAction.DeleteOwn,
  AttachmentsAction.EditOwn,
  AttachmentsAction.DeleteOffice,
  AttachmentsAction.EditOffice,
  AttachmentsAction.Upload
];

export enum DocumentSectionAction {
  Edit = '14', // Scarica in locale e apre con il Document manager il file odt con il testo parziale dell'atto
  View = '15' // Scarica in locale  il file odt con il testo parziale dell'atto. Da confermare
}

export const EditSectionActions = [DocumentSectionAction.Edit];
