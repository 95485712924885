import * as React from 'react';
import { Card, Space } from 'antd';
import { LabelValueLine } from 'client/ui/display/LabelValueLine';
import { ISODate } from 'client/ui/display/date/ISODate';
import { ISORelativeDate } from 'client/ui/display/date/ISORelativeDate';
import { ProcedureTableTag } from 'client/ui/tag/ProcedureTableTag';
import { ProcedurePhaseTag } from 'client/ui/tag/ProcedurePhaseTag';
import { ProcedimentoConTask } from 'client/api/backend/schemas';

export interface TabInfoProps {
  procedure: ProcedimentoConTask | undefined;
}

/**
 * Tab con le informazioni generali del procedimento
 */
export function TabInfo(props: TabInfoProps) {
  const { procedure } = props;

  return (
    <Card bordered={false}>
      <LabelValueLine
        label="Procedimento:"
        value={
          <ProcedureTableTag
            name={procedure?.tipologiaProcedimento?.nomeTipo}
            color={procedure?.tipologiaProcedimento?.coloreRendering}
            icon={procedure?.tipologiaProcedimento?.iconaRendering}
            compact={false}
          />
        }
      />
      <LabelValueLine
        label="Fase:"
        value={<ProcedurePhaseTag phase={procedure?.nomeFaseCorrente} />}
      />

      <LabelValueLine label="Oggetto:" value={procedure?.oggetto} />
      <LabelValueLine
        label="Data avvio:"
        value={
          <ISODate
            date={procedure?.dataAvvio}
            parseFormat="DD/MM/YYYY HH:mm:ss"
            format="DD/MM/YYYY HH:mm"
          />
        }
      />
      <LabelValueLine
        label="Avviato da:"
        value={
          <div>
            <div>{procedure?.nomeUtenteAvvio}</div>
            <div>{procedure?.nomeUfficioAvvio}</div>
          </div>
        }
      />
      <LabelValueLine
        label="Responsabile:"
        value={
          <div>
            <div>{procedure?.nomeUfficioCompetente}</div>
          </div>
        }
      />
      <LabelValueLine
        label="Data ultima modifica:"
        value={
          <Space>
            <ISODate
              date={procedure?.dataUltimaModifica}
              parseFormat="DD/MM/YYYY HH:mm:ss"
              format="DD/MM/YYYY HH:mm"
            />
            <span>
              (
              <ISORelativeDate
                date={procedure?.dataUltimaModifica}
                relativeOnly
                parseFormat="DD/MM/YYYY HH:mm:ss"
              />
              )
            </span>
          </Space>
        }
      />
    </Card>
  );
}
