import { DownloadOutlined, SyncOutlined } from '@ant-design/icons';
import { Alert, Button, Space, Typography } from 'antd';
import { useGetAzioniDocumento } from 'client/api/backend/attivita/attivita';
import { Documento } from 'client/api/backend/schemas';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import * as React from 'react';
import { useImperativeHandle, useState } from 'react';

const JNLP_REFETCH_INTERVAL_MS = 5 * 1_000;

const { Paragraph, Text } = Typography;

export interface IJnlpWindowManagerProps {
  taskId: string;
  document: Documento | undefined;
}

const getDocumentKey = (document: Documento | undefined) => {
  const file = document?.files?.find(
    file => file.flFirmato && file.codiceTipologia === 'FIRMATO'
  );
  return [
    document?.idTipoDocumento ?? '',
    document?.idDocumento ?? '',
    file?.idFile ?? ''
  ].join('_');
};

export type ISignJnlpWindowManagerHandle = {
  open: (objectUrl: string, document: Documento) => void;
};

/**
 * Manager modal per firma **singola** "STANDARD"
 */
export const SignJnlpWindowManager = React.forwardRef(
  (
    props: IJnlpWindowManagerProps,
    ref: React.ForwardedRef<ISignJnlpWindowManagerHandle>
  ) => {
    const [objectUrl, setObjectUrl] = useState('');
    const [visible, setVisible] = useState(false);
    const [previousKey, setPreviousKey] = useState(
      getDocumentKey(props.document)
    );

    useImperativeHandle(ref, () => ({
      open: (objectUrl: string, document: Documento) => {
        setObjectUrl(objectUrl);
        setPreviousKey(getDocumentKey(document));
        setVisible(true);
      }
    }));
    // console.log({ objectUrl });
    const documentsResult = useGetAzioniDocumento(props.taskId, {
      query: {
        enabled: visible,
        refetchInterval: JNLP_REFETCH_INTERVAL_MS,
        refetchIntervalInBackground: true,
        onSuccess: data => {
          const nextDocument = data.data?.find(
            d =>
              d.documento?.idTipoDocumento === props.document?.idTipoDocumento
          );

          const nextKey = getDocumentKey(nextDocument?.documento);
          // console.log('nextKey', nextKey, 'prevKey', previousKey, documentsResult.data?.data); // prettier-ignore
          if (nextKey !== previousKey) {
            setVisible(false);
          }
        }
      }
    });

    const handleReload = () => {
      documentsResult.refetch();
    };

    return (
      <ModalDialog
        title={'Firma Documento'}
        visible={visible}
        size="small"
        onCancel={() => {
          setVisible(false);
        }}
        footer={
          <Space>
            <Button
              icon={<SyncOutlined />}
              children="Aggiorna manualmente"
              onClick={handleReload}
              loading={documentsResult.isFetching}
              disabled={documentsResult.isFetching}
            />
          </Space>
        }
        maskClosable={false}
      >
        <Paragraph>
          A breve verrà avviato il download del programma per la firma del
          documento.
        </Paragraph>
        <Paragraph>
          Non appena scaricato, avviare il programma con estensione{' '}
          <Text strong>.jnlp</Text>. Verrà aperto il proprio sistema di firma.
        </Paragraph>
        <Paragraph>
          La pagina si ricaricherà automaticamente al termine delle modifiche.
          In caso contrario, premere il pulsante "Aggiorna manualmente".
        </Paragraph>
        <Alert
          message="Se il download non parte automaticamente, è possibile avviare nuovamente il download."
          type="info"
          action={
            <a
              href={objectUrl}
              target="_blank"
              title="Avvia il download"
              download="firmaManager.jnlp"
            >
              <Button
                icon={<DownloadOutlined />}
                children="Avvia il download"
              />
            </a>
          }
        />
      </ModalDialog>
    );
  }
);
