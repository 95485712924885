import React from 'react';
import { FormikValues } from 'formik';
import { FormItem, SingleFormItemProps } from '../FormItem';
import { TextAreaInput, TextAreaInputProps } from './TextAreaInput';
import { createGlobalStyle } from 'styled-components';

export const TextAreaInputGlobalStyle = createGlobalStyle`

  body {
    .text-area-input-item {
      .ant-form-item-control-wrapper {
        .ant-form-item-children {
          position: static;
          textarea {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

interface Props<T extends FormikValues> extends TextAreaInputProps<T> {
  itemProps?: SingleFormItemProps<T>;
  label?: React.ReactNode;
}

/**
 * Form Item per input testuale.
 */
export function TextAreaInputItem<T extends FormikValues>(props: Props<T>) {
  const { name, label, itemProps, ...otherProps } = props;

  return (
    <FormItem
      {...itemProps}
      name={name}
      label={label}
      className="text-area-input-item"
    >
      <TextAreaInput {...otherProps} name={name} />
    </FormItem>
  );
}
