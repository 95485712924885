import React from 'react';
import { FormikValues } from 'formik';
import { FormItem, SingleFormItemProps } from '../FormItem';
import { RichTextInput, RichTextInputProps } from './RichTextInput';
import { createGlobalStyle } from 'styled-components';

export const RichTextInputGlobalStyle = createGlobalStyle`
  .ql-toolbar {
    line-height: initial;
  }
`;

interface Props<T extends FormikValues> extends RichTextInputProps<T> {
  itemProps?: SingleFormItemProps<T>;
  label?: React.ReactNode;
}

/**
 * Form Item per input RTE.
 */
export function RichTextInputItem<T extends FormikValues>(props: Props<T>) {
  const { name, label, itemProps, ...otherProps } = props;

  return (
    <FormItem
      {...itemProps}
      name={name}
      label={label}
      className="text-area-input-item"
    >
      <RichTextInput {...otherProps} name={name} />
    </FormItem>
  );
}
