import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Card, CardProps, Col, Row, Skeleton, Space } from 'antd';
import { ProcedureButton } from './detail/ProcedureButton';
import { FixturesTipologiaProcedimentoDTO } from 'client/fixtures/FixturesHomeProcedimenti';
import { LinkButton } from 'client/ui/link/LinkButton';
import {
  useGetTipiProcedimento,
  useGetTipiProcedimentoPerUtente
} from 'client/api/backend/procedimenti/procedimenti';
import { LoadingQuery } from 'client/ui/loader/LoadingQuery';
import { down } from 'styled-breakpoints';

const ButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  ${down('md')} {
    padding-top: 24px;
  }
`;

export interface ProcedureHomeCardProps extends CardProps {}

/**
 * Card personalizzata per la Home con i procedimenti
 */
export function ProcedureHomeCard(props: ProcedureHomeCardProps) {
  const procedures = useGetTipiProcedimentoPerUtente({});

  // Mostriamo solamente i primi due
  const shownProcedures = procedures.data?.data?.slice(0, 2);

  return (
    <Card bordered={false}>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={18}>
          <Row gutter={[24, 24]}>
            <LoadingQuery
              query={procedures}
              errorMessage="Impossibile caricare i procedimenti"
            >
              {shownProcedures?.map((procedure, index) => (
                <Col xs={24} sm={12} md={12} key={index}>
                  <ProcedureButton procedure={procedure} />
                </Col>
              ))}
            </LoadingQuery>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <ButtonWrapper>
            {/* {itemsLength > 6 ? ( */}
            <LinkButton
              to="/home/procedure/type/list"
              size="large"
              type="primary"
              children="Mostra Tutti"
            />
            {/* ) : (
              <Button
                onClick={toggleShowAll}
                size="large"
                type="primary"
                children={showAll ? 'Mostra Principali' : 'Mostra Tutti'}
              />
            )} */}
          </ButtonWrapper>
        </Col>
      </Row>
    </Card>
  );
}
