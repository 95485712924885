/**
 * Generato automaticamente.
 * Procedimenti
 */
import { useQuery } from 'react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type { DataOption, SearchParams } from '.././schemas';
import { backendApiInstance } from '../../../core/network/mutators/apiClient';
import type { ErrorType } from '../../../core/network/mutators/apiClient';

/**
 * cerca una serie di utenti che effettuano le lavorazioni
 * @summary Utenti [search]
 */
export const search = (params: SearchParams, signal?: AbortSignal) => {
  return backendApiInstance<DataOption[]>({
    url: `/utenti`,
    method: 'get',
    params,
    signal
  });
};

export const getSearchQueryKey = (params: SearchParams) => [
  `/utenti`,
  ...(params ? [params] : [])
];

export type SearchQueryResult = NonNullable<Awaited<ReturnType<typeof search>>>;
export type SearchQueryError = ErrorType<unknown>;

export const useSearch = <
  TData = Awaited<ReturnType<typeof search>>,
  TError = ErrorType<unknown>
>(
  params: SearchParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof search>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof search>>> = ({
    signal
  }) => search(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof search>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
