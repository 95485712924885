import React from 'react';
import styled from 'styled-components';
import { Card, CardProps } from 'antd';

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0;
    padding-top: 1px;
    padding-bottom: 4px;
  }
`;

export interface TableCardProps extends CardProps {}

/**
 * Card personalizzata per icontenere le tabelle (rimosso padding del body)
 */
export function TableCard(props: TableCardProps) {
  return <StyledCard bordered={false} {...props} />;
}
