/**
 * Thema di default dell'applicativo
 *
 * Contiene le variabili utilizzate nell'applicativo
 * che possono essere sovrascritte per personalizzazione
 * nel file theme.tsx
 */
export const mainTheme = {
  /**
   * Variabili di stile
   */
  logoWidth: '199px',
  logoHeight: '42px',
  logoWidthMobile: '150px',
  logoHeightMobile: '31px',
  // TODO: Gestire inserimento url immagine e file in configurazione
  // logoUrl: require('./image/logo-whitelabel.svg'),
  // logoUrlMobile: require('./image/logo-whitelabel.svg'),
  logoUrl: require('./image/flownext.svg'),
  logoSpace: require('./image/logo-space.png'),

  // TODO verificare se portare a full width
  contentMaxWidth: '1340px',
  textColorGray: '#748494',

  // Altezza header (64px) + altezza footer per la versione (25px)
  layoutHeaderAndVersionFooterHeight: '89px',
  layoutVersionFooterHeight: '25px',

  /**
   * Colori predefiniti per i procedimenti
   */
  procedureFallbackColor: '#979797'
};
