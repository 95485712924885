import { Card } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import {
  AttivitaDTO,
  GetAttivitaSortBy,
  GetAttivitaSortDirection,
  ProcedimentoConTask
} from 'client/api/backend/schemas';
import {
  getColumnsTasksProcedureDetail,
  columnsTasksProcedureDetailMobile
} from 'client/routes/task/list/table/columnsTaskListTable';
import { useTaskList } from 'client/routes/task/list/table/useTaskList';
import { getAttivitaParamsValidator } from 'client/components/schema/task/validation/getAttivitaParamsValidator';
import { TableData } from 'client/ui/table/TableData';
import React from 'react';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';
import { useCurrentUser } from 'client/components/auth/AuthModule';

const TabTaskWrapper = styled.div`
  padding-top: 24px;
  ${down('md')} {
    padding-top: 2px;
  }
`;

const DEFAULT_PAGE_SIZE = 10;

export interface ITabTasksProps {
  procedure: ProcedimentoConTask | undefined;
}

export function TabTasks(props: ITabTasksProps) {
  const { procedure } = props;
  const isMobile = useBreakpoint(down('md'));
  const user = useCurrentUser();

  const {
    query,
    setQuery,
    tasks,
    meta: { total, pageSize, pageNumber },
    apiResult
  } = useTaskList(
    {
      firstResult: 0,
      maxResults: DEFAULT_PAGE_SIZE,
      sortBy: GetAttivitaSortBy.CREATED,
      sortDirection: GetAttivitaSortDirection.DESC,
      processInstanceId: procedure?.id
    },
    { defaultPageSize: DEFAULT_PAGE_SIZE }
  );
  return (
    <TabTaskWrapper>
      <TableData
        columns={
          isMobile
            ? columnsTasksProcedureDetailMobile
            : getColumnsTasksProcedureDetail(user)
        }
        showHeader={isMobile ? false : true}
        dataSource={tasks}
        loading={apiResult.isLoading || apiResult.isFetching}
        pagination={{
          pageSize: pageSize,
          current: pageNumber,
          total: total
        }}
        onChange={async (pagination, _, sorter) => {
          const { field, order } = sorter as SorterResult<AttivitaDTO>;

          const nextPageSize = pagination.pageSize ?? pageSize;

          const offset = pagination.current
            ? (pagination.current - 1) * nextPageSize
            : query.firstResult;
          const newQuery = {
            ...query,
            firstResult: offset,
            maxResults: nextPageSize,
            sortBy:
              order && field
                ? (field.toString().toUpperCase() as GetAttivitaSortBy)
                : GetAttivitaSortBy.CREATED,
            sortDirection:
              order === 'ascend'
                ? GetAttivitaSortDirection.ASC
                : GetAttivitaSortDirection.DESC
          };

          setQuery(await getAttivitaParamsValidator.validate(newQuery));
        }}
      />
    </TabTaskWrapper>
  );
}
