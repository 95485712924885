/**
 * Generato automaticamente.
 * Procedimenti
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  BEResponseObject,
  BEResponseString,
  NotificaRequestDTO,
  BEResponsePagedResponseNotificaResponseDTO,
  GetNotificheParams
} from '.././schemas';
import { backendApiInstance } from '../../../core/network/mutators/apiClient';
import type { ErrorType } from '../../../core/network/mutators/apiClient';

/**
 * Registrazione dell'avvenuta lettura della notifica
 * @summary Registrazione lettura notifica
 */
export const registraLetturaNotifica = (idNotifica: string) => {
  return backendApiInstance<BEResponseObject>({
    url: `/notifiche/${idNotifica}`,
    method: 'put'
  });
};

export type RegistraLetturaNotificaMutationResult = NonNullable<
  Awaited<ReturnType<typeof registraLetturaNotifica>>
>;

export type RegistraLetturaNotificaMutationError = ErrorType<unknown>;

export const useRegistraLetturaNotifica = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registraLetturaNotifica>>,
    TError,
    { idNotifica: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registraLetturaNotifica>>,
    { idNotifica: string }
  > = props => {
    const { idNotifica } = props ?? {};

    return registraLetturaNotifica(idNotifica);
  };

  return useMutation<
    Awaited<ReturnType<typeof registraLetturaNotifica>>,
    TError,
    { idNotifica: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Creazione notifica
 * @summary Crea Notifica
 */
export const creaNotifica = (notificaRequestDTO: NotificaRequestDTO) => {
  return backendApiInstance<BEResponseString>({
    url: `/notifiche/crea`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: notificaRequestDTO
  });
};

export type CreaNotificaMutationResult = NonNullable<
  Awaited<ReturnType<typeof creaNotifica>>
>;
export type CreaNotificaMutationBody = NotificaRequestDTO;
export type CreaNotificaMutationError = ErrorType<unknown>;

export const useCreaNotifica = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof creaNotifica>>,
    TError,
    { data: NotificaRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof creaNotifica>>,
    { data: NotificaRequestDTO }
  > = props => {
    const { data } = props ?? {};

    return creaNotifica(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof creaNotifica>>,
    TError,
    { data: NotificaRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Lista Notifiche
 * @summary Elenco Notifiche
 */
export const getNotifiche = (
  params?: GetNotificheParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponsePagedResponseNotificaResponseDTO>({
    url: `/notifiche`,
    method: 'get',
    params,
    signal
  });
};

export const getGetNotificheQueryKey = (params?: GetNotificheParams) => [
  `/notifiche`,
  ...(params ? [params] : [])
];

export type GetNotificheQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNotifiche>>
>;
export type GetNotificheQueryError = ErrorType<unknown>;

export const useGetNotifiche = <
  TData = Awaited<ReturnType<typeof getNotifiche>>,
  TError = ErrorType<unknown>
>(
  params?: GetNotificheParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getNotifiche>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotificheQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotifiche>>> = ({
    signal
  }) => getNotifiche(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getNotifiche>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
