import React from 'react';
import clx from 'classnames';
import { Layout, BackTop } from 'antd';
const { Content, Sider } = Layout;
import { PageLayoutHeader } from './header/PageLayoutHeader';
import styled from 'styled-components';
import { StaticFooter } from './StaticFooter';

export interface PageLayoutProps {
  children: React.ReactNode;
  hasTopBar?: boolean;
  className?: string;
}

const StyledContent = styled(Content)`
  // inseriamo -25px per il testo della versione in basso in fondo alla pagina
  min-height: calc(100vh - ${props => props.theme.layoutVersionFooterHeight});
  // calc(100vh - ${props => props.theme.layoutFooterHeight});
  &.has-top-bar {
    margin-top: ${props => props.theme.layoutHeaderHeight};
    min-height: calc(
      100vh - ${props => props.theme.layoutHeaderAndVersionFooterHeight}
    );
  }
`;

/**
 * Layout principale dell'applicativo.
 */
export function PageLayout(props: PageLayoutProps) {
  return (
    <Layout className={clx(props.className)}>
      {props.hasTopBar && <PageLayoutHeader />}
      <StyledContent className={clx({ 'has-top-bar': props.hasTopBar })}>
        {props.children}
      </StyledContent>
      <BackTop className="page-back-top" visibilityHeight={200} />
      <StaticFooter />
    </Layout>
  );
}
