import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { NameResponsive } from 'client/ui/tabs/NameToIconResponsive';
import React, { useEffect, useState } from 'react';
import { TbSettingsAutomation } from 'react-icons/tb';
import { AssignTaskMassiveMenuItem } from './actions/AssignTaskMassiveMenuItem';
import { AssignToOtherMassiveMenuItem } from './actions/AssignToOtherMassiveMenuItem';
import { CompleteTasksMassiveMenuItem } from './actions/complete-menu-item/CompleteTasksMassiveMenuItem';
import { ReleaseMassiveMenuItem } from './actions/ReleaseMassiveMenuItem';
import { MassiveRemoteSignMenuItem } from './actions/remote-sign-menu-item/MassiveRemoteSignMenuItem';
import { MassiveSignMenuItem } from './actions/sign-menu-item/MassiveSignMenuItem';

export interface ITaskMassiveActionBarProps {
  tasks: AttivitaDTO[] | undefined;
  selected: string[];
}

function getButtonText(selected: string[]) {
  if (selected.length === 0) return 'Azioni Massive';
  return `Azioni Massive (${selected.length})`;
}

export function TaskMassiveActionBar(props: ITaskMassiveActionBarProps) {
  const { tasks, selected } = props;
  const [loading, setLoading] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<AttivitaDTO[]>([]);

  useEffect(() => {
    const updated =
      tasks?.filter(t => selected.includes(t.idTaskInstance!)) ?? [];
    setSelectedTasks(updated);
  }, [tasks, selected]);

  const buttonText = getButtonText(selected);

  return (
    <Space>
      <Dropdown
        disabled={selected.length === 0}
        overlayStyle={{ zIndex: 990 }}
        overlay={
          <Menu disabled={loading}>
            <AssignTaskMassiveMenuItem
              tasks={selectedTasks}
              setLoading={setLoading}
            />
            <CompleteTasksMassiveMenuItem tasks={selectedTasks} />
            <AssignToOtherMassiveMenuItem taskIds={selected} tasks={tasks} />
            <ReleaseMassiveMenuItem
              tasks={selectedTasks}
              setLoading={setLoading}
            />
            <MassiveSignMenuItem
              tasks={selectedTasks}
              setLoading={setLoading}
            />
            <MassiveRemoteSignMenuItem tasks={selectedTasks} />
          </Menu>
        }
      >
        <Button>
          <NameResponsive name={buttonText} icon={<TbSettingsAutomation />} />{' '}
          <DownOutlined />
        </Button>
      </Dropdown>
    </Space>
  );
}
