import { UtenteUffici } from 'client/api/backend/schemas';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { useField } from 'formik';
import React, { useState } from 'react';
import { useCurrentUser } from '../AuthModule';

export function getOfficeLabel(office: UtenteUffici) {
  return `${office.codiceUfficio ?? ''} ${
    office.denominazioneUfficio ?? 'N.A.'
  }`;
}

export interface ILoginSelectRoleInputProps {}

export function LoginSelectRoleInput(props: ILoginSelectRoleInputProps) {
  const {} = props;
  const currentUser = useCurrentUser()!;
  const [selectedOffice, setSelectedOffice] = useState(null as string | null);
  const availableRoles = currentUser.utenteLoggato?.utenteUfficis?.find(
    u => u.idUfficio === selectedOffice
  )?.ruolos;

  const [, , roleFieldHelpers] = useField('codRuolo');

  return (
    <>
      <SelectInputItem
        size="large"
        name="codUfficio"
        placeholder="Ufficio"
        options={currentUser.utenteLoggato?.utenteUfficis?.map(office => ({
          className: 'full-text-options',
          label: getOfficeLabel(office),
          value: office.idUfficio!
        }))}
        onChange={(value: any) => {
          setSelectedOffice(value);
          roleFieldHelpers.setValue(undefined);
        }}
      />
      <SelectInputItem
        size="large"
        name="codRuolo"
        placeholder="Ruolo"
        disabled={selectedOffice == null}
        options={
          availableRoles?.map(role => ({
            className: 'full-text-options',
            label: `${role.denominazione}`,
            value: role.idRuolo
          })) ?? []
        }
      />
    </>
  );
}
