import * as yup from 'yup';
import moment from 'moment';

declare module 'yup' {
  interface DateSchema {
    dateOnlyFormat(): DateSchema;
  }
  // interface StringSchema {
  //   italianDate(): StringSchema;
  // }
}

const invalidDate = new Date('');

yup.addMethod(yup.date, 'dateOnlyFormat', function () {
  return this.transform(function (value: any, originalValue) {
    if (!this.isType(value) || value == null) return value;

    value = moment(originalValue, [
      'YYYY-MM-DD',
      'DD-MM-YYYY',
      'DD/MM/YYYY',
      'MMM DD YYYY hh:mmA',
      moment.ISO_8601
    ]) as moment.Moment;

    return value.isValid() ? value.toDate() : invalidDate;
  });
});
