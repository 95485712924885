import { AzioneDTO } from 'client/api/backend/schemas';

export function filterActions(
  actions: AzioneDTO[] | undefined,
  isAssigned: boolean | undefined,
  editActionList: any[]
) {
  if (!actions) return actions;
  if (isAssigned) return actions;
  return actions.filter(action => {
    if (!action.idAzione) return false;
    return !editActionList.includes(action.idAzione);
  });
}
