import * as React from 'react';
import clx from 'classnames';
import styled, { css } from 'styled-components';
import { IconWrapper } from '../../../../ui/icon/IconWrapper';
import { HiOutlineChatAlt } from 'react-icons/hi';

const NoteContenWrapper = styled.div<{ isCurrentUser?: boolean }>`
  display: inline-flex;

  background-color: #f5f5f5; // ${props => props.theme.borderColorSplit};
  border-radius: ${props => props.theme.borderRadiusBase};
  padding: 4px 8px 4px 4px;
  font-size: ${props => props.theme.fontSizeSm};
  word-wrap: break-word;
  ${props => {
    if (props.isCurrentUser) {
      return css`
        background-color: rgba(0, 102, 204, 0.12);
        .anticon {
          color: ${props => props.theme.primaryColor};
        }
      `;
    }
  }};
`;
const NoteContent = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
`;
const IconChat = styled(IconWrapper)`
  display: inline-flex;
  flex: 0 0 auto;
  min-width: 1px;
  font-size: 20px;
  margin-right: 4px;
`;

export interface HistoryItemNoteProps {
  isCurrentUser?: boolean;
  children?: React.ReactNode;
}
/**
 * Note Stilizzate con Avatar utente e box per le note
 * con versione alternata per evidenziare l'Utente Corrente
 */
export function HistoryItemNote(props: HistoryItemNoteProps) {
  if (!props.children) {
    return null;
  }
  return (
    <NoteContenWrapper isCurrentUser={props.isCurrentUser}>
      <IconChat icon={<HiOutlineChatAlt />} />
      <NoteContent>{props.children}</NoteContent>
    </NoteContenWrapper>
  );
}
