import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { TextInput } from 'client/ui/form/input/TextInput';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { IDeclarationFieldOptions } from '../DeclarationFieldSwitch';
import { ComputedFieldLogic } from '../parser/ComputedFieldLogic';

export interface IDeclarationFieldComputedProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

export function DeclarationFieldComputed(
  props: IDeclarationFieldComputedProps
) {
  const { field, options } = props;
  const prefix = options.namePrefix;
  const fieldName = prefix ? `${prefix}.${field.nome}` : field.nome;

  useFormikFieldChange(formik => {
    const value = ComputedFieldLogic.parseFormula(
      field.formula,
      field.computationDependency,
      prefix!,
      formik.values
    );

    if (value == null) {
      formik.setFieldValue(fieldName, undefined);
    } else {
      formik.setFieldValue(fieldName, value);
    }
    setTimeout(() => {
      formik.setFieldTouched(fieldName, true, true);
    }, 0);
  }, field.computationDependency?.map(dep => `${prefix}.${dep}`) ?? []);

  return (
    <FormFieldItem
      name={fieldName}
      label={field.des_campo}
      component={TextInput}
    />
  );
}
