import React from 'react';
import { Space, Tooltip } from 'antd';
import styled from 'styled-components';
import { ProcedureIcon } from 'client/components/schema/variables/icons/ProcedureIcon';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';
import { DefaultProcedureInfo } from 'client/components/schema/variables/colors/colorNames';

const Icon = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  background-color: ${props =>
    props.color ?? props.theme.procedureFallbackColor};
  font-size: 16px;
`;
const Text = styled.div`
  display: flex;
`;

export interface ProcedureTableTagProps {
  name?: string | React.ReactNode;
  color?: string;
  icon?: string;

  /*
   * Nasconde il titolo spostando il testo in un tooltip sull'icona.
   *
   * Di default il titolo viene nascosto per la versione TABLET (no mobile),
   * la proprietà sovrascrive questo comportamento
   *
   */
  compact?: boolean;
}
/*
 * Tag grafico con Icona e Nome della procedura
 */
export function ProcedureTableTag(props: ProcedureTableTagProps) {
  const { name, color, icon, compact } = props;
  // Nascondiamo il titolo della procedura spostandolo in un tooltip
  // per ridurre la dimensione della cella nelle tabelle versione TABLET
  // Se utilizzato in un altro contesto (es: Titolo pagina) è possibile
  // sostituire il comportamento utilizzando il parametro compact
  const isMobile = useBreakpoint(between('md', 'lg'));

  // Momentaneamente sospendo comportamento Responsive
  // Bisogna agire anche sull'intestazione della tabella
  // per ridurre la dimensione del testo (della cella)
  // const tooltips = compact ?? isMobile;
  const tooltips = compact;

  const procedureIcon = (
    <Icon color={color ?? DefaultProcedureInfo.Color}>
      <ProcedureIcon name={icon ?? DefaultProcedureInfo.Icon} />
    </Icon>
  );

  return (
    <Space align="center">
      {tooltips ? (
        <Tooltip title={name} placement="leftTop">
          {procedureIcon}
        </Tooltip>
      ) : (
        <>{procedureIcon}</>
      )}
      {!tooltips && <Text>{name}</Text>}
    </Space>
  );
}
