import { DownloadOutlined } from '@ant-design/icons';
import { appConfig } from 'client/core/appConfig';
import { joinPath } from 'client/core/network/joinPath';
import { apiClient } from 'client/core/network/mutators/apiClient';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import React from 'react';
import { HiDownload } from 'react-icons/hi';
import { Icon24px } from './FileRow';

export interface IDownloadFileIconButtonProps {
  fileId: string;
  filename: string;
  taskId?: string;
  /** Se fornito, richiama l'API di download relativa alle procedure */
  procedureId?: string;
}

export function DownloadFileIconButton(props: IDownloadFileIconButtonProps) {
  const { fileId, filename, procedureId, taskId } = props;

  const path = procedureId
    ? joinPath([
        appConfig.remotePath,
        appConfig.apiBackendPath,
        'procedimenti',
        procedureId,
        'fileAllegato',
        fileId
      ])
    : joinPath([
        appConfig.remotePath,
        appConfig.apiBackendPath,
        '/attivita/',
        taskId,
        `/allegati/`,
        fileId,
        '/contenutoBinario'
      ]);

  return (
    <a href={path} target="_blank">
      <Icon24px icon={<HiDownload />} />
    </a>
  );
}
