/**
 * Generato automaticamente.
 * Procedimenti
 */

export type GetNotificheSortDirection =
  typeof GetNotificheSortDirection[keyof typeof GetNotificheSortDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetNotificheSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC'
} as const;
