import * as React from 'react';
import clx from 'classnames';
import styled, { css } from 'styled-components';
import Tag from 'antd/lib/tag';

const UserInfoLineWrapper = styled.div<{ isHistory?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  color: ${props => props.theme.textColorSecondary};
  font-size: ${props => props.theme.fontSizeSm};
  transition: color 0.3s;
  ${props =>
    props.isHistory &&
    css`
      color: ${props => props.theme.textColorGray};
      font-size: ${props => props.theme.fontSizeBase};
    `}
`;
const UserName = styled.span`
  padding-right: 8px;
  font-weight: 600;
`;
const UserRole = styled.span``;

const ActionUserTag = styled(Tag)`
  height: 18px;
  line-height: 12px;
  padding: 2px 7px;
`;

export interface UserInfoLineProps {
  name: string;
  role?: string;
  action?: string;
  isHistory?: boolean;
}

export function UserInfoLine(props: UserInfoLineProps) {
  return (
    <UserInfoLineWrapper isHistory={props.isHistory}>
      <UserName>{props.name}</UserName>
      {props.role && <UserRole children={props.role} />}
      {props.action && <ActionUserTag children={props.action} />}
    </UserInfoLineWrapper>
  );
}
