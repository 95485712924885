import { Menu } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { IconAssignToOther } from 'client/ui/icon/ApplicationIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { UserLogic } from 'common/schema/user/UserLogic';
import React, { useState } from 'react';
import { AssignToOtherModal } from './AssignToOtherModal';

export interface IAssignToOtherMassiveMenuItemProps {
  taskIds: string[];
  tasks?: AttivitaDTO[];
}

export function AssignToOtherMassiveMenuItem(
  props: IAssignToOtherMassiveMenuItemProps
) {
  const { taskIds, tasks, ...menuProps } = props;
  const [visible, setVisible] = useState(false);
  const currentUser = useCurrentUser();

  if (!UserLogic.hasPermission(currentUser, 'assegna_task')) return null;

  return (
    <Menu.Item
      onClick={e => {
        setVisible(true);
      }}
      key="assignToOthers"
      {...menuProps}
    >
      <AssignToOtherModal
        taskId={taskIds}
        tasks={tasks}
        visible={visible}
        hide={() => {
          setVisible(false);
        }}
      />
      <IconWrapper icon={<IconAssignToOther />} /> Assegna
    </Menu.Item>
  );
}
