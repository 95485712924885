import { AttivitaDTO } from 'client/api/backend/schemas';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { IconView } from 'client/ui/icon/ApplicationIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import React from 'react';

export interface IViewTaskButtonProps {
  task: AttivitaDTO;
}

export function ViewTaskButton(props: IViewTaskButtonProps) {
  const { task } = props;
  return (
    <ButtonIcon
      icon={<IconWrapper icon={<IconView />} />}
      to={`/task/list/${task.idTaskInstance}`}
      tooltip="Visualizza"
      tooltipProps={{ placement: 'bottomRight' }}
    />
  );
}
