import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { appConfig } from 'client/core/appConfig';
import { authInterceptor } from '../interceptors/authInterceptor';
import { apiClientMockDevelopment } from './apiClientMockDevelopment';
import { joinPath } from '../joinPath';

export const apiClient = axios.create({
  baseURL: joinPath([appConfig.remotePath, appConfig.apiAuthPath]),
  withCredentials: true
});

export const backendApiClient = axios.create({
  baseURL: joinPath([appConfig.remotePath, appConfig.apiBackendPath]),
  withCredentials: true
});

authInterceptor(apiClient);
authInterceptor(backendApiClient);

if (process.env.NODE_ENV === 'development') {
  apiClientMockDevelopment(apiClient);
}

export const authApiInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  return apiClient.request(config).then(({ data }) => data);
};

export const authApiFullInstance = <T>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  return apiClient.request(config);
};

export const backendApiInstance = <T>(
  config: AxiosRequestConfig
): Promise<T> => {
  return backendApiClient
    .request({
      ...config
    })
    .then(({ data }) => data);
};

export type ErrorType<Error> = AxiosError<Error>;
