import React from 'react';
import styled from 'styled-components';

const TagFase = styled.span`
  display: inline-block;
  vertical-align: 5px;
  background-color: #00b798;
  padding: 4px 14px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #fff;
  line-height: 1;
`;

export interface ProcedurePhaseTagProps {
  phase?: string;
}
/*
 * // TODO
 */
export function ProcedurePhaseTag(props: ProcedurePhaseTagProps) {
  return <TagFase>{props.phase}</TagFase>;
}
