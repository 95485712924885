import { Button, Tooltip } from 'antd';
import { appConfig } from 'client/core/appConfig';
import { joinPath } from 'client/core/network/joinPath';
import QueryString from 'qs';
import React from 'react';

export interface IProcedureDocumentViewButtonProps {
  procedureId: string;
  fileId: string | undefined;
}

export function ProcedureDocumentViewButton(
  props: IProcedureDocumentViewButtonProps
) {
  const { procedureId, fileId } = props;

  if (!fileId)
    return (
      <Tooltip title={'Nessun documento caricato'}>
        <Button size="large" type="primary" disabled>
          Visualizza Atto
        </Button>
      </Tooltip>
    );

  return (
    <a
      target="_blank"
      href={joinPath([
        appConfig.remotePath,
        appConfig.apiBackendPath,
        '/procedimenti/' + procedureId + '/fileDocumento/' + fileId
      ])}
    >
      <Button size="large" type="primary">
        Visualizza Atto
      </Button>
    </a>
  );
}
