import { Switch, SwitchProps } from 'antd';
import { FormikValues, useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useFormikFormContext } from '../FormikFormContext';

export interface ISwitchInputProps<T extends FormikValues>
  extends Omit<SwitchProps, 'name'> {
  name: keyof T & string;
}

export function SwitchInput<T extends FormikValues>(
  props: ISwitchInputProps<T>
) {
  const { name } = props;
  const [field, meta] = useField<boolean>(name);
  const formik = useFormikContext<T>();
  const { disabled } = useFormikFormContext();
  const handleChange = useCallback(
    (value: boolean) => {
      formik.setFieldValue(name, value);
      setTimeout(() => {
        formik.setFieldTouched(name, true, true);
      }, 0);
    },
    [name, formik.setFieldTouched, formik.setFieldValue]
  );

  return (
    <Switch
      {...props}
      {...field}
      disabled={props.disabled || disabled}
      checked={field.value}
      onChange={handleChange}
    />
  );
}
