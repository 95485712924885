import * as React from 'react';
import { Card, Col, Row, Space, Tabs, Typography } from 'antd';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { colorNames } from 'client/components/schema/variables/colors/colorNames';
import styled, { css } from 'styled-components';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { TabsPageHeading } from 'client/ui/tabs/TabsPageHeading';
import { ProcedureButtonBox } from '../../home/procedure/detail/ProcedureButtonBox';
import { iconNames } from 'client/components/schema/variables/icons/iconNames';
import { useState } from 'react';
import { ProcedureIcon } from 'client/components/schema/variables/icons/ProcedureIcon';
const { TabPane } = Tabs;
const { Title, Text, Paragraph } = Typography;

const TitleRow = styled(Row)`
  min-width: 800px;
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`;

const BaseBox = styled.div`
  background-color: ${props => props.theme.procedureFallbackColor};
  width: 200px;
  height: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  flex: 0 0 200px;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
`;
const SelectedColorBox = styled(BaseBox)<{ selected?: boolean }>`
  ${({ selected }) =>
    selected &&
    css`
      border-radius: 100px;
    `}
`;
const BackColorBox = styled(SelectedColorBox)<{ color: string }>`
  background-color: ${({ color }) => color};
`;
const Icon = styled(BaseBox)`
  background-color: transparent;
  font-size: 48px;
  height: 48px;
  width: auto;
  flex: 0 0 48px;
  border-radius: 0;
`;

export interface ProcedureVariablesPageProps {}

export function ProcedureVariablesPage(props: ProcedureVariablesPageProps) {
  const [visibleTab, setVisibleTab] = useState('colors');
  const [selectedColor, setSelectedColor] = useState('MediumBlue');
  const [selectedIcon, setSelectedIcon] = useState('RiPenNibFill');

  return (
    <CorePageLayout>
      <PageHeading
        title={
          <TitleRow>
            <Col span={14}>
              <ProcedureButtonBox
                color={selectedColor}
                title="Procedure Name"
                size="middle"
                icon={<ProcedureIcon name={selectedIcon} />}
              />
            </Col>
          </TitleRow>
        }
        extra={
          <Card>
            <Space direction="vertical">
              <Title level={5} type="secondary">
                Colore: <Text copyable>{selectedColor}</Text>
              </Title>
              <Title level={5} type="secondary">
                Icona: <Text copyable>{selectedIcon}</Text>
              </Title>
            </Space>
          </Card>
        }
        footer={
          <TabsPageHeading
            defaultActiveKey={visibleTab ?? 'colors'}
            onChange={key => setVisibleTab(key)}
          >
            <TabPane tab="Colori" key="colors" />
            <TabPane tab="Icone" key="icons" />
          </TabsPageHeading>
        }
      />
      {/* PageContent */}
      <PageContent>
        <Card bordered={false}>
          {visibleTab == 'colors' && (
            <Container>
              {colorNames.map(color => (
                <BackColorBox
                  selected={selectedColor == color}
                  color={color.toLowerCase()}
                  key={color}
                  onClick={() => setSelectedColor(color)}
                >
                  {color}
                </BackColorBox>
              ))}
            </Container>
          )}
          {visibleTab == 'icons' && (
            <Container>
              {iconNames.map(icon => (
                <BackColorBox
                  selected={selectedIcon == icon.name}
                  color={selectedColor}
                  key={icon.name}
                  onClick={() => setSelectedIcon(icon.name)}
                >
                  <Icon>{icon.icon}</Icon>
                  {icon.name}
                </BackColorBox>
              ))}
            </Container>
          )}
        </Card>
      </PageContent>
    </CorePageLayout>
  );
}
