import { logger } from 'client/core/logger/logger';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import {
  DeclarationFieldCheckType,
  DeclarationFieldDto
} from 'common/dto/declaration/DeclarationFieldDto';
import { assertNever } from 'common/utils/assertNever';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import { IDeclarationFieldOptions } from '../DeclarationFieldSwitch';
import { DeclarationFieldComputed } from './DeclarationFieldComputed';

export interface IDeclarationFieldInputProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

export function DeclarationFieldInput(props: IDeclarationFieldInputProps) {
  const { field, options } = props;

  const prefix = options.namePrefix;

  const fieldName = prefix ? `${prefix}.${field.nome}` : field.nome;

  if (field.tp_controllo === DeclarationFieldCheckType.Date) {
    return (
      <FormFieldItem
        label={field.des_campo}
        name={fieldName}
        component={DatePickerInput}
        placeholder={field.placeholder}
        type="date-only"
      />
    );
  }

  return (
    <FormFieldItem
      name={fieldName}
      label={field.des_campo}
      placeholder={field.placeholder}
      component={TextInput}
    />
  );
}

// TODO
export function createDeclarationSchemaInput(field: DeclarationFieldDto) {
  if (field.tp_controllo == null) {
    // logger.warn(`[createDeclarationSchemaInput] Tipologia campo null per "${field.nome}"`); // prettier-ignore
    return yup.string();
  }
  switch (field.tp_controllo) {
    case DeclarationFieldCheckType.Computed:
      return yup.string();
    case DeclarationFieldCheckType.Date:
      return yup.date();
    case DeclarationFieldCheckType.Email:
      return yup.string().email();
    case DeclarationFieldCheckType.FiscalId:
      return yup.string().fiscalNumber();
    case DeclarationFieldCheckType.IVA:
      return yup.string().vatNumber();
    case DeclarationFieldCheckType.RegEx:
      return yup
        .string()
        .test('regex-test', '${path} non è nel formato corretto', input => {
          if (!field.pattern || !input) return true;
          return new RegExp(field.pattern).test(input);
        });
    case DeclarationFieldCheckType.Text:
      return yup.string();
    default:
      logger.error(`[DeclarationFieldInput] "${field.tp_controllo}" non riconosciuto.`); // prettier-ignore
      assertNever(field.tp_controllo);
  }
}
