import { AttivitaDTO } from 'client/api/backend/schemas';
import { JnlpService } from 'client/components/schema/task/jnlp/JnlpService';
import React from 'react';
import { MassiveFastSignMenuItem } from './MassiveFastSignMenuItem';
import { MassiveStandardSignMenuItem } from './MassiveStandardSignMenuItem';

export interface IMassiveSignMenuItemProps {
  tasks: AttivitaDTO[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MassiveSignMenuItem(props: IMassiveSignMenuItemProps) {
  // TODO QTEST
  if (JnlpService.isStandardJnlp()) {
    return <MassiveStandardSignMenuItem {...props} />;
  } else {
    return <MassiveFastSignMenuItem {...props} />;
  }
}
