import { Tag } from 'antd';
import { AttivitaDTOStato } from 'client/api/backend/schemas';
import { assertNever } from 'common/utils/assertNever';
import React from 'react';

export interface ITaskStateTagProps {
  state: AttivitaDTOStato | undefined;
}

export function TaskStateTag(props: ITaskStateTagProps) {
  if (!props.state) {
    return <Tag>Non definito</Tag>;
  }
  const options = getTaskStateTagOptions(props.state);
  return <Tag color={options.color}>{options.text}</Tag>;
}

/**
 * Ritorna le impostazioni per il tag di stato dell'attività
 */
function getTaskStateTagOptions(state: AttivitaDTOStato) {
  switch (state) {
    case AttivitaDTOStato.IN_CORSO:
      return {
        color: 'geekblue',
        text: 'In corso'
      };
    case AttivitaDTOStato.PENDENTE:
      return {
        color: 'orange',
        text: 'Pendente'
      };
  }
  assertNever(state);
}
