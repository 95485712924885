import React from 'react';
import styled from 'styled-components';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';

const RowProcedure = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
`;

export interface ITaskRowProcedureProps {
  children?: React.ReactNode;
}
/**
 * Container per visualizzazione elementi su una riga per Mobile
 * Il nome della procedura e lo stato dell'attività
 *
 */
export function TaskRowProcedure(props: ITaskRowProcedureProps) {
  return <RowProcedure>{props.children}</RowProcedure>;
}
