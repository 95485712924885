import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuButton } from './main-button/MenuButton';
import { Dropdown, Menu, Space } from 'antd';
import { useCurrentMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { LinkButton } from 'client/ui/link/LinkButton';
import { Link } from 'react-router-dom';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { HiMenu } from 'react-icons/hi';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { MenuButtonIcon } from './main-button/MenuButtonIcon';
import { getMainMenuItems } from './main-button/getMainMenuItems';

export interface MainMenuMobileProps {}

export function MainMenuMobile(props: MainMenuMobileProps) {
  const currentPathKey = useCurrentMenuKey()[0];

  const items = getMainMenuItems();

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu
          selectable
          defaultSelectedKeys={[currentPathKey]}
          items={items.map(item => ({
            key: item.menuKey,
            label: <Link to={item.to}>{item.children}</Link>
          }))}
        />
      }
    >
      <MenuButtonIcon icon={<IconWrapper icon={<HiMenu />} />} />
    </Dropdown>
  );
}
