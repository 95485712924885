import {
  ProviderInternalDynamicFieldType,
  ProviderParameters
} from './ProviderParameters';

/**
 * Lista dei nomi dei campi di tipo OTP
 */
export function getAllOtpFieldsNames(
  provider: ProviderParameters | null | undefined
) {
  const fromCertificate =
    provider?.certificateParameters
      .filter(p => p.tipo === ProviderInternalDynamicFieldType.Otp)
      .map(p => p.nome) ?? [];

  const fromOtp =
    provider?.otpParameters
      .filter(p => p.tipo === ProviderInternalDynamicFieldType.Otp)
      .map(p => p.nome) ?? [];

  const fromRemoteSign =
    provider?.remoteSignParameters
      .filter(p => p.tipo === ProviderInternalDynamicFieldType.Otp)
      .map(p => p.nome) ?? [];

  return [...fromCertificate, ...fromOtp, ...fromRemoteSign];
}
