import {
  DeclarationFieldDto,
  DeclarationFieldType
} from 'common/dto/declaration/DeclarationFieldDto';
import { DeclarationFieldTreeNode } from './getDeclarationFieldsTree';

/**
 * Trasformiamo i campi ripetuti introducendo un campo dichiarazione di tipo
 * "repatable" che contiene un array di campi.
 */
export function normalizeRepeatableFields(tree: DeclarationFieldTreeNode[]) {
  let fields: DeclarationFieldDto[] = [];

  let repeateable: DeclarationFieldDto | null = null;

  for (const node of tree) {
    let field = node.field as DeclarationFieldDto;
    let children = normalizeRepeatableFields(node.children);

    // Saltiamo le definizioni delle tabelle perché non sono rilevanti.
    if (
      field.tipo === DeclarationFieldType.TableHeaders ||
      field.tipo === DeclarationFieldType.TableBody
    ) {
      continue;
    }

    if (field.duplicaTabella) {
      field.subtype = 'repeatable';
    }

    if (field.tp_riga === '1') {
      // Se il campo è ripetibile, e non siamo già in gruppo, creiamo il
      // gruppo stesso.
      if (!repeateable) {
        repeateable = {
          ...field,
          nome: `repeateable_${field.nome}`,
          subtype: 'repeatable',
          subfields: []
        };

        // Aggiungiamo il gruppo all'elenco dei campi
        fields.push(repeateable);
      }

      // Se la validazione del campo `r1` dipende dal campo `test1`, dato che
      // lo inseriamo dentro `repeatable_r1`, la validazione sarebbe su
      // `repeatable_r1.test1` che ovviamente non esiste.
      // Dato che però questa validazione è ridondante (se ce l'ha il campo ripetibile
      // "padre" di `r1`, non c'è bisogno che ce l'abbia anche `r1` stesso)
      // la eliminiamo.
      if (
        field.campo_collegato === repeateable.campo_collegato &&
        field.val_campo_collegato === repeateable.val_campo_collegato
      ) {
        field.campo_collegato = null;
      }

      field.indentation = 0;

      // Aggiungiamo il campo al gruppo
      repeateable.subfields!.push(field, ...children);
    } else {
      fields.push(field, ...children);
      // Abbiamo terminato la ripetibilità del gruppo
      repeateable = null;
    }
  }

  return fields;
}
