import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import React, { useMemo } from 'react';
import { useRemoteSignContext } from '../../../RemoteSignContext';
import {
  IProviderDynamicFieldsKey,
  useRemoteSignDynamicValues
} from '../../hooks/useRemoteSignDynamicValues';
import { RemoteSignDynamicFieldSwitch } from './RemoteSignDynamicFieldSwitch';
import styled from 'styled-components';

const DinamicWrapper = styled.div`
  background-color: ${props => props.theme.bodyBackground};
  border-radius: 8px;
  padding: 12px 18px;
  padding-bottom: 0;
  /* margin: 0 -12px 24px -12px; */
  margin: 0 0 24px 0;
  .form-field-item .ant-form-item-label > label {
    border-radius: 4px;
  }
`;

export interface IRemoteSignDynamicFormProps {
  afterSubmit: (values: any) => Promise<void>;
  paramsKey: IProviderDynamicFieldsKey;
  textButton: string;
}

/**
 * Form dinamico con i campi forniti dal provider di autenticazione.
 */
export function RemoteSignDynamicForm(
  props: Readonly<IRemoteSignDynamicFormProps>
) {
  const { afterSubmit, paramsKey, textButton } = props;
  const {
    state: { provider, dynamicValues }
  } = useRemoteSignContext();
  const { fields: dynamicFields, schema } = useRemoteSignDynamicValues(
    provider,
    paramsKey
  );

  const intialValue = useMemo(() => {
    return schema.cast({ ...dynamicValues, otp: null }, { assert: false });
  }, [schema]);

  if (!provider) return null;

  return (
    <FormikAugmented
      validationSchema={schema}
      initialValues={intialValue}
      onSubmit={async values => {
        const updatedValues = { ...dynamicValues, ...values };
        await afterSubmit(updatedValues);
      }}
    >
      <FormikForm
        layout="vertical"
        showVisualFeedback
        editable={true}
        nested
        disableOnSubmit
      >
        <DinamicWrapper>
          {dynamicFields.map(field => (
            <RemoteSignDynamicFieldSwitch field={field} key={field.nome} />
          ))}
        </DinamicWrapper>

        <FormikSendButton block type="primary" size="large">
          {textButton}
        </FormikSendButton>
      </FormikForm>
    </FormikAugmented>
  );
}
