import * as React from 'react';
import clx from 'classnames';
import { Avatar } from 'antd';

import styled, { css } from 'styled-components';
import { getInitials } from '../../../../ui/avatar/getInitials';

const NoteWrapper = styled.div`
  display: flex;
  padding: 16px 0;
`;
const NoteAvatar = styled.div<{ isCurrentUser?: boolean }>`
  flex-shrink: 0;
  order: 2;

  .ant-avatar {
    background-color: ${props => props.theme.textColorSecondary};
    ${props =>
      props.isCurrentUser &&
      css`
        background-color: ${props => props.theme.primaryColor};
      `}
  }
`;
const NoteContent = styled.div<{ isCurrentUser?: boolean }>`
  background-color: #f5f5f5; // ${props => props.theme.borderColorSplit};
  border-radius: ${props => props.theme.borderRadiusBase};
  margin-left: 12px;
  padding: 16px;
  flex: 1 1 auto;
  order: 3;
  min-width: 1px;
  font-size: ${props => props.theme.fontSizeBase};
  word-wrap: break-word;
  ${props =>
    props.isCurrentUser &&
    css`
      background-color: rgba(0, 102, 204, 0.12);
      order: 1;
      margin-left: 0;
      margin-right: 12px;
    `}
`;

const Content = styled.div<{ hasAfter?: boolean }>`
  ${props =>
    props.hasAfter &&
    css`
      margin-bottom: 8px;
    `}
`;

export interface NoteProps {
  name?: string | null;
  isCurrentUser?: boolean;
  beforeContent: React.ReactNode;
  children: React.ReactNode;
  afterContent: React.ReactNode;
}
/**
 * Note Stilizzate con Avatar utente e box per le note
 * con versione alternata per evidenziare l'Utente Corrente
 */
export function Note(props: NoteProps) {
  return (
    <NoteWrapper>
      <NoteAvatar
        isCurrentUser={props.isCurrentUser}
        children={<Avatar children={getInitials(props.name)} />}
      />
      <NoteContent isCurrentUser={props.isCurrentUser}>
        {props.beforeContent}
        <Content
          hasAfter={props.afterContent ? true : false}
          children={props.children}
        />
        {props.afterContent}
      </NoteContent>
    </NoteWrapper>
  );
}
