import { Dichiarazione } from 'client/api/backend/schemas';
import {
  DeclarationFieldCheckType,
  DeclarationFieldDto,
  DeclarationFieldType
} from 'common/dto/declaration/DeclarationFieldDto';
import { FormikValues } from 'formik';
import {
  DeclarationJsonValuesSchema,
  String,
  Number,
  Boolean,
  Date,
  Datetime,
  Repeatable
} from './DeclarationJsonValuesSchema';

type DeclarationJsonValue =
  | String
  | Number
  | Boolean
  | Date
  | Datetime
  | Repeatable;

/**
 * Converte un oggetto in un insieme chiave-valore
 */
function getValues(
  fields: DeclarationFieldDto[],
  formValues: any
): DeclarationJsonValue[] {
  if (!formValues) return [];
  return Object.entries(formValues)
    .filter(([key, value]) => value != null)
    .map(([key, value]) => {
      return {
        name: key,
        ...getValue(
          fields.find(f => f.nome === key),
          value
        )
      } as DeclarationJsonValue;
    });
}

// TODO Al momento questa funzione è completamente scollegata dal validatore.
function getValue(
  field: DeclarationFieldDto | undefined,
  value: any
): Omit<DeclarationJsonValue, 'name'> {
  if (Array.isArray(value)) {
    return {
      type: 'array',
      items: value.map((v: any) => ({
        type: 'object',
        values: getValues(field?.subfields ?? [], v)
      }))
    } as Repeatable;
  }

  if (
    field &&
    (field.tipo === DeclarationFieldType.Date ||
      field.tp_controllo === DeclarationFieldCheckType.Date)
  ) {
    return {
      type: 'date',
      value
    };
  }

  return {
    type: typeof value,
    value
  };
}

function parseValue(value: DeclarationJsonValue): any {
  if (value?.type === 'array') {
    return [
      value.name,
      value.items.map(v =>
        v ? Object.fromEntries(v.values.map(parseValue)) : {}
      )
    ];
  }

  return [value.name, value.value];
}

/**
 * Effettua la conversione fra i dati del form ottenuti da formik e il json
 * della dichiarazione secondo lo schema condiviso.
 */
export class DeclarationConvert {
  /**
   * Conversione dai dati di Formik ai dati in JSON Schema
   */
  // TODO Supportare array ecc.
  static to(
    declaration: Dichiarazione,
    formValues: FormikValues
  ): DeclarationJsonValuesSchema {
    if (!formValues) {
      return {
        version: process.env.VERSION,
        values: []
      } as any;
    }

    const fields = declaration.jsonCampi as DeclarationFieldDto[];

    const values = getValues(fields, formValues);

    return {
      version: process.env.VERSION,
      values
    } as any;
  }

  /**
   * Conversione dai dati salvati come JSON Schema ai dati in Formik
   */
  static from(
    declaration: Dichiarazione,
    jsonValues: DeclarationJsonValuesSchema | null
  ): FormikValues {
    if (!jsonValues || !jsonValues.values) return {};

    const values = Object.fromEntries(jsonValues.values.map(parseValue));
    return values;
  }
}
