import { XMLParser } from 'fast-xml-parser';

const parser = new XMLParser({
  allowBooleanAttributes: true,
  ignorePiTags: true,
  ignoreDeclaration: true,
  ignoreAttributes: true
});

export const xmlParser = {
  parse: (xml: string) => parser.parse(xml)
};
