import React from 'react';
import { Form } from 'antd';

import { useRemoteSignContext } from '../RemoteSignContext';
import { AsyncProviderSelect } from './components/AsyncProviderSelect';
import { RemoteSignCertificateListForm } from './components/dynamic/forms/RemoteSignCertificateListForm';
import { RemoteSignForm } from './components/dynamic/forms/RemoteSignForm';
import { RemoteSignTypeSelect } from './components/RemoteSignTypeSelect';

export interface IRemoteSignProviderStepProps {
  handleSignSubmit: (values: any) => Promise<void>;
}

/**
 * Form di scelta del provider di autenticazione da parte dell'utente
 */
export function RemoteSignProviderStep(
  props: Readonly<IRemoteSignProviderStepProps>
) {
  const { handleSignSubmit } = props;

  const {
    state: { provider }
  } = useRemoteSignContext();

  return (
    <div>
      <Form component="div" layout="vertical">
        <div style={{ marginBottom: 34 }}>
          <AsyncProviderSelect />
        </div>
        <div style={{ marginBottom: 24 }}>
          <RemoteSignTypeSelect />
        </div>
      </Form>
      <div>
        {/*  Se è impostato il multicertificato, devo compilare il form per richiederne la lista. Altrimenti posso firmare direttamente */}
        {provider?.multiCertificate ? (
          <RemoteSignCertificateListForm provider={provider} />
        ) : (
          <RemoteSignForm handleSubmit={handleSignSubmit} />
        )}
      </div>
    </div>
  );
}
