import React from 'react';
import styled from 'styled-components';
import { Space, Typography } from 'antd';
const { Title, Paragraph } = Typography;
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { HomeTaskListTable } from './task/HomeTaskListTable';
import { TableCard } from 'client/ui/card/TableCard';
import { ProcedureHomeCard } from 'client/routes/home/procedure/ProcedureHomeCard';
import { LinkButton } from 'client/ui/link/LinkButton';

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

export interface HomePageProps extends RouteComponentProps {}

/**
 * Pagina Home.
 */
export function HomePage(props: HomePageProps) {
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Elenco Attività'
  });

  return (
    <CorePageLayout>
      <PageContent>
        <FullWidthSpace direction="vertical" size={24}>
          <ProcedureHomeCard />
          <TableCard
            title={<Title level={2}>Attività</Title>}
            extra={
              <LinkButton size="large" type="primary" to="/task/list">
                Mostra Tutte
              </LinkButton>
            }
          >
            <HomeTaskListTable />
          </TableCard>
        </FullWidthSpace>
      </PageContent>
    </CorePageLayout>
  );
}
