import * as React from 'react';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { Alert, Button, Space, Tabs } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { TabsPageHeading } from 'client/ui/tabs/TabsPageHeading';
import { ProcedureListTable } from './table/ProcedureListTable';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { SearchCollapseContainer } from 'client/ui/collapse/SearchCollapseContainer';
import { SearchOutlined } from '@ant-design/icons';
import { ProcedureSearchForm } from './search/ProcedureSearchForm';
import { SearchTitleTabs } from 'client/ui/tabs/SearchTitleTabs';
import { useState } from 'react';
import {
  GetProcedimentiSortBy,
  GetProcedimentiSortDirection
} from 'client/api/backend/schemas';
import { useProcedureList } from './useProcedureList';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { getProcedimentiParamsValidator } from '../validators/getProcedimentiParamsValidator';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { NameResponsive } from 'client/ui/tabs/NameToIconResponsive';
import { TbSearch } from 'react-icons/tb';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

const { TabPane } = Tabs;

export interface ProcedureListPageProps extends RouteComponentProps {}

export function ProcedureListPage(props: ProcedureListPageProps) {
  const defaultQuery = {
    page: 1,
    sortBy: GetProcedimentiSortBy.DATA_ULTIMA_MODIFICA,
    sortDirection: GetProcedimentiSortDirection.DESC
  };

  const {
    procedures,
    query,
    setQuery,
    meta: { total },
    apiResult,
    isFiltered
  } = useProcedureList(defaultQuery);

  const [searchCollapseOpened, setSearchCollapseOpened] = useState(isFiltered);
  const [searchActive, setSearchActive] = useState(isFiltered);
  const isMobile = useBreakpoint(down('md'));

  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Elenco Procedimenti'
  });

  const toggleOpenSearchCollapse = () => {
    setSearchCollapseOpened(!searchCollapseOpened);
  };

  return (
    <CorePageLayout>
      <PageHeading
        breadcrumb={<BreadcrumbsContainer />}
        title={'Elenco Procedimenti'}
        footer={
          <TabsPageHeading
            onTabClick={key => {
              if (key === 'search') {
                toggleOpenSearchCollapse();
              }
              if (searchCollapseOpened) {
                setSearchCollapseOpened(false);
              }
            }}
          >
            <TabPane
              tab={
                <SearchTitleTabs
                  opened={searchCollapseOpened}
                  filtered={searchActive}
                  children={
                    <NameResponsive name="Ricerca Estesa" icon={<TbSearch />} />
                  }
                />
              }
              key="search"
            />
          </TabsPageHeading>
        }
      />
      <PageContent noPadding>
        <FormikAugmented
          enableReinitialize
          initialValues={query}
          validationSchema={getProcedimentiParamsValidator}
          onSubmit={async rawValues => {
            const values = await getProcedimentiParamsValidator.validate(
              rawValues
            );
            setQuery(values);
            setSearchActive(true);
          }}
          validationContext={{}}
        >
          <SearchCollapseContainer
            opened={searchCollapseOpened}
            isMobile={isMobile}
            button={
              <Space direction={isMobile ? 'horizontal' : 'vertical'}>
                <Button
                  block
                  onClick={() => {
                    setQuery(defaultQuery);
                    setSearchActive(false);
                  }}
                >
                  Pulisci
                </Button>
                <FormikSendButton
                  block
                  type="primary"
                  icon={<SearchOutlined />}
                  children="Cerca"
                />
              </Space>
            }
          >
            <ProcedureSearchForm />
          </SearchCollapseContainer>
        </FormikAugmented>
        {apiResult.isError && (
          <Alert
            type="error"
            showIcon
            style={{ marginBottom: '24px' }}
            message={getNetworkErrorMessage(
              apiResult.error,
              "Impossibile caricare l'elenco dei procedimenti."
            )}
          />
        )}
        {/* Tabella Elenco Procedimenti */}
        <ProcedureListTable
          data={procedures}
          loading={apiResult.isLoading || apiResult.isFetching}
          total={total}
          query={query}
          setQuery={setQuery}
        />
      </PageContent>
    </CorePageLayout>
  );
}
