import { Menu, message } from 'antd';
import { useAssegnazioneMassiva } from 'client/api/backend/attivita/attivita';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { logger } from 'client/core/logger/logger';
import { TaskActionLogic, TaskActions } from 'client/logic/TaskActionLogic';
import { IconAssignToMe } from 'client/ui/icon/ApplicationIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { UserLogic } from 'common/schema/user/UserLogic';
import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

export interface IAssignTaskMassiveMenuItemProps {
  tasks: AttivitaDTO[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AssignTaskMassiveMenuItem(
  props: IAssignTaskMassiveMenuItemProps
) {
  const { tasks, setLoading, ...menuProps } = props;

  const queryClient = useQueryClient();
  const assignFunction = useAssegnazioneMassiva();
  const user = useCurrentUser();

  const LOADING_MESSAGE_KEY = 'assign-task-massive-menu';

  // Se non ha il permesso di assegnarsi la task, disabilito l'azione
  const disabled = tasks.some(
    task =>
      !UserLogic.hasPermission(user, task.abilitazioneLavorazione) ||
      TaskActionLogic.getAction(task, user) !== TaskActions.Assign
  );

  const handleAssign = useCallback(async () => {
    if (tasks.some(task => !task.idTaskInstance)) return;
    try {
      setLoading(true);
      message.loading({ content: 'Presa in carico in corso...', key: LOADING_MESSAGE_KEY }); // prettier-ignore
      await assignFunction.mutateAsync({
        data: {
          idTaskInstanceList: tasks.map(t => t.idTaskInstance!)
        }
      });
      await queryClient.invalidateQueries();
      message.success('Attività prese in carico');
    } catch (e) {
      logger.error({ error: e });
      message.error(`Si è verificato un errore durante l'assegnazione. Riprovare...`); // prettier-ignore
    } finally {
      message.destroy(LOADING_MESSAGE_KEY);
      setLoading(false);
    }
  }, [tasks, queryClient]);

  return (
    <Menu.Item
      disabled={disabled}
      key="assign"
      onClick={handleAssign}
      {...menuProps}
    >
      <IconWrapper icon={<IconAssignToMe />} /> Prendi in carico
    </Menu.Item>
  );
}
