import * as React from 'react';
import { Timeline, TimelineProps } from 'antd';
import styled from 'styled-components';

const TimelineWrapper = styled.div`
  padding-top: 15px;
`;

export interface HistoryItemsContainerProps extends TimelineProps {
  children: React.ReactNode;
}
// mode="left"
export function HistoryItemsContainer(props: HistoryItemsContainerProps) {
  const { children, mode, ...rest } = props;
  return (
    <TimelineWrapper>
      <Timeline {...rest}>{children}</Timeline>
    </TimelineWrapper>
  );
}
