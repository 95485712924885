/**
 * Crea un errore statico (a livello di check-types) per garantire
 * che non venga mai raggiunto il punto in cui è chiamato.
 *
 * Da utilizzare per gli switch o combinazioni di if per assicurarsi che
 * siano esaustivi.
 */
export function assertNever(value: never, error?: Error): never {
  if (error) {
    throw error;
  }
  throw new Error(
    `Unhandled discriminated union member: ${JSON.stringify(value)}`
  );
}
