import { Method } from 'axios';
import { BEResponse } from 'client/api/backend/schemas';
import { backendApiClient } from 'client/core/network/mutators/apiClient';
import { pick } from 'lodash';
import { useCallback } from 'react';
import { ProviderInternalDynamicField } from '../../provider-parameters/ProviderParameters';

/**
 * Hook che ritorna la funzione per gestire
 * le chiamatate alle API di firma remota,
 * che richiedono i campi nell'header.
 */
export function useRemoteSignApi<T extends BEResponse>(params: {
  method: Method;
  fields: ProviderInternalDynamicField[];
  apiUrl: string;
  skip?: boolean;
  body?: any;
}) {
  const { method, fields, apiUrl, skip, body } = params;

  const apiFn = useCallback(
    async (dynamicParams: { [key: string]: string }) => {
      if (skip) return null;

      const requestInput = pick(
        dynamicParams,
        fields.map(p => p.nome)
      );

      const { data } = await backendApiClient.request<T>({
        url: apiUrl,
        method,
        headers: {
          keyValueMap: Object.keys(requestInput)
            .flatMap(key => [key, requestInput[key]])
            .join(',')
        },
        data: body
      });

      if (data.esito?.errore) {
        throw new Error(data.esito.errore.messaggioDiErrore); // prettier-ignore
      }
      return data;
    },
    [method, fields, backendApiClient, apiUrl, skip, body]
  );

  return apiFn;
}
