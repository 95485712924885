import { Alert } from 'antd';
import {
  getGetProcedimentiQueryKey,
  useGetTipiProcedimento
} from 'client/api/backend/procedimenti/procedimenti';
import { TipologiaProcedimentoDTO } from 'client/api/backend/schemas';
import { logger } from 'client/core/logger/logger';
import { backendApiClient } from 'client/core/network/mutators/apiClient';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import React from 'react';

function mapOptions(procedures: TipologiaProcedimentoDTO[] | undefined) {
  if (!procedures) return [];
  return procedures
    .map(procedure => ({
      value: procedure.id!,
      label: procedure.nomeTipo!
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export interface IProcedureAsyncSelectInputProps {
  disabled?: boolean;
  name: string;
  placement?: 'bottomRight' | undefined;
}

export function ProcedureAsyncSelectInput(
  props: IProcedureAsyncSelectInputProps
) {
  const { disabled, name } = props;

  const procedures = useGetTipiProcedimento();

  if (procedures.isError) {
    logger.error(`[ProcedureAsyncSelectInput] Error: ${procedures.error}`);
    return (
      <Alert
        message="Error"
        description="Impossibile recuperare la lista dei procedimenti"
        showIcon
        type="error"
      />
    );
  }

  return (
    <FormFieldItem
      size="middle"
      component={SelectInput}
      name={name}
      label="Procedimento"
      placeholder="Procedimento..."
      dropdownMatchSelectWidth={false}
      placement={props.placement}
      allowClear
      loading={procedures.isLoading}
      options={mapOptions(procedures.data?.data)}
      disabled={disabled}
      showSearch
      filterOption={(input: string, option: any) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
    />
  );
}
