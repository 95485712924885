import { DownloadOutlined } from '@ant-design/icons';
import { Alert, Button, Typography } from 'antd';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import React from 'react';
import { jnlpPoolingStatus } from './JnlpSubject';
import { IWebSocketJnlpManager } from './JnlpService';

const { Paragraph, Text } = Typography;

export interface IWebSocketSectionJnlpModalProps {
  visible: boolean;
  fastJnlpManager: IWebSocketJnlpManager;
  hide: () => void;
}

export function WebSocketSectionJnlpModal(
  props: IWebSocketSectionJnlpModalProps
) {
  const { fastJnlpManager, visible, hide } = props;

  const isHttps = location.protocol === 'https:';

  return (
    <ModalDialog
      title={'Modifica Sezione'}
      visible={visible}
      size="small"
      onCancel={() => {
        hide();
        jnlpPoolingStatus({ type: 'end' });
      }}
      maskClosable={false}
      footer={null}
    >
      <Paragraph>
        Il processo di modifica del documento è stato avviato. Il sistema
        avvierà automaticamente il software di modifica. Questa finestra si
        chiuderà in automatico al termine del processo.
      </Paragraph>
      {fastJnlpManager.awaitConnection && (
        <>
          <Paragraph>
            Sembra che <Text code>{fastJnlpManager.filename}</Text> non sia
            ancora stato avviato. Per permettere la modifica della sezione, è
            necessario avviare il file
            <Text code>{fastJnlpManager.filename}</Text> scaricato.
          </Paragraph>
          <Paragraph>
            In attesa della connessione al software di modifica...
          </Paragraph>
          {fastJnlpManager.httpsCertificateUrl && isHttps && (
            <Alert
              type="warning"
              showIcon
              description={
                <>
                  Se è la prima volta che utilizzi questa funzionalità, una
                  volta avviato l’applicativo è necessario accettare il
                  certificato di sicurezza navigando su{' '}
                  <a href={fastJnlpManager.httpsCertificateUrl} target="_blank">
                    QUESTO LINK
                  </a>
                  . Una volta aperta la pagina accettare il certificato per
                  proseguire.
                </>
              }
            />
          )}
        </>
      )}

      {fastJnlpManager.needRetry && fastJnlpManager.objectUrl && (
        <Alert
          message="Se si è verificato un problema con il download, è possibile riscaricare il file."
          type="info"
          action={
            <a
              href={fastJnlpManager.objectUrl}
              target="_blank"
              title="Avvia il download"
              download={fastJnlpManager.filename}
            >
              <Button
                icon={<DownloadOutlined />}
                children="Avvia il download"
              />
            </a>
          }
        />
      )}
      {fastJnlpManager.needRetry && (
        <Alert
          message="Si è verificato un errore durante il processo di modifica. Riprovare."
          type="warning"
          action={
            <Button
              type="primary"
              onClick={() => fastJnlpManager.jnlpProcess()}
              loading={fastJnlpManager.loading}
            >
              Modifica Sezione
            </Button>
          }
        />
      )}
    </ModalDialog>
  );
}
