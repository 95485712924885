import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { Documento } from 'client/api/backend/schemas';
import { DocumentLogic } from 'client/components/schema/task/document/DocumentLogic';
import React, { useState } from 'react';
import { RemoteSignActionModal } from './RemoteSignActionModal';
import { RemoteSignContextProvider } from './RemoteSignContext';

export interface IRemoteSignActionButtonProps {
  buttonText: string;
  document: Documento;
  taskId: string;
  flags: { flIntermedio: boolean; flCompletare: boolean };
}

/**
 * Bottone per la gestione dell'azione firma remota
 */
export function RemoteSignActionButton(
  props: Readonly<IRemoteSignActionButtonProps>
) {
  const { document, taskId, buttonText, flags } = props;

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        size="large"
        type="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Space>
          <>{buttonText}</>{' '}
          {DocumentLogic.isSigned(document, flags) && (
            <Tooltip title={`Documento caricato`}>
              <CheckCircleFilled />
            </Tooltip>
          )}
        </Space>
      </Button>
      <RemoteSignContextProvider>
        <RemoteSignActionModal
          visible={open}
          onCancel={() => setOpen(false)}
          documentId={document.idDocumento!}
          taskId={taskId}
          flags={flags}
        />
      </RemoteSignContextProvider>
    </>
  );
}
