import { AzioniDocumento, AzioniSezione } from 'client/api/backend/schemas';
import { createAttachmentGroupValidator } from 'client/components/schema/attachments/validator/createAttachmentGroupsValidator';
import {
  DocumentSectionAction,
  DocumentSignActions
} from 'client/components/schema/declaration/actions/DeclarationActions';
import { IOutcomeConstraints } from 'client/components/schema/outcome/useOutcomes';
import { yup } from 'common/validation/initYup';
import { merge } from 'lodash';
import { ObjectSchema } from 'yup';

/**
 * Per ogni documento presente, genera un validatore con chiave idTipoDocumento
 * @param documents
 * @returns
 */
export function createDocumentsValidator(
  documents: AzioniDocumento[],
  partial?: boolean
) {
  if (!documents) return yup.object().strip();
  const documentValidator = merge(
    {},
    ...documents.map(document => {
      // Validatore sezione allegati
      const attachmentGroup = document.documento?.gruppoAllegati;
      const fileValidator = createAttachmentGroupValidator(attachmentGroup, partial); // prettier-ignore

      // Validatore delle sezioni dei documenti (file da modificare con il jnlp)
      const sections = document.documento?.sezioni;
      const sectionValidator = yup
        .object(createSectionValidator(sections, partial))
        .required()
        .noUnknown();

      const signed = getSignDocumentValidator(document, partial);

      // Se almeno un allegato è obbligatorio, l'intero group diventa obbligatorio
      const isSchemaRequired = attachmentGroup?.some(group =>
        group.gruppoAllegati?.allegati?.some(
          attachment => attachment.flAllegatoObbligatorio
        )
      );

      let schema: ObjectSchema<any>;

      if (isSchemaRequired && !partial) {
        schema = yup
          .object({
            signed,
            ...fileValidator,
            sections: sectionValidator
          })
          .required()
          .noUnknown()
          .label(document.documento?.nomeTipoDocumento ?? 'Documento');
      } else {
        schema = yup
          .object({
            signed,
            ...fileValidator,
            sections: sectionValidator
          })
          .noUnknown()
          .label(document.documento?.nomeTipoDocumento ?? 'Documento');
      }

      return {
        [document.documento!.idTipoDocumento!]: schema
      };
    })
  );

  return documentValidator;
}

function createSectionValidator(
  sections: AzioniSezione[] | undefined,
  partial: boolean | undefined
) {
  if (!sections) return yup.object().strip();
  return merge(
    {},
    ...sections.map(section => {
      return {
        [section.sezione!.idTipoSezione!]: yup
          // Se idSezioneDocumento non è null -> allora è stato caricato
          .object({
            idSezioneDocumento: yup
              .string()
              .when(
                ['$values', '$outcomesConstraints'],
                // @ts-ignore Problema col tipo della funzione
                // https://github.com/jquense/yup/issues/1529
                (
                  values: any,
                  constraints: IOutcomeConstraints,
                  schema: any
                ) => {
                  if (partial) return schema.notRequired().nullable();
                  // Recupero l'ID dell'esito selezionato
                  const outcomeId = values?.outcome?.id;

                  // Recupero le azioni obbligatorie sulla sezione
                  const actions =
                    constraints[outcomeId]?.sections?.[
                      section?.sezione?.idTipoSezione!
                    ] ?? [];

                  // La sezione è obbligatoria se è compresa l'azione di Edit
                  if (actions.includes(DocumentSectionAction.Edit)) {
                    return schema.required(
                      'La sezione "${label}" deve essere modificata.'
                    );
                  }
                  return schema.notRequired().nullable();
                }
              )
              .label(section.sezione?.nomeTipoSezione ?? 'Sezione Documento')
          })
          .required()
          .noUnknown()
      };
    })
  );
}

function getSignDocumentValidator(
  document: AzioniDocumento,
  partial: boolean | undefined
) {
  return yup
    .boolean()
    .when(
      ['$values', '$outcomesConstraints'],
      // @ts-ignore Problema col tipo della funzione
      // https://github.com/jquense/yup/issues/1529
      (values: any, constraints: IOutcomeConstraints, schema: any) => {
        if (partial) return schema.notRequired().nullable();
        // Recupero l'ID dell'esito selezionato
        const outcomeId = values?.outcome?.id;

        // Recupero le azioni obbligatorie sulla sezione
        const actions =
          constraints[outcomeId]?.documents?.[
            document.documento?.idTipoDocumento!
          ] ?? [];

        // La sezione è obbligatoria se è compresa l'azione di Edit
        if (actions.some(a => DocumentSignActions.includes(a))) {
          const errorMessage = 'Il documento ${label} deve essere firmato.';
          return schema.required(errorMessage).isTrue(errorMessage);
        }
        return schema.notRequired().nullable();
      }
    )
    .label(document.documento?.nomeTipoDocumento ?? 'Documento');
}
