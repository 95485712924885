import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthActions, useAuth, useCurrentUser } from '../AuthModule';
import { Spin, Alert, message } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { useLocation } from 'react-router';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { useGetDatiSessioneUtente } from 'client/api/auth/sessione-controller/sessione-controller';
import { logger } from 'client/core/logger/logger';
import { shouldRetryRequest } from 'client/core/network/interceptors/retryInterceptor';
import { AxiosError } from 'axios';

export interface IAuthCurrentLoaderProps {
  children: React.ReactNode;
}

const SkipHttpStatuses = [400, 401, 403];

/**
 * Caricamento dell'utente corrente. Serve anche per controllare come side
 * effect se l'utente è loggato, in quanto i token sono salvati su
 * cookie HTTP-Only e non sono quindi accessibili da JS.
 */
export function AuthCurrentLoader(props: IAuthCurrentLoaderProps) {
  const dispatch = useDispatch();
  const { logged, prelogged } = useAuth();
  const current = useCurrentUser();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null as any);
  const [errorType, setErrorType] = useState('error' as 'error' | 'info');

  const session = useGetDatiSessioneUtente({
    query: {
      enabled: false
    }
  });

  useEffect(() => {
    let isMounted = true;

    const fetchCurrent = async () => {
      try {
        setLoading(true);
        const result = await session.refetch({ throwOnError: true });
        dispatch(AuthActions.currentLoaded(result.data!));
      } catch (e: any) {
        // Gestiamo il caso in cui il componente è stato ricaricato
        if (!isMounted) return;

        if (e && e.response && SkipHttpStatuses.includes(e.response.status)) {
          // message.error(
          //   `La sessione non è valida, si prega di autenticarsi nuovamente.`
          // );
        } else if (e.response?.status === 302) {
          setErrorType('info');
          setError(getNetworkErrorMessage(e, 'Reindirizzamento in corso...'));
        } else {
          setErrorType('error');
          setError(
            getNetworkErrorMessage(
              e,
              "Si è verificato un errore durante il caricamento dell'utente."
            )
          );
        }
      }
      setLoading(false);
    };

    fetchCurrent();

    return () => {
      isMounted = false;
    };
  }, [logged, prelogged]);

  if (loading) return <LoadingPage full />;

  if (error) {
    return (
      <NetworkAlertPage message="Impossibile caricare l'utente" error={error} />
    );
  }

  return <>{props.children}</>;
}
