import React from 'react';
import styled from 'styled-components';
import { Alert, Card, CardProps } from 'antd';
import { HiDocumentText } from 'react-icons/hi';
import { IconWrapper } from '../icon/IconWrapper';
import { CardCoverAction } from './header/CardCoverAction';
import { CardCoverDocument } from './header/CardCoverDocument';

const TaskCardStyled = styled(Card)`
  & + & {
    margin-top: 24px;
  }
`;

export enum TaskCardMode {
  Action = 'action',
  Document = 'document'
}

export interface TaskCardProps extends CardProps {
  mode?: TaskCardMode;
}

/**
 * Card personalizzata per i Task
 */
export function TaskCard(props: TaskCardProps) {
  const { mode, title, children, ...othersProps } = props;
  const cover =
    mode === TaskCardMode.Document ? (
      <CardCoverDocument
        icon={<IconWrapper icon={<HiDocumentText />} />}
        text={title ?? 'Atto'}
      />
    ) : mode == TaskCardMode.Action ? (
      <CardCoverAction />
    ) : undefined;
  return (
    <TaskCardStyled {...othersProps} bordered={false} cover={cover}>
      {children}
    </TaskCardStyled>
  );
}
