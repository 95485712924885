import * as React from 'react';
import { FormikForm } from 'client/ui/form/FormikForm';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { ProcedureAsyncSelectInput } from 'client/routes/task/list/search/ProcedureAsyncSelectInput';
import { UserAsyncSelectInput } from 'client/routes/task/list/search/UserAsyncSelectInput';
import { OfficeSelectInput } from './OfficeSelectInput';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { PhaseProcedureSelectInput } from './PhaseProcedureSelectInput';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { UserLogic } from 'common/schema/user/UserLogic';
import { UserFeatureFlags } from 'common/schema/user/UserFeatureFlags';

export interface ProcedureSearchFormProps {}

export function ProcedureSearchForm(props: ProcedureSearchFormProps) {
  const isMobile = useBreakpoint(down('sm'));
  const user = useCurrentUser();

  // Controlliamo se l'utente ha il permesso di visualizzare il filtro per fase
  const showPhaseFilter = UserLogic.hasPermission(
    user,
    UserFeatureFlags.ShowPhaseFilter
  );

  const columns = isMobile ? 1 : showPhaseFilter ? 4 : 3;

  return (
    <FormikForm nested layout="vertical" editable helpInTooltips>
      <FormFieldsContainer columns={isMobile ? 1 : 3} gutter={12}>
        <FormFieldItem
          colSpan={isMobile ? undefined : 2}
          size="middle"
          component={TextInput}
          name="oggetto"
          label="Oggetto"
          placeholder="Oggetto..."
        />
        <FormFieldItem
          size="middle"
          component={TextInput}
          name="numero"
          label="Numero"
          placeholder="Numero..."
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={columns} gutter={12}>
        <ProcedureAsyncSelectInput name="tipo" />

        {showPhaseFilter && <PhaseProcedureSelectInput name="stato" />}

        <OfficeSelectInput
          name="ufficioAvvio"
          label="Ufficio di avvio"
          placeholder="Avviato dall'ufficio..."
          responseField={office => office.descrizione!}
        />

        <UserAsyncSelectInput name="responsabile" />

        <OfficeSelectInput
          name="ufficioResponsabile"
          label="Ufficio Responsabile"
          placeholder="Ufficio Responsabile..."
          responseField={office => office.descrizione!}
        />

        <FormFieldItem
          size="middle"
          component={DatePickerInput}
          placement="bottomRight"
          type="from"
          name="dataAvvioDal"
          label="Da Data di Avvio"
          placeholder="Data di Avvio (inizio)"
          allowClear
        />
        <FormFieldItem
          size="middle"
          component={DatePickerInput}
          placement="bottomRight"
          type="to"
          name="dataAvvioAl"
          label="A Data di Avvio"
          placeholder="Data di Avvio (fine)"
          allowClear
        />
      </FormFieldsContainer>
    </FormikForm>
  );
}
