import React from 'react';
import styled from 'styled-components';
import { Space, Avatar, Typography, Button, ButtonProps } from 'antd';
const { Text, Paragraph } = Typography;
import { DownOutlined } from '@ant-design/icons';
import { UserDto } from 'common/dto/UserDto';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';

// const defaultBreakpoints = {
//   xs: '0px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1400px',
// };

const StyledUserButton = styled(Button)`
  display: flex;
  flex: 0 1 auto;
  min-width: 0;
  height: auto;
  padding: 0;
  border: none;
  color: ${props => props.theme.textColorSecondary};
  &:active,
  &:focus {
    color: ${props => props.theme.textColorSecondary};
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  min-width: 0;
  max-width: 100%;
  align-items: center;
`;

const AvatarUser = styled(Avatar)`
  display: flex;
  flex: 0 0 38px;
  min-width: 0;
  background-color: ${props => props.theme.textColorSecondary};
  .ant-btn:hover & {
    background-color: ${props => props.theme.primaryColor};
  }
`;
const UserTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  min-width: 0;
  max-width: 270px;
  text-align: left;
  line-height: 1.3;
  padding: 0 8px;
`;
const UserNameLine = styled(Text)`
  display: inline-block;
  color: inherit;
`;

const UserRole = styled.span`
  font-weight: 400;
  font-size: 0.9em;
  margin-left: 1em;
`;
const UserOfficeLine = styled(Paragraph)`
  display: inline-block;
  flex: 0 1 auto;
  min-width: 0;
  color: inherit;
  font-size: 0.9em;
  &.ant-typography {
    margin-bottom: 0;
  }
`;
const ArrowIcon = styled(DownOutlined)`
  flex: 0 0 auto;
  min-width: 0;
`;

export interface UserButtonProps extends Omit<ButtonProps, 'type'> {
  currentUser: UserDto | null;
}
/**
 * Bottone personalizzato per il menu utente
 * Visualizza il nome, il ruolo e l'ufficio dell'utente loggato.
 */
export function UserButton(props: UserButtonProps) {
  const { currentUser, ...buttonProps } = props;
  const compact = useBreakpoint(down('lg'));

  const office = currentUser?.utenteLoggato?.utenteUfficis?.[0];
  const role = office?.ruolos?.[0]?.denominazione;

  const name = currentUser?.utenteLoggato?.denominazione.split(' ') ?? [
    '-',
    '-'
  ];
  const initials =
    name[0].substring(0, 1).toUpperCase() +
    name[1].substring(0, 1).toUpperCase();

  if (compact)
    return (
      <StyledUserButton type="link" {...buttonProps}>
        <ContentWrapper
          title={currentUser?.utenteLoggato?.denominazione ?? '' + ' - ' + role}
        >
          <AvatarUser size={38}>{initials}</AvatarUser>
        </ContentWrapper>
      </StyledUserButton>
    );

  return (
    <StyledUserButton type="link" {...buttonProps}>
      <ContentWrapper>
        <AvatarUser size={38}>{initials}</AvatarUser>
        <UserTextWrapper>
          <UserNameLine
            strong
            ellipsis
            title={
              currentUser?.utenteLoggato?.denominazione ?? '' + ' - ' + role
            }
          >
            {currentUser?.utenteLoggato?.denominazione ?? ''}{' '}
            <UserRole>{role}</UserRole>
          </UserNameLine>
          <UserOfficeLine ellipsis title={office?.denominazioneUfficio ?? ''}>
            {office?.denominazioneUfficio ?? ''}
          </UserOfficeLine>
        </UserTextWrapper>
        <ArrowIcon />
      </ContentWrapper>
    </StyledUserButton>
  );
}
