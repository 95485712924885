import { useFirma, useFirmaArguments } from 'client/api/auth/jnlp/jnlp';
import { getGetAttivitaQueryKey } from 'client/api/backend/attivita/attivita';
import { WebSocketKind } from 'client/components/digital-signature/DigitalSignatureProvider';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { JnlpService } from './JnlpService';
import { WebSocketSignJnlpModal } from './WebSocketSignJnlpModal';

export interface IMassiveSignJnlpWindowManagerProps {
  taskIds: string[];
  visible: boolean;
  hide: () => void;
}

/**
 * Manager modal per firma **massiva** "FAST"
 */
export function MassiveSignJnlpWindowManager(
  props: IMassiveSignJnlpWindowManagerProps
) {
  const { taskIds, visible, hide } = props;

  const queryClient = useQueryClient();

  const signManager = JnlpService.useDocumentMassiveSignManager();

  const fastJnlpManager = JnlpService.useWebSocketJnlpManager({
    kind: WebSocketKind.Sign,
    getJnlp: async () => {
      return signManager.getJnlp(taskIds);
    },
    getJnlpArguments: async () => {
      const result = await signManager.getJnlpArguments(taskIds);
      return result;
    },
    maxConnectionTries: 5 * 60, // ~5 minuti
    successMessage: 'Documenti firmati con successo',
    onSuccess: () => {
      queryClient.invalidateQueries(getGetAttivitaQueryKey());
      hide();
    },
    filename: 'firmaMassivaManager.jnlp'
  });

  useEffect(() => {
    if (!visible) {
      fastJnlpManager.setToDefault();
      return;
    }

    fastJnlpManager.jnlpProcess();
  }, [visible]);

  return (
    <WebSocketSignJnlpModal
      visible={visible}
      fastJnlpManager={fastJnlpManager}
      hide={hide}
    />
  );
}
