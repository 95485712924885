import * as React from 'react';
import styled from 'styled-components';
import Paragraph, { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { EllipsisTooltip } from '../ellipsis/EllipsisTooltip';
import { Button } from 'antd';

const ExpandButton = styled(Button)`
  &.ant-btn {
    line-height: inherit;
    box-shadow: none;
    font-weight: 400;
    height: auto;
    padding: 0;
    border-radius: 0;
  }
`;

export interface TableCellEllipsisProps {
  text?: string;
  length?: number;
}

/**
 * Render per testo con ellipsis
 */
export function TableCellEllipsis(props: TableCellEllipsisProps) {
  const { text, length } = props;
  const [expand, setExpand] = React.useState(false);
  if (!text) return null;

  const needEllipsis = text.length > (length ? length + 10 : 110);

  const newText = needEllipsis ? text.substring(0, length ?? 100) : text;

  return (
    <span>
      {expand ? text : newText}
      {needEllipsis && (
        <>
          {expand ? (
            <span>
              {' '}
              <ExpandButton type="link" onClick={() => setExpand(false)}>
                contrai
              </ExpandButton>
            </span>
          ) : (
            <span>
              …{' '}
              <ExpandButton type="link" onClick={() => setExpand(true)}>
                espandi
              </ExpandButton>
            </span>
          )}
        </>
      )}
    </span>
  );
}
