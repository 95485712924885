import { Tooltip } from 'antd';
import React from 'react';
import { TbWorldOff } from 'react-icons/tb';
import { Icon24px } from '../FileRow';

export interface IOmittedInPublishIconProps {}

/**
 * Icona per indicare che il file è stato omesso in pubblicazione
 */
export function OmittedInPublishIcon(props: IOmittedInPublishIconProps) {
  return (
    <Tooltip title="Omesso dalla pubblicazione">
      {/* TODO Cambiare icona */}
      <Icon24px icon={<TbWorldOff />} />
    </Tooltip>
  );
}
