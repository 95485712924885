import React from 'react';
import { RemoteSignDynamicForm } from '../RemoteSignDynamicForm';

export interface IRemoteSignFormProps {
  handleSubmit: (values: any) => Promise<void>;
}

/**
 * Form dinamico per l'azione di _FIRMA_ remota del documento.
 */
export function RemoteSignForm(props: IRemoteSignFormProps) {
  const { handleSubmit } = props;
  return (
    <RemoteSignDynamicForm
      paramsKey="remoteSignParameters"
      textButton="Firma"
      afterSubmit={handleSubmit}
    />
  );
}
