/**
 * file da modificare per cambiare il tema dell'applicazione
 */
export const themeOverride = {
  /**
   * Variabili Styled Component
   * per personalizzazione Logo
   */

  // Logo applicazione max-height 42px
  logoWidth: '199px',
  logoHeight: '42px',
  // Logo applicazione max-width 150px
  logoWidthMobile: '150px',
  logoHeightMobile: '31px',

  ...(process.env.THEME_VARIABLES as any)
};
