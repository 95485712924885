import { Reducer } from 'redux';
import { createAction, ActionTypes } from 'client/core/store/actions';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'client/core/store/RootReducer';
import { PermissionsType } from 'common/permissions/Permissions';
import { PermissionLogic } from 'common/permissions/PermissionLogic';
import { AuthStorage } from './AuthStorage';
import { UserDto } from 'common/dto/UserDto';

export const AuthActions = {
  loginSucceed: createAction(
    '@auth/loginSucceed',
    (strategy: string, nonce: string) => ({
      strategy,
      nonce
    })
  ),
  tokenRefreshed: createAction(
    '@auth/tokenRefreshed',
    (strategy: string, nonce: string) => ({
      strategy,
      nonce
    })
  ),
  logout: createAction('@auth/logout'),
  tokenExpired: createAction('@auth/tokenExpired'),
  officeAndRoleSelected: createAction('@auth/officeAndRoleSelected'),
  currentLoaded: createAction('@auth/currentLoaded', (current: UserDto) => ({
    current
  }))
};

export interface AuthState {
  isLogged: boolean;
  isPrelogged: boolean;
  strategy: string | null;
  /**
   * Viene fornito un nonce randomico ogni volta che viene effettuato un nuovo
   * login (chiamata ad access token).
   */
  nonce: string;
  isTokenExpired: boolean;
  current: UserDto | null;
}

export const AuthReducer: Reducer<
  AuthState,
  ActionTypes<typeof AuthActions>
> = (
  state = {
    nonce: 'first-load',
    isTokenExpired: false,
    current: null,
    /**
     * Carichiamo i dati iniziali dal `localStorage` in caso siano
     * presenti.
     */
    ...AuthStorage.initialState()
  },
  action
) => {
  switch (action.type) {
    /**
     * Al login, salviamo il token.
     */
    case '@auth/loginSucceed': {
      const { strategy, nonce } = action.payload;
      AuthStorage.saveState({ strategy, isLogged: false, isPrelogged: true });
      return {
        ...state,
        isLogged: false,
        isPrelogged: true,
        strategy,
        nonce,
        isTokenExpired: false,
        current: null
      };
    }

    /**
     * Quando il token viene refreshato correttamente, non reimposto il
     * `current` perché è ancora valido (almeno parzialmente) il precedente.
     */
    case '@auth/tokenRefreshed': {
      const { strategy, nonce } = action.payload;
      AuthStorage.saveState({ strategy, isLogged: true, isPrelogged: true });
      return {
        ...state,
        isLogged: true,
        strategy,
        nonce,
        isTokenExpired: false
      };
    }

    /**
     * Utente corrente caricato. Quando questa richiesta ha esito positivo,
     * vuol dire che l'utente è autorizzato quindi possiamo impostare il token.
     */
    case '@auth/currentLoaded': {
      const isLogged = hasUserSelectedOfficeAndRole(action.payload.current);
      AuthStorage.saveState({
        strategy: state.strategy,
        isLogged,
        isPrelogged: true
      });
      return {
        ...state,
        isLogged,
        isPrelogged: true,
        isTokenExpired: false,
        current: action.payload.current
      };
    }

    case '@auth/officeAndRoleSelected': {
      AuthStorage.saveState({
        strategy: state.strategy,
        isLogged: true,
        isPrelogged: true
      });
      return {
        ...state,
        isLogged: true,
        isPrelogged: true,
        isTokenExpired: false
      };
    }

    /**
     * In caso di scadenza del token lo registriamo.
     */
    case '@auth/tokenExpired':
      return { ...state, isTokenExpired: true };

    /**
     * Rimozione del token dalla sessione.
     */
    case '@auth/logout':
      AuthStorage.saveState({
        strategy: null,
        isLogged: false,
        isPrelogged: false
      });
      return {
        ...state,
        isLogged: false,
        isPrelogged: false,
        strategy: null,
        nonce: 'logout',
        isTokenExpired: false,
        current: null
      };

    default:
      return state;
  }
};

/**
 * Hook per sapere se si è loggati.
 */
export const useAuth = () =>
  useSelector(
    (state: RootState) => ({
      logged: state.auth.isLogged,
      prelogged: state.auth.isPrelogged,
      strategy: state.auth.strategy,
      nonce: state.auth.nonce
    }),
    shallowEqual
  );

export const useCurrentUser = () =>
  useSelector((state: RootState) => state.auth.current, shallowEqual);

export function hasUserSelectedOfficeAndRole(user?: UserDto | null) {
  return !!(
    user &&
    user.utenteLoggato?.utenteUfficis?.length === 1 &&
    user.utenteLoggato?.utenteUfficis?.[0]?.ruolos?.length === 1
  );
}

/**
 * Hook per controllare i permessi
 */
export const usePermissions = () => {
  const user = useCurrentUser();
  return {
    has(permission: PermissionsType | PermissionsType[]) {
      return PermissionLogic.has(user, permission);
    }
  };
};
