import { AuditOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { TaskActionLogic } from 'client/logic/TaskActionLogic';
import { RemoteSignContextProvider } from 'client/routes/task/single/documents/actions/remote-sign/RemoteSignContext';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { UserLogic } from 'common/schema/user/UserLogic';
import React, { useMemo, useState } from 'react';
import { MassiveRemoteSignModal } from './MassiveRemoteSignModal';

export interface IMassiveRemoteSignMenuItemProps {
  tasks: AttivitaDTO[];
}

export function MassiveRemoteSignMenuItem(
  props: IMassiveRemoteSignMenuItemProps
) {
  const { tasks, ...menuProps } = props;

  const currentUser = useCurrentUser();

  const [isOpen, setIsOpen] = useState(false);

  const disabled = useMemo(
    () => tasks.some(task => !TaskActionLogic.isAssigned(task, currentUser)),
    [tasks, currentUser]
  );

  const hidden = !UserLogic.hasPermission(currentUser, 'firma_remota_massiva');

  return (
    <>
      <Menu.Item
        key="massive-remote-sign"
        disabled={disabled}
        hidden={hidden}
        onClick={async () => {
          setIsOpen(true);
        }}
        {...menuProps}
      >
        <IconWrapper icon={<AuditOutlined />} /> Firma remota
      </Menu.Item>
      <RemoteSignContextProvider>
        <MassiveRemoteSignModal
          onCancel={() => setIsOpen(false)}
          tasks={tasks}
          visible={isOpen}
        />
      </RemoteSignContextProvider>
    </>
  );
}
