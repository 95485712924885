import React from 'react';
import styled from 'styled-components';
import { getAnchorId } from '../anchor/getAnchorId';
import { Dichiarazione, GruppoAllegati } from 'client/api/backend/schemas';

const DeclarationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 1rem;
`;

const DeclarationLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1 1 auto;
  min-width: 10%;
`;

const Stroke = styled.div`
  display: flex;
  height: 1px;
  width: 100%;

  border-bottom: 1px solid ${props => props.theme.primaryColor};
`;

const DeclarationText = styled.div`
  display: inline-flex;
  flex: 0 0 auto;
  min-width: 0;
  max-width: 90%;
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
  color: ${props => props.theme.primaryColor};
  padding-right: 1rem;
`;

export interface DeclarationTitleProps {
  declaration?: Dichiarazione;
  attachments?: GruppoAllegati;
  /**
   * Permette di generare un Titolo con lo stile del titolo della dichiarazione
   * L'Id viene dal titolo inserito ma non è gestita
   * la generazione degli Anchor (TaskStateAnchors)
   */
  title?: string;
}

/**
 * Evidenziazione del titolo della dichiarazione
 * Per ogni titolo viene generato un id Html che è utilizzato
 * per la navigazione con gli Anchor nella pagina dei Task (TaskStateAnchors)
 */
export function DeclarationTitle(props: DeclarationTitleProps) {
  if (!props.declaration && !props.title) {
    return null;
  }
  const title = props.declaration
    ? props.declaration.titoloDichiarazione
    : props.title;
  const id =
    props.declaration?.idTipoDichiarazione ??
    props.attachments?.idGruppoAllegato ??
    props.title;
  return (
    <DeclarationContainer>
      <DeclarationText id={getAnchorId(id)}>{props.title}</DeclarationText>
      <DeclarationLine>
        <Stroke />
      </DeclarationLine>
    </DeclarationContainer>
  );
}
