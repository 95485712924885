import React from 'react';
import styled from 'styled-components';
import { Menu, Dropdown, Button } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { UserButton } from './UserButton';
import { Link } from 'react-router-dom';
import { LogoutRoleButton } from 'client/components/auth/logout-role/LogoutRoleButton';
import { LogoutButton } from 'client/components/auth/logout/LogoutButton';
import { appConfig } from 'client/core/appConfig';

export interface UserMenuProps {}
/**
 * Pulsante Dropdown per il logout dell'utente
 * Visualizza il nome ed il ruolo dell'utente loggato. admin/variables
 */
export function UserMenu(props: UserMenuProps) {
  const currentUser = useCurrentUser();
  const handleClick = () => {};

  const menu = (
    <Menu>
      {appConfig.showTestUserMenu && (
        <>
          <Menu.Item disabled>Preferenze</Menu.Item>
          <Menu.Item>
            <Link to="/admin/variables">Variabili Procedure</Link>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <LogoutRoleButton />
      <LogoutButton />
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <UserButton currentUser={currentUser} />
    </Dropdown>
  );
}
