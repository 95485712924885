import * as React from 'react';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const EXTRA_BUTTONS_MENU_PORTAL = 'header-buttons--menu-portal';

export interface IPageLayoutRightMenuProps {
  children: React.ReactNode;
}

/**
 * Permette di posizionare del contenuto aggiuntivo nella posizione
 * dei bottoni nell'header della task, mantenendo il `Context` di React anche se annidato
 * nell'albero del DOM.
 */
export function TabTaskPortal(props: IPageLayoutRightMenuProps) {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const portal = document.getElementById(EXTRA_BUTTONS_MENU_PORTAL)!;
    portal.appendChild(el.current);

    return () => {
      portal.removeChild(el.current);
    };
  }, [props.children]);

  return ReactDOM.createPortal(props.children, el.current);
}

export const TabTaskPortalContainer = () => (
  <div id={EXTRA_BUTTONS_MENU_PORTAL} />
);
