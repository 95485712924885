import { message } from 'antd';
import { useCompleta } from 'client/api/backend/attivita/attivita';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { logger } from 'client/core/logger/logger';
import { getResponseErrorMessage } from 'client/core/network/errors/getResponseErrorMessage';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { yup } from 'common/validation/initYup';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  CompleteTasksMassiveForm,
  LOADING_COMPLETE_MESSAGE_KEY
} from './CompleteTasksMassiveForm';

export interface ITaskInError {
  esito: 'error';
  taskId: string;
  message: string;
}
export interface ITaskSuccess {
  esito: 'success';
  taskId: string;
}

export type ITaskResult = ITaskInError | ITaskSuccess;

export interface ICompleteTasksMassiveModalProps {
  tasks: AttivitaDTO[];
  visible: boolean;
  hide: () => void;
}

/**
 * Modale per il completamento massivo delle attività
 */
export function CompleteTasksMassiveModal(
  props: ICompleteTasksMassiveModalProps
) {
  const { tasks, visible } = props;
  const completeFunction = useCompleta();
  const [progress, setProgress] = useState(0);
  const [taskResults, setTaskResults] = useState<ITaskResult[]>([]);
  const isRunning = useRef(true);
  const queryClient = useQueryClient();

  /** Metodo che completa una singola attività e ritorna il risultato */
  const completeTask = useCallback(
    async (
      task: AttivitaDTO,
      values: ICompleteMassiveTasks
    ): Promise<ITaskResult> => {
      try {
        await completeFunction.mutateAsync({
          idTaskInstance: task.idTaskInstance!,
          data: {
            noteCompletamento: values.finalNotes,
            esitoCompletamento: undefined as any,
            nomeVariabileEsito: undefined as any
          }
        });
        setProgress(progress => progress + 1);
        return {
          esito: 'success',
          taskId: task.procedimento!.numero!
        };
      } catch (e: any) {
        logger.error({ error: e });
        return {
          esito: 'error',
          taskId: task.procedimento!.numero!,
          message: getResponseErrorMessage(
            e,
            "Errore durante il completamento dell'attività"
          )!
        };
      }
    },
    [completeFunction]
  );

  /** Reset della progress bar e dei risultati */
  const reset = () => {
    setProgress(0);
    setTaskResults([]);
    isRunning.current = !!visible;
  };

  /** Informo l'utente che l'operazione è stata annullata  */
  const handleStop = () => {
    props.hide();
    message.destroy(LOADING_COMPLETE_MESSAGE_KEY);
    message.warning('Le attività sono state completate parzialmente');
    // Invalida le query per aggiornare la tabella
    queryClient.invalidateQueries();
  };

  useEffect(() => {
    reset();
  }, [visible]);

  /** Metodo che itera su tutte le attività e le completa */
  const runTasks = useCallback(
    async (values: ICompleteMassiveTasks) => {
      const currentTaskResults: ITaskResult[] = [];
      for (const task of tasks) {
        // Se l'utente ha annullato l'operazione, interrompe il ciclo e mostra un messaggio di warning
        if (!isRunning.current) {
          handleStop();
          return;
        }

        const taskResult = await completeTask(task, values);
        currentTaskResults.push(taskResult);
      }

      setTaskResults(currentTaskResults);

      // Se non ci sono errori, mostra un messaggio di successo e chiude il Modal
      if (!currentTaskResults.some(result => result.esito === 'error')) {
        message.success('Attività completate con successo');
        props.hide();
      }
    },
    [tasks]
  );

  return (
    <ModalDialog
      title={'Completa Attività'}
      visible={visible}
      size="medium"
      footer={null}
      closable={false}
    >
      <FormikAugmented
        enableReinitialize
        validationSchema={completeMassiveTasksValidator}
        validationContext={{}}
        initialValues={completeMassiveTasksValidator.cast({})}
        onSubmit={async (rawValues: ICompleteMassiveTasks, helpers) => {
          const values = await completeMassiveTasksValidator.validate(
            rawValues
          );

          try {
            await runTasks(values);
          } catch (e) {
            logger.error({ error: e });
            message.error(`Si è verificato un errore durante il completamento. Riprovare...`); // prettier-ignore
          } finally {
            queryClient.invalidateQueries();
          }
        }}
      >
        <CompleteTasksMassiveForm
          tasks={tasks}
          taskResults={taskResults}
          progress={progress}
          hide={props.hide}
          isRunning={isRunning}
        />
      </FormikAugmented>
    </ModalDialog>
  );
}

const completeMassiveTasksValidator = yup
  .object({
    finalNotes: yup.string().notRequired()
  })
  .required()
  .noUnknown();

type ICompleteMassiveTasks = yup.InferType<
  typeof completeMassiveTasksValidator
>;
