import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';

export interface IAutoValidateFormikProps {
  /** Oggetto con la stessa struttura del form ma con valori booleani */
  touched: any;
  /** Il validatore del form */
  validator: any;
}

/**
 * Gestisce l'aggiornamento dell'onTouch su tutto il formik
 * così da validare l'intero form all'ingresso nella pagina.
 */
export function AutoValidateFormik(props: IAutoValidateFormikProps) {
  const formik = useFormikContext();

  useEffect(() => {
    setTimeout(() => {
      formik.setTouched(props.touched, true);
    }, 0);
  }, [props.touched]);

  return null;
}
