import { useGetAttivita } from 'client/api/backend/attivita/attivita';
import { GetAttivitaParams } from 'client/api/backend/schemas';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { useLocalPageSize } from 'client/core/hooks/useLocalPageSize';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

export function useTaskList(
  defaultQuery: GetAttivitaParams,
  props: { defaultPageSize: number }
) {
  const location = useLocation();
  const history = useHistory();
  const query = getPaginationUrlQuery(location.search, defaultQuery);

  const { pageSize } = useLocalPageSize(
    query.maxResults,
    props.defaultPageSize
  );

  const apiResult = useGetAttivita(
    { ...query, maxResults: pageSize },
    {
      query: {
        keepPreviousData: true
      }
    }
  );

  const setQuery = useCallback(
    (input: GetAttivitaParams) =>
      setPaginationUrlQuery<GetAttivitaParams>({ history }, input),
    [history]
  );

  const tasks = apiResult.data?.data?.attivitas;
  const total = apiResult.data?.data?.totale ?? 0;

  const pageNumber = Math.floor((query.firstResult ?? 0) / pageSize) + 1;

  return {
    query,
    setQuery,
    tasks,
    meta: { total, pageSize, pageNumber },
    apiResult
  };
}
