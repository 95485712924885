import { UploadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Allegato } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { RelationEditor } from 'client/components/form/relations/useRelationEditor';
import { logger } from 'client/core/logger/logger';
import { getResponseErrorMessage } from 'client/core/network/errors/getResponseErrorMessage';
import { uploadFile } from 'client/components/schema/document/uploadFile';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInput } from 'client/ui/form/input/TextInput';
import {
  B_TO_MB,
  IDocumentFile,
  UploadInput
} from 'client/ui/form/input/UploadInput';
import { yup } from 'common/validation/initYup';
import React, { useCallback, useState } from 'react';
import { UploadInputWrapper } from './UploadInputWrapper';
import { AddFileSwitchesWrapper } from './AddFileSwitchesWrapper';
import {
  IUploadFileData,
  uploadFileValidator
} from './validator/updateFileValidator';

export interface IAddFileRowProps {
  editor: RelationEditor<IDocumentFile>;
  taskId: string;
  attachment: Allegato;
  canUpload: boolean;
}

export function AddFileRow(props: IAddFileRowProps) {
  const { editor, taskId, attachment, canUpload } = props;
  const currentUser = useCurrentUser();
  const [loading, setLoading] = useState(false);

  // Invia il file al BE
  const handleUpload = useCallback(
    async (input: IUploadFileData) => {
      const { file, description, integralPart, omittedInPublish } = input;
      if (!file) return;
      if (!canUpload) return;
      setLoading(true);
      try {
        // Usiamo una funzione custom perché quella autogenerata
        // non gestisce correttamente i multipart/form-data.
        // Invalida le query per gli allegati e i documenti per ricaricare le liste.
        const result = await uploadFile({
          taskId,
          attachmentId: attachment.idTipoAllegato!,
          file,
          params: {
            descrizione: description,
            flParteIntegrante: integralPart,
            flPubblicabile: !omittedInPublish
          }
        });

        editor.add({
          description,
          file: {
            id: result.data,
            name: file.name,
            size: file.size,
            ownerFiscalId: currentUser?.utenteLoggato?.codiceFiscale!,
            office: currentUser?.utenteLoggato?.utenteUfficis?.[0].codiceUfficio
          }
        });

        message.success(`File caricato correttamente`);
      } catch (e: any) {
        logger.error(`[UploadInput] Errore durante l'upload del file`, {error: e}); // prettier-ignore
        message.error(getResponseErrorMessage(e, 'Errore durante il caricamento del file')); // prettier-ignore
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [taskId, attachment, editor]
  );

  if (!canUpload) {
    return null;
  }

  return (
    <FormikAugmented
      validationSchema={uploadFileValidator}
      validationContext={{}}
      initialValues={uploadFileValidator.cast({})}
      enableReinitialize
      onSubmit={async (rawValues, { resetForm }) => {
        const values = await uploadFileValidator.validate(rawValues);

        // Evito l'upload di file vuoti
        if (values.file.size === 0) {
          message.error('Impossibile caricare un file vuoto');
          return;
        }

        // Controllo di non superare il massimo numero di file caricabili
        if (
          attachment.numMaxFile &&
          attachment.numMaxFile > 0 &&
          editor.items.length >= attachment.numMaxFile
        ) {
          logger.warn(`[AddFileRow] Numero massimo di file raggiunto`);
          message.error(`Numero massimo di file raggiunto`);
          return;
        }

        // Controllo che la dimensione totale dei file sia inferiore al massimo consentito
        const totalSize = editor.items.reduce(
          (acc, item) => acc + (item.file?.size ?? 0),
          0
        );

        if (
          attachment.dimMaxTotale &&
          attachment.dimMaxTotale > 0 &&
          totalSize + values.file.size > attachment.dimMaxTotale * B_TO_MB
        ) {
          logger.warn(`[AddFileRow] Dimensione totale files raggiunta`);
          message.error(`Dimensione massima totale dei file raggiunta. (${attachment.dimMaxTotale} MB)`); // prettier-ignore
          return;
        }

        // Carico il file
        try {
          await handleUpload(values);
        } catch (e) {
          return;
        }
        resetForm();
      }}
    >
      <FormikForm nested editable helpInTooltips hideSuccess>
        <UploadInputWrapper>
          <FormFieldItem
            formItemClassName="upload-button"
            label={null}
            name="file"
            component={UploadInput}
            buttonProps={{ size: 'large' }}
            options={attachment}
            disabled={loading}
          />
          <FormFieldItem
            formItemClassName="description-field"
            label={null}
            placeholder="Descrizione"
            name="description"
            component={TextInput}
            disabled={loading}
            suffix={<AddFileSwitchesWrapper loading={loading} />}
          />
          <FormikSendButton
            className="send-button"
            size="large"
            icon={<UploadOutlined />}
            loading={loading}
          >
            Carica
          </FormikSendButton>
        </UploadInputWrapper>
      </FormikForm>
    </FormikAugmented>
  );
}
