import { useGetAzioniDocumento } from 'client/api/backend/attivita/attivita';
import {
  AzioniDocumento,
  AzioniSezione,
  Documento,
  GruppoAllegati
} from 'client/api/backend/schemas';
import { arrayToObject } from 'client/core/utils/arrayToObject';
import { useMemo } from 'react';
import {
  getAttachmentGroupsInitialValues,
  sortAttachmentsWithActions
} from '../attachments/useAttachments';
import {
  declarationWithActionSort,
  noralizeDeclarationWithAction
} from '../declaration/useDeclarations';

export function useDocuments(taskId: string) {
  const result = useGetAzioniDocumento(taskId);

  const documents = useMemo(
    () =>
      [...(result.data?.data ?? [])].sort(
        (a, b) =>
          (a.documento?.ordinamento ?? 0) - (b.documento?.ordinamento ?? 0)
      ),
    [result.data?.data]
  );

  // Evitiamo di fare side-effect sull'array delle dichiarazioni perché
  // altrimenti modificheremmo direttamente la cache di react-query, quindi
  // al `render` successivo di React faremmo una **doppia normalizzazione**
  // delle dichiarazioni (rif: problema sui campi ripetibili "repeatable_repetable_"...)
  let nextDocuments = useMemo(
    () =>
      documents?.map(document => {
        sortDocumentAttachments(document);

        if (!document.documento?.dichiarazioni) return document;

        return {
          ...document,
          documento: {
            ...document.documento,

            // Ordino le dichiarazioni
            dichiarazioni: document.documento.dichiarazioni
              .sort(declarationWithActionSort)
              .map(noralizeDeclarationWithAction)
          }
        };
      }),
    [documents]
  );

  return {
    result,
    documents: nextDocuments
  };
}

export type IDocumentsResult = ReturnType<typeof useDocuments>;

export function getDocumentsInitialValues(
  documents: Documento[] | null | undefined
) {
  return arrayToObject(
    documents?.map(document => {
      const groups = getAttachmentGroupsInitialValues(document.gruppoAllegati);
      const sections = getSectionsInitialValues(document.sezioni);
      const signed = document.files?.some(
        file => file.flFirmato === true && file.codiceTipologia === 'FIRMATO'
      );
      return { [document.idTipoDocumento!]: { ...groups, sections, signed } };
    })
  );

  function getSectionsInitialValues(sections: AzioniSezione[] | undefined) {
    return arrayToObject(
      sections?.map(section => {
        return {
          [section.sezione?.idTipoSezione!]: {
            // Evitiamo il null che dà problemi con yup.string()
            idSezioneDocumento: section.sezione?.idSezioneDocumento ?? undefined
          }
        };
      })
    );
  }
}

/**
 * Dato un documento, ordina i gruppi allegati
 */
function sortDocumentAttachments(document: AzioniDocumento) {
  // Ordino i gruppi di allegati
  document.documento?.gruppoAllegati?.sort(sortAttachmentsWithActions);

  // TODO -> aggiungere se e quando presente l'ordinamento sugli allegati
  // document.documento?.gruppoAllegati?.forEach(gruppoAllegati => {
  //   // Ordino gli allegati
  //   gruppoAllegati.gruppoAllegati?.allegati?.sort(
  //     (a, b) =>
  //       (a.ordinamento ?? 0) - (b.ordinamento ?? 0)
  //   );
  // });
}

/** Ordino gruppo di allegati */
function sortAttachments(attachments?: GruppoAllegati[] | undefined) {
  if (!attachments) return;
  attachments.sort((a, b) => (a.ordinamento ?? 0) - (b.ordinamento ?? 0));
}
