import { getSearchQueryKey } from 'client/api/backend/utenti/utenti';
import { backendApiClient } from 'client/core/network/mutators/apiClient';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import React from 'react';

const MIN_LEN = 3;

const userApi = (opts: { query: any }) =>
  backendApiClient.get(getSearchQueryKey(opts.query)[0].toString(), {
    params: opts.query
  });

export interface IUserAsyncSelectInputProps {
  name: string;
}

export function UserAsyncSelectInput(props: IUserAsyncSelectInputProps) {
  const { name } = props;

  return (
    <FormFieldItem
      size="middle"
      component={AsyncSelectInput}
      name={name}
      label="Utente Assegnatario"
      placeholder="Utente..."
      dropdownMatchSelectWidth={false}
      placement="bottomRight"
      allowClear
      showSearch
      refreshOnSearch
      query={{
        apiFn: userApi as any,
        options: (_: any, search: string | undefined) => ({
          data: { query: { search } },
          skip: !search || search.length < MIN_LEN
        })
      }}
      optionTransform={(options: any) => ({
        label: options.label,
        value: options.id
      })}
      notFoundMessage={(search: string | undefined) =>
        !search || search.length < MIN_LEN
          ? 'Digitare per iniziare la ricerca...'
          : undefined
      }
    />
  );
}
