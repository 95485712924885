import * as React from 'react';
import { Card, Collapse, CollapsePanelProps, CollapseProps } from 'antd';
const { Panel } = Collapse;
import styled, { createGlobalStyle } from 'styled-components';
import { down } from 'styled-breakpoints';

export const CollapseGlobalStyle = createGlobalStyle`
  .custom-collapse {
    .ant-collapse-item {
      & > .ant-collapse-header {
        padding: 6px 0 12px 0;
        align-items: center;
        /* line-height: 1; */
        .ant-collapse-arrow {
          margin-right: 4px;
        }
      }
      & > .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }
`;
const PhaseWrapper = styled.div`
  position: relative;
`;
const PhaseLine = styled.div`
  position: absolute;
  left: 144px;
  top: 20px;
  bottom: 40px;
  width: 2px;
  background-color: ${props => props.theme.borderColorSplit};
  ${down('sm')} {
    left: 31px; // base 144px - 113px
  }
`;

export interface PhasesContainerProps {
  children: React.ReactNode;
}

/**
 * Contenitore generale per la visualizzazione
 * delle fasi e del dettaglio della cronologia
 */
export function PhasesContainer(props: PhasesContainerProps) {
  return (
    <PhaseWrapper className="custom-collapse">
      <PhaseLine />
      {/* <HistoryCollapse {...props} ghost /> */}
      {/* <Collapse {...props} ghost /> */}
      {props.children}
    </PhaseWrapper>
  );
}
