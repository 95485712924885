import * as React from 'react';
import { ISORelativeDate } from 'client/ui/display/date/ISORelativeDate';
import {
  LabelMobile,
  TimelineItem
} from 'client/components/schema/history/detail/TimelineItem';
import { UserInfoLine } from 'client/components/schema/note/detail/UserInfoLine';
import { HiUserCircle } from 'react-icons/hi';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { HistoryItemNote } from 'client/components/schema/history/detail/HistoryItemNote';
import { HistoryItemTaskInfo } from './detail/HistoryItemTaskInfo';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { IHistorySubphase } from '../task/hooks/useTaskHistory';

export interface HistoryItemProps {
  item: IHistorySubphase;
}
/**
 * Mosra il singolo item della Cronologia
 */
export function HistoryItem(props: HistoryItemProps) {
  const { item } = props;
  const currentUser = useCurrentUser();

  // const isSystem = item.type === 'system';
  const isCurrentUser =
    item.userFiscalNumber === currentUser?.utenteLoggato?.codiceFiscale;

  return (
    <TimelineItem
      color={'blue'}
      dot={isCurrentUser ? <IconWrapper icon={<HiUserCircle />} /> : undefined}
      label={<ISORelativeDate date={item.date} relativeOnly />}
    >
      <LabelMobile>
        <ISORelativeDate date={item.date} relativeOnly />
      </LabelMobile>
      <HistoryItemTaskInfo date={item.date} type={item.type} />
      <UserInfoLine name={item.user!} isHistory />
      <HistoryItemNote isCurrentUser={isCurrentUser} children={item.note} />
    </TimelineItem>
  );
}
