import * as React from 'react';
import clx from 'classnames';
import { Tag, Tooltip } from 'antd';
import { HiArrowSmRight, HiArrowRight } from 'react-icons/hi';
import styled from 'styled-components';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { ISODate } from '../../../../ui/display/date/ISODate';
import { ISORelativeDate } from '../../../../ui/display/date/ISORelativeDate';

const TaskInfoLineWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${props => props.theme.textColorSecondary};
  font-size: ${props => props.theme.fontSizeSm};
  transition: color 0.3s;
`;
const Arrow = styled(IconWrapper)`
  padding: 0 8px;
`;
const Task = styled.span`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
`;
const TaskInfoLineTag = styled(Tag)`
  margin-left: 8px;
  line-height: 12px;
  padding: 2px 7px;
`;

export interface TaskInfoLineProps {
  date: Date;
  task: string;
  action: string;
}
/**
 * // TODO
 */
export function TaskInfoLine(props: TaskInfoLineProps) {
  return (
    <TaskInfoLineWrapper>
      <ISORelativeDate date={props.date} format="DD/MM/YYYY HH:mm" />
      <Task>
        <Arrow icon={<HiArrowRight />} />
        {props.task}
      </Task>
      <TaskInfoLineTag color="blue" children={props.action} />
    </TaskInfoLineWrapper>
  );
}
