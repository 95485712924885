import { Space, Button } from 'antd';
import { AttivitaDTO, AzioniDocumento } from 'client/api/backend/schemas';
import { getAnchorId } from 'client/ui/anchor/getAnchorId';
import { ActionsContainer } from 'client/ui/card/container/ActionsContainer';
import { TaskCard, TaskCardMode } from 'client/ui/card/TaskCard';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import React from 'react';
import { DeclarationSection } from '../declarations/DeclarationSection';
import { DocumentActionsBar } from './actions/DocumentActionsBar';
import { AttachmentGroupSection } from './AttachmentGroupSection';
import { SectionButton } from './SectionButton';

export interface IDocumentSectionProps {
  document: AzioniDocumento;
  task: AttivitaDTO | null;
  isTaskAssigned: boolean;
}

export function DocumentSection(props: IDocumentSectionProps) {
  const { document, task } = props;
  const taskId = task?.idTaskInstance!;

  return (
    <TaskCard
      id={getAnchorId(document.documento!.idTipoDocumento!)}
      mode={TaskCardMode.Document}
      title={document.documento?.nomeTipoDocumento}
    >
      {/* <FormikForm nested layout="vertical" editable> */}
      <ActionsContainer
        right={<DocumentActionsBar document={document} taskId={taskId} />}
        left={
          <Space wrap>
            {document.documento?.sezioni?.map((section, index) => (
              <SectionButton
                section={section}
                key={index}
                taskId={taskId}
                document={document}
              />
            ))}
          </Space>
        }
      />
      {/* // Gestire automaticamente spazio verticale dopo <CardActions /> */}
      {/* <VerticalSpace /> */}
      {/* </FormikForm> */}

      {/* Sezione Dichiarazione */}
      {document.documento?.dichiarazioni?.map((declaration, index) => (
        <DeclarationSection
          declaration={declaration}
          key={index}
          isTaskAssigned={props.isTaskAssigned}
        />
      ))}

      {/*  Sezione Gruppo allegati */}
      {document.documento?.gruppoAllegati?.map((group, index) => (
        <AttachmentGroupSection
          key={index}
          group={group}
          name={document.documento?.idTipoDocumento!}
          task={task}
        />
      ))}
    </TaskCard>
  );
}
