import { Alert, Form, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useRemoteSignContext } from '../../RemoteSignContext';

export interface IRemoteSignTypeSelectProps {}

export function RemoteSignTypeSelect(props: IRemoteSignTypeSelectProps) {
  const {
    state: { provider, signType },
    handlers: { setSignType }
  } = useRemoteSignContext();

  const options = useMemo(
    () => provider?.signType.map(t => ({ value: t })) ?? [],
    [provider?.signType]
  );

  useEffect(() => {
    if (!provider?.signType) return;
    if (!signType) {
      setSignType(provider.signType[0]);
    }
  }, [provider, signType, setSignType]);

  if (!provider) return null;

  if (!provider.signType) {
    return (
      <Alert
        type="error"
        message="Nessuna firma supportata"
        description="Nessuna firma supportata. Cambiare provider o riprovare più tardi."
      />
    );
  }

  if (provider.signType?.length === 1) {
    return (
      <div className="ant-form-vertical">
        <Form.Item
          label="Tipo Firma"
          className="form-field-item not-editable ghost"
        >
          {provider.signType[0]}
        </Form.Item>
      </div>
    );
  }
  return (
    <Form.Item label="Tipo Firma" className="form-field-item editable">
      <Select
        style={{ width: '100%' }}
        size="large"
        options={options}
        defaultValue={provider.signType[0]}
        onChange={value => {
          setSignType(value);
        }}
        value={signType}
      />
    </Form.Item>
  );
}
