import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { SwitchInput } from 'client/ui/form/input/SwitchInput';
import { useFormikContext } from 'formik';
import React from 'react';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { IEditFileData } from './validator/updateFileValidator';

export interface IAddFileSwitchesWrapperProps {
  loading: boolean;
}

/**
 * Componente che contiene gli switch per l'aggiunta di un file.
 */
export function AddFileSwitchesWrapper(props: IAddFileSwitchesWrapperProps) {
  const { loading } = props;
  const { integralPart } = useFormikContext().values as IEditFileData;

  return (
    <>
      {integralPart && (
        <Tooltip title="Omesso dalla pubblicazione?">
          <FormFieldItem
            formItemClassName="in-field-switch"
            label={null}
            component={SwitchInput}
            name="omittedInPublish"
            disabled={loading}
            checkedChildren={<IconWrapper icon={<FiEyeOff />} />}
            unCheckedChildren={<IconWrapper icon={<FiEye />} />}
          />
        </Tooltip>
      )}
      <Tooltip title="Parte Integrante?">
        <FormFieldItem
          formItemClassName="in-field-switch"
          label={null}
          component={SwitchInput}
          name="integralPart"
          disabled={loading}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Tooltip>
    </>
  );
}
