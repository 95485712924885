import { useGetAttivita } from 'client/api/backend/attivita/attivita';

/**
 * Ritorna il task richiesto con i dati della request.
 * Dato che la get è non è del dettaglio, ma della lista, non ritorna 404 se non trova il task.
 * Quindi viene gestito col flag `notFound`, a true nel caso l'array risultante sia vuoto.
 */
export function useTask(id: string) {
  const taskResult = useGetAttivita({ taskId: id });
  const tasksFound = taskResult.data?.data?.attivitas;
  const task = tasksFound?.[0] ?? null;
  return {
    task,
    notFound: tasksFound && tasksFound.length === 0,
    loading: taskResult.isLoading,
    error: taskResult.error
  };
}
