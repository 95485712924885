export const colorNames = [
  'DarkSlateBlue',
  // 'MidnightBlue',
  // 'Navy',
  'DarkBlue',
  'MediumBlue',
  // 'Blue',
  'RoyalBlue',
  // 'CornflowerBlue',
  // 'SteelBlue',
  'DodgerBlue',

  'DeepSkyBlue',
  'DarkTurquoise',
  // 'CadetBlue',
  'LightSeaGreen',
  'DarkCyan',
  'SeaGreen',
  'ForestGreen',

  'DarkGreen',
  'DarkOliveGreen',
  'OliveDrab',
  'Olive',
  // 'LimeGreen',
  'LightSlateGray',
  // 'SlateGrey',
  'Grey',
  'DimGrey',
  'DarkSlateGrey',
  // 'Green',
  // 'Teal',
  'Indigo',
  'RebeccaPurple',
  'SlateBlue',
  // 'MediumSlateBlue',
  // 'BlueViolet',
  // 'DarkViolet',
  'DarkOrchid',
  'Purple',
  // 'Maroon',
  // 'DarkMagenta',
  'MediumVioletRed',
  // 'DeepPink',
  'Crimson',
  // 'Red',
  'OrangeRed',
  'DarkOrange',
  'DarkGoldenRod',
  'Chocolate',

  'Brown',
  'FireBrick',
  'DarkRed',
  'SaddleBrown',
  'Sienna'
];

export const DefaultProcedureInfo = {
  Color: 'MediumBlue',
  Icon: 'RiArchiveFill'
};
