import { CheckCircleFilled, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space, Tooltip } from 'antd';
import {
  AzioneDTO,
  Documento,
  InviaDocumentoFirmatoParams,
  RecuperaDocumentoPerFirmaParams
} from 'client/api/backend/schemas';
import { DocumentAction } from 'client/components/schema/declaration/actions/DeclarationActions';
import { DocumentLogic } from 'client/components/schema/task/document/DocumentLogic';
import { JnlpService } from 'client/components/schema/task/jnlp/JnlpService';
import React from 'react';
import { DocumentActionFastSign } from '../DocumentActionFastSign';
import { DownloadSignDocumentAction } from './DownloadSignDocumentAction';
import { SignManagerDownloadAction } from './SignManagerDownloadAction';
import { UploadSignDoumentAction } from './UploadSignDoumentAction';

export interface IDocumentSignActionsProps {
  taskId: string;
  documentId: string;
  document: Documento;
  // Flag flIntermedio, indica se il documento in esame è completo o parziale
  params: RecuperaDocumentoPerFirmaParams;
  uploadParams: InviaDocumentoFirmatoParams;
  actions: AzioneDTO[];
  buttonText: string;
}

export function DocumentSignActions(
  props: Readonly<IDocumentSignActionsProps>
) {
  const { document, uploadParams, ...otherProps } = props;
  // Si può firmare offline se è presente l'azione di download (azione intermedia in caso di documento intermedio)
  const canOfflineSign = props.actions.some(
    action =>
      (action.idAzione === DocumentAction.OfflineTextSign &&
        props.params.flIntermedio) ||
      action.idAzione === DocumentAction.OfflineSignAct
  );

  const menu = (
    <Menu>
      {JnlpService.isStandardJnlp() ? (
        <SignManagerDownloadAction
          {...otherProps}
          document={document}
          key="sign"
        />
      ) : (
        <DocumentActionFastSign
          {...otherProps}
          document={document}
          key="sign"
        />
      )}
      {canOfflineSign && (
        <>
          <DownloadSignDocumentAction
            {...otherProps}
            document={document}
            key="download"
          />
          <UploadSignDoumentAction
            {...otherProps}
            params={uploadParams}
            key="upload"
          />
        </>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Button size="large" type="primary">
        <Space>
          <>{props.buttonText}</>{' '}
          {DocumentLogic.isSigned(document, otherProps.params) && (
            <Tooltip title={`Documento caricato`}>
              <CheckCircleFilled />
            </Tooltip>
          )}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
