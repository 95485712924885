import { AzioniDichiarazione, Dichiarazione } from 'client/api/backend/schemas';
import { DeclarationActionLogic } from 'client/components/schema/declaration/actions/DeclarationActionLogic';
import { getFields } from 'client/components/schema/declaration/getFields';
import { IOutcomeConstraints } from 'client/components/schema/outcome/useOutcomes';
import {
  DeclarationFieldDto,
  DeclarationFieldType
} from 'common/dto/declaration/DeclarationFieldDto';
import { yup } from 'common/validation/initYup';
import { groupBy, merge } from 'lodash';
import { createDeclarationSchemaInput } from '../type/DeclarationFieldInput';

const HTML_SIMPLE_REGEX = /(<([^>]+)>)/gi;

export function createDeclarationsValidator(
  declarations: AzioniDichiarazione[] | null | undefined,
  /** Se true, viene creato un validatore generico per il salvataggio dei dati come bozza */
  partial?: boolean
) {
  if (!declarations) return yup.object().strip();
  return merge(
    {},
    ...declarations.map(declaration => {
      const fields = getFields(declaration.dichiarazione);

      const schema = createDeclarationValidator(
        declaration.dichiarazione?.idTipoDichiarazione!,
        fields,
        DeclarationActionLogic.isDeclarationEditable(declaration.azioni),
        partial
      )!;

      return {
        [getFieldPrefix(declaration.dichiarazione)!]: schema
      };
    })
  );
}

export function createDeclarationValidator(
  declarationId: string,
  fields: DeclarationFieldDto[],
  isEditable: boolean,
  partial?: boolean
) {
  const validators: any = merge(
    {},
    ...fields.map(field => {
      //  Se parziale ritorno un validatore generico
      if (partial) {
        return {
          [field.nome]: yup
            .mixed()
            .notRequired()
            .label(field.des_campo ?? '')
        };
      }
      let schema = createDeclarationFieldValidator(
        declarationId,
        field,
        isEditable
      )!; // TODO typings
      schema = (schema as any).conditionals(field, declarationId, isEditable);
      schema = (schema as any).meta({ editable: field.editabile !== false });
      schema = schema.label(
        field.des_campo?.replace(HTML_SIMPLE_REGEX, '') ?? field.nome
      ); // TODO -> label per i campi di tipo radio

      return { [field.nome]: schema };
    })
  );

  let result = yup.object(validators).noUnknown().required();

  // Gestisco i campi checkbox
  const checkboxGroups = getCheckboxGroups(fields);
  Object.keys(checkboxGroups).forEach(groupName => {
    result = createCheckboxGroupValidation(result, checkboxGroups[groupName]);
  });

  return result;
}

function createDeclarationFieldValidator(
  declarationId: string,
  field: DeclarationFieldDto,
  isEditable: boolean
) {
  switch (field.subtype) {
    case 'repeatable':
    case 'table':
      return yup.array(
        createDeclarationValidator(
          declarationId,
          field.subfields ?? [],
          isEditable
        )!
      );
  }

  switch (field.tipo) {
    case DeclarationFieldType.Text:
      return yup.mixed();

    case DeclarationFieldType.TextArea:
      return yup.string();

    case DeclarationFieldType.Input:
      return createDeclarationSchemaInput(field);

    case DeclarationFieldType.Radio:
      return yup.string();
    case DeclarationFieldType.Checkbox: {
      let schema = yup.boolean();
      return schema;
    }
    default:
      return yup.mixed(); // TODO Implementare
  }
}

/**
 * Recupera il prefisso da una dichiarazione da aggiungere al nome del campo,
 * e da usare come nome per l'oggetto dichiarazione nel validatore:
 *
 * {
 *  "dic_id": {"field1": ..., "field2": ...}
 * }
 *
 * <input name="dic_id.field1" />
 */
export function getFieldPrefix(declaration: Dichiarazione | null | undefined) {
  return declaration?.idTipoDichiarazione!;
}

/** Raggruppa le checkbox per il campo "raggruppamento_check" */
function getCheckboxGroups(fields: DeclarationFieldDto[]) {
  return groupBy(
    fields.filter(field => field.tipo === DeclarationFieldType.Checkbox),
    'raggruppamento_check'
  );
}

/**
 * Crea il validatore per i gruppi di checkbox.
 * Preso un gruppo (aggregato dalla key "raggruppamento_check") se sono tutti obbligatori,
 * si controlla che almeno uno dei campi sia selezionato. Se è così, il test è valido,
 * altrimenti si genera un errore sull'ultimo elemento del gruppo.
 */
function createCheckboxGroupValidation(
  schema: any,
  group: DeclarationFieldDto[]
) {
  // Il gruppo è "almeno uno selezionato" se _tutte_ le checkbox sono obbligatorie
  if (group.some(field => field.controllo !== 'O')) {
    return schema;
  }

  // Recupero il nome del gruppo (uguale per tutte le checkbox)
  const groupName = group[0].raggruppamento_check;

  // Aggiungo il test di validazione
  return schema.test({
    name: 'group_' + groupName,
    message:
      'È obbligatorio selezionare almeno una voce tra quelle disponibili.',
    test: function (value: { [key: string]: any }, ctx: any) {
      // Prendo tutti i campi del gruppo in esame e filtro prendendo solo quelle "checked"
      const checked = Object.values(group)
        .filter(
          field =>
            field.raggruppamento_check === groupName &&
            field.tipo === DeclarationFieldType.Checkbox
        )
        .map(f => value[f.nome])
        .filter(f => !!f);

      // Se almeno uno è selezionato, il test è valido
      if (checked.length > 0) return true;

      // Altrimenti, genero un errore sull'ultimo elemento del gruppo
      return ctx.createError({
        path: ctx.path + '.' + group[group.length - 1].nome,
        message:
          'È obbligatorio selezionare almeno una voce tra quelle disponibili.'
      });
    }
  });
}
