import { FileOutlined } from '@ant-design/icons';
import { GruppoAllegati } from 'client/api/backend/schemas';
import { getAnchorId } from 'client/ui/anchor/getAnchorId';
import { StateAnchorLink } from 'client/ui/anchor/StateAnchorLink';
import React from 'react';

export interface IProcedureAttachmentAnchorLinkProps {
  attachments: GruppoAllegati;
}

export function ProcedureAttachmentAnchorLink(
  props: IProcedureAttachmentAnchorLinkProps
) {
  const { attachments } = props;
  return (
    <StateAnchorLink
      href={`#${getAnchorId(attachments?.idGruppoAllegato)}`}
      title={attachments?.denominazioneGruppoAllegato}
    />
  );
}
