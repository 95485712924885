import {
  AzioniDocumento,
  AzioniGruppoAllegato
} from 'client/api/backend/schemas';
import { useMemo } from 'react';
import { EditAttachmentsActions } from '../../declaration/actions/DeclarationActions';
import { filterActions } from './utils/filterActions';

export function filterAttachmentsActions(
  attachments: AzioniGruppoAllegato[] | undefined,
  isAssigned: boolean | undefined
) {
  if (!attachments) return [];
  if (isAssigned) return attachments;
  const result =
    attachments.map(attachmentGroup => {
      if (!attachmentGroup) return attachmentGroup;
      return {
        ...attachmentGroup,
        azioni: filterActions(
          attachmentGroup.azioni,
          isAssigned,
          EditAttachmentsActions
        )
      };
    }) ?? [];

  return result;
}

// Rimuovo gli allegati duplicati (già nei documenti)
function filterAttachments(
  attachments: AzioniGruppoAllegato[],
  documents: AzioniDocumento[]
): AzioniGruppoAllegato[] {
  // Genero la lista degli ID degi gruppi allegati presenti nei documenti
  const documentAttachments = documents
    .map(doc =>
      doc.documento?.gruppoAllegati?.map(
        d => d.gruppoAllegati?.idGruppoAllegato
      )
    )
    .flat()
    .filter(item => !!item) as string[];

  // Rimuovo i gruppi duplicati
  return attachments.filter(attachment => {
    if (!attachment.gruppoAllegati?.idGruppoAllegato) return true;
    return !documentAttachments.includes(
      attachment.gruppoAllegati.idGruppoAllegato
    );
  });
}

export function useAttachmentsActions(
  attachmentGroups: AzioniGruppoAllegato[],
  isAssigned: boolean | undefined,
  documents: AzioniDocumento[]
) {
  const result = useMemo(() => {
    const attachmentsActions = filterAttachmentsActions(
      attachmentGroups,
      isAssigned
    );
    return filterAttachments(attachmentsActions, documents);
  }, [attachmentGroups, isAssigned, documents]);

  return result;
}
