import {
  useGetInfoGenerali,
  useGetOggettiCollegati
} from 'client/api/backend/procedimenti/procedimenti';
import {
  Dichiarazione,
  Documento,
  DocumentoSenzaAzioni,
  GruppoAllegati,
  ProcedimentoConOggettiCollegati
} from 'client/api/backend/schemas';
import { getDeclarationsInitialValues } from 'client/components/schema/declaration/getDeclarationsInitialValues';
import { normalizeDeclaration } from 'client/components/schema/declaration/normalize/normalizeDeclaration';
import { declarationSort } from 'client/components/schema/declaration/useDeclarations';
import { getDocumentsInitialValues } from 'client/components/schema/document/useDocuments';

/**
 * Ritorna la procedure normalizzata
 */
export function useProcedure(procedureId: string) {
  const procedureResult = useGetInfoGenerali(procedureId);
  const contentResult = useGetOggettiCollegati(procedureId);

  // Ordino i documenti
  const documents = contentResult.data?.data?.documenti?.sort((a, b) => (a.ordinamento ?? 0) - (b.ordinamento ?? 0)) // prettier-ignore
  // Ordino le dichiarazioni e gli allegati dei documenti
  documents?.forEach(document => {
    if (document.dichiarazioni) {
      document.dichiarazioni = document.dichiarazioni
        .sort(declarationSort)
        .map(normalizeDeclaration);
    }
    if (document.gruppoAllegati) {
      document.gruppoAllegati.sort((a, b) => (a.ordinamento ?? 0) - (b.ordinamento ?? 0)); // prettier-ignore
    }
  });

  const allDeclarations = contentResult.data?.data?.dichiarazioni
    ?.sort(declarationSort)
    .map(normalizeDeclaration);

  const procedureContent: ProcedimentoConOggettiCollegati = {
    ...contentResult.data?.data,
    dichiarazioni: filterDeclarations(allDeclarations, documents),
    documenti: documents,
    gruppiAllegati: filterProcedureAttachmentGroups(
      contentResult.data?.data?.gruppiAllegati,
      documents
    )
  };

  const initialValues = {
    ...getDeclarationsInitialValues(allDeclarations),
    ...getDocumentsInitialValues(documents as Documento[])
  };

  return {
    initialValues,
    content: {
      ...contentResult,
      result: procedureContent
    },
    procedure: {
      ...procedureResult,
      result: procedureResult.data?.data
    }
  };
}

function filterDeclarations(
  declaration: Dichiarazione[] | undefined,
  documents: DocumentoSenzaAzioni[] | undefined
) {
  if (!declaration) return [] as Dichiarazione[];
  const documentDeclarations =
    documents?.flatMap(document => document.dichiarazioni!) ?? [];

  return declaration.filter(
    declaration =>
      !documentDeclarations.some(
        d => d.idTipoDichiarazione === declaration.idTipoDichiarazione
      )
  );
}

/**
 * Filtra i gruppi allegati del procedimento, rimuovendo quelli già presenti nei documenti
 * così da non mostrarli nella sezione "Gruppo allegati" della procedura.
 */
function filterProcedureAttachmentGroups(
  allGroups: GruppoAllegati[] | undefined,
  documents: DocumentoSenzaAzioni[] | undefined
) {
  if (!allGroups) return [] as GruppoAllegati[];
  if (!documents) return allGroups;

  const documentGroups =
    documents?.flatMap(document =>
      document.gruppoAllegati?.map(group => group.idGruppoAllegato)
    ) ?? [];
  return allGroups
    ?.sort((a, b) => (a.ordinamento ?? 0) - (b.ordinamento ?? 0))
    .filter(group => {
      return !documentGroups.includes(group.idGruppoAllegato);
    });
}
