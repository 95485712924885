import React from 'react';
import { DownloadOutlined, SyncOutlined } from '@ant-design/icons';
import { Alert, Button, Space, Typography } from 'antd';
import { useGetAzioniDocumento } from 'client/api/backend/attivita/attivita';
import { AzioniSezione, Documento } from 'client/api/backend/schemas';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { useImperativeHandle, useState } from 'react';

const { Paragraph, Text } = Typography;

const JNLP_REFETCH_INTERVAL_MS = 5 * 1_000;

export interface IStandardSectionJnlpWindowManagerProps {
  taskId: string;
  document: Documento | undefined;
  section: AzioniSezione;
}

const getSectionKey = (section: AzioniSezione | null | undefined) => {
  return [
    section?.sezione?.nomeCompletoFile ?? '',
    section?.sezione?.idSezioneDocumento ?? '',
    section?.sezione?.dataUltimaModifica ?? ''
  ].join('_');
};

export type IStandardSectionJnlpWindowManagerHandle = {
  open: (objectUrl: string, section: AzioniSezione) => void;
};

/**
 * Gestone "FAST" della modale per la modifica delle sezioni
 */
export const StandardSectionJnlpWindowManager = React.forwardRef(
  (
    props: IStandardSectionJnlpWindowManagerProps,
    ref: React.ForwardedRef<IStandardSectionJnlpWindowManagerHandle>
  ) => {
    const [objectUrl, setObjectUrl] = useState('');
    const [visible, setVisible] = useState(false);
    const [previousKey, setPreviousKey] = useState(
      getSectionKey(props.section)
    );

    useImperativeHandle(ref, () => ({
      open: (objectUrl: string, section: AzioniSezione) => {
        setObjectUrl(objectUrl);
        setPreviousKey(getSectionKey(section));
        setVisible(true);
      }
    }));

    const documentsResult = useGetAzioniDocumento(props.taskId, {
      query: {
        enabled: visible,
        refetchInterval: JNLP_REFETCH_INTERVAL_MS,
        refetchIntervalInBackground: true,
        onSuccess: data => {
          const nextSection = data.data
            ?.find(
              d =>
                d.documento?.idTipoDocumento === props.document?.idTipoDocumento
            )
            ?.documento?.sezioni?.find(
              s =>
                s.sezione?.idTipoSezione ===
                props.section.sezione?.idTipoSezione
            );

          const nextKey = getSectionKey(nextSection);
          console.log('nextKey', nextKey, 'prevKey', previousKey, documentsResult.data?.data); // prettier-ignore
          if (nextKey !== previousKey) {
            setVisible(false);
          }
        }
      }
    });

    const handleReload = () => {
      documentsResult.refetch();
    };

    return (
      <ModalDialog
        title={'Modifica Sezione'}
        visible={visible}
        size="small"
        onCancel={() => {
          setVisible(false);
        }}
        footer={
          <Space>
            <Button
              icon={<SyncOutlined />}
              children="Aggiorna manualmente"
              onClick={handleReload}
              loading={documentsResult.isFetching}
              disabled={documentsResult.isFetching}
            />
          </Space>
        }
        maskClosable={false}
      >
        <Paragraph>
          A breve verrà avviato il download del programma per la modifica della
          sezione.
        </Paragraph>
        <Paragraph>
          Non appena scaricato, avviare il programma con estensione{' '}
          <Text strong>.jnlp</Text>. Verrà aperto il proprio Editor di
          Documenti. Effettuare e salvare le modifiche, quindi uscire
          dall'editor.
        </Paragraph>
        <Paragraph>
          La pagina si ricaricherà automaticamente al termine delle modifiche.
          In caso contrario, premere il pulsante "Aggiorna manualmente".
        </Paragraph>
        <Alert
          message="Se il download non parte automaticamente, è possibile avviare nuovamente il download."
          type="info"
          action={
            <a
              href={objectUrl}
              target="_blank"
              title="Avvia il download"
              download="documentiManager.jnlp"
            >
              <Button
                icon={<DownloadOutlined />}
                children="Avvia il download"
              />
            </a>
          }
        />
      </ModalDialog>
    );
  }
);
