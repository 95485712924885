import { FileDoneOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { MassiveSignJnlpWindowManager } from 'client/components/schema/task/jnlp/MassiveSignJnlpWindowManager';
import { TaskActionLogic } from 'client/logic/TaskActionLogic';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { UserLogic } from 'common/schema/user/UserLogic';
import React, { useState } from 'react';

export interface IMassiveFastSignMenuItemProps {
  tasks: AttivitaDTO[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MassiveFastSignMenuItem(props: IMassiveFastSignMenuItemProps) {
  const { tasks, setLoading, ...menuProps } = props;
  const currentUser = useCurrentUser();

  const [visible, setVisible] = useState(false);

  const disabled = tasks.some(
    task => !TaskActionLogic.isAssigned(task, currentUser)
  );

  const hidden = !UserLogic.hasPermission(currentUser, 'firma_massiva');

  return (
    <>
      <Menu.Item
        key="massive-fast-sign"
        disabled={disabled}
        hidden={hidden}
        onClick={async () => {
          setVisible(true);
        }}
        {...menuProps}
      >
        <IconWrapper icon={<FileDoneOutlined />} /> Firma con dispositivo
      </Menu.Item>
      <MassiveSignJnlpWindowManager
        taskIds={[...tasks.map(task => task.idTaskInstance!)]}
        visible={visible}
        hide={() => {
          setVisible(false);
        }}
      />
    </>
  );
}
