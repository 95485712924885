import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { IFixturesNotificaionsPriorita } from 'client/fixtures/FixturesNotifications';
import { assertNever } from 'common/utils/assertNever';
import { NotificaResponseDTO } from 'client/api/backend/schemas';

const Wrapper = styled.span`
  margin-right: 4px;
`;
function getPriorityTag(priorita: IFixturesNotificaionsPriorita) {
  switch (priorita) {
    case IFixturesNotificaionsPriorita.BASSA:
      return <Tag>{priorita}</Tag>;
    case IFixturesNotificaionsPriorita.NORMALE:
      return <Tag color="blue">{priorita}</Tag>;
    case IFixturesNotificaionsPriorita.ALTA:
      return <Tag color="orange">{priorita}</Tag>;
    case IFixturesNotificaionsPriorita.URGENTE:
      return <Tag color="red">{priorita}</Tag>;
    default:
      console.warn("[NotificationPriorityTag] Nessuna priorità trovata: ", {priorita}); // prettier-ignore
      assertNever(priorita);
  }
}

interface NotificationTagProps {
  notification: NotificaResponseDTO;
}

/**
 * Tag della priorità di una notifica
 */
export function NotificationPriorityTag(props: NotificationTagProps) {
  const { notification } = props;
  if (!notification.priorita) return null;

  return <Wrapper>{getPriorityTag(notification.priorita as any)}</Wrapper>;
}
