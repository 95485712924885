/**
 * Ottiene il messaggio di un errore avvenuto a livello di network.
 */
export function getNetworkErrorMessage(err: any, defaultMessage?: string) {
  if (err?.response?.data?.message) {
    return err.response.data.message;
  }

  if (err?.response?.data?.esito?.errore?.messaggioDiErrore) {
    return err?.response?.data?.esito?.errore?.messaggioDiErrore;
  }

  if (err?.response?.data?.errore?.messaggioDiErrore) {
    return err?.response?.data?.errore?.messaggioDiErrore;
  }

  if (err?.message) return err.message;

  return err || defaultMessage;
}
