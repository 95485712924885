import React, { useContext, useMemo } from 'react';
import { IRemoteSignAction } from './reducer/RemoteSignActions';
import {
  IRemoteSignHandlers,
  IRemoteSignState,
  useRemoteSignReducer
} from './reducer/RemoteSignReducer';

export interface IRemoteSignContext {
  state: IRemoteSignState;
  dispatch: React.Dispatch<IRemoteSignAction>;
  handlers: IRemoteSignHandlers;
}

export const RemoteSignContext = React.createContext<IRemoteSignContext>({
  state: {},
  dispatch: () => {},
  handlers: {}
} as any);

export interface IRemoteSignContextProviderProps {
  children: React.ReactNode;
}

export function RemoteSignContextProvider(
  props: Readonly<IRemoteSignContextProviderProps>
) {
  const { children } = props;

  const remoteSignReduce = useRemoteSignReducer();

  const value = useMemo(() => remoteSignReduce, [remoteSignReduce]);

  return (
    <RemoteSignContext.Provider value={value}>
      {children}
    </RemoteSignContext.Provider>
  );
}

export function useRemoteSignContext() {
  return useContext(RemoteSignContext);
}
