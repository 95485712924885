import * as React from 'react';
import { Space, Typography } from 'antd';
import { ProcedureTableTag } from 'client/ui/tag/ProcedureTableTag';
import styled from 'styled-components';
import { ProcedimentoDTO } from 'client/api/backend/schemas/procedimentoDTO';
const { Paragraph } = Typography;
import { down } from 'styled-breakpoints';
import { TaskEditedFlagContainer } from 'client/components/schema/task/validation/TaskEditedFlag';

const SpaceStyled = styled(Space)`
  max-width: 100%;
`;

const ProcedureTitle = styled.div`
  font-size: 20px;
  height: 32px;
  /* ${down('md')} {
    font-size: 18px;
  } */
`;
const ProcedureSubjectText = styled(Paragraph)`
  &&& {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
`;

const TitleNormal = styled.span`
  font-weight: 400;
`;

export interface PageTitleProps {
  /** Oggetto del Procedimento */
  procedure?: ProcedimentoDTO | null;
  /** Nome Task o Nome Fase */
  name?: string;
  /** Testo alternativo da mostrare al posto dell'Oggetto del Procedimento */
  subject?: string;
}

/**
 * Titolo complesso della pagina singola (Attività o Procedimento)
 *
 * Nella prima riga visualizza Icona e Nome del Procedimento più il nome passato al componente (es. Attività)
 * Nella seconda riga visualizza il soggetto del Procedimento o, in alternativa, un testo passato al componente
 */
export function PageTitle(props: PageTitleProps) {
  const { procedure, name, subject } = props;

  const procedureTitle = (
    <Space>
      {procedure?.tipologiaProcedimento?.nomeTipo}
      {name && (
        <TitleNormal>
          {' | '}
          {name}
        </TitleNormal>
      )}
      <TaskEditedFlagContainer />
    </Space>
  );

  const procedureSubject = procedure?.oggetto
    ? 'Numero: ' + procedure?.numero + ' | Oggetto: ' + procedure?.oggetto
    : 'Numero: ' + procedure?.numero;

  return (
    <SpaceStyled direction="vertical" size={0}>
      <ProcedureTitle>
        <ProcedureTableTag
          name={procedureTitle}
          color={procedure?.tipologiaProcedimento?.coloreRendering}
          icon={procedure?.tipologiaProcedimento?.iconaRendering}
          compact={false}
        />
      </ProcedureTitle>
      <ProcedureSubjectText ellipsis>
        {subject ?? procedureSubject}
      </ProcedureSubjectText>
    </SpaceStyled>
  );
}
