import React from 'react';
import styled from 'styled-components';
import Paragraph from 'antd/lib/typography/Paragraph';
import { ISORelativeDate } from 'client/ui/display/date/ISORelativeDate';
import { ListItemNotification } from './ListItemNotification';
import { ButtonIconToggleRead } from './ButtonIconToggleRead';
import { ListItemMetaNotification } from './ListItemMetaNotification';
import { NotificationPriorityTag } from './NotificationPriorityTag';
import { NotificationIconType } from './NotificationIconType';
import { NotificaResponseDTO } from 'client/api/backend/schemas';
import moment from 'moment';
import { ButtonNotificationRead } from './ButtonNotificationRead';
import { ReadIndicator } from './ReadIndicator';

const SecondaryText = styled.span`
  color: ${props => props.theme.textColorSecondary};
  opacity: 0.75;
  font-size: 12px;
  display: inline-flex;
  line-height: 1.3;
  padding-bottom: 6px;
`;
const TitleParagraph = styled(Paragraph)`
  &&& {
    margin-bottom: 0;
  }
`;

const TextRow = styled.div<{ read?: boolean }>`
  ${props => props.read && 'opacity: 0.75;'}
`;
const DescriptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

interface NotificationListItemProps {
  notification: NotificaResponseDTO;
}

/**
 * Singola notifica
 */
export function NotificationListItem(props: NotificationListItemProps) {
  const { notification } = props;

  return (
    <ListItemNotification
      type={notification.tipo as any}
      actions={[
        <ReadIndicator
          read={
            notification.dataPresaVisione
              ? moment(notification.dataPresaVisione).toDate()
              : null
          }
        />
      ]}
    >
      <ListItemMetaNotification
        title={
          <TextRow read={notification.dataPresaVisione ? true : false}>
            {/* Riga Struttura */}
            {notification.nomeStrutturaMittente && (
              <SecondaryText>
                {notification.nomeStrutturaMittente}
              </SecondaryText>
            )}
            {/* Riga Oggetto */}
            {notification.oggetto && (
              <TitleParagraph strong ellipsis={{ expandable: true, rows: 1 }}>
                <NotificationIconType type={notification.tipo as any} />
                {notification.oggetto}
              </TitleParagraph>
            )}
            {/* Riga Messaggio */}
            <Paragraph ellipsis={{ expandable: true, rows: 3 }}>
              {!notification.oggetto && (
                // Se non è presente un oggetto, visualiziamo qui l'icona del tipo di notifica
                <NotificationIconType type={notification.tipo as any} />
              )}
              {notification.messaggio}
            </Paragraph>
          </TextRow>
        }
        description={
          <DescriptionRow>
            <div>
              <NotificationPriorityTag {...props} />
              <SecondaryText>
                <ISORelativeDate
                  date={notification.dataNotifica}
                  relativeOnly
                />
              </SecondaryText>
            </div>
            <ButtonNotificationRead
              notificationId={notification.id!}
              read={
                notification.dataPresaVisione
                  ? moment(notification.dataPresaVisione).toDate()
                  : null
              }
            />
          </DescriptionRow>
        }
      />
    </ListItemNotification>
  );
}
