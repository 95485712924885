import * as React from 'react';

import { Timeline, TimelineItemProps } from 'antd';
import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';

const TimelineItemStyle = styled(Timeline.Item)`
  &.ant-timeline-item.custom-timeline {
    > .ant-timeline-item-label {
      width: 130px; // base 144px -14px
      top: -4.001px;
      font-size: ${props => props.theme.fontSizeSm};
      color: ${props => props.theme.textColorSecondary};
      position: absolute;
      text-align: right;
      ${down('sm')} {
        display: none;
      }
    }
    > .ant-timeline-item-tail {
      left: 144px; // base 144px
      ${down('sm')} {
        left: 31px; // base 144px - 113px
      }
    }
    > .ant-timeline-item-head {
      left: 144px; // base 144px
      margin-left: -4px;
      ${down('sm')} {
        left: 31px; // base 144px - 113px
      }

      &.ant-timeline-item-head-custom {
        width: 24px;
        height: 24px;
        font-size: 24px;
        margin-left: 0;
        padding: 0 1px;
      }
    }
    > .ant-timeline-item-content {
      left: 136px; // base 144px -8px
      top: -15.001px;
      width: calc(100% - 154px); // base 144px +10px
      text-align: left;
      ${down('sm')} {
        left: 23px; // base 144px - 113px -8px
        width: calc(100% - 41px); // base 144px - 113px +10px
      }
    }
  }
  &:last-child {
    padding-bottom: 0;
    > .ant-timeline-item-tail {
      .custom-collapse:last-child & {
        display: none;
      }
    }
  }
`;
export const LabelMobile = styled.div`
  font-size: ${props => props.theme.fontSizeSm};
  color: ${props => props.theme.textColorSecondary};
  ${up('sm')} {
    display: none;
  }
`;

export interface CustomTimelineItemProps extends TimelineItemProps {
  children: React.ReactNode;
}

export function TimelineItem(props: CustomTimelineItemProps) {
  const { children, ...rest } = props;
  return (
    <TimelineItemStyle {...rest} className="custom-timeline">
      {props.children}
    </TimelineItemStyle>
  );
}
