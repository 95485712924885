import { message } from 'antd';
import React from 'react';

export interface IMessageGlobalConfigurationProps {}

/**
 * Richiama la configurazione globale per i message di antd per impostare
 * il tempo di visualizzazione dei messaggi.
 */
export function MessageGlobalConfiguration(
  props: IMessageGlobalConfigurationProps
) {
  const {} = props;
  message.config({ duration: 5 });
  return null;
}
