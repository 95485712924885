import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, CardProps, Col, Row, Typography } from 'antd';
const { Title } = Typography;
import { FixturesTipologiaProcedimentoDTO } from 'client/fixtures/FixturesHomeProcedimenti';
import { ProcedureButton } from 'client/routes/home/procedure/detail/ProcedureButton';
import Search from 'antd/lib/input/Search';
import { useGetTipiProcedimentoPerUtente } from 'client/api/backend/procedimenti/procedimenti';

const SearchInputStyle = styled(Search)`
  width: 300px;
`;

export interface ProcedureListCardProps extends CardProps {}

/**
 * Card personalizzata per la Home con i procedimenti
 */
export function ProcedureListCard(props: ProcedureListCardProps) {
  const procedures = useGetTipiProcedimentoPerUtente({});
  const shownProcedures = procedures.data?.data;

  return (
    <Card
      bordered={false}
      // title={<Title level={2}>Tipi di Procedimento</Title>}
      // extra={
      //   <SearchInputStyle
      //     size="large"
      //     placeholder="Cerca Tipo Procedimento"
      //     onSearch={() => {}}
      //     enterButton
      //   />
      // }
    >
      <Row gutter={[24, 24]}>
        {shownProcedures?.map((procedure, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <ProcedureButton procedure={procedure} />
          </Col>
        ))}
      </Row>
    </Card>
  );
}
