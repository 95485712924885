import * as React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import { PhaseTimeInfo } from 'client/components/schema/history/detail/PhaseTimeInfo';

const HeaderPhase = styled.div`
  position: relative;
  background-color: #fff;
  border: 2px solid ${props => props.theme.borderColorSplit};
  display: flex;
  align-items: center;
  padding: 6px 26px 6px 30px;
  min-height: 32px;
  border-radius: 16px;
  min-width: 180px;
  width: 100%;
`;

const Point = styled.div`
  position: absolute;
  left: 8px;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border: 2px solid ${props => props.theme.primaryColor};
  border-radius: 50%;
`;

const PhaseTitle = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  padding-right: 10px;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  min-width: 0;
`;

export interface PhaseHeaderTitleProps {
  title: React.ReactNode;
  from?: Date | null;
  to?: Date | null;
}

/**
 * // TODO
 */
export function PhaseHeaderTitle(props: PhaseHeaderTitleProps) {
  const { title, ...rest } = props;
  return (
    <HeaderPhase>
      <Point />
      <TitleWrapper>
        <PhaseTitle>{title}</PhaseTitle>
        <PhaseTimeInfo {...rest} />
      </TitleWrapper>
    </HeaderPhase>
  );
}
