import React from 'react';
import { FormikValues } from 'formik';
import { FormItem, SingleFormItemProps } from '../FormItem';
import { RadioGroupInput, RadioGroupInputProps } from './RadioGroupInput';
import { createGlobalStyle } from 'styled-components';

export const RadioInputGlobalStyle = createGlobalStyle`
    .radio-input-vertical {
      > label {
        display: block;
        height: 24px;
        line-height: 24px;
      }
    }
`;

interface Props<T extends FormikValues> extends RadioGroupInputProps<T> {
  itemProps?: SingleFormItemProps<T>;
  label?: React.ReactNode;
}

/**
 * Form Item per input di tipo Radio.
 */
export function RadioGroupInputItem<T extends FormikValues>(props: Props<T>) {
  const { name, label, itemProps, ...otherProps } = props;

  return (
    <FormItem {...itemProps} name={name} label={label}>
      <RadioGroupInput {...otherProps} name={name} />
    </FormItem>
  );
}
