import { Allegato, AttivitaDTO, AzioneDTO } from 'client/api/backend/schemas';
import { List } from 'antd';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { AttachmentActionLogic } from 'client/components/schema/declaration/actions/AttachmentActionLogic';
import { IDocumentFile } from 'client/ui/form/input/UploadInput';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { AttachmentRequiredLabel } from './AttachmentRequiredLabel';
import { AttachmentRequiredSignedLabel } from './AttachmentRequiredSignedLabel';
import { AddFileRow } from './file/AddFileRow';
import { FileRow } from './file/FileRow';

export const AttachmentsTypeName = styled.h3`
  font-size: 14px;
  margin-bottom: 6px;
  line-height: 1.3;
`;

const AttachmentSectionWrapper = styled.div`
  margin-top: 6px;
  margin-bottom: 18px;
`;

export interface IAttachmentSectionProps {
  attachment: Allegato;
  actions: AzioneDTO[];
  name: string;
  task: AttivitaDTO | null;
  isAssigned: boolean;
}

/** Sezione di un singolo allegato. Contiene la gestione a più file. */
export function AttachmentSection(props: IAttachmentSectionProps) {
  const { attachment, name, task, actions, isAssigned } = props;

  const taskId = task?.idTaskInstance;

  const canUpload = AttachmentActionLogic.canUpload(actions);

  const componentName = `${name}.${attachment.idTipoAllegato}`;

  const editor = useRelationEditor<IDocumentFile>(componentName);

  const handleRemove = (item: IDocumentFile) => {
    editor.remove(item);
  };

  if (!attachment.idTipoAllegato || !taskId) return null;

  // Se non ci sono file e non è assegnata, nascondi la lista
  if (attachment.files?.length === 0 && !isAssigned) return null;

  return (
    <AttachmentSectionWrapper>
      <AttachmentsTypeName>
        {attachment.denominazioneAllegato}
      </AttachmentsTypeName>
      <List
        size="small"
        bordered
        dataSource={editor.items}
        locale={{
          emptyText: <span>Nessun file caricato</span>
        }}
        renderItem={item => (
          <List.Item>
            <FileRow
              item={item}
              key={item.file?.id}
              onRemove={() => handleRemove(item)}
              taskId={taskId}
              actions={actions}
            />
          </List.Item>
        )}
      />

      <AddFileRow
        attachment={attachment}
        editor={editor}
        taskId={taskId}
        canUpload={canUpload}
      />
      <AttachmentRequiredLabel
        required={!!attachment.flAllegatoObbligatorio && editor.count === 0}
        plural={!attachment.numMaxFile || attachment.numMaxFile > 1}
        visible={canUpload}
      />
      <AttachmentRequiredSignedLabel
        signed={attachment.flFirmaObbligatoria}
        plural={!attachment.numMaxFile || attachment.numMaxFile > 1}
        visible={canUpload}
      />
    </AttachmentSectionWrapper>
  );
}
