import { useField, useFormikContext } from 'formik';
import { cloneDeep, without } from 'lodash';
import { useCallback } from 'react';
import {
  RelationActiveHandle,
  useRelationActiveHandle
} from './useRelationActiveHandle';

export interface BaseRelationEditor<T> {
  items: T[];
  /** Inserisce un elemento alla posizione indicata */
  insert: (index: number, item: T) => T;
  /** Aggiunge un elemento alla fine dell'array */
  add: (item: T) => T;
  /** Aggiorna un elemento */
  update: (prev: T, item: T) => void;
  /** Rimuove un elemento */
  remove: (item: T) => T;
  /** Sostituisce tutti gli elementi */
  replaceAll: (items: T[]) => void;
  /** Ritorna l'ultimo elemento */
  last: () => T | null;
  /** Aggiunge più elementi alla fine dell'array */
  addItems: (items: T[]) => void;
}

/**
 * Editor per gestire la modifica di un array dentro Formik. Include un
 * `handle` per gestire la modal di modifica dell'elemento attivo.
 */
export interface RelationEditor<T> extends BaseRelationEditor<T> {
  /** Handle di modifica dell'elemento corrente (per Modal o Drawer) */
  handle: RelationActiveHandle<T>;
  count: number;
}

/**
 * Hook per gestire la modifica di un array dentro formik
 */
export function useRelationEditor<T>(name: string): RelationEditor<T> {
  const formik = useFormikContext();
  const [field, _, helpers] = useField<T[]>(name);

  const items = field.value ?? [];

  /** Inserisce un elemento alla posizione indicata */
  const insert = useCallback(
    (index: number, item: T) => {
      const nextItems = [...items.slice(0, index), item, ...items.slice(index)];
      helpers.setValue(nextItems);
      setTimeout(() => {
        helpers.setTouched(true, true);
      }, 0);
      return item;
    },
    [items]
  );

  /** Aggiunge un elemento alla fine dell'array */
  const add = useCallback(
    (item: T) => {
      const nextIndex = items.length;
      return insert(nextIndex, item);
    },
    [items, insert]
  );

  /** Aggiunge una lista di elementi alla fine dell'array */
  const addItems = useCallback(
    (list: T[]) => {
      const nextItems = [...items, ...list];
      helpers.setValue(nextItems);
      setTimeout(() => {
        helpers.setTouched(true, true);
      }, 0);
    },
    [items]
  );

  /** Aggiorna un elemento */
  const update = useCallback(
    (prev: T, item: T) => {
      const index = items.indexOf(prev);

      // Evitiamo di perdere le proprietà del vecchio oggetto.
      const updated = Object.assign(cloneDeep(prev), item);

      formik.setFieldValue(`${name}[${index}]`, updated);
      setTimeout(() => {
        helpers.setTouched(true, true);
      }, 0);
    },
    [items]
  );

  /** Rimuove un elemento */
  const remove = useCallback(
    (item: T) => {
      helpers.setValue(without(items, item));
      setTimeout(() => {
        helpers.setTouched(true, true);
      }, 0);
      return item;
    },
    [items]
  );

  /** Sostituisce gli elementi */
  const replaceAll = useCallback(
    (nextItems: T[]) => {
      helpers.setValue(nextItems);
      setTimeout(() => {
        helpers.setTouched(true, true);
      }, 0);
    },
    [helpers, items]
  );

  /** Ritorna l'ultimo elemento */
  const last = useCallback(() => {
    if (items.length === 0) return null;
    return items[items.length - 1];
  }, [items]);

  const editor = {
    items,
    insert,
    add,
    update,
    remove,
    replaceAll,
    last,
    addItems
  };

  const handle = useRelationActiveHandle(editor);
  return {
    ...editor,
    handle,
    count: items.length
  };
}
