import { Dichiarazione } from 'client/api/backend/schemas';
import { getFields } from 'client/components/schema/declaration/getFields';
import { DeclarationForm } from 'client/components/schema/task/declaration/DeclarationForm';
import { getFieldPrefix } from 'client/components/schema/task/declaration/validation/createDeclarationValidator';
import { DeclarationTitle } from 'client/ui/declaration/DeclarationTitle';
import { FormikForm } from 'client/ui/form/FormikForm';
import React from 'react';

export interface IProcedureDeclarationSectionProps {
  declaration: Dichiarazione;
}

export function ProcedureDeclarationSection(
  props: IProcedureDeclarationSectionProps
) {
  const { declaration } = props;

  return (
    <>
      <DeclarationTitle
        declaration={declaration}
        title={declaration.titoloDichiarazione ?? 'Dichiarazione'}
      />
      <FormikForm nested disableEnterSubmit editable={false}>
        <DeclarationForm
          fields={getFields(declaration)}
          prefix={getFieldPrefix(declaration)}
          editable={false}
        />
      </FormikForm>
    </>
  );
}
