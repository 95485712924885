import { AzioniDocumento } from 'client/api/backend/schemas';
import { useMemo } from 'react';
import {
  DocumentSignActions,
  EditSectionActions
} from '../../declaration/actions/DeclarationActions';
import { filterAttachmentsActions } from './useAttachmentsActions';
import { filterDeclarationsActions } from './useDeclarationsActions';
import { filterActions } from './utils/filterActions';

function filterDocumentsActions(
  documents: AzioniDocumento[] | undefined,
  isAssigned: boolean | undefined
) {
  if (!documents) return [];
  if (isAssigned) return documents;
  const result = documents.map(document => {
    if (!document.documento) return document;
    return {
      azioni: filterActions(document.azioni, isAssigned, DocumentSignActions),
      documento: {
        ...document.documento,
        sezioni:
          document.documento.sezioni?.map(sezione => {
            if (!sezione) return sezione;
            return {
              ...sezione,
              azioni: filterActions(
                sezione.azioni,
                isAssigned,
                EditSectionActions
              )
            };
          }) ?? [],
        dichiarazioni: filterDeclarationsActions(
          document.documento.dichiarazioni,
          isAssigned
        ),
        gruppoAllegati: filterAttachmentsActions(
          document.documento.gruppoAllegati,
          isAssigned
        )
      }
    };
  });
  return result;
}

export function useDocumentsActions(
  documents: AzioniDocumento[],
  isAssigned: boolean | undefined
) {
  const result = useMemo(() => {
    return filterDocumentsActions(documents, isAssigned);
  }, [documents, isAssigned]);

  return result;
}
