import moment from 'moment';

export enum IFixturesNotificaionsPriorita {
  BASSA = 'BASSA',
  NORMALE = 'NORMALE',
  ALTA = 'ALTA',
  URGENTE = 'URGENTE'
}

export enum IFixturesNotificaionsTipo {
  INFO = 'INFO',
  WARNING = 'WARNING',
  NOTIFICA = 'NOTIFICA'
}

export interface IFixturesNotificaions {
  id: string;
  tipo?: IFixturesNotificaionsTipo;
  oggetto?: string;
  messaggio: string;
  priorita?: IFixturesNotificaionsPriorita;
  dataNotifica: Date;
  dataPresaVisione?: Date;
  idIstanzaProcedimento?: string;
  nomeStrutturaMittente?: string;
  idStrutturaMittente?: string;
  cfUtenteMittente?: string;

  tag?: string;
  read?: boolean;
}

export const FixturesNotificaions: IFixturesNotificaions[] = [
  {
    id: '124-21',
    tipo: IFixturesNotificaionsTipo.INFO,
    priorita: IFixturesNotificaionsPriorita.NORMALE,
    oggetto: 'Oggetto notifica Informativa',
    messaggio:
      'Lorem ipsum dolor sit amet, sea pertinax pertinacia appellantur.',
    dataNotifica: moment('2022-04-28T01:28:45.001Z').toDate(),
    nomeStrutturaMittente: 'Nome Struttura 1'
  },
  {
    id: '124-25',
    tipo: IFixturesNotificaionsTipo.INFO,
    priorita: IFixturesNotificaionsPriorita.BASSA,
    messaggio:
      'Notifica senza OGGETTO e NOME STRUTTURA MITTENTE - Lorem ipsum dolor sit amet, sea pertinax pertinacia appellantur.',
    dataNotifica: moment('2022-04-28T01:28:45.001Z').toDate()
  },
  {
    id: '124-28',
    tipo: IFixturesNotificaionsTipo.WARNING,
    oggetto: 'Oggetto della notifica',
    priorita: IFixturesNotificaionsPriorita.URGENTE,
    messaggio:
      'No ferri partem ceteros pro. Everti volumus menandri at pro. Cum illud euripidis cu, mazim deterruisset ei eum. Ex alia dolorem insolens per, malis clita laboramus duo ut, ridens appareat philosophia ea quo.',
    dataNotifica: moment('2022-04-28T01:15:45.001Z').toDate(),
    nomeStrutturaMittente: 'Struttura Emittente'
  },
  {
    id: '124-32',
    tipo: IFixturesNotificaionsTipo.NOTIFICA,
    oggetto: 'Notifica già letta',
    priorita: IFixturesNotificaionsPriorita.ALTA,
    messaggio: 'Vix elit tantas phaedrum et, ea quo vide facete scriptorem.',
    dataNotifica: moment('2022-04-26T01:28:45.001Z').toDate(),
    dataPresaVisione: moment('2022-04-27T01:28:45.001Z').toDate(),
    nomeStrutturaMittente: 'Struttura Emittente'
  }
];
