import * as React from 'react';
import { Col, Row, Space, Tabs } from 'antd';
import styled from 'styled-components';
import { up, down, between, only, createTheme } from 'styled-breakpoints';

const RowWrapper = styled(Row)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;
const ColTask = styled(Col)`
  flex: 1 1 auto;
  min-width: 0px;
`;
const ColAnchor = styled(Col)`
  flex: 0 0 150px;
  min-width: 0px;
  display: none;
  ${up('md')} {
    display: block;
    flex: 0 0 200px;
  }
  ${up('lg')} {
    flex: 0 0 280px;
  }
`;
const SpaceCol = styled(Space)`
  width: 100%;
`;

export interface TaskFormContainerProps {
  children: React.ReactNode;
  anchors: React.ReactNode;
}

export function TaskFormContainer(props: TaskFormContainerProps) {
  return (
    <RowWrapper gutter={12}>
      <ColTask>
        <SpaceCol direction="vertical" size={24}>
          {props.children}
        </SpaceCol>
      </ColTask>
      <ColAnchor>{props.anchors}</ColAnchor>
    </RowWrapper>
  );
}
