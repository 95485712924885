import { setLocale } from 'yup';
import moment from 'moment';

setLocale({
  mixed: {
    default: 'Il campo ${path} non è valido' as any,
    required: 'Il campo ${path} è un campo richiesto',
    oneOf: 'Il campo ${path} deve avere uno dei seguenti valori: ${values}',
    notOneOf:
      'Il campo ${path} non deve avere uno dei seguenti valori: ${values}',
    notType: (params: any) => {
      return typeToMessage(params.path, params.type);
    }
  },
  string: {
    length: 'Il campo ${path} deve avere esattamente ${length} caratteri',
    min: 'Il campo ${path} deve avere almeno ${min} caratteri',
    max: 'Il campo ${path} deve avere al massimo ${max} caratteri',
    matches:
      'Il campo ${path} deve seguire il seguente pattern: "${regex}"' as any,
    email: 'Il campo ${path} deve essere una email valida',
    url: 'Il campo ${path} deve essere un URL valido',
    trim: 'Il campo ${path} deve essere un testo senza spazi iniziali o finali',
    lowercase: 'Il campo ${path} deve contenere solamente caratteri minuscoli',
    uppercase: 'Il campo ${path} deve contenere solamente caratteri maiuscoli'
  },
  number: {
    min: 'Il campo ${path} deve essere maggiore o uguale di ${min}',
    max: 'Il campo ${path} deve essere minore o uguale di ${max}',
    lessThan: 'Il campo ${path} deve essere minore di ${less}',
    moreThan: 'Il campo ${path} deve essere maggiore di ${more}',
    positive: 'Il campo ${path} deve essere un numero positivo',
    negative: 'Il campo ${path} deve essere un numero negativo',
    integer: 'Il campo ${path} deve essere un numero intero'
  },
  array: {
    min: 'Il campo ${path} deve avere almeno ${min} elementi',
    max: 'Il campo ${path} deve avere al massimo ${max} elementi'
  },
  date: {
    min: (params: any) => {
      return `La data "${params.path}" deve essere successiva al ${moment(
        params.min
      ).format('DD/MM/YYYY')}`;
    },
    max: (params: any) =>
      `La data "${params.path}" deve essere antecedente al ${moment(
        params.max
      ).format('DD/MM/YYYY')}`
  }
});

function typeToMessage(path: string, type: string) {
  const message = `Il campo ${path} deve essere `;
  switch (type) {
    case 'number':
      return message + 'un numero';
    case 'string':
      return message + 'una stringa';
    default:
      return `Il campo ${path} non è valido`;
  }
}
