import {
  GetProcedimentiSortBy,
  GetProcedimentiSortDirection
} from 'client/api/backend/schemas';
import { yup } from 'common/validation/initYup';
import moment from 'moment';
import { StringSchema } from 'yup';

function normalizeDate(date: Date) {
  if (!date) return undefined;
  return moment(date).format('YYYY-MM-DD HH:mm');
}

export const getProcedimentiParamsValidator = yup
  .object({
    numero: yup.string().notRequired(),
    tipo: yup
      .string()
      .notRequired()
      .nullable()
      .transform(value => (value == null ? undefined : value)) as StringSchema,
    stato: yup
      .string()
      .notRequired()
      .nullable()
      .transform(value => (value == null ? undefined : value)) as StringSchema,
    dataAvvioDal: yup
      .string()
      .notRequired()
      .transform(value => normalizeDate(value)),
    dataAvvioAl: yup
      .string()
      .notRequired()
      .transform(value => normalizeDate(value)),
    ufficioAvvio: yup
      .string()
      .notRequired()
      .nullable()
      .transform(value => (value == null ? undefined : value)) as StringSchema,
    responsabile: yup
      .string()
      .notRequired()
      .nullable()
      .transform(value => (value == null ? undefined : value)) as StringSchema,
    ufficioResponsabile: yup
      .string()
      .notRequired()
      .nullable()
      .transform(value => (value == null ? undefined : value)) as StringSchema,
    oggetto: yup
      .string()
      .notRequired()
      .nullable()
      .transform(value => (value == null ? undefined : value)) as StringSchema,
    page: yup.number().notRequired().default(1).min(1),
    pageSize: yup.number().notRequired().default(10),
    sortBy: yup
      .string()
      .oneOfEnum(GetProcedimentiSortBy)
      .notRequired()
      .default(GetProcedimentiSortBy.DATA_ULTIMA_MODIFICA),
    sortDirection: yup
      .string()
      .oneOfEnum(GetProcedimentiSortDirection)
      .notRequired()
      .default(GetProcedimentiSortDirection.DESC)
  })
  .noUnknown()
  .required();
