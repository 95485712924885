import { getGetCertificatiQueryKey } from 'client/api/backend/firma-remota/firma-remota';
import { BEResponseListCertificato } from 'client/api/backend/schemas';
import { ProviderParameters } from '../../provider-parameters/ProviderParameters';
import { useRemoteSignApi } from './useRemoteSignApi';

export function useCertificateList(params: {
  provider: ProviderParameters | null | undefined;
}) {
  const { provider } = params;

  const certificateListApi = useRemoteSignApi<BEResponseListCertificato>({
    method: 'GET',
    fields: provider?.certificateParameters!,
    apiUrl: getGetCertificatiQueryKey(provider?.webserviceId!)[0],
    skip: !provider
  });

  return certificateListApi;
}
