import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { IconAssignToOther } from 'client/ui/icon/ApplicationIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { UserLogic } from 'common/schema/user/UserLogic';
import React, { useState } from 'react';
import { AssignToOtherModal } from './AssignToOtherModal';

export interface IAssignToOtherButtonProps {
  taskId: string | undefined;
}

export function AssignToOtherButton(props: IAssignToOtherButtonProps) {
  const { taskId } = props;
  const [visible, setVisible] = useState(false);
  const currentUser = useCurrentUser();

  if (!UserLogic.hasPermission(currentUser, 'assegna_task')) {
    return null;
  }

  return (
    <>
      <ButtonIcon
        icon={<IconWrapper icon={<IconAssignToOther />} />}
        onClick={e => {
          setVisible(true);
          e.stopPropagation();
        }}
        tooltip="Assegna"
        tooltipProps={{ placement: 'bottomRight' }}
      />

      <AssignToOtherModal
        taskId={taskId}
        visible={visible}
        hide={() => setVisible(false)}
      />
    </>
  );
}
