import { AttivitaDTO } from 'client/api/backend/schemas';
import { RemoteSignModalDialog } from 'client/routes/task/single/documents/actions/remote-sign/modal-dialog/RemoteSignModalDialog';
import { useRemoteSignContext } from 'client/routes/task/single/documents/actions/remote-sign/RemoteSignContext';
import { useRemoteSign } from 'client/routes/task/single/documents/actions/remote-sign/steps/hooks/useRemoteSign';
import { ModalDialogProps } from 'client/ui/modal/ModalDialog';
import React from 'react';

export interface IMassiveRemoteSignModalProps
  extends Omit<ModalDialogProps, 'children'> {
  tasks: AttivitaDTO[];
}

export function MassiveRemoteSignModal(props: IMassiveRemoteSignModalProps) {
  const { tasks } = props;
  const { state, dispatch, handlers } = useRemoteSignContext();

  // Funzione di submit che gestisce la firma remota del documento
  const handleRemoteSignSubmit = useRemoteSign({
    tasks,
    certificate: state.certificate,
    provider: state.provider,
    signType: state.signType,
    setCurrentStep: handlers.setCurrentStep,
    setErrors: handlers.setErrors,
    flCompletare: true,
    flIntermedio: false
  });

  return (
    <RemoteSignModalDialog
      {...props}
      handleRemoteSignSubmit={handleRemoteSignSubmit}
      currentStep={state.currentStep}
      dispatch={dispatch}
      errorBag={state.errorBag}
      taskList={tasks}
    />
  );
}
