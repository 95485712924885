import { AttivitaDTO } from 'client/api/backend/schemas';
import { ModalDialog, ModalDialogProps } from 'client/ui/modal/ModalDialog';
import React from 'react';
import {
  IRemoteSignAction,
  IRemoteSignErrorBag,
  RemoteSignAction
} from '../reducer/RemoteSignActions';
import { RemoteSignStep } from '../reducer/RemoteSignReducer';
import { RemoteSignCertificateStep } from '../steps/RemoteSignCertificateStep';
import { RemoteSignProviderStep } from '../steps/RemoteSignProviderStep';
import { RemoteSignSigningStep } from '../steps/RemoteSignSigningStep';

export interface IRemoteSignModalDialogProps
  extends Omit<ModalDialogProps, 'children'> {
  handleRemoteSignSubmit: (values: any) => Promise<void>;
  dispatch: (value: IRemoteSignAction) => void;
  currentStep: RemoteSignStep | null | undefined;
  // Campi necessari per la firma massiva
  taskList?: AttivitaDTO[];
  errorBag?: IRemoteSignErrorBag;
}

/**
 * Modal dialog che gestisce la firma remota del documento.
 * Centralizzata per poterla usare sia nella versione singola, sia in quella massiva
 */
export function RemoteSignModalDialog(props: IRemoteSignModalDialogProps) {
  const {
    handleRemoteSignSubmit,
    dispatch,
    currentStep,
    onCancel,
    taskList,
    errorBag
  } = props;
  return (
    <ModalDialog
      {...props}
      destroyOnClose
      maskClosable={false}
      title="Firma remota"
      size="small"
      footer={null}
      afterClose={() => {
        dispatch({ type: RemoteSignAction.Reset });
      }}
    >
      {currentStep === RemoteSignStep.Provider && (
        <RemoteSignProviderStep handleSignSubmit={handleRemoteSignSubmit} />
      )}
      {currentStep === RemoteSignStep.Certificate && (
        <RemoteSignCertificateStep handleSubmit={handleRemoteSignSubmit} />
      )}
      {currentStep === RemoteSignStep.Signing && (
        <RemoteSignSigningStep
          onClose={onCancel}
          taskList={taskList}
          errorBag={errorBag}
        />
      )}
    </ModalDialog>
  );
}
