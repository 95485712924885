import { BEResponseErroreWS } from 'client/api/backend/schemas';

/**
 * Ritorna la stringa del messaggio d'errore presente nell'esito ritornato
 * da alcune API. Se non presente ritorna il default fornito.
 */
export function getResponseErrorMessage(
  error: {
    response: { data: BEResponseErroreWS };
  },
  defaultMessage?: string
) {
  return error.response.data.esito?.errore?.messaggioDiErrore ?? defaultMessage;
}
