import { AzioneDTO } from 'client/api/backend/schemas';

export enum DeclarationFieldType {
  Text = 'T',
  TextArea = 'TA',
  Input = 'I',
  Radio = 'R',
  Checkbox = 'N',
  Select = 'L',
  AsyncSelect = 'LWS',
  Date = 'D',
  Table = 'B',
  TableHeaders = 'A',
  TableBody = 'C'
}

export enum DeclarationFieldCheckType {
  Text = 'T',
  Date = 'D',
  Email = 'X',
  IVA = 'I',
  FiscalId = 'F',
  RegEx = 'R',
  Computed = 'CC'
}

/**
 * Descrizione del campo di una dichiarazione
 */
export interface DeclarationFieldDto {
  /** Testo di aiuto */
  help?: string | null;
  /** Nome */
  nome: string;
  /** Numero di riga */
  riga: number; // TODO | null?
  /** Tipo del campo */
  tipo: DeclarationFieldType;
  azioni: AzioneDTO[]; // TODO
  nrighe: null; // TODO
  /** Valore in caso di checkbox, radio, ecc. da attribuire al campo */
  valore: any; // TODO Collegare fisicamente
  err_msg: null; // TODO
  /** Livello di annidamento */
  livello: 1; // TODO
  pattern: RegExp | null | undefined;
  tp_riga: '0' | '1';
  forzaAci: false; // TODO
  ncolonne: null; // TODO
  nome_xsd: null; // TODO
  web_serv: null; // TODO
  campo_key: 'false'; // TODO
  contatore: 10; // TODO
  controllo: null; // TODO
  /** Descrizione in HTML del campo */
  des_campo: string | null;
  documenti: []; // TODO
  editabile: boolean; // TODO
  /** Lunghezza massima del campo (dove supportato) */
  lunghezza?: number | null;
  /** Ordinamento all'interno della riga */
  posizione: number;
  campo_dati: null; // TODO
  idServizio: ''; // TODO
  interventi: []; // TODO
  sottocampi: DeclarationFieldDto[]; // TODO
  ubicazione: false; // TODO
  rigaTabella: number;
  tipoColonna: null; // TODO
  aggiungiRiga: boolean;
  campoRicerca: null; // TODO
  tp_controllo: DeclarationFieldCheckType | null;
  campo_xml_mod: null; // TODO
  colonnaTabella: number;
  duplicaTabella: false; // TODO
  /** Campi da cui dipende la visualizzazione di questo specifico campo (validazione condizionale) */
  campo_collegato: string | null;
  /** In caso sia specificato un campo collegato, indica come deve essere valorizzato  */
  val_campo_collegato: any | null;
  precompilazione: null; // TODO
  flg_precompilazione: 'N'; // TODO
  raggruppamento_check: ''; // TODO
  anagraficaCampiValoriTesti: AnagraficaCampiValoriTesti[]; // TODO
  visibile: boolean | null; // Indica quando un campo è nascosto per la "Modifica Custom"

  tipoWsSelezionato: {
    IdWSEsterno: string;
    Parametri: any[]; // TODO ??
    IdTipoWS: string; // TODO ??
    Url: string; // http://bon-web.nco.inet:7080/suape-gestioneBDU/services/ente/ricercaistat-full?nomeComune=';
    isSelected: boolean;
    Descrizione: string;
    Username: string;
    Password: string;
    ParametriOut: {
      id: WsOutParameter;
      desc: string;
      codice: string;
      valore: string;
      required: 'required' | '';
    }[];
    NomeEnte: null; // TODO ??
    NomeTipoWS: string;
    IdEnte: null; // TODO ??
    Denominazione: string;
    Contatore: string;
    tiposervizio: boolean; // TODO ??
    selectByTipoWS: null; // TODO ??
  };

  // Aggiunti dinamicamente lato frontend.
  options?: DeclarationFieldOption[];
  indentation?: number;

  // Campi annidati
  subtype?: 'repeatable' | 'table' | 'computed';
  headers?: DeclarationFieldDto[];
  subfields?: DeclarationFieldDto[];

  placeholder?: string;

  // Nome univoco per gestire i campi omonimi come i radio input
  uniqueName: string;

  // Gestione campo calcolato
  formula?: string;
  computationDependency?: string[];

  // Gestione tabelle
  defaultRowsCount?: number;
}

export type WsOutParameter =
  | 'root'
  | 'returntype'
  | 'filter'
  | 'elementview'
  | 'infield'
  | 'outfield';

interface AnagraficaCampiValoriTesti {
  val_select: string;
  des_valore: string;
}

interface DeclarationFieldOption {
  value: string;
  label: string;
}
