import { RcFile } from 'antd/lib/upload';
import { yup } from 'common/validation/initYup';

export type IEditFileData = yup.InferType<typeof editFileValidator>;

export type IUploadFileData = yup.InferType<typeof uploadFileValidator>;

/**
 * Validator per la modifica delle informazioni aggiuntive di un file.
 */
export const editFileValidator = yup
  .object({
    description: yup.string().notRequired(),
    integralPart: yup
      .boolean()
      .notRequired()
      .default(false)
      .label('Flag Parte Integrante'),
    omittedInPublish: yup
      .boolean()
      .label('Flag Omesso dalla Pubblicazione')
      .default(false)
      .when(
        'integralPart',
        (integralPart: boolean, schema: yup.BooleanSchema) =>
          integralPart === false
            ? schema.transform((value: boolean) => true)
            : schema
      )
  })
  .required()
  .noUnknown();

/**
 * Validator per il caricamento di un file.
 */
export const uploadFileValidator = editFileValidator.shape({
  file: yup.mixed<RcFile>().required()
});
