import { AzioneDTO, AzioniDocumento } from 'client/api/backend/schemas';
import { DocumentAction } from 'client/components/schema/declaration/actions/DeclarationActions';
import * as React from 'react';
import { DocumentActionViewButton } from './DocumentActionViewButton';
import { DocumentViewSignedContent } from './DocumentViewSignedContent';
import { RemoteSignActionButton } from './remote-sign/RemoteSignActionButton';
import { DocumentSignActions } from './sign/DocumentSignActions';

export interface IDocumentActionButtonProps {
  taskId: string;
  action: AzioneDTO;
  actions: AzioneDTO[];
  document: AzioniDocumento;
}

export function DocumentActionButton(
  props: Readonly<IDocumentActionButtonProps>
) {
  const { taskId, action, document } = props;
  switch (action.idAzione) {
    // 10
    case DocumentAction.ViewOmissis:
      return (
        <DocumentActionViewButton
          taskId={taskId}
          document={document}
          params={{ flOmissis: true, flIntermedio: false }}
          buttonText={action.denominazione ?? 'Visualizza Omissis'} // TODO che testo metterci?
        />
      );
    // 11
    case DocumentAction.SignAct:
      return (
        <DocumentSignActions
          document={document.documento!}
          taskId={taskId}
          documentId={document.documento?.idDocumento!}
          actions={props.actions}
          params={{ flIntermedio: false }}
          buttonText={action.denominazione ?? 'Firma Documento'}
          uploadParams={{ flIntermedio: false, flCompletare: false }}
        />
      );
    //12
    case DocumentAction.ViewText:
      return (
        <DocumentActionViewButton
          taskId={taskId}
          document={document}
          params={{ flOmissis: false, flIntermedio: true }}
          buttonText={action.denominazione ?? 'Visualizza Testo Atto'} // TODO che testo metterci?
        />
      );
    //13
    case DocumentAction.ViewSigned:
      return (
        <DocumentViewSignedContent
          taskId={taskId}
          params={{ flIntermedio: false }}
          document={document.documento}
          buttonText={action.denominazione ?? 'Scarica Documento Firmato'}
        />
      );
    // 9
    case DocumentAction.View:
      return (
        <DocumentActionViewButton
          taskId={taskId}
          document={document}
          params={{ flOmissis: false, flIntermedio: false }}
          buttonText={action.denominazione ?? 'Visualizza Atto'}
        />
      );
    //19
    case DocumentAction.Sign:
      return (
        <DocumentSignActions
          document={document.documento!}
          taskId={taskId}
          documentId={document.documento?.idDocumento!}
          actions={props.actions}
          params={{ flIntermedio: true }}
          uploadParams={{ flCompletare: false, flIntermedio: true }}
          buttonText={action.denominazione ?? 'Firma Documento'}
        />
      );
    //21 Già contenuti
    case DocumentAction.OfflineSignAct:
      return null;
    //22
    case DocumentAction.OfflineTextSign:
      return null;
    case DocumentAction.ViewSignedText:
      return (
        <DocumentViewSignedContent
          taskId={taskId}
          params={{ flIntermedio: true }}
          document={document.documento}
          buttonText={action.denominazione ?? 'Visualzza Testo Firmato'}
        />
      );
    //28
    case DocumentAction.RemoteSignAct:
      return (
        <RemoteSignActionButton
          taskId={taskId}
          buttonText={action.denominazione ?? 'Firma Remota Principale'}
          document={document.documento!}
          flags={{
            flIntermedio: false,
            flCompletare: false
          }}
        />
      );
    // 29
    case DocumentAction.RemoteTextSign:
      return (
        <RemoteSignActionButton
          taskId={taskId}
          buttonText={action.denominazione ?? 'Firma Remota Testo'}
          document={document.documento!}
          flags={{
            flIntermedio: true,
            flCompletare: false
          }}
        />
      );

    default:
      return null;
  }
}
