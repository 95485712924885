import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  height: ${props => props.theme.layoutVersionFooterHeight};
  padding-top: 3px;
  padding-left: 10px;
  position: relative;
  bottom: 0;
  color: ${props => props.theme.textColorGray};
  font-size: 12px;
`;

/**
 * Footer statico
 */
export function StaticFooter() {
  return <StyledFooter>v{process.env.VERSION}</StyledFooter>;
}
