import * as yup from 'yup';
import moment from 'moment';

declare module 'yup' {
  interface DateSchema {
    italianFormat(): DateSchema;
  }
  // interface StringSchema {
  //   italianDate(): StringSchema;
  // }
}

const invalidDate = new Date('');

yup.addMethod(yup.date, 'italianFormat', function () {
  return this.transform(function (value: moment.Moment, originalValue) {
    value = moment(originalValue, [
      'DD-MM-YYYY',
      'DD/MM/YYYY',
      'MMM DD YYYY hh:mmA',
      moment.ISO_8601
    ]) as moment.Moment;

    return value.isValid() ? value.toDate() : invalidDate;
  });
});
