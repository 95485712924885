import { DocumentoSenzaAzioni } from 'client/api/backend/schemas';
import { getAnchorId } from 'client/ui/anchor/getAnchorId';
import { StateAnchorLink } from 'client/ui/anchor/StateAnchorLink';
import { useField } from 'formik';
import React from 'react';
import { HiDocumentText } from 'react-icons/hi';

export interface IProcedureDocumentAnchorLinkProps {
  document: DocumentoSenzaAzioni;
}

export function ProcedureDocumentAnchorLink(
  props: IProcedureDocumentAnchorLinkProps
) {
  const { document } = props;
  const [, meta] = useField(document!.idTipoDocumento!);

  return (
    <StateAnchorLink
      href={`#${getAnchorId(document.idTipoDocumento!)}`}
      prefixIcon={<HiDocumentText />}
      title={document!.nomeTipoDocumento}
      status={meta.touched ? (meta.error ? 'error' : 'success') : undefined}
    />
  );
}
