import * as React from 'react';
import { Badge, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ProcedureTableTag } from 'client/ui/tag/ProcedureTableTag';
import { TableCellEllipsis } from 'client/ui/table/TableCellEllipsis';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { ISODate } from 'client/ui/display/date/ISODate';
import { TaskStateTag } from './TaskStateTag';
import { TaskActionButton } from './actions/TaskActionButton';
import { AlignType } from 'rc-table/lib/interface';
import { TaskListItemMobile } from './TaskListItemMobile';
import { TaskName } from 'client/ui/tag/TaskName';
import { TasksProcedureDetailListItemMobile } from './TasksProcedureDetailListItemMobile';
import { DatiSessioneUtente } from 'client/api/auth/schemas';
import { UserLogic } from 'common/schema/user/UserLogic';
import { UserFeatureFlags } from 'common/schema/user/UserFeatureFlags';

const columnsTaskList = {
  procedure: {
    title: 'Procedimento',
    dataIndex: 'tipologiaProcedimento',
    key: 'procedimento.tipologiaProcedimento',
    render: (_: any, record: AttivitaDTO) => (
      <ProcedureTableTag
        name={record.procedimento?.tipologiaProcedimento?.nomeTipo}
        color={record.procedimento?.tipologiaProcedimento?.coloreRendering}
        icon={record.procedimento?.tipologiaProcedimento?.iconaRendering}
      />
    )
  },
  number: {
    title: 'Numero',
    dataIndex: 'procedimento.numero',
    key: 'numero',
    render: (_: any, record: AttivitaDTO) => record.procedimento?.numero
  },
  subject: {
    title: 'Oggetto',
    dataIndex: 'procedimento.oggetto',
    key: 'oggetto',
    render: (_: any, record: AttivitaDTO) => (
      <TableCellEllipsis text={record.procedimento?.oggetto} />
    )
  },
  taskName: {
    title: 'Attività',
    dataIndex: 'name',
    key: 'name',
    render: (_: any, record: AttivitaDTO) => <TaskName>{record.name}</TaskName>
  },
  state: {
    title: '',
    dataIndex: 'stato',
    key: 'stato',
    render: (_: any, record: AttivitaDTO) => (
      <TaskStateTag state={record.stato} />
    )
  },
  fromDate: {
    title: 'Disp. Da',
    key: 'tags',
    dataIndex: 'tags',
    render: (_: any, record: AttivitaDTO) => (
      <Space direction="vertical" size={0}>
        <ISODate
          date={record.ultimaLavorazioneTask?.dataInizioLavorazione}
          format={'DD/MM/YYYY HH:mm'}
        />
      </Space>
    )
  },
  actions: {
    key: 'action',
    align: 'right' as AlignType,
    render: (_: any, record: AttivitaDTO) => <TaskActionButton task={record} />
  },
  office: {
    key: 'office',
    title: 'Ufficio Responsabile',
    render: (_: any, record: AttivitaDTO) => (
      <TableCellEllipsis
        text={record.ultimaLavorazioneTask?.nomeUfficioAssegnazione}
      />
    )
  },
  user: {
    key: 'user',
    title: 'Utente Responsabile',
    render: (_: any, record: AttivitaDTO) => (
      <TableCellEllipsis
        text={record.ultimaLavorazioneTask?.nomeUtenteLavorazione}
      />
    )
  },
  mobile: {
    title: 'Mobile',
    dataIndex: 'tipologiaProcedimento',
    key: 'procedimento.tipologiaProcedimento',
    render: (_: any, record: AttivitaDTO) => (
      <TaskListItemMobile record={record} />
    )
  },
  mobileTasksProcedureDetail: {
    title: 'Mobile',
    dataIndex: 'tipologiaProcedimento',
    key: 'procedimento.tipologiaProcedimento',
    render: (_: any, record: AttivitaDTO) => (
      <TasksProcedureDetailListItemMobile record={record} />
    )
  },
  startupOffice: {
    title: 'Ufficio di avvio',
    dataIndex: 'nomeUfficioAvvio',
    key: 'procedimento.nomeUfficioAvvio',
    render: (_: any, record: AttivitaDTO) =>
      record.procedimento?.nomeUfficioAvvio ?? ''
  }
};

/**
 * Colonne tabella attività.
 *
 * N.B. soltanto le colonne indicate in GetAttivitaSortBy sono ordinabili
 */
export function getColumnsTaskListTable(
  user: DatiSessioneUtente | null
): ColumnsType<AttivitaDTO> {
  return [
    columnsTaskList.procedure,
    columnsTaskList.number,
    columnsTaskList.subject,
    columnsTaskList.taskName,
    columnsTaskList.state,
    columnsTaskList.startupOffice,
    columnsTaskList.fromDate,
    ...getColumnTaskDirectorOpinions(user),
    columnsTaskList.actions
  ];
}
// versione Mobile
export const columnsTaskListTableMobile: ColumnsType<AttivitaDTO> = [
  columnsTaskList.mobile
];

/** Colonne tabella attività dettaglio procedure */
export function getColumnsTasksProcedureDetail(
  user: DatiSessioneUtente | null
): ColumnsType<AttivitaDTO> {
  return [
    columnsTaskList.number,
    columnsTaskList.taskName,
    columnsTaskList.state,
    columnsTaskList.user,
    columnsTaskList.office,
    columnsTaskList.fromDate,
    ...getColumnTaskDirectorOpinions(user),
    columnsTaskList.actions
  ];
}

export const columnsTasksProcedureDetailMobile: ColumnsType<AttivitaDTO> = [
  columnsTaskList.mobileTasksProcedureDetail
];

/**
 * Funzione che controlla se abbiamo i permessi per visualizzare il parere dei direttori
 */
function getColumnTaskDirectorOpinions(user: DatiSessioneUtente | null) {
  return UserLogic.hasPermission(user, UserFeatureFlags.ShowDirectorOpinions)
    ? [
        {
          title: 'Pareri',
          dataIndex: 'procedimento.parereDeiDirettori',
          key: 'procedimento.parereDeiDirettori',
          render: (_: any, record: AttivitaDTO) => {
            return record.procedimento?.parereDeiDirettori == null ? null : (
              <Tooltip
                title={record.procedimento?.parereDeiDirettori?.toolTip}
                overlayStyle={{ maxWidth: '300px' }}
              >
                <Badge
                  style={{ display: 'flex', justifyContent: 'center' }}
                  color={
                    record.procedimento?.parereDeiDirettori?.colorRendering
                  }
                />
              </Tooltip>
            );
          }
        }
      ]
    : [];
}
