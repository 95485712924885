import { Form, Spin, Typography } from 'antd';
import Select, { DefaultOptionType, SelectValue } from 'antd/lib/select';
import { useGetListaProvider } from 'client/api/backend/firma-remota/firma-remota';
import React, { useEffect, useState } from 'react';
import { useRemoteSignContext } from '../../RemoteSignContext';

export function AsyncProviderSelect() {
  const {
    state: { provider },
    handlers: { setProvider }
  } = useRemoteSignContext();

  const { data, error, isLoading } = useGetListaProvider();

  const [providerOptions, setProviderOptions] = useState<DefaultOptionType[]>(
    []
  );

  const [isSigleProvider, setIsSigleProvider] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) return;
    if (!data?.data) return;
    if (data.data.length === 0) return;

    // Se ho un solo provider, lo seleziono automaticamente
    if (data.data.length === 1) {
      setProvider(data.data[0]);
      setIsSigleProvider(true);
      return;
    }

    const options = data.data
      .map(provider => ({
        value: provider.idWSEsterno,
        label:
          provider.denominazione ??
          provider.descrizione ??
          provider.idWSEsterno ??
          'Fornitore senza nome'
      }))
      .filter((opt): opt is { value: string; label: string } =>
        Boolean(opt.value)
      );

    setProviderOptions(options);
  }, [data?.data, isLoading]);

  if (error || data?.esito?.errore) {
    return (
      <Typography.Text type="danger">
        {error?.message ?? data?.esito?.errore?.messaggioDiErrore}
      </Typography.Text>
    );
  }

  // Nel caso ci sia un solo provider, lo mostro come non editabile
  if (isSigleProvider && provider) {
    return (
      <div className="ant-form-vertical">
        <Form.Item
          label="Fornitore"
          className="form-field-item not-editable ghost"
        >
          {provider.name}
        </Form.Item>
      </div>
    );
  }

  return (
    <Form.Item label="Fornitore" className="form-field-item editable">
      <Select
        style={{ width: '100%' }}
        size="large"
        placeholder="Scegli fornitore..."
        dropdownMatchSelectWidth={false}
        placement="bottomRight"
        allowClear
        showSearch
        notFoundContent={
          isLoading ? <Spin size="small" /> : 'Nessun fornitore disponibile'
        }
        options={providerOptions}
        value={provider?.webserviceId}
        onChange={(value: SelectValue) => {
          if (!value) setProvider(null);

          const selected = data?.data?.find(
            provider => provider.idWSEsterno === value
          );

          setProvider(selected);
        }}
      />
    </Form.Item>
  );
}
