import { Button, List, message, Tag, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaRegCircle, FaCircle, FaCheck } from 'react-icons/fa';
import { ISODate } from 'client/ui/display/date/ISODate';
import {
  getGetNotificheQueryKey,
  useRegistraLetturaNotifica
} from 'client/api/backend/notifiche/notifiche';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import moment from 'moment';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { queryClient } from 'client/core/network/queryClient';
import { BEResponsePagedResponseNotificaResponseDTO } from 'client/api/backend/schemas';

const SecondaryText = styled.span`
  color: ${props => props.theme.textColorSecondary};
  opacity: 0.75;
  font-size: 12px;
  display: inline-flex;
  line-height: 1.3;
  padding-bottom: 1px;
  span {
    margin-left: 5px;
  }
  .anticon {
    color: ${props => props.theme.primaryColor};
  }
`;
const MinimalButton = styled(Button)`
  &&& {
    font-size: 13px;
    font-weight: 400;
    height: auto;
  }
`;

interface ButtonNotificationReadProps {
  read?: Date | null;
  notificationId: string;
}

const NotificationsApiKey = getGetNotificheQueryKey()[0] as string; // "/notifiche"

/**
 * Pulsante per la segnare una notifica come letta
 */
export function ButtonNotificationRead(props: ButtonNotificationReadProps) {
  const { read, notificationId } = props;

  const readFn = useRegistraLetturaNotifica({
    mutation: {
      onMutate: ({ idNotifica }) => {
        queryClient.cancelQueries(NotificationsApiKey);

        // https://github.com/TanStack/query/issues/599#issuecomment-647586208
        const [key, previousData] =
          queryClient.getQueriesData<BEResponsePagedResponseNotificaResponseDTO>(
            NotificationsApiKey
          )[0];

        queryClient.setQueryData<BEResponsePagedResponseNotificaResponseDTO>(
          key,
          (data: BEResponsePagedResponseNotificaResponseDTO | undefined) => {
            if (!data) return {};
            return {
              ...data,
              data: {
                ...data.data,
                list: data.data?.list?.map(item => {
                  if (item.id === idNotifica) {
                    return { ...item, dataPresaVisione: moment().toDate() };
                  }
                  return item;
                })
              }
            } as BEResponsePagedResponseNotificaResponseDTO;
          }
        );

        return { key, previousData };
      },
      onError: (error, { idNotifica }, context: any) => {
        // In caso ci sia stato un errore, prima ripristiniamo le notifiche
        // di quello eliminato..
        queryClient.setQueryData(context.key, context.previousData);
        // ..e dopo ricarichiamo dal backend
        queryClient.invalidateQueries(NotificationsApiKey);
      }
    }
  });

  const handleClick = useCallback(async () => {
    if (read) return;
    try {
      await readFn.mutateAsync({ idNotifica: notificationId });
    } catch (e) {
      console.error(`[ButtonNotificationRead] Error durante la lettura della notifica.`, {error: e}); // prettier-ignore
      message.error('Si è verificato un errore, riprovare.');
    }
  }, [readFn, notificationId]);

  // if (readFn.isLoading) return <LoaderSpin size="small" />;

  return (
    <>
      {read ? (
        <SecondaryText>
          Letta il <ISODate date={read} /> <IconWrapper icon={<FaCheck />} />
        </SecondaryText>
      ) : (
        <MinimalButton size="small" type="primary" ghost onClick={handleClick}>
          Segna come letta <IconWrapper icon={<FaCheck />} />
        </MinimalButton>
      )}
    </>
  );
}
