import { List } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ListItemProps } from 'antd/lib/list/Item';
import { IFixturesNotificaionsTipo } from 'client/fixtures/FixturesNotifications';

const ListItem = styled(List.Item)`
  &&& {
    padding-right: 4px;
    /* &.type-info {
      background-color: #e6f7ff;
    } */
    &.type-warning {
      background-color: #fff7e6;
    }
  }
  .ant-list-item-action {
    margin-left: 0; // 14px;
  }
  .ant-list-item-action {
    li:last-child {
      padding-right: 0;
    }
  }
`;

interface ListItemNotificationProps extends ListItemProps {
  type?: IFixturesNotificaionsTipo;
}
/**
 * Singolo elemento stilizzato di una notifica
 * imposta il colore di sfondo in base al tipo
 */
export function ListItemNotification(props: ListItemNotificationProps) {
  const { type, ...othersProps } = props;

  return (
    <ListItem
      {...othersProps}
      className={type ? `type-${type.toLowerCase()}` : ''}
    />
  );
}
