import { Dichiarazione } from 'client/api/backend/schemas';
import { getAnchorId } from 'client/ui/anchor/getAnchorId';
import { StateAnchorLink } from 'client/ui/anchor/StateAnchorLink';
import { useField } from 'formik';
import React from 'react';

export interface IProcedureDeclarationAnchorLinkProps {
  declaration: Dichiarazione;
}

export function ProcedureDeclarationAnchorLink(
  props: IProcedureDeclarationAnchorLinkProps
) {
  const { declaration } = props;

  const [field, meta, helpers] = useField(declaration!.idTipoDichiarazione!);

  return (
    <StateAnchorLink
      href={`#${getAnchorId(declaration!.idTipoDichiarazione!)}`}
      title={declaration!.titoloDichiarazione}
      status={meta.touched ? (meta.error ? 'error' : 'success') : undefined}
    />
  );
}
