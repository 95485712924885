import React from 'react';
import styled from 'styled-components';
import { Space } from 'antd';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';
import { IISODateProps, ISODate } from 'client/ui/display/date/ISODate';

const TaskDateFromStyle = styled.span`
  font-size: ${props => props.theme.fontSizeSm};
  color: ${props => props.theme.textColorSecondary};
  line-height: 1;
`;

export interface ITaskDateFromProps extends IISODateProps {
  text?: string;
}

/**
 * Visualizzazione data inizio attività per Mobile
 *
 */
export function TaskDateFrom(props: ITaskDateFromProps) {
  const { text, ...otherProps } = props;
  return (
    <TaskDateFromStyle>
      {text ?? 'Dal'} <ISODate {...otherProps} />
    </TaskDateFromStyle>
  );
}
