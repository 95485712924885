import { List, message, Tag, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaRegCircle, FaCircle, FaCheck } from 'react-icons/fa';
import { ISODate } from 'client/ui/display/date/ISODate';
import { IconWrapper } from 'client/ui/icon/IconWrapper';

const StyledIcon = styled.div`
  font-size: 14px;
  color: ${props => props.theme.primaryColor};
`;

const IndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
`;

interface ReadIndicatorProps {
  read?: Date | null;
}

/**
 * Indicatore della lettura della notifica
 * Se è stata letta nel tooltips mostra la data di lettura
 */
export function ReadIndicator(props: ReadIndicatorProps) {
  const { read } = props;

  const tooltip = read ? (
    <span>
      Letta il <ISODate date={read} />
    </span>
  ) : (
    'Da leggere'
  );

  return (
    <IndicatorWrapper>
      <Tooltip title={tooltip} placement="left">
        <StyledIcon>
          <IconWrapper icon={read ? <FaRegCircle /> : <FaCircle />} />
        </StyledIcon>
      </Tooltip>
    </IndicatorWrapper>
  );
}
