import { CheckCircleFilled } from '@ant-design/icons';
import { Button, message, Tooltip } from 'antd';
import { useDocumentiManagerJnlp } from 'client/api/auth/jnlp/jnlp';
import {
  AzioniSezione,
  AzioneDTO,
  AzioniDocumento
} from 'client/api/backend/schemas';
import { DocumentLogic } from 'client/components/schema/task/document/DocumentLogic';
import { JnlpService } from 'client/components/schema/task/jnlp/JnlpService';
import { FastSectionJnlpWindowManager } from 'client/components/schema/task/jnlp/FastSectionJnlpWindowManager';
import { appConfig } from 'client/core/appConfig';
import React, { useEffect } from 'react';
import {
  IStandardSectionJnlpWindowManagerHandle,
  StandardSectionJnlpWindowManager
} from 'client/components/schema/task/jnlp/StandardSectionJnlpWindowManager';
import { WebSocketKind } from 'client/components/digital-signature/DigitalSignatureProvider';

export interface ISectionStandardActionButtonProps {
  section: AzioniSezione;
  action: AzioneDTO;
  taskId: string;
  document: AzioniDocumento;
}

/**
 * Pulsante per l'azione sulla singola sezione.
 */
// TODO: Unificare in un unico pulsante con Dropdown
// TODO/HI: Gestire ulteriori azioni (per ora c'è solo modifica)
export function SectionStandardActionButton(
  props: ISectionStandardActionButtonProps
) {
  const { section, action, taskId, document } = props;
  const [objectUrl, setObjectUrl] = React.useState<string | null>(null);
  const getSectionEditUrl = useDocumentiManagerJnlp({});

  useEffect(() => {
    return () => {
      if (objectUrl) {
        JnlpService.revokeObjectUrl(objectUrl);
      }
    };
  }, [objectUrl]);

  const jnlpWindowRef =
    React.useRef<IStandardSectionJnlpWindowManagerHandle>(null);

  return (
    <>
      <Button
        size="large"
        onClick={async () => {
          try {
            let result = await getSectionEditUrl.mutateAsync({
              data: {
                idTaskInstance: taskId,
                idTipoSezione: section.sezione!.idTipoSezione!,
                idDocumento: document.documento!.idDocumento!
              }
            });

            if (!result) {
              throw new Error('Impossibile effettuare il download');
            }

            if (appConfig.useFvProxy) {
              result = JnlpService.setFvProxy(result, WebSocketKind.Document);
            }
            const resultObjectUrl = JnlpService.getObjectUrl(result);

            // Rimuovo l'URL precedente ed imposto il nuovo
            if (objectUrl) {
              JnlpService.revokeObjectUrl(objectUrl);
            }
            setObjectUrl(resultObjectUrl);

            // Apro il file
            JnlpService.downloadFile(resultObjectUrl, 'documentiManager.jnlp');

            // Apriamo la modal
            jnlpWindowRef.current?.open(objectUrl!, section);
          } catch (e) {
            message.error(`Si è verificato un errore durante l'apertura dell'applicativo.`); // prettier-ignore
            console.error(e);
          }
        }}
      >
        {section.sezione?.nomeTipoSezione}{' '}
        {DocumentLogic.isSectionCompiled(section) && (
          <Tooltip title={`Sezione caricata`}>
            <CheckCircleFilled />
          </Tooltip>
        )}
      </Button>
      <StandardSectionJnlpWindowManager
        ref={jnlpWindowRef}
        taskId={taskId}
        section={props.section}
        document={props.document.documento}
      />
    </>
  );
}
