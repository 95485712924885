import { Alert, Skeleton } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import * as React from 'react';
import { UseQueryResult } from 'react-query';

export interface ILoadingQueryProps {
  query: UseQueryResult;
  errorMessage?: string;
  children?: React.ReactNode;
}

export function LoadingQuery(props: ILoadingQueryProps) {
  const { query, errorMessage } = props;

  if (query.isError) {
    return (
      <Alert
        showIcon
        type="error"
        description={errorMessage ?? getNetworkErrorMessage(query.error ?? '')}
      />
    );
  }

  return <Skeleton loading={query.isLoading}>{props.children}</Skeleton>;
}
