import { Subject } from 'rxjs';

export interface IJnlpPoolingEvent {
  type: 'end';
}

export const jnlpSubject = new Subject<IJnlpPoolingEvent>();

/**
 * Permette di fermare il pooling del jnlp quando la modal viene chiusa
 */
export function jnlpPoolingStatus(event: IJnlpPoolingEvent) {
  jnlpSubject.next(event);
}
