import React from 'react';
import styled from 'styled-components';
import {
  MdWarning,
  MdInfoOutline,
  MdOutlineNotifications
} from 'react-icons/md';
import { IFixturesNotificaionsTipo } from 'client/fixtures/FixturesNotifications';
import { assertNever } from 'common/utils/assertNever';
import { IconWrapper } from 'client/ui/icon/IconWrapper';

const TypeIcon = styled.span`
  max-height: 14px;
  line-height: 14px;
  position: relative;
  margin-right: 25px;
  .anticon {
    font-size: 1.4em;
    line-height: 14px;
    position: absolute;
  }

  .type-info-icon {
    color: #096dd9;
  }
  .type-warning-icon {
    color: #d46b08;
  }
  .type-notifica-icon {
    color: #17324d;
    opacity: 0.75;
  }
`;

function getTypeIcon(type?: IFixturesNotificaionsTipo) {
  if (!type) return null;
  switch (type) {
    case IFixturesNotificaionsTipo.INFO:
      return <MdInfoOutline className="type-info-icon" />;
    case IFixturesNotificaionsTipo.WARNING:
      return <MdWarning className="type-warning-icon" />;
    case IFixturesNotificaionsTipo.NOTIFICA:
      return <MdOutlineNotifications className="type-notifica-icon" />;
    default:
      assertNever(type);
  }
}

interface NotificationIconTypeProps {
  type?: IFixturesNotificaionsTipo;
}

/**
 * Icona del tipo di notifica
 */
export function NotificationIconType(props: NotificationIconTypeProps) {
  const { type } = props;
  return (
    <TypeIcon>
      <IconWrapper>{getTypeIcon(type)}</IconWrapper>
    </TypeIcon>
  );
}
