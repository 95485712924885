import { AzioniDichiarazione, Dichiarazione } from 'client/api/backend/schemas';
import { ComputedFieldLogic } from 'client/components/schema/task/declaration/parser/ComputedFieldLogic';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import {
  DeclarationFieldTreeNode,
  getDeclarationFieldsTree
} from './getDeclarationFieldsTree';
import { normalizeDeclarationField } from './normalizeDeclarationField';
import { normalizeRepeatableFields } from './normalizeRepeatableFields';

/**
 * Trasforma la dichiarazione per ristrutturarla in una forma consona ad
 * essere gestita dall'applicativo / da Formik.
 */
export function normalizeDeclaration(
  declaration: Dichiarazione | undefined
): Dichiarazione {
  if (!declaration) return {};
  const jsonCampi = declaration.jsonCampi;

  let fields = (jsonCampi ?? []) as DeclarationFieldDto[];

  // 1. Normalizzo i campi calcolati
  ComputedFieldLogic.normalizeComputedFields(fields);

  // 2. Normalizzazione dei campi
  fields = fields.map(normalizeDeclarationField);

  // Registriamo l'alberatura dei campi
  const tree = getDeclarationFieldsTree(fields);

  // 3. Normalizziamo i campi ripetuti / annidati
  fields = normalizeRepeatableFields(tree ?? []);

  return {
    ...declaration,
    jsonCampi: fields
  };
}
