import { EsitoRispostaWS } from 'client/api/backend/schemas';
import { appConfig } from 'client/core/appConfig';

export enum WebSocketKind {
  Sign = 'sign',
  Document = 'document'
}

export interface IWebSocketUrl {
  ws: string;
  wss: string;
}

function getSocketUrl(urls: IWebSocketUrl) {
  switch (location.protocol) {
    case 'https:':
      return urls.wss;
    case 'http:':
      return urls.ws;
  }
  console.warn(`⚠️ Protocollo non supportato: ${location.protocol}`);
  return '';
}

type Deferred = {
  resolve: (response: any) => void;
  reject: (reason: any) => void;
};

export class DigitalSignatureProvider {
  private static signInstance = new DigitalSignatureProvider();
  private static documentInstance = new DigitalSignatureProvider();

  /**
   * Inizializzazione
   */
  static async open(urls: IWebSocketUrl, kind: WebSocketKind) {
    switch (kind) {
      case WebSocketKind.Sign:
        await this.signInstance.connectIfNecessary(urls);
        return this.signInstance;
      case WebSocketKind.Document:
        await this.documentInstance.connectIfNecessary(urls);
        return this.documentInstance;
    }
  }

  /**
   * Socket di connessione WS
   */
  private socket: WebSocket | undefined;

  /**
   * Coda di richieste inviate per essere gestite in sequenza.
   */
  private queue: Deferred[] = [];
  private urls: IWebSocketUrl | undefined;

  constructor() {}

  async connectIfNecessary(urls: IWebSocketUrl) {
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      this.urls = urls;
      this.socket = await this.connect(urls);
      this.socket.addEventListener('message', this.handleMessage);
      this.socket.addEventListener('error', this.handleError);
      this.socket.addEventListener('close', this.handleClose); // prettier-ignore
    }
  }

  private connect(urls: IWebSocketUrl) {
    return new Promise<WebSocket>((resolve, reject) => {
      let socket = new WebSocket(getSocketUrl(urls));
      console.log('[WS] Connetto...');

      socket.onopen = (event: Event) => {
        console.log('[WS] Connesso', event);
        resolve(socket);
      };
      socket.onerror = (event: Event) => {
        console.error('[WS] Errore', event);
        reject("Impossibile connettersi all'applicativo desktop di Firma.");
      };
    });
  }

  isConnected() {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }

  handleMessage = (event: MessageEvent) => {
    let data: EsitoRispostaWS;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      console.error('Errore nella deserializzazione della risposta', e);
      return this.queue.shift()!.reject({
        code: 'GENERIC_ERROR',
        message: `Si è verificato un errore nell'elaborazione della risposta.`
      });
    }
    console.log('[WS] Ricevuta risposta', data);
    if (!data.valore) {
      return this.queue.shift()!.reject(
        data.errore
          ? {
              code: data.errore?.codice,
              message: data.errore?.messaggioDiErrore
            }
          : {
              code: 'GENERIC_ERROR',
              message: `Si è verificato un errore nell'elaborazione della risposta.`
            }
      );
    }

    return this.queue.shift()!.resolve(data);
  };

  handleError = (event: Event) => {
    this.queue.shift()?.reject(event);
  };

  handleClose = (event: Event) => {
    console.warn(`[WS] chiusura socket.. riconnetto`, event);
    this.socket?.removeEventListener('message', this.handleMessage);
    this.socket?.removeEventListener('close', this.handleClose);
    this.socket?.removeEventListener('error', this.handleError);

    this.queue.shift()?.reject(event);
  };

  sendRequest(message: any): Promise<any> {
    if (this.queue.length !== 0) {
      throw new Error('Si è verificato un errore nella gestione della firma.');
    }

    if (!this.socket) {
      throw new Error(
        `Non è stato possibile connettersi all'applicativo desktop di Firma.`
      );
    }

    return new Promise((resolve, reject) => {
      this.socket!.send(JSON.stringify(message));
      this.queue.push({ resolve, reject });
    });
  }
}
