import { Alert, Button, Space } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { ModalFooter } from 'client/ui/modal/ModalFooter';
import React from 'react';
import { IRemoteSignErrorBag } from '../reducer/RemoteSignActions';

export interface IRemoteSignSigningStepProps {
  onClose?: (e: any) => void;
  taskList?: AttivitaDTO[];
  errorBag?: IRemoteSignErrorBag;
}

export function RemoteSignSigningStep(
  props: Readonly<IRemoteSignSigningStepProps>
) {
  const { onClose, taskList, errorBag } = props;

  // Se sono presenti errori, mostro l'alert
  if (taskList && taskList.length > 0 && errorBag && errorBag.length > 0) {
    return (
      <Space direction="vertical" size="middle">
        <Alert
          type="error"
          showIcon
          style={{ width: '100%' }}
          description="Si sono verificati degli errori durante il processo di firma. I documenti elencati di seguito non sono stati firmati."
          message="Errore durante la firma del documento"
        />

        {errorBag.map(error => {
          const task = taskList.find(t => t.idTaskInstance === error.taskId);

          const taskName = task
            ? `${task.procedimento?.tipologiaProcedimento?.nomeTipo ?? ''} - ${
                task.name ?? ''
              } (n. ${task.procedimento?.numero})`
            : null;
          return (
            <div key={error.taskId}>
              <b>{taskName ?? error.taskId}</b>:{' '}
              <ul>
                {error.messages.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </div>
          );
        })}

        <ModalFooter>
          <Button onClick={onClose}>Chiudi</Button>
        </ModalFooter>
      </Space>
    );
  }

  return (
    <Space direction="vertical" size="middle">
      <Alert
        type="success"
        showIcon
        description="Il documento è stato firmato con successo. Puoi chiudere questa finestra e continuare."
        message="Documento firmato con successo"
      />
      <ModalFooter>
        <Button onClick={onClose}>Chiudi</Button>
      </ModalFooter>
    </Space>
  );
}
