import React from 'react';
import styled, { css } from 'styled-components';
import { Space } from 'antd';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${between('sm', 'md')} {
    flex-direction: row;
    align-items: center;
  }
`;
const RowBody = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;
const RowActions = styled.div<{ onlyOnAction?: boolean }>`
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
  height: 40px;
  ${between('sm', 'md')} {
    min-width: 100px;
    ${props =>
      props.onlyOnAction &&
      css`
        width: 60px;
        min-width: 60px;
      `}
  }
`;

export interface IItemListMobileProps {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  onlyOnAction?: boolean;
}
/**
 * Visualizzazione elementi in singola cella come lista per Mobile
 */
export function ItemListMobile(props: IItemListMobileProps) {
  const { actions, onlyOnAction, children } = props;
  return (
    <RowWrapper>
      <RowBody>{children}</RowBody>
      {actions && (
        <RowActions onlyOnAction={onlyOnAction}>{actions}</RowActions>
      )}
    </RowWrapper>
  );
}
