import { Button, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { TableData } from 'client/ui/table/TableData';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { useEffect, useMemo } from 'react';
import { HiTrash } from 'react-icons/hi';
import {
  DeclarationFieldSwitch,
  IDeclarationFieldOptions
} from '../DeclarationFieldSwitch';
import { PlusOutlined } from '@ant-design/icons';

export const FieldTableTitle = styled.div`
  padding-bottom: 8px;
`;

export const FieldTable = styled(TableData)`
  margin-bottom: 24px;

  .ant-table-footer {
    border: 1px solid ${props => props.theme.borderColorSplit};
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 0;
  }
  .ant-table-tbody > tr > td {
    padding: 4px;
    .form-field-item {
      margin-top: 0;
    }
  }

  &.not-editable {
    .ant-table-tbody > tr > td {
      .form-field-item {
        margin-top: 0;
        .ant-form-item-control-input {
          border-bottom: none;
          border-top: none;
          background-color: transparent;
          padding: 8px 11px 5px 11px;
          padding: 8px;
        }
      }
    }
    .ant-table-footer {
      border: none;
    }
  }
`;

export interface IDeclarationFieldTableProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

export function DeclarationFieldTable(props: IDeclarationFieldTableProps) {
  const { field, options } = props;

  const fieldName = options.namePrefix
    ? `${options.namePrefix}.${field.nome}`
    : field.nome;

  const editor = useRelationEditor<any>(fieldName);
  const formContext = useFormikFormContext();

  const isRowRepeatable = field.aggiungiRiga && formContext.editable;

  const columns: ColumnType<any>[] = useMemo(
    () =>
      field
        .headers!.map((header, index) => {
          const subfield = field.subfields![index]; // Possiamo usare "index" perché i campi sono già ordinati per "colonnaTabella" in normalizeDeclarationField
          return {
            title: header.des_campo,
            dataIndex: field.subfields![index].nome,
            render: (_: any, item: any, j: number) => {
              return (
                <DeclarationFieldSwitch
                  field={subfield}
                  options={{ namePrefix: `${fieldName}.${j}` }}
                />
              );
            }
          } as ColumnType<any>;
        })
        .concat(
          isRowRepeatable
            ? [
                {
                  key: 'actions',
                  title: null,
                  width: '40px',
                  align: 'right',
                  render: (_, item) => {
                    return (
                      <Button
                        disabled={editor.items.length <= 1}
                        danger
                        type="link"
                        icon={<HiTrash />}
                        onClick={() => editor.remove(item)}
                      />
                    );
                  }
                }
              ]
            : []
        ),
    [editor.items, editor.remove, field.headers, field.subfields, fieldName]
  );

  return (
    <FormikForm
      nested
      helpInTooltips
      editable={formContext.editable}
      disabled={formContext.disabled}
    >
      <FieldTableTitle>{field.des_campo}</FieldTableTitle>
      <FieldTable
        className={clx({ 'not-editable': !formContext.editable })}
        footer={currentPageData =>
          isRowRepeatable && (
            <Button
              block
              icon={<PlusOutlined />}
              type="link"
              onClick={() => {
                // Se vuoto, aggiungo due elementi, dato che uno è sempre
                // rendeizzato anche se non è presente.
                if (editor.count === 0) {
                  editor.addItems([{}, {}]);
                } else {
                  editor.add({});
                }
              }}
            >
              Aggiungi
            </Button>
          )
        }
        rowKey={(_, i) => i!}
        tableLayout="fixed"
        columns={columns}
        dataSource={editor.items.length === 0 ? [{}] : editor.items}
        pagination={false}
      />
    </FormikForm>
  );
}
