import { AttivitaDTO, AzioniGruppoAllegato } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { AttachmentLogic } from 'client/components/schema/attachments/AttachmentLogic';
import { AttachmentActionLogic } from 'client/components/schema/declaration/actions/AttachmentActionLogic';
import { TaskActionLogic } from 'client/logic/TaskActionLogic';
import { DeclarationTitle } from 'client/ui/declaration/DeclarationTitle';
import React from 'react';
import { AttachmentSection } from './AttachmentSection';

export interface IAttachmentGroupSectionProps {
  group: AzioniGruppoAllegato;
  name: string | null;
  task: AttivitaDTO | null;
}

/** Sezione gruppo di allegati */
export function AttachmentGroupSection(props: IAttachmentGroupSectionProps) {
  const { group, name, task } = props;

  const currentUser = useCurrentUser();

  if (!AttachmentActionLogic.isVisible(group.azioni)) return null;

  // Se non ha file e non è assegnato, nascondi il gruppo
  const isAssigned = TaskActionLogic.isAssigned(task, currentUser);
  if (!AttachmentLogic.hasAnyFile(group.gruppoAllegati) && !isAssigned)
    return null;

  return (
    <>
      <DeclarationTitle
        title={group.gruppoAllegati?.denominazioneGruppoAllegato ?? 'Allegati'}
        attachments={group.gruppoAllegati}
      />
      {group.gruppoAllegati?.allegati?.map((attachment, index) => (
        <AttachmentSection
          actions={group.azioni ?? []}
          attachment={attachment}
          key={index}
          name={`${name ? name + '.' : ''}${
            group.gruppoAllegati?.idGruppoAllegato
          }`}
          task={task}
          isAssigned={isAssigned}
        />
      ))}
    </>
  );
}
