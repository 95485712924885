import { Menu } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { TaskActionLogic } from 'client/logic/TaskActionLogic';
import { IconComplete } from 'client/ui/icon/ApplicationIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { UserLogic } from 'common/schema/user/UserLogic';
import React, { useState } from 'react';
import { CompleteTasksMassiveModal } from './CompleteTasksMassiveModal';

export interface ICompleteTasksMassiveMenuItemProps {
  tasks: AttivitaDTO[];
}

/**
 * Elemento di menu per il completamento massivo dei task selezionati
 */
export function CompleteTasksMassiveMenuItem(
  props: ICompleteTasksMassiveMenuItemProps
) {
  const { tasks } = props;

  const user = useCurrentUser();
  const [visible, setVisible] = useState(false);

  const disabled = tasks.some(task => !TaskActionLogic.isAssigned(task, user));

  // Se non è autorizzato all'azione di completamento, nascondo l'opzione
  const hidden = !UserLogic.hasPermission(user, 'completa_massivo');

  return (
    <>
      <Menu.Item
        disabled={disabled}
        hidden={hidden}
        key="complete"
        onClick={() => setVisible(true)}
      >
        <IconWrapper icon={<IconComplete />} /> Completa
      </Menu.Item>
      <CompleteTasksMassiveModal
        visible={visible}
        hide={() => setVisible(false)}
        tasks={tasks}
      />
    </>
  );
}
