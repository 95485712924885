import { useGetAzioniGruppoAllegati } from 'client/api/backend/attivita/attivita';
import { Allegato, AzioniGruppoAllegato } from 'client/api/backend/schemas';
import { arrayToObject } from 'client/core/utils/arrayToObject';
import { IDocumentFile } from 'client/ui/form/input/UploadInput';
import { useMemo } from 'react';

export function useAttachments(taskId: string) {
  const result = useGetAzioniGruppoAllegati(taskId);

  const attachments = useMemo(
    () =>
      result.data?.data
        ?.map(normalizeAttachment)
        .sort(sortAttachmentsWithActions) ?? [],
    [result.data?.data]
  );

  return {
    result,
    attachments
  };
}

// TODO HI Rimuovere normalizzazioni una volta adattate le API
function normalizeAttachment(
  attachment: AzioniGruppoAllegato
): AzioniGruppoAllegato {
  return attachment;
}

/** Ordino gruppo di allegati con azioni */
export function sortAttachmentsWithActions(
  attachmentA: AzioniGruppoAllegato,
  attachmentB: AzioniGruppoAllegato
) {
  return (
    (attachmentA.gruppoAllegati?.ordinamento ?? 0) -
    (attachmentB.gruppoAllegati?.ordinamento ?? 0)
  );
}

export type IAttachmentResult = ReturnType<typeof useAttachments>;

export function getAttachmentGroupsInitialValues(
  groups: AzioniGruppoAllegato[] | null | undefined
) {
  return arrayToObject(
    groups?.map(group => {
      const attachments = getAttachmentsInitialValues(
        group.gruppoAllegati?.allegati
      );
      return { [group.gruppoAllegati?.idGruppoAllegato!]: attachments };
    })
  );
}

function getAttachmentsInitialValues(
  attachments: Allegato[] | null | undefined
) {
  return arrayToObject(
    attachments?.map(attachment => ({
      [attachment.idTipoAllegato!]: attachment.files?.map(
        file =>
          ({
            description: file.descrizione,
            file: {
              id: file.idFile,
              size: file.dimensione,
              name: file.nomeFile,
              ownerFiscalId: file.idUtenteCreazione,
              office: file.idUfficioCreazione
            },
            integralPart: file.flParteIntegrante,
            omittedInPublish: !file.flPubblicabile
          } as IDocumentFile)
      )
    }))
  );
}
