import React, { useState } from 'react';
import styled from 'styled-components';

const TaskNameStyle = styled.span`
  color: ${props => props.theme.primaryColor};
  font-size: ${props => props.theme.fontSizeSm};
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
`;

export interface ITaskNameProps {
  children?: React.ReactNode;
}

/**
 * Nome dell'attività stilizzato per le tabelle
 *
 */
export function TaskName(props: ITaskNameProps) {
  if (!props.children) return null;

  return <TaskNameStyle>{props.children}</TaskNameStyle>;
}
