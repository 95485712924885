import React from 'react';
import { Popconfirm, Button } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { ButtonProps } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export interface ButtonConfirmProps extends PopconfirmProps {
  as?: React.ComponentType<ButtonProps>;
  loading?: boolean;
  size?: SizeType;
  icon?: ButtonProps['icon'];
  type?: ButtonProps['type'];
  block?: ButtonProps['block'];
  buttonColor?: ButtonProps['color'];
  children?: React.ReactElement | string;
  okDanger?: boolean;
  popIcon?: React.ReactElement;
}

export function ButtonConfirm(props: ButtonConfirmProps) {
  const {
    as,
    placement,
    loading,
    size,
    type,
    block,
    children,
    buttonColor,
    icon,
    okDanger,
    popIcon,
    ...otherProps
  } = props;

  const ButtonComponent = props.as ?? Button;

  return (
    <Popconfirm
      placement={placement ? placement : 'topRight'}
      {...otherProps}
      {...(popIcon ? { icon: popIcon } : {})}
      okButtonProps={{
        type: 'primary',
        danger: okDanger == null ? true : okDanger
      }}
    >
      <ButtonComponent
        type={type}
        icon={icon}
        size={size ?? 'small'}
        block={block}
        loading={loading}
        color={buttonColor}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </ButtonComponent>
    </Popconfirm>
  );
}
