import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { AuthCurrentLoader } from 'client/components/auth/current-loader/AuthCurrentLoader';
import { useAuth } from 'client/components/auth/AuthModule';
import { NotFoundPage } from 'client/components/errors/not-found/NotFoundPage';
import { useModalHistoryCleanup } from 'client/ui/modal/useModalHistoryCleanup';
import { TestApiPage } from './TestApiPage';
import { TaskListPage } from './task/list/TaskListPage';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { ForbiddenPage } from 'client/components/errors/forbidden/ForbiddenPage';
import { LoginPage } from './login/LoginPage';
import { TaskPage } from './task/single/TaskPage';
import { ProcedureListPage } from './procedure/list/ProcedureListPage';
import { ProcedurePage } from './procedure/single/ProcedurePage';
import { HomePage } from './home/HomePage';
import { ProcedureVariablesPage } from './admin/variables/ProcedureVariablesPage';
import { ProcedureTypeListPage } from './home/procedure-type/list/ProcedureTypeListPage';

export const Routes = () => {
  const { logged } = useAuth();

  // const permissions = usePermissions();

  // Chiudiamo tutte le modal alla navigazione
  useModalHistoryCleanup();

  return (
    <AuthCurrentLoader>
      {!logged ? (
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Redirect to={{ pathname: '/login' }} />
        </Switch>
      ) : (
        <Switch>
          <Redirect exact from="/login" to="/" />
          <Redirect exact from="/" to="/home" />
          <Route
            path="/home/procedure/type/list"
            component={ProcedureTypeListPage}
          />
          <Route path="/home" component={HomePage} />
          <Route path="/task/list/:id" component={TaskPage} />
          <Route path="/task/list" component={TaskListPage} />
          <Route path="/procedure/list/:id" component={ProcedurePage} />
          <Route path="/procedure/list" component={ProcedureListPage} />
          <Route path="/alert" component={AlertPage} />
          <Route path="/no" component={ForbiddenPage} />
          <Route path="/404" component={NotFoundPage} />

          {/** Testing */}
          <Route path="/alert" component={AlertPage} />
          <Route path="/403" component={ForbiddenPage} />
          <Route path="/404" component={NotFoundPage} />
          <Route path="/admin/variables" component={ProcedureVariablesPage} />
          <Route path="/test-api" component={TestApiPage} />

          {/** Pagina non trovata */}
          <Route component={NotFoundPage} />
        </Switch>
      )}
    </AuthCurrentLoader>
  );
};
