import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { IconConfirm } from 'client/ui/icon/IconConfirm';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import React from 'react';
import { HiTrash } from 'react-icons/hi';

export interface IDeleteFileIconButtonProps {
  canDelete: boolean;
  loading: boolean;
  handleDelete: () => Promise<void>;
}

/**
 * Icona di eliminazione file con conferma
 */
export function DeleteFileIconButton(props: IDeleteFileIconButtonProps) {
  const { canDelete, loading, handleDelete } = props;

  if (!canDelete) return null;

  if (loading) {
    return <Spin size="small" indicator={<Loading3QuartersOutlined spin />} />;
  }

  return (
    <IconConfirm
      title="Vuoi eliminare questo file?"
      okText="Elimina"
      okDanger
      popIcon={<IconWrapper icon={<HiTrash />} />}
      icon={<IconWrapper icon={<HiTrash />} />}
      onConfirm={handleDelete}
    />
  );
}
