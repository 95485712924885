import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    /**
     * Controlla che la stringa sia una partita IVA italiana valida.
     */
    vatNumber(): StringSchema;
  }
}

yup.addMethod(yup.string, 'vatNumber', function (this: yup.StringSchema) {
  return this.length(11).matches(/^[0-9]{11}$/, 'La Partita IVA non è valida');
});
