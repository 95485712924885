import { TaskCard, TaskCardMode } from 'client/ui/card/TaskCard';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import React from 'react';

export interface ILoadingCardProps {
  mode?: TaskCardMode;
}

/**
 * Card mostrata mentre i dati vengono caricati dal BE
 */
export function LoadingCard(props: ILoadingCardProps) {
  return (
    <TaskCard mode={props.mode}>
      <LoaderSpin transparent />
    </TaskCard>
  );
}
