import { Menu, message } from 'antd';
import { useRilascia1 } from 'client/api/backend/attivita/attivita';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { logger } from 'client/core/logger/logger';
import { TaskActionLogic } from 'client/logic/TaskActionLogic';
import { IconRelaese } from 'client/ui/icon/ApplicationIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';

export interface IReleaseMassiveMenuItemProps {
  tasks: AttivitaDTO[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ReleaseMassiveMenuItem(props: IReleaseMassiveMenuItemProps) {
  const { tasks, setLoading, ...menuProps } = props;
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  // Rilascio massivo
  const releaseFunction = useRilascia1();

  const LOADING_MESSAGE_KEY = 'release-massive-menu-item';

  const handleRelease = useCallback(async () => {
    const taskIds = tasks?.map(task => task.idTaskInstance!) ?? [];
    if (!taskIds || taskIds.length === 0) return;
    try {
      setLoading(true);
      message.loading({ content: 'Rilascio in corso...', key: LOADING_MESSAGE_KEY }); // prettier-ignore

      await releaseFunction.mutateAsync({
        data: {
          idTaskInstanceList: taskIds
        }
      });
      queryClient.invalidateQueries();
      message.success('Attività rilasciate');
    } catch (e) {
      logger.error({ error: e });
      message.error(`Si è verificato un errore durante il rilascio. Riprovare...`); // prettier-ignore
    } finally {
      message.destroy(LOADING_MESSAGE_KEY);
      setLoading(false);
    }
  }, [tasks, releaseFunction, queryClient]);

  const disabled = tasks.some(
    task => !TaskActionLogic.canExecRelease(task, currentUser)
  );

  return (
    <Menu.Item
      key="release"
      disabled={disabled}
      onClick={handleRelease}
      {...menuProps}
    >
      <IconWrapper icon={<IconRelaese />} /> Rilascia
    </Menu.Item>
  );
}
