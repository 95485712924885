import { DatiSessioneUtente } from 'client/api/auth/schemas';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { UserLogic } from 'common/schema/user/UserLogic';

/** Azione possibile sul task */
export enum TaskActions {
  ExecRelease = 'ExecRelease',
  View = 'View',
  Assign = 'Assign'
}

export class TaskActionLogic {
  // TODO HIGH: dipende dall'action, non più valida
  static getAction(
    task: AttivitaDTO | undefined | null,
    user: DatiSessioneUtente | undefined | null
  ) {
    if (!task || !user) return null;
    // Se è richiesta un'abilitazione, l'utente deve possederla, altrimenti può solo visualizzare
    if (!this.hasPermission(task, user)) {
      return TaskActions.View;
    } else if (this.canAssign(task)) {
      return TaskActions.Assign;
    } else if (this.canExecRelease(task, user)) {
      return TaskActions.ExecRelease;
    }

    return TaskActions.View;
  }

  /**
   * Ritorna vero se l'utente ha l'abilitazione richiesta per compiere operazioni sull'attività
   */
  static hasPermission(task: AttivitaDTO, user: DatiSessioneUtente) {
    return (
      task.abilitazioneLavorazione &&
      UserLogic.hasPermission(user, task.abilitazioneLavorazione)
    );
  }

  /**
   * Ritorna vero se il task è già assegnato a un utente, falso altrimenti
   */
  static isTaskAssigned(task: AttivitaDTO) {
    return (
      !!task.ultimaLavorazioneTask &&
      !task.ultimaLavorazioneTask.dataRilascio &&
      !task.ultimaLavorazioneTask.dataCompletamento
    );
  }

  /**
   * Ritorna `true` se l'utente risulta assegnato all'attività.
   */
  static isAssigned(
    task: AttivitaDTO | null | undefined,
    user: DatiSessioneUtente | null | undefined
  ) {
    if (!user?.utenteLoggato) {
      return false;
    }

    if (!task) return false;

    // Se l'utente non ha i permessi, non è assegnato (potrebbe essere assegnato ma con un altro ruolo)
    if (!this.hasPermission(task, user)) {
      return false;
    }

    // TODO sarebbe meglio fare un check sull'userId ma l'api dei task non lo ritorna
    return (
      this.isTaskAssigned(task) &&
      task.ultimaLavorazioneTask?.cfUtenteLavorazione ===
        user.utenteLoggato.codiceFiscale
    );
  }

  /**
   * Ritorna vero se l'utente risulta abilitato al rilascio dell'attività o alla sua esecuzione.
   */
  static canExecRelease(
    task: AttivitaDTO,
    user: DatiSessioneUtente | null | undefined
  ) {
    return this.isAssigned(task, user);
  }

  /**
   * Ritorna vero se l'attività può essere assegnata all'utente
   */
  static canAssign(task: AttivitaDTO) {
    // Se è già assegnata a qualcuno, non può essere riassegnata
    if (this.isTaskAssigned(task)) {
      return false;
    }

    return true;
  }
}
