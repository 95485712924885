import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import {
  AzioneDTO,
  AzioniDocumento,
  AzioniSezione
} from 'client/api/backend/schemas';
import { DocumentLogic } from 'client/components/schema/task/document/DocumentLogic';
import { FastSectionJnlpWindowManager } from 'client/components/schema/task/jnlp/FastSectionJnlpWindowManager';
import * as React from 'react';
import { useState } from 'react';

export interface ISectionFastActionButtonProps {
  section: AzioniSezione;
  action: AzioneDTO;
  taskId: string;
  document: AzioniDocumento;
}

/**
 * Pulsante per l'azione sulla singola sezione.
 */
// TODO: Unificare in un unico pulsante con Dropdown
// TODO/HI: Gestire ulteriori azioni (per ora c'è solo modifica)
export function SectionFastActionButton(props: ISectionFastActionButtonProps) {
  const { section, action, taskId, document } = props;
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button
        size="large"
        onClick={async () => {
          setVisible(true);
        }}
      >
        {section.sezione?.nomeTipoSezione}{' '}
        {DocumentLogic.isSectionCompiled(section) && (
          <Tooltip title={`Sezione caricata`}>
            <CheckCircleFilled />
          </Tooltip>
        )}
      </Button>
      <FastSectionJnlpWindowManager
        taskId={taskId}
        section={section}
        document={document.documento!}
        visible={visible}
        hide={() => {
          setVisible(false);
        }}
      />
    </>
  );
}
