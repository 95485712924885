/**
 * Generato automaticamente.
 * Procedimenti
 */
import { useQuery } from 'react-query';
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  BEResponseListUfficioType,
  RicercaUfficiParams
} from '.././schemas';
import { backendApiInstance } from '../../../core/network/mutators/apiClient';
import type { ErrorType } from '../../../core/network/mutators/apiClient';

/**
 * Restituisce un unico ufficio
 * @summary Dati ufficio
 */
export const ricercaUfficioById = (idUfficio: string, signal?: AbortSignal) => {
  return backendApiInstance<BEResponseListUfficioType>({
    url: `/uffici/${idUfficio}`,
    method: 'get',
    signal
  });
};

export const getRicercaUfficioByIdQueryKey = (idUfficio: string) => [
  `/uffici/${idUfficio}`
];

export type RicercaUfficioByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof ricercaUfficioById>>
>;
export type RicercaUfficioByIdQueryError = ErrorType<unknown>;

export const useRicercaUfficioById = <
  TData = Awaited<ReturnType<typeof ricercaUfficioById>>,
  TError = ErrorType<unknown>
>(
  idUfficio: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof ricercaUfficioById>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRicercaUfficioByIdQueryKey(idUfficio);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof ricercaUfficioById>>
  > = ({ signal }) => ricercaUfficioById(idUfficio, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof ricercaUfficioById>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idUfficio,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Restituisce una lista di uffici
 * @summary Ricerca uffici
 */
export const ricercaUffici = (
  params?: RicercaUfficiParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseListUfficioType>({
    url: `/uffici/`,
    method: 'get',
    params,
    signal
  });
};

export const getRicercaUfficiQueryKey = (params?: RicercaUfficiParams) => [
  `/uffici/`,
  ...(params ? [params] : [])
];

export type RicercaUfficiQueryResult = NonNullable<
  Awaited<ReturnType<typeof ricercaUffici>>
>;
export type RicercaUfficiQueryError = ErrorType<unknown>;

export const useRicercaUffici = <
  TData = Awaited<ReturnType<typeof ricercaUffici>>,
  TError = ErrorType<unknown>
>(
  params?: RicercaUfficiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof ricercaUffici>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRicercaUfficiQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ricercaUffici>>> = ({
    signal
  }) => ricercaUffici(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof ricercaUffici>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
