/**
 * Generato automaticamente.
 * Procedimenti
 */

export type GetNotificheSortField =
  typeof GetNotificheSortField[keyof typeof GetNotificheSortField];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetNotificheSortField = {
  DATA_NOTIFICA: 'DATA_NOTIFICA',
  DATA_PRESA_VISIONE: 'DATA_PRESA_VISIONE',
  TIPO: 'TIPO',
  PRIORITA: 'PRIORITA'
} as const;
