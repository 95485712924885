import { message, Tooltip } from 'antd';
import { useRilascia } from 'client/api/backend/attivita/attivita';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { logger } from 'client/core/logger/logger';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import { IconRelaese } from 'client/ui/icon/ApplicationIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import React from 'react';

import { useQueryClient } from 'react-query';
import { IconTable } from './IconTable';

export interface IReleaseTaskIconButtonProps {
  taskId: string | undefined;
}

export function ReleaseTaskIconButton(props: IReleaseTaskIconButtonProps) {
  const { taskId } = props;
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();
  // Rilascio massivo
  const releaseFunction = useRilascia();

  return (
    <Tooltip
      overlayClassName="button-icon-tooltip"
      title="Rilascia"
      placement="bottomRight"
    >
      <ButtonConfirm
        title="Vuoi rilasciare questa attività?"
        okText="Rilascia"
        okType="primary"
        okDanger={false}
        size="large"
        type="link"
        popIcon={<IconWrapper icon={<IconRelaese />} />}
        icon={<IconTable icon={<IconRelaese />} />}
        loading={releaseFunction.isLoading}
        disabled={!taskId}
        onConfirm={async e => {
          if (!taskId) return;
          try {
            await releaseFunction.mutateAsync({
              idTaskInstance: taskId
            });
            queryClient.invalidateQueries();
            message.success('Attività rilasciata');
          } catch (e) {
            logger.error({ error: e });
            message.error(`Si è verificato un errore durante il rilascio. Riprovare...`); // prettier-ignore
          } finally {
            e?.stopPropagation();
          }
        }}
      />
    </Tooltip>
  );
}
