import { DatiSessioneUtente } from 'client/api/auth/schemas';
import { AzioneDTO } from 'client/api/backend/schemas/azioneDTO';
import { IDocumentFileInfo } from 'client/ui/form/input/UploadInput';
import { AttachmentsAction } from './DeclarationActions';

export class AttachmentActionLogic {
  static can(actions: AzioneDTO[] | undefined, action: AttachmentsAction) {
    if (!actions) return false;
    return actions.some(a => a.idAzione === action);
  }

  static isVisible(actions?: AzioneDTO[]) {
    return this.can(actions, AttachmentsAction.View);
  }

  static canEditOwn(actions?: AzioneDTO[]) {
    return this.can(actions, AttachmentsAction.EditOwn);
  }

  static canEditOffice(actions?: AzioneDTO[]) {
    return this.can(actions, AttachmentsAction.EditOffice);
  }

  static canDeleteOwn(actions?: AzioneDTO[]) {
    return this.can(actions, AttachmentsAction.DeleteOwn);
  }

  static canDeleteOffice(actions?: AzioneDTO[]) {
    return this.can(actions, AttachmentsAction.DeleteOffice);
  }

  static canUpload(actions?: AzioneDTO[]) {
    return this.can(actions, AttachmentsAction.Upload);
  }

  /**
   * Controlla che l'utente possa eliminare/modificare il file in base ai permessi.
   */
  static canHandleFile(
    actions?: AzioneDTO[],
    user?: DatiSessioneUtente | null,
    file?: IDocumentFileInfo
  ) {
    const isSameFiscalCode =
      file?.ownerFiscalId === user?.utenteLoggato?.codiceFiscale;

    const isSameOfficeOrNull =
      !file?.office ||
      user?.utenteLoggato?.utenteUfficis?.some(
        office => office.idUfficio === file?.office
      );

    let canDelete = false;
    let canEdit = false;

    // Controllo se ho i permessi di modifica
    if (AttachmentActionLogic.canEditOwn(actions) && isSameFiscalCode) {
      canEdit = true;
    }
    if (AttachmentActionLogic.canEditOffice(actions) && isSameOfficeOrNull) {
      canEdit = true;
    }

    // Controllo se ho i permessi di eliminazione
    if (AttachmentActionLogic.canDeleteOwn(actions) && isSameFiscalCode) {
      canDelete = true;
    }

    if (AttachmentActionLogic.canDeleteOffice(actions) && isSameOfficeOrNull) {
      canDelete = true;
    }

    return { canDelete, canEdit };
  }

  /**
   * Controlla se il gruppo di allegati è modificabile in base alle azioni associate.
   */
  static isAttachmentGroupEditable(actions?: AzioneDTO[]) {
    if (!actions) return false;
    return actions.some(
      a =>
        a.idAzione === AttachmentsAction.EditOwn ||
        a.idAzione === AttachmentsAction.EditOffice ||
        a.idAzione === AttachmentsAction.CustomEdit
    );
  }
}
