import * as React from 'react';
import moment, { Moment } from 'moment';
import { DatePicker } from 'antd';
import { useField, useFormikContext, FormikValues } from 'formik';
import { dateInputUtils } from './dateInputUtils';
import { DatePickerProps } from 'antd/lib/date-picker';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import { useFormikFormContext } from '../FormikFormContext';
import { ISODate } from 'client/ui/display/date/ISODate';

export interface DatePickerInputProps<T extends FormikValues>
  extends PickerDateProps<Moment> {
  name: keyof T & string;
  type: 'from' | 'to' | 'date-only';
}

/**
 * Input per cercare su una data.
 */
export function DatePickerInput<T extends FormikValues>(
  props: DatePickerInputProps<T>
) {
  const { name, type, ...otherProps } = props;
  const [field, meta, helpers] = useField(name);
  const formik = useFormikContext<T>();
  const { disabled } = useFormikFormContext();

  return (
    <DatePicker
      name={name}
      value={dateInputUtils.toMoment(type, field.value)}
      onOpenChange={status => {
        if (!status) helpers.setTouched(true);
      }}
      format={otherProps.format ?? 'DD/MM/YYYY'}
      onBlur={field.onBlur}
      onChange={(date, raw) => {
        helpers.setValue(
          date == null ? null : dateInputUtils.toString(type, date)
        );
        setTimeout(() => {
          helpers.setTouched(true, true);
        }, 0);
      }}
      style={{ width: '100%' }}
      {...otherProps}
      disabled={otherProps.disabled || disabled}
    />
  );
}

DatePickerInput.renderFieldValue = (value: any) => {
  // TODO Gestire
  return <ISODate date={value} />;
};
