import { BEResponse } from 'client/api/backend/schemas';
import { ProviderParameters } from '../../provider-parameters/ProviderParameters';
import { useRemoteSignApi } from './useRemoteSignApi';

export interface IUseOtpRequestParams {
  provider: ProviderParameters | null | undefined;
}

export function useOtpRequest(props: IUseOtpRequestParams) {
  const { provider } = props;

  const handleRequestOtp = useRemoteSignApi<BEResponse>({
    method: 'POST',
    apiUrl: `/firma-remota/otp/${provider?.webserviceId}`,
    skip: !provider,
    fields: provider?.otpParameters ?? []
  });

  return handleRequestOtp;
}
