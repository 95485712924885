import React from 'react';
import { Formik } from 'formik';
import { Card, Button, Typography, Divider, message } from 'antd';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useDispatch } from 'react-redux';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { Link } from 'react-router-dom';
import { appConfig } from 'client/core/appConfig';
import styled from 'styled-components';
import { joinPath } from 'client/core/network/joinPath';
import { LoginCard } from './LoginCard';
import { useLogin } from 'client/api/auth/sessione-controller/sessione-controller';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { AuthActions } from '../AuthModule';

interface Props {}

/**
 * Form di login all'applicativo, tramite le API di creazione AccessToken JWT.
 */
export function LoginForm(props: Props) {
  const login = useLogin({});
  const dispatch = useDispatch();

  const strategies = appConfig.loginStrategies;

  return (
    <Formik
      initialValues={{}}
      // validate={schemaToValidator(createAccessTokenValidator)}
      onSubmit={async (values, helpers) => {
        try {
          const result = await login.mutateAsync({ data: values });
          dispatch(AuthActions.loginSucceed('jwt', 'first-auth'));
        } catch (e) {
          message.error(
            getNetworkErrorMessage(
              e,
              `Si è verificato un errore durante il login, si prega di controllare le credenziali e riprovare.`
            )
          );
        }
      }}
    >
      <LoaderSpin spinning={login.isLoading} transparent>
        <LoginCard
          title="Login"
          subTitle="Accedi alla piattaforma Procedimenti"
        >
          <FormikForm layout="vertical">
            {strategies.includes('basic') && (
              <>
                <TextInputItem
                  size="large"
                  name="username"
                  autoComplete="username"
                  placeholder="User"
                />
                <TextInputItem
                  size="large"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  placeholder="Password"
                />
                <Button
                  htmlType="submit"
                  size="large"
                  type="primary"
                  block
                  loading={login.isLoading}
                >
                  Login
                </Button>
              </>
            )}
            {strategies.includes('basic') && strategies.length > 1 && (
              <Divider />
            )}
            {strategies.includes('spid') && (
              <Button
                size="large"
                type="primary"
                style={{ width: '100%' }}
                href={
                  joinPath([
                    appConfig.remotePath,
                    `/procedimenti-proxy?redirect=`
                  ]) + encodeURIComponent(window.location.href)
                }
              >
                Login con SPID
              </Button>
            )}
            {/* <Divider />
            <Link to="/task/list">Lorem ipsum du</Link> */}
          </FormikForm>
        </LoginCard>
      </LoaderSpin>
    </Formik>
  );
}
