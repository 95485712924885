import React from 'react';
import styled from 'styled-components';

const ActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;

  // gestione pulsanti multipli (mandiamo accapo)
  flex-wrap: wrap;
  justify-content: flex-end;

  // gestione spazio tra i gruppi di pulsanti (tra quelli di destra e sinistra)
  margin: -4px;

  & + div {
    margin-top: 24px;
  }
`;
const LeftZone = styled.div`
  flex: 1 1 auto;
  min-width: 1px;
  max-width: 100%;
  margin: 4px;
`;
const RightZone = styled.div`
  flex: 0 0 auto;
  min-width: 1px;
  max-width: 100%;
  margin: 4px;
`;

export interface ActionsContainerProps {
  right?: React.ReactNode;
  left?: React.ReactNode;
}

/**
 * Conteiner delle azioni sul documento
 */
export function ActionsContainer(props: ActionsContainerProps) {
  return (
    <ActionsWrapper>
      <LeftZone>{props.left}</LeftZone>
      <RightZone>{props.right}</RightZone>
    </ActionsWrapper>
  );
}
