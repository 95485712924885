import { logger } from 'client/core/logger/logger';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import {
  DeclarationFieldCheckType,
  DeclarationFieldDto
} from 'common/dto/declaration/DeclarationFieldDto';
import { assertNever } from 'common/utils/assertNever';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import { IDeclarationFieldOptions } from '../DeclarationFieldSwitch';

export interface IDeclarationFieldTextAreaProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

export function DeclarationFieldTextArea(
  props: IDeclarationFieldTextAreaProps
) {
  const { field, options } = props;

  const prefix = options.namePrefix;

  const fieldName = prefix ? `${prefix}.${field.nome}` : field.nome;

  return (
    <FormFieldItem
      name={fieldName}
      label={field.des_campo}
      component={TextAreaInput}
    />
  );
}
