import { IRemoteSignState } from './RemoteSignReducer';

export enum RemoteSignAction {
  SetProvider = 'SET_PROVIDER',
  SetCertificate = 'SET_CERTIFICATE',
  SetCertificates = 'SET_CERTIFICATES',
  SetSignType = 'SET_SIGN_TYPE',
  SetDynamicValues = 'SET_DYNAMIC_VALUES',
  SetCurrentStep = 'SET_CURRENT_STEP',
  Reset = 'RESET',
  SetError = 'SET_ERROR'
}

interface IBaseAction {
  type: RemoteSignAction;
}

export interface IRemoteSignSetterAction extends IBaseAction {
  type:
    | RemoteSignAction.SetProvider
    | RemoteSignAction.SetCertificate
    | RemoteSignAction.SetCertificates
    | RemoteSignAction.SetSignType
    | RemoteSignAction.SetDynamicValues
    | RemoteSignAction.SetCurrentStep;
  payload: Partial<IRemoteSignState>;
}

export interface IResetAction extends IBaseAction {
  type: RemoteSignAction.Reset;
}

export type IRemoteSignErrorBag = {
  taskId: string;
  messages: string[];
}[];

export interface ISetErrorAction extends IBaseAction {
  type: RemoteSignAction.SetError;
  payload: {
    errorBag: IRemoteSignErrorBag;
  };
}

export type IRemoteSignAction =
  | IRemoteSignSetterAction
  | IResetAction
  | ISetErrorAction;
