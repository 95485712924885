import { GruppoAllegati } from 'client/api/backend/schemas';

export class AttachmentLogic {
  /**
   * Ritorna vero se il gruppo contiene almeno un allegato con file
   */
  static hasAnyFile(group: GruppoAllegati | undefined) {
    if (!group?.allegati) return false;
    return group.allegati.some(a => a.files && a.files?.length > 0);
  }
}
