import * as React from 'react';
import { Space } from 'antd';
import { TableData } from 'client/ui/table/TableData';
import styled from 'styled-components';
import {
  GetProcedimentiParams,
  GetProcedimentiSortBy,
  GetProcedimentiSortDirection,
  Procedimento
} from 'client/api/backend/schemas';
import moment from 'moment';
import { SorterResult } from 'antd/lib/table/interface';
import { getColumnsProcedureList } from './getColumnsProcedureList';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { columnsProcedureListMobile } from './columnsProcedureListMobile';

const ExpandedRowWrapper = styled(Space)`
  margin: -6px 0 0 40px;
  display: flex;
  ${down('md')} {
    flex-wrap: wrap;
  }
`;
const Label = styled.div`
  font-size: ${props => props.theme.fontSizeSm};
  line-height: 1em;
  color: ${props => props.theme.textColorSecondary};
  opacity: 0.75;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
`;

export interface ProcedureListTableProps {
  data: Procedimento[];
  total: number;
  query: GetProcedimentiParams;
  setQuery: (input: GetProcedimentiParams) => void;
  loading: boolean;
}

const DEFAULT_PAGE_SIZE = 10;

export function ProcedureListTable(props: ProcedureListTableProps) {
  const { data, total, setQuery, query, loading } = props;
  const isMobile = useBreakpoint(down('md'));
  const user = useCurrentUser();

  const pageSize = query.pageSize
    ? parseInt(query.pageSize as any, 10)
    : DEFAULT_PAGE_SIZE;
  const pageNumber = query.page ? parseInt(query.page as any, 10) : 1;

  return (
    <TableData
      rowKey="id"
      columns={
        isMobile ? columnsProcedureListMobile : getColumnsProcedureList(user)
      }
      showHeader={isMobile ? false : true}
      dataSource={data}
      loading={loading}
      pagination={{
        current: pageNumber,
        pageSize: pageSize,
        total
      }}
      onChange={(pagination, filters, sorter) => {
        const { field, order } = sorter as SorterResult<Procedimento>;

        const newQuery = {
          ...query,
          page: pagination.current,
          pageSize: pagination.pageSize,
          sortBy: !order
            ? GetProcedimentiSortBy.DATA_ULTIMA_MODIFICA
            : GetProcedimentiSortBy[field as GetProcedimentiSortBy] ??
              GetProcedimentiSortBy.DATA_ULTIMA_MODIFICA,
          sortDirection:
            order === 'ascend'
              ? GetProcedimentiSortDirection.ASC
              : GetProcedimentiSortDirection.DESC
        };
        setQuery(newQuery);
      }}
      expandable={{
        expandedRowRender: record => (
          <ExpandedRowWrapper size={[24, 10]}>
            {/* nomeUfficioAvvio */}
            <Space direction="vertical" size={0}>
              <Label>Ufficio d'Avvio</Label>
              <div>{record.nomeUfficioAvvio}</div>
            </Space>
            {/* nomeUtenteAvvio */}
            <Space direction="vertical" size={0}>
              <Label>Utente Avvio</Label>
              <div>{record.nomeUtenteAvvio}</div>
            </Space>
            {/* nomeUfficioCompetente */}
            <Space direction="vertical" size={0}>
              <Label>Ufficio Competente</Label>
              <div>{record.nomeUfficioCompetente}</div>
            </Space>
            {/* dataUltimaModifica */}
            <Space direction="vertical" size={0}>
              <Label>Ultima Modifica</Label>
              <div>
                {record.dataUltimaModifica
                  ? moment(
                      record.dataUltimaModifica,
                      'DD/MM/YYYY HH:mm:ss'
                    ).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </div>
            </Space>
          </ExpandedRowWrapper>
        )
      }}
    />
  );
}
