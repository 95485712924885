import * as React from 'react';
import { Alert, Card, Collapse } from 'antd';
import { PhasesContainer } from 'client/components/schema/history/detail/PhasesContainer';
import { PhasePanel } from 'client/components/schema/history/PhasePanel';
import { HistoryItem } from 'client/components/schema/history/HistoryItem';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { ResponsiveCard } from 'client/ui/card/ResponsiveCard';
import { useTaskHistory } from 'client/components/schema/task/hooks/useTaskHistory';

export interface TabHistoryProps {
  procedureId: string | undefined;
}

/**
 *  Tab della Cronologia
 */
export function TabHistory(props: TabHistoryProps) {
  const { history, result } = useTaskHistory(props.procedureId);

  if (result?.isLoading) {
    return (
      <Card bordered={false} style={{ padding: '12px' }}>
        <LoaderSpin full />
      </Card>
    );
  }

  if (result?.isError) {
    return (
      <Alert
        showIcon
        type="error"
        message="Impossibile caricare la cronologia"
        description={getNetworkErrorMessage(
          result.error,
          'Si è verificato un errore'
        )}
      />
    );
  }

  return (
    <ResponsiveCard bordered={false}>
      <PhasesContainer>
        <Collapse defaultActiveKey={history?.map((_, index) => index)} ghost>
          {history?.map((mainPhase, i) => (
            <PhasePanel key={i} phase={mainPhase}>
              {mainPhase.subphases.map((item, k) => (
                <HistoryItem key={k} item={item} />
              ))}
            </PhasePanel>
          ))}
        </Collapse>
      </PhasesContainer>
    </ResponsiveCard>
  );
}
