import { Alert } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { TaskCard, TaskCardMode } from 'client/ui/card/TaskCard';
import React from 'react';

export interface IErrorCardProps {
  message: string;
  description: string;
  mode?: TaskCardMode;
}

/**
 * Card che viene mostrata in caso di errore in una chiamata al BE
 */
export function ErrorCard(props: IErrorCardProps) {
  const {} = props;
  return (
    <TaskCard mode={props.mode}>
      <Alert
        showIcon
        type="error"
        message={props.message}
        description={props.description}
      />
    </TaskCard>
  );
}
