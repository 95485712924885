import * as React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';

const LabelCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;
const DataCol = styled(Col)`
  display: flex;
  padding: 8px 0;
`;
const DataRow = styled(Row)`
  border-bottom: 1px solid #e8e8e8;
  &:first-child {
    border-top: 1px solid #e8e8e8;
  }
`;

export interface LabelValueLineProps {
  label?: React.ReactNode;
  value?: React.ReactNode;
  labelSpan?: number;
}

/**
 * Semlice riga per visualizzazione di una label e un valore
 */
export function LabelValueLine(props: LabelValueLineProps) {
  const { label, value, labelSpan = 8 } = props;
  return (
    <DataRow gutter={18} align="middle">
      <LabelCol span={labelSpan}>{label}</LabelCol>
      <DataCol span={24 - labelSpan}>{value}</DataCol>
    </DataRow>
  );
}
