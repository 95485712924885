import React from 'react';
import { AttivitaDTO, Procedimento } from 'client/api/backend/schemas';
import styled from 'styled-components';
import { ProcedureTableTag } from 'client/ui/tag/ProcedureTableTag';
import { Space, Typography } from 'antd';
const { Text, Paragraph } = Typography;
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';
import { TaskName } from 'client/ui/tag/TaskName';
import { TaskRowProcedure } from 'client/ui/table/mobile/TaskRowProcedure';
import { TaskDateFrom } from 'client/ui/table/mobile/TaskDateFrom';
import { ItemListMobile } from 'client/ui/table/mobile/ItemListMobile';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { IconView } from 'client/ui/icon/ApplicationIcon';

export interface IProcedureListItemMobileProps {
  record: Procedimento | undefined;
}
/**
 * Visualizzazione a lista per Mobile dell'attività
 */
export function ProcedureListItemMobile(props: IProcedureListItemMobileProps) {
  const { record } = props;

  if (!record) return null;

  return (
    <ItemListMobile
      onlyOnAction
      actions={
        <ButtonIcon
          icon={<IconWrapper icon={<IconView />} />}
          to={`/procedure/list/${record.id}`}
          tooltip="Dettaglio"
          tooltipProps={{ placement: 'bottomRight' }}
        />
      }
    >
      <Space style={{ width: '100%' }} direction="vertical" size={2}>
        <TaskRowProcedure>
          <ProcedureTableTag
            name={record.tipologiaProcedimento?.nomeTipo}
            color={record.tipologiaProcedimento?.coloreRendering}
            icon={record.tipologiaProcedimento?.iconaRendering}
          />
          <TaskName>{record.nomeFaseCorrente}</TaskName>
        </TaskRowProcedure>

        <Paragraph
          ellipsis={{
            rows: 2,
            expandable: true
          }}
        >
          <Text strong>{record.numero}</Text> - {record.oggetto}
        </Paragraph>

        {record.dataAvvio && (
          <TaskDateFrom
            date={record.dataAvvio}
            format={'DD/MM/YYYY HH:mm'}
            text="Avviato il"
          />
        )}
      </Space>
    </ItemListMobile>
  );
}
