import modal, { ModalProps } from 'antd/lib/modal';

/**
 * Mostra un modal in modalità asincrona per conferma in linea.
 */
export function requestModalConfirmation(props: ModalProps) {
  return new Promise((resolve, reject) => {
    modal.confirm({
      ...props,
      onOk: () => {
        resolve(true);
      },
      onCancel: () => {
        resolve(false);
      },
      content: props.children
    });
  });
}
