import { ProcedimentoConOggettiCollegati } from 'client/api/backend/schemas';
import { TaskFormContainer } from 'client/routes/task/single/container/TaskFormContainer';
import { ProcedureDeclarations } from 'client/routes/procedure/single/declarations/ProcedureDeclarations';
import React from 'react';
import { ProcedureDocuments } from '../documents/ProcedureDocuments';
import { FormikForm } from 'client/ui/form/FormikForm';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { yup } from 'common/validation/initYup';
import { ProcedureStateAnchors } from './anchor/ProcedureStateAnchors';
import { ProcedureAttachmentGroupSection } from '../attachment/ProcedureAttachmentGroupSection';

export interface ITabProcedureProps {
  procedure: ProcedimentoConOggettiCollegati;
  initialValues: any;
}

export function TabProcedure(props: ITabProcedureProps) {
  const { procedure, initialValues } = props;

  const {
    dichiarazioni: declarations,
    documenti: documents,
    gruppiAllegati: attachmentGroups
  } = procedure;

  return (
    <FormikAugmented
      validationSchema={yup.object()}
      validationContext={{}}
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      <FormikForm layout="vertical">
        <TaskFormContainer
          anchors={
            <ProcedureStateAnchors
              documents={documents}
              declarations={declarations}
              attachmentGroups={attachmentGroups}
            />
          }
        >
          <ProcedureDeclarations
            declarations={declarations}
            procedureId={procedure.id!}
            attachmentGroups={procedure.gruppiAllegati}
          />
          <ProcedureDocuments
            documents={documents}
            procedureId={procedure.id!}
          />
        </TaskFormContainer>
      </FormikForm>
    </FormikAugmented>
  );
}
