import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Button, Tooltip, TooltipProps } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import clx from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

export const ButtonTooltipsGlobalStyle = createGlobalStyle`
  body {
    .button-icon-tooltip.ant-tooltip {
      /* &.ant-tooltip-hidden {
        display: block;
      } */
      // Modificato la posizione del tootip
      // per avvicinarlo all'icona
      &.ant-tooltip-placement-top,
      &.ant-tooltip-placement-topLeft,
      &.ant-tooltip-placement-topRight {
        .ant-tooltip-content {
          padding-top: 15px;
          margin-bottom: -15px;
        }
      }
      &.ant-tooltip-placement-bottom,
      &.ant-tooltip-placement-bottomLeft,
      &.ant-tooltip-placement-bottomRight {
        .ant-tooltip-content {
          padding-bottom: 15px;
          margin-top: -15px;
        }
      }
      &.ant-tooltip-placement-left
      &.ant-tooltip-placement-leftTop,
      &.ant-tooltip-placement-leftBottom {
        .ant-tooltip-content {
          padding-left: 15px;
          margin-right: -15px;
        }
      }
      &.ant-tooltip-placement-right
      &.ant-tooltip-placement-rightTop,
      &.ant-tooltip-placement-rightBottom {
        .ant-tooltip-content {
          padding-right: 15px;
          margin-left: -15px;
        }
      }
        
    }
  }
`;

const StyledButton = styled(Button)`
  padding: 6.4px 0;
  height: 40px;
  width: 40px;
`;
const IconWrapperButtonIcon = styled.span`
  &&& {
    font-size: 20px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export interface ButtonIconProps extends Omit<ButtonProps, 'children'> {
  to?: LinkProps['to'];
  linkProps?: Omit<LinkProps, 'to'>;
  tooltip?: TooltipProps['title'];
  tooltipProps?: Omit<TooltipProps, 'title'>;
}

export function ButtonIcon(props: ButtonIconProps) {
  const {
    to,
    tooltip,
    icon,
    // onClick,
    linkProps,
    tooltipProps,
    ...buttonProps
  } = props;
  if (!icon) return null;

  const onClick = to ? undefined : props.onClick;
  const baseButton = (
    <StyledButton type="link" {...buttonProps} {...onClick}>
      <IconWrapperButtonIcon className="anticon">{icon}</IconWrapperButtonIcon>
    </StyledButton>
  );
  const button = tooltip ? (
    <Tooltip
      overlayClassName="button-icon-tooltip"
      title={tooltip}
      {...tooltipProps}
      children={baseButton}
    />
  ) : (
    baseButton
  );
  return to ? <Link to={to} {...linkProps} children={button} /> : button;
}
