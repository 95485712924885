import { Button, Divider, message } from 'antd';
import { ErrorNotificationModal } from 'client/ui/form/errors/ErrorNotificationModal';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { ValidationError } from 'yup';
import { ProviderParameterService } from '../../provider-parameters/ProviderParameterService';
import { useRemoteSignContext } from '../../RemoteSignContext';
import { useOtpRequest } from '../hooks/useOtpRequest';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';

export interface IRemoteSignOtpFieldProps {
  name: string;
  label: string;
}

export function RemoteSignOtpField(props: Readonly<IRemoteSignOtpFieldProps>) {
  const { name, label } = props;
  const {
    state: { provider, dynamicValues }
  } = useRemoteSignContext();

  const formContext = useFormikContext<any>();

  const handleRequestOtp = useOtpRequest({ provider });

  const [loading, setLoading] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [errors, setErrors] = useState<any>(undefined);

  const handleOnClick = useCallback(async () => {
    if (!provider) {
      message.error('Provider non selezionato');
      return;
    }
    setLoading(true);

    // I valori sono tutti quelli precedenti + quelli inseriti nel form attuale
    const requestValues = { ...dynamicValues, ...formContext.values };

    // Creo lo schema di validazione
    const schema = ProviderParameterService.getSchema(provider?.otpParameters);

    try {
      // Per non usare un ulteriore form interno, valido direttamente i campi
      // all'onClick e ne gestisco l'errore simulando formik.
      const input = await schema.validate(requestValues, {
        abortEarly: false,
        stripUnknown: true
      });
      await handleRequestOtp(input);
      message.success('OTP inviata con successo');
    } catch (e: any) {
      console.error(`[RemoteSignOtpField] Errore durante la richiesta di OTP`, { error: e }); // prettier-ignore

      // Gestisco gli errori di tipo
      if (e instanceof ValidationError) {
        e.inner.forEach(err => {
          formContext.setFieldError(err.path ?? '', err.message);
        });
        setErrors(e.inner.map(err => err.errors));
        setSubmitCount(c => c + 1);
        return;
      }
      const errorMessage = e?.response?.data?.esito?.errore.messaggioDiErrore ?? e.message; // prettier-ignore
      message.error(`Errore durante la richiesta di OTP: ${errorMessage}`); // prettier-ignore
    } finally {
      setLoading(false);
    }
  }, [dynamicValues, formContext.values, handleRequestOtp, setLoading]);

  if (!provider) return null;

  // OTP tramite strumenti terzi (e.g. APP)
  if (!provider.requestOtp) {
    return (
      <>
        <Divider>Inserisci OTP</Divider>
        <FormFieldItem
          label={label}
          name={name}
          component={TextInput}
          placeholder="OTP"
        />
      </>
    );
  }

  // OTP tramite richiesta API
  return (
    <>
      <Button onClick={handleOnClick} loading={loading} block size="large">
        Richiedi
      </Button>
      <VerticalSpace />
      <Divider>Inserisci OTP</Divider>
      <FormFieldItem
        label={label}
        name={name}
        component={TextInput}
        placeholder="OTP"
      />
      <ErrorNotificationModal submitCount={submitCount} errors={errors} />
    </>
  );
}
