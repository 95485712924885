import { Alert } from 'antd';
import React from 'react';
import { ITaskInError, ITaskResult } from './CompleteTasksMassiveModal';

export interface ICompleteTasksMassiveAlertProps {
  tasksInError: ITaskInError[];
  taskResults: ITaskResult[];
}

/**
 * Alert per la visualizzazione degli errori durante il completamento massivo
 */
export function CompleteTasksMassiveAlert(
  props: ICompleteTasksMassiveAlertProps
) {
  const { tasksInError, taskResults } = props;
  return (
    <Alert
      showIcon
      type="error"
      description={
        <>
          <p>
            Si sono verificati errori durante il completamento di{' '}
            {tasksInError.length} attività su {taskResults.length} :
          </p>
          <ul>
            {tasksInError.map((task: ITaskInError) => (
              <li>{`${task.taskId}  - ${task.message}`}</li>
            ))}
          </ul>
        </>
      }
    />
  );
}
