import * as React from 'react';
import { FormikForm } from 'client/ui/form/FormikForm';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { StateSelectIputItem } from './StateSelectIputItem';
import { UserAsyncSelectInput } from './UserAsyncSelectInput';
import { ProcedureAsyncSelectInput } from './ProcedureAsyncSelectInput';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { OfficeSelectInput } from 'client/routes/procedure/list/search/OfficeSelectInput';

export interface TaskSearchFormProps {}

export function TaskSearchForm(props: TaskSearchFormProps) {
  const isMobile = useBreakpoint(down('sm'));

  return (
    <FormikForm nested layout="vertical" editable helpInTooltips>
      <FormFieldsContainer columns={isMobile ? 1 : 4} gutter={12}>
        <FormFieldItem
          colSpan={isMobile ? undefined : 2}
          size="middle"
          component={TextInput}
          name="oggettoProcedimento"
          label="Oggetto"
          placeholder="Oggetto..."
          allowClear
        />
        <FormFieldItem
          size="middle"
          component={TextInput}
          name="numeroProcedimento"
          label="Numero"
          placeholder="Numero..."
          allowClear
        />
        {/* TODO quale è il campo corretto? */}
        <ProcedureAsyncSelectInput
          name="processDefinitionId"
          placement="bottomRight"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={isMobile ? 1 : 3} gutter={12}>
        <StateSelectIputItem name="statoAttivita" />

        <OfficeSelectInput
          name="idUfficioAvvio"
          label="Ufficio di Avvio"
          placeholder="Avviato dall'ufficio..."
          responseField={office => office.id!}
        />
        <UserAsyncSelectInput name="idUtenteAssegnatarioTask" />
      </FormFieldsContainer>

      <FormFieldsContainer columns={isMobile ? 1 : 4} gutter={12}>
        <FormFieldItem
          size="middle"
          component={DatePickerInput}
          type="from"
          name="dataCreazioneTaskIn"
          label="Da Data Creazione"
          placement="bottomRight"
          placeholder="Data Creazione (inizio)"
          allowClear
        />
        <FormFieldItem
          size="middle"
          component={DatePickerInput}
          placement="bottomRight"
          type="to"
          name="dataCreazioneTaskFn"
          label="A Data Creazione"
          placeholder="Data Creazione (fine)"
          allowClear
        />
        <FormFieldItem
          size="middle"
          component={DatePickerInput}
          placement="bottomRight"
          type="from"
          name="dataInizioLavorazioneTaskIn"
          label="Da Data Avvio Lavorazione"
          placeholder="Data Avvio Lavorazione (inizio)"
          allowClear
        />
        <FormFieldItem
          size="middle"
          component={DatePickerInput}
          placement="bottomRight"
          type="to"
          name="dataInizioLavorazioneTaskFn"
          label="A Data Avvio Lavorazione"
          placeholder="Data Avvio Lavorazione (fine)"
          allowClear
        />
      </FormFieldsContainer>
    </FormikForm>
  );
}
