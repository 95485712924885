import React from 'react';
import { iconNames } from './iconNames';

export interface ProcedureIconProps {
  name?: string;
}

export function ProcedureIcon(props: ProcedureIconProps) {
  const icon = iconNames.find(icon => icon.name === props.name);
  if (!icon) {
    return null;
  }
  return icon.icon;
}
