import React from 'react';
import moment from 'moment';

export interface IISODateProps {
  date: string | null | undefined | Date;
  format?: string;
  parseFormat?: string;
}

/**
 * Mostra una data nel formato locale.
 * Al momento è fissato in DD/MM/YYYY HH:mm:ss per via del formato.
 */
export function ISODate(props: IISODateProps) {
  if (!props.date) return null;

  const date = moment(props.date, props.parseFormat);
  return <span>{date.format(props.format || 'DD/MM/YYYY')}</span>;
}
