import {
  AzioniDichiarazione,
  AzioniDocumento,
  AzioniGruppoAllegato
} from 'client/api/backend/schemas';
import { useEffect, useMemo, useState } from 'react';
import { getAttachmentGroupsInitialValues } from '../../attachments/useAttachments';
import { getDeclarationsInitialValues } from '../../declaration/getDeclarationsInitialValues';
import { getDocumentsInitialValues } from '../../document/useDocuments';
import { useInitialTouched } from '../declaration/validation/useInitialTouched';
import {
  createTaskValidator,
  getPartialValidator
} from './createTaskValidator';

/**
 * Hook che ritorna le varie proprietà utili per la validazione delle attività.
 */
export function useTaskValidation(props: {
  declarations: AzioniDichiarazione[];
  documents: AzioniDocumento[];
  attachments: AzioniGruppoAllegato[];
  isLoading?: boolean;
}) {
  const { declarations, documents, attachments, isLoading } = props;
  // Genero il validatore dinamico
  const validator = useMemo(
    () =>
      createTaskValidator({
        declarations: declarations,
        documents: documents,
        attachments: attachments
      }),
    [declarations, documents, attachments]
  );

  const partialValidator = useMemo(
    () =>
      getPartialValidator({
        declarations: declarations,
        documents: documents,
        attachments: attachments
      }),
    [declarations, documents, attachments]
  );

  const [initialValues, setInitialValues] = useState<any>({});

  useEffect(() => {
    if (isLoading) return;
    setInitialValues({
      ...getDeclarationsInitialValues(declarations.map(d => d.dichiarazione!)),
      ...getDocumentsInitialValues(documents.map(d => d.documento!)),
      ...getAttachmentGroupsInitialValues(attachments)
    });
  }, [declarations, documents, attachments, isLoading]);

  const initialTouched = useInitialTouched(initialValues);

  return {
    validator,
    partialValidator,
    initialValues,
    initialTouched
  };
}
