import { assertNever } from 'common/utils/assertNever';
import { yup } from 'common/validation/initYup';
import { fromPairs } from 'lodash';
import {
  ParameterKey,
  ProviderInternalDynamicField,
  ProviderInternalDynamicFieldType,
  ProviderParameters,
  RemoteSignType
} from './ProviderParameters';

export class ProviderParameterService {
  /**
   * Converte il campo `parametri` della GET dei provider (JSON stringa)
   * in un oggetto da utilizzare internamente al portale.
   */
  static parse(input: {
    id: string | null | undefined;
    name: string | null | undefined;
    params: string | undefined | null;
  }): ProviderParameters | null {
    const { id, name, params } = input;

    if (!params || !id) return null;

    const parsed = JSON.parse(params) as Array<{
      codice: string;
      valore: string;
    }>;

    if (!parsed) return null;

    const multiCertificate =
      parsed.find(p => p.codice === ParameterKey.multiCertificate)?.valore ===
      'true';

    const requestOtp =
      parsed.find(p => p.codice === ParameterKey.requestOtp)?.valore === 'true';

    const signType = parsed
      .filter(p => p.codice === ParameterKey.signType)
      .map(p => p.valore) as RemoteSignType[];

    const otpAuthType = parsed.find(
      p => p.codice === ParameterKey.otpAuthType
    )?.valore;

    const certificateParameters = this.parseInternalParameters(
      parsed.find(p => p.codice === ParameterKey.certificateParameters)?.valore
    );

    const remoteSignParameters = this.parseInternalParameters(
      parsed.find(p => p.codice === ParameterKey.remoteSignParameters)?.valore
    );

    const otpParameters = this.parseInternalParameters(
      parsed.find(p => p.codice === ParameterKey.otpParameters)?.valore
    );

    return {
      name: name ?? 'Provider senza nome',
      webserviceId: id,
      multiCertificate,
      requestOtp,
      signType,
      otpAuthType,
      certificateParameters,
      remoteSignParameters,
      otpParameters
    };
  }

  private static parseInternalParameters(
    params: string | undefined | null
  ): ProviderInternalDynamicField[] {
    if (!params) return [];

    return JSON.parse(params) as ProviderInternalDynamicField[];
  }

  /**
   * Ritorna lo schema di validazione per i parametri di
   * una fase del provider.
   */
  static getSchema(
    params: ProviderInternalDynamicField[]
  ): yup.ObjectSchema<any> {
    const shape = fromPairs(
      params.map(p => [p.nome, this.getParamFieldSchema(p)])
    );

    const schema = yup.object().shape(shape).required().noUnknown();

    return schema;
  }

  private static getParamFieldSchema(param: ProviderInternalDynamicField) {
    switch (param.tipo) {
      case ProviderInternalDynamicFieldType.String:
      case ProviderInternalDynamicFieldType.Password:
      case ProviderInternalDynamicFieldType.Otp:
        return yup.string().required().label(param.descrizione).default('');
      case ProviderInternalDynamicFieldType.Select:
        return yup
          .string()
          .required()
          .oneOf(
            param.opzioni.map(o => o.valore),
            'Opzione non valida'
          )
          .label(param.descrizione);
      default:
        assertNever(param);
    }
  }
}
