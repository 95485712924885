import { useField } from 'formik';
import { useEffect } from 'react';
import { useFormikFormContext } from '../FormikFormContext';
import { useFormikFieldValidation } from '../hooks/validation/useFormikFieldValidation';

// TODO: Usare direttamente questo campo anche in `FormFieldItem` per centralizzare
export function useFormFieldReset(
  name: string,
  options?: { readOnly?: boolean }
) {
  const [field, , helper] = useField(name);
  const formikForm = useFormikFormContext();
  const validation = useFormikFieldValidation(name, {
    readOnly: options?.readOnly
  });

  // Reset a undefined il valore del campo quando viene rimosso dalla validazione.
  // In questo modo permette di nascondere anche i sottocampi più interni la cui
  // visibilità dipende dal valore dei campi padre. (che viene annullata quando
  // vengono nascosti, nascondendo a loro volta tutti i sottocampi a cascata)
  useEffect(() => {
    if (validation.stripped) helper.setValue(undefined);
  }, [validation.stripped]);

  return {
    validation,
    stripped: validation.stripped
  };
}
