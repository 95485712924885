import { DocumentoSenzaAzioni } from 'client/api/backend/schemas';
import { AttachmentGroupSection } from 'client/routes/task/single/documents/AttachmentGroupSection';
import { getAnchorId } from 'client/ui/anchor/getAnchorId';
import { ActionsContainer } from 'client/ui/card/container/ActionsContainer';
import { TaskCard, TaskCardMode } from 'client/ui/card/TaskCard';
import React from 'react';
import { ProcedureAttachmentGroupSection } from '../attachment/ProcedureAttachmentGroupSection';
import { ProcedureDeclarationSection } from '../declarations/ProcedureDeclarationSection';
import { ProcedureDocumentViewButton } from './actions/ProcedureDocumentViewButton';

export interface IProcedureDocumentSectionProps {
  document: DocumentoSenzaAzioni;
  procedureId: string;
}

export function ProcedureDocumentSection(
  props: IProcedureDocumentSectionProps
) {
  const { document, procedureId } = props;

  // Id del file più recente del documento
  const documentFileId = document.files?.find(
    f => f.progressivo === Math.max(...document.files!.map(f => f.progressivo!))
  )?.idFile;

  return (
    <TaskCard
      id={getAnchorId(document.idTipoDocumento!)}
      mode={TaskCardMode.Document}
      title={document.nomeTipoDocumento}
    >
      <ActionsContainer
        right={
          <ProcedureDocumentViewButton
            procedureId={procedureId}
            fileId={documentFileId}
          />
        }
        left={<></>}
      />

      {/* Sezione Dichiarazione */}
      {document.dichiarazioni?.map((declaration, index) => (
        <ProcedureDeclarationSection declaration={declaration} key={index} />
      ))}

      {/*  Sezione Gruppo allegati */}
      {document.gruppoAllegati?.map((group, index) => (
        <ProcedureAttachmentGroupSection
          group={group}
          key={index}
          procedureId={procedureId}
        />
      ))}
    </TaskCard>
  );
}
