import { List, Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ListItemMetaProps } from 'antd/lib/list/Item';

const StyledListItemMeta = styled(List.Item.Meta)`
  .ant-list-item-meta-title,
  .ant-typography {
    font-weight: 400;
    margin-bottom: 8px;
  }
`;

interface ListItemMetaNotificationProps extends ListItemMetaProps {}

/**
 * Sezione finale stilizzata di una singola notifica
 */
export function ListItemMetaNotification(props: ListItemMetaNotificationProps) {
  return <StyledListItemMeta {...props} />;
}
