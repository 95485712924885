import React, { useCallback, useEffect, useState } from 'react';
import { FormikValues, useField } from 'formik';
import { Radio, RadioChangeEvent, RadioProps } from 'antd';
import { useFormikFormContext } from '../FormikFormContext';
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
  MdCheckBoxOutlineBlank,
  MdCheckBox
} from 'react-icons/md';
import { RadioCheckboxInputRenderValue } from './RadioCheckboxInputRenderValue';

export interface IRadioInputProps<T extends FormikValues>
  extends Omit<RadioProps, 'name'> {
  /** Nome del campo che verrà valorizzato (nome del "gruppo" di radio) */
  name: keyof T & string;

  /** Valore assegnato al campo `name` al momento della selezione */
  radioValue: string;

  /** Testo del radio */
  title: string;
}

/**
 * Gestisce i gruppi di radio come singoli Radio
 * @param props
 * @returns
 */
export function RadioInput<T extends FormikValues>(props: IRadioInputProps<T>) {
  const { name, title, radioValue, ...otherProps } = props;
  const [field, meta, helpers] = useField<string>(name);
  const isChecked = field.value === radioValue;
  const { disabled } = useFormikFormContext();

  // Quando viene selezionato, setto il valore del campo
  // a quello del radio
  const handleChange = useCallback(
    (e: RadioChangeEvent) => {
      try {
        if (e.target.checked) {
          helpers.setValue(radioValue);
        }
        setTimeout(() => {
          helpers.setTouched(true, true);
        }, 0);
      } catch (e) {
        console.error(e);
      }
    },
    [radioValue, helpers.setTouched]
  );

  return (
    <Radio
      onChange={handleChange}
      disabled={disabled}
      {...otherProps}
      checked={isChecked}
    >
      {title}
    </Radio>
  );
}

RadioInput.renderFieldValue = (
  value: string | number,
  props: IRadioInputProps<any>
) => {
  return (
    <RadioCheckboxInputRenderValue
      type="radio"
      value={value === props.radioValue}
      title={props.title}
    />
  );
};
