import * as React from 'react';
import { Result } from 'antd';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { OnPageAlertContainer } from 'client/ui/alert/OnPageAlertContainer';

export interface INotFoundPageProps {}

export function NotFoundPage(props: INotFoundPageProps) {
  return (
    <CorePageLayout>
      <OnPageAlertContainer>
        <Result status="error" title="404" subTitle="Pagina Non Trovata." />
      </OnPageAlertContainer>
    </CorePageLayout>
  );
}
