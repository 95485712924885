import * as React from 'react';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useAuth, AuthActions, useCurrentUser } from '../AuthModule';
import { useDispatch } from 'react-redux';
import { UserLogic } from 'common/schema/user/UserLogic';
import { LogoutOutlined } from '@ant-design/icons';
import { logger } from 'client/core/logger/logger';
import { appConfig, resolveApiPath } from 'client/core/appConfig';
import { Menu } from 'antd';
import styled from 'styled-components';
import { joinPath } from 'client/core/network/joinPath';

export interface ILogoutButtonProps {}

/**
 * Gestisce il logout dell'utente corrente, sia dalla sessione remota che dai
 * dati locali.
 */
export function LogoutButton(props: ILogoutButtonProps) {
  const auth = useAuth();
  const user = useCurrentUser();
  const dispatch = useDispatch();

  if (!auth.logged) return null;
  if (!UserLogic.canLogout(user)) return null;

  return (
    <Menu.Item {...props} key="logout" icon={<LogoutOutlined />}>
      <a
        title="Effettua il Logout"
        href={
          joinPath([
            appConfig.remotePath,
            `/procedimenti-proxy/logout?redirect=`
          ]) + encodeURIComponent(window.location.href)
        }
      >
        Logout
      </a>
    </Menu.Item>
  );
}
