import * as React from 'react';
import clx from 'classnames';
import { FormField, IFormFieldProps } from './FormField';
import './FormFieldItem.less';
// non importiamo al momento creato componente apposito per il rendering dei valori
// import './FormFieldItemBooleanInputNotEditable.less';
import { useFormikFormContext } from '../FormikFormContext';
import { useField } from 'formik';
import { FormItem } from '../FormItem';
import { ILabelWidth } from './ILabelWidth';
import { useFormikFieldValidation } from '../hooks/validation/useFormikFieldValidation';
import { RadioGroupInput } from '../input/RadioGroupInput';
import { CheckboxInput } from '../input/CheckboxInput';
import { RadioInput } from '../input/RadioInput';
import { useEffect } from 'react';

export interface IFormFieldItemProps<P extends {}> extends IFormFieldProps<P> {
  /** Nasconde se è vuoto */
  hideIfEmpty?: boolean;

  /**
   * EREDITATE DA FormFieldLabelProps
   */
  label: React.ReactNode;
  /**
   * // TODO Verificare ed eliminare quelle che non servono
   * EREDITATE DA FormFieldLabelProps
   *
   * ghost: Mostra l'elemento senza bordo inferiore
   */
  ghost?: boolean;

  /**
   * inline: Mostra la Label e il campo di Input in linea
   */
  inline?: boolean;

  /**
   * labelWidth: Per gli elementi non in linea definisce
   * la larghezza della colonna della label
   * di default è 100px
   */
  labelWidth?: ILabelWidth;

  /**
   * ColSpan
   */
  colSpan?: number;

  /**
   * className per il FormItem (Container dell'elemento)
   */
  formItemClassName?: string;
}

/**
 * Componente per mostrare una riga di Input con una label che sia
 * visualizzabile sia in Lettura (come semplice etichetta) sia in Scrittura
 * (come Input in base a quanto specificato).
 *
 * Per la specifica del comportamento del campo di input, vedere `FormField`
 */
export function FormFieldItem<P>(props: IFormFieldItemProps<P> & P) {
  const {
    label,
    ghost,
    inline,
    labelWidth,
    hideIfEmpty,
    formItemClassName,
    ...otherProps
  } = props;

  // in caso di visualizzazione dell'Input
  // il componente è sempre senza filo inferiore (ghost)
  const [field, , helper] = useField(props.name);
  const formikForm = useFormikFormContext();
  const validation = useFormikFieldValidation(props.name, {
    readOnly: otherProps.readOnly
  });

  // Reset a undefined il valore del campo quando viene rimosso dalla validazione.
  // In questo modo permette di nascondere anche i sottocampi più interni la cui
  // visibilità dipende dal valore dei campi padre. (che viene annullata quando
  // vengono nascosti, nascondendo a loro volta tutti i sottocampi a cascata)
  useEffect(() => {
    if (validation.stripped) helper.setValue(undefined);
  }, [validation.stripped]);

  const borderless = formikForm.editable ?? ghost;

  const widthClass = inline ? 'width-inline' : labelWidth ?? 'width-100';

  // Editable
  const editable = validation.editable;

  // Nascondo il campo se vuoto
  if (hideIfEmpty && field.value == null) return null;

  // Escludiamo il simbolo richiesto per CheckBox o Radio
  const excludeRequired =
    props.component === (RadioGroupInput as any) ||
    props.component === (RadioInput as any) ||
    props.component === (CheckboxInput as any);

  if (validation.stripped) return null;

  // Se è in HTML lo renderizzo
  const labelNode =
    typeof label === 'string' ? (
      <span dangerouslySetInnerHTML={{ __html: label }} />
    ) : (
      label
    );

  return (
    <FormItem
      label={labelNode}
      name={props.name}
      labelAlign="left"
      colon={false}
      required={editable && validation.required && !excludeRequired}
      className={clx('form-field-item', widthClass, formItemClassName, {
        ghost: borderless,
        editable: editable,
        'not-editable': !editable,
        // Inserita per gestire gli stili in maniera specifica
        'radio-checkbox': excludeRequired
      })}
    >
      <FormField {...(otherProps as IFormFieldProps<P> & P)} />
    </FormItem>
  );
}
