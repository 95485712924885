import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { sanitizeColumnsCount } from 'client/ui/form/field/row/sanitizeColumnsCount';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import * as React from 'react';
import { DeclarationFieldSwitch } from './DeclarationFieldSwitch';
import { useDeclarationFields } from './useDeclarationFields';

export interface IDeclarationFormProps {
  fields: DeclarationFieldDto[];
  prefix: string | null | undefined;
  editable: boolean;
}

/**
 * Effettua il rendering dei campi di una dichiarazione
 */
export function DeclarationForm(props: IDeclarationFormProps) {
  const rows = useDeclarationFields(props.fields);

  return (
    <>
      {rows.map((columns, index) => (
        <FormFieldsContainer
          key={index}
          columns={sanitizeColumnsCount(columns.length)}
          className="declaration-row"
        >
          {columns.map((column, index) => {
            return (
              <ErrorBoundary description="" key={index}>
                <DeclarationFieldSwitch
                  field={column}
                  key={index}
                  options={{ namePrefix: props.prefix }}
                  editable={props.editable}
                />
              </ErrorBoundary>
            );
          })}
        </FormFieldsContainer>
      ))}
    </>
  );
}
