/**
 * Generato automaticamente.
 * Procedimenti
 */

export type GetAttivitaSortBy =
  typeof GetAttivitaSortBy[keyof typeof GetAttivitaSortBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAttivitaSortBy = {
  ID: 'ID',
  NAME: 'NAME',
  ASSIGNEE: 'ASSIGNEE',
  CREATED: 'CREATED'
} as const;
