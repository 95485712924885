import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { ISODate } from './ISODate';

export interface ISORelativeDateProps {
  date: string | null | undefined | Date;
  to?: string | null | undefined | Date;
  format?: string;
  parseFormat?: string;
  relativeOnly?: boolean;
}

/**
 * Mostra una data in formato testuale relativa al momento attuale.
 * La data è mostrata nel Tooltips
 */
export function ISORelativeDate(props: ISORelativeDateProps) {
  if (!props.date) return null;
  const date = moment(props.date, props.parseFormat);
  // se inserito il parametro to, mostriamo il tempo tra le due date
  if (props.to) {
    const endDate = moment(props.to, props.parseFormat);
    // in questo caso non mostriamo il tooltip
    return <span>{endDate.from(date, true)}</span>;
  }
  // calcoliamo il testo da mostrare
  const text = date.fromNow();

  // valutiamo se mostrare il Tooltip
  if (props.relativeOnly) return <span>{text}</span>;

  return (
    <Tooltip title={<ISODate {...props} />}>
      <span>{text}</span>
    </Tooltip>
  );
}
