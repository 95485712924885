import { resolvePublicPath } from 'client/core/appConfig';
import React from 'react';
import styled from 'styled-components';
import { up, down, between, only } from 'styled-breakpoints';
import { theme } from 'client/ui/theme/theme';

const LogoImg = styled.img`
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 50px;

  border: none;
  margin: 0;
  padding: 0;

  ${down('sm')} {
    max-width: 150px;
  }
`;

const LogoWrapper = styled.div`
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${down('sm')} {
    max-width: 150px;
  }
`;

export interface HeaderLogoProps {
  alt?: string;
  ariaLabel?: string;
}

/**
 * Logo principale dell'applicativo.
 */
export function HeaderLogo(props: HeaderLogoProps) {
  return (
    <LogoWrapper>
      <LogoImg
        src={resolvePublicPath(theme.logoUrl)}
        alt={props.alt ?? 'Logo'}
        aria-label={props.ariaLabel ?? 'Logo'}
      />
    </LogoWrapper>
  );
}
