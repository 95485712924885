import * as React from 'react';
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ProcedureTableTag } from 'client/ui/tag/ProcedureTableTag';
import { TableCellEllipsis } from 'client/ui/table/TableCellEllipsis';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { Procedimento } from 'client/api/backend/schemas';
import moment from 'moment';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { IconView } from 'client/ui/icon/ApplicationIcon';
import { TaskName } from 'client/ui/tag/TaskName';
import { DatiSessioneUtente } from 'client/api/auth/schemas';
import { UserLogic } from 'common/schema/user/UserLogic';
import { UserFeatureFlags } from 'common/schema/user/UserFeatureFlags';

/**
 * Colonne della tabella di ricerca delle procedure.
 * @description Se l'utente è abilitato mostra anche le colonne con le informazioni di registrazione
 */
export function getColumnsProcedureList(
  user: DatiSessioneUtente | null
): ColumnsType<Procedimento> {
  /**
   * Funzione che controlla se abbiamo i permessi per visualizzare le colonne aggiuntive
   */
  const withFeatureFlag = (
    flag: UserFeatureFlags,
    columns: ColumnsType<Procedimento>
  ) => (UserLogic.hasPermission(user, flag) ? columns : []);

  // Colonne con l'informazione di registrazione
  const columnsRegistrationInfo: ColumnsType<Procedimento> = [
    {
      title: 'Numero Registrazione',
      dataIndex: 'registrazioneNumero',
      key: 'procedimento.registrazioneNumero',
      filterSearch: true,
      render: (_, record) => record.registrazioneNumero
    },
    {
      title: 'Data Registrazione',
      key: 'registrazioneData',
      dataIndex: 'registrazioneData',
      render: (_, record) => {
        if (!record.registrazioneData) return null;

        const date = moment(record.registrazioneData, 'DD/MM/YYYY HH:mm:ss');
        return (
          <Space direction="vertical" size={0}>
            <div>{date.format('DD/MM/YYYY')}</div>
          </Space>
        );
      }
    }
  ];

  // Colonne della tabella di ricerca delle procedure
  const columnsProcedureList: ColumnsType<Procedimento> = [
    {
      title: 'Procedimento',
      dataIndex: 'procedimento.tipologiaProcedimento',
      key: 'procedimento.tipologiaProcedimento',
      render: (_, record) => (
        <ProcedureTableTag
          name={record.tipologiaProcedimento?.nomeTipo}
          color={record.tipologiaProcedimento?.coloreRendering}
          icon={record.tipologiaProcedimento?.iconaRendering}
        />
      )
    },
    // {
    //   title: 'Stato',
    //   dataIndex: 'stato',
    //   key: 'stato',
    //   sorter: (a, b) => a.name.length - b.name.length,
    //   render: (_, record) => {
    //     // TODO Componente per Tag di stato Attività
    //     const color =
    //       record.stato == AttivitaDTOStato.IN_CORSO ? 'geekblue' : 'green';
    //     const text =
    //       record.stato == AttivitaDTOStato.IN_CORSO ? 'In corso' : 'Pendente';
    //     return <Tag color={color}>{text}</Tag>;
    //   }
    // },
    {
      title: 'Numero',
      dataIndex: 'NUMERO',
      key: 'procedimento.numero',
      filterSearch: true,
      sorter: true,
      render: (_, record) => record.numero
    },
    {
      title: 'Oggetto',
      dataIndex: 'OGGETTO',
      key: 'procedimento.oggetto',
      width: '40%',
      sorter: true,
      render: (_, record) => <TableCellEllipsis text={record.oggetto} />
    },

    {
      title: 'Fase',
      dataIndex: 'name',
      key: 'name',
      filterSearch: true,
      render: (_, record) => <TaskName>{record.nomeFaseCorrente}</TaskName>
    },
    {
      title: 'Ufficio di Avvio',
      key: 'nomeUfficioAvvio',
      dataIndex: 'nomeUfficioAvvio',
      width: '190px',
      render: (_, record) => record.nomeUfficioAvvio
    },
    {
      title: 'Data di Avvio',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, record) => {
        const date = moment(record.dataAvvio, 'DD/MM/YYYY HH:mm:ss');
        return (
          <Space direction="vertical" size={0}>
            <div>{date.format('DD/MM/YYYY')}</div>
            <div>{date.format('HH:mm')}</div>
          </Space>
        );
      }
    },
    // Se l'utente è abilitato mostra anche le colonne con le informazioni di registrazione
    ...withFeatureFlag(
      UserFeatureFlags.ShowProtocolData,
      columnsRegistrationInfo
    ),
    {
      key: 'action',
      align: 'right',
      render: (_, record) => (
        <Space size={0}>
          <ButtonIcon
            icon={<IconWrapper icon={<IconView />} />}
            to={`/procedure/list/${record.id}`}
            tooltip="Dettaglio"
            tooltipProps={{ placement: 'bottomRight' }}
          />
        </Space>
      )
    }
  ];

  return columnsProcedureList;
}
