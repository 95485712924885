import * as React from 'react';
import { Collapse, CollapsePanelProps } from 'antd';
const { Panel } = Collapse;
import { HistoryItemsContainer } from 'client/components/schema/history/detail/HistoryItemsContainer';
import { PhaseHeaderTitle } from './detail/PhaseHeaderTitle';
import { IHistoryPhase } from '../task/hooks/useTaskHistory';

export interface PhasePanelProps
  extends Omit<CollapsePanelProps, 'children' | 'header'> {
  children?: React.ReactNode;
  phase: IHistoryPhase;
}

/**
 * Mostra una fase della cronologia
 */
export function PhasePanel(props: PhasePanelProps) {
  const { children, phase, ...rest } = props;
  return (
    <Panel
      {...rest}
      header={
        <PhaseHeaderTitle title={phase.name} from={phase.from} to={phase.to} />
      }
    >
      <HistoryItemsContainer>{props.children}</HistoryItemsContainer>
    </Panel>
  );
}
