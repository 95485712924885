/**
 * File generato dinamicamente
 *
 * Contiene le Variabili dei componenti Ant
 * convertite dal thema base
 */
export const lessThemeConverted = {
  primaryColor: '#0066cc',
  successColor: '#00cf86',
  infoColor: '#0b00d9',
  warningColor: '#ff9700',
  errorColor: '#f83e5a',
  bodyBackground: '#f2f6fa',
  componentBackground: '#ffffff',

  borderColorBase: '#c2c7cc',
  borderColorSplit: '#e6e9f2',
  layoutBodyBackground: '#f2f6fa',
  layoutHeaderBackground: '#ffffff',
  layoutTriggerBackground: '#ffffff',

  // Text
  textColor: '#17324d',
  textColorSecondary: '#455b71',
  fontSizeBase: '14px',
  fontSizeSm: '12px',

  borderRadiusBase: '4px',

  // Header
  layoutHeaderPadding: '0 12px',
  layoutHeaderHeight: '64px',

  // Table
  tableHeaderIconColor: '#e0eefc',
  tableHeaderIconColorHover: '#ffffff',
  tablePaddingVertical: '12px',
  tablePaddingHorizontal: '12px'
};
