import { Col, message, Row, Space, Typography } from 'antd';
import { useDeleteAllegato } from 'client/api/backend/attivita/attivita';
import { AzioneDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { logger } from 'client/core/logger/logger';
import { getResponseErrorMessage } from 'client/core/network/errors/getResponseErrorMessage';
import { AttachmentActionLogic } from 'client/components/schema/declaration/actions/AttachmentActionLogic';
import { IDocumentFile } from 'client/ui/form/input/UploadInput';
const { Text } = Typography;
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import React, { useCallback, useState } from 'react';
import { ImAttachment } from 'react-icons/im';
import styled from 'styled-components';
import { IntegralPartIcon } from './components/IntegralPartIcon';
import { DeleteFileIconButton } from './DeleteFileIconButton';
import { DownloadFileIconButton } from './DownloadFileIconButton';
import { OmittedInPublishIcon } from './components/OmittedInPublishIcon';
import { EditFileButton } from './EditFileIconButton';
import { AttachmentsAction } from 'client/components/schema/declaration/actions/DeclarationActions';

export const WrapperRow = styled(Row)`
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
`;
export const ActionsCol = styled(Col)`
  flex: 0 0 120px;
  min-width: 120px;
  display: flex;
  justify-content: flex-end;
`;
export const SwitchCol = styled(Col)`
  flex: 0 0 30px;
  min-width: 30px;
  display: flex;
  justify-content: flex-end;
  color: ${props => props.theme.textColorSecondary};
`;

export const Icon24px = styled(IconWrapper)`
  font-size: 24px;
  vertical-align: -6px;
`;
export const FileNameText = styled(Text)`
  color: ${props => props.theme.textColorGray};
`;

export interface IFileRowProps {
  item: IDocumentFile;
  taskId: string;
  onRemove: () => void;
  actions: AzioneDTO[];
}

export function FileRow(props: IFileRowProps) {
  const { item, onRemove, taskId, actions } = props;
  const { file, description, integralPart, omittedInPublish } = item;
  const [loading, setLoading] = useState(false);
  const currentUser = useCurrentUser();

  const { canDelete, canEdit } = AttachmentActionLogic.canHandleFile(
    actions,
    currentUser,
    file
  );

  const deleteFile = useDeleteAllegato();

  const handleDelete = useCallback(async () => {
    if (!canDelete) return;
    if (!file?.id) {
      onRemove?.();
      return;
    }
    setLoading(true);
    try {
      await deleteFile.mutateAsync({
        // NB si chiama idAllegato ma ci va l'id del file
        idAllegato: file.id,
        idTaskInstance: taskId
      });
      onRemove?.();
      message.success(`File eliminato correttamente`);
    } catch (e: any) {
      logger.error(`[UploadInput] Errore durante la cancellazione del file`, {error: e}); // prettier-ignore
      message.error(getResponseErrorMessage(e, 'Errore durante il caricamento del file')); // prettier-ignore
    } finally {
      setLoading(false);
    }
  }, [taskId, file, onRemove]);

  return (
    <WrapperRow>
      <Col flex="1 1 auto">
        <Text>
          <FileNameText>
            <IconWrapper icon={<ImAttachment />} /> {file?.name}
          </FileNameText>
          {description && <span> | {description}</span>}
        </Text>
      </Col>
      {/* Mostriamo l'icona se il file risulta parte integrante e omesso alla pubblicazione */}
      {integralPart && omittedInPublish && (
        <SwitchCol>
          <OmittedInPublishIcon />
        </SwitchCol>
      )}
      {integralPart && (
        <SwitchCol>
          <IntegralPartIcon />
        </SwitchCol>
      )}
      <ActionsCol>
        <Space size={16}>
          <EditFileButton canEdit={canEdit} taskId={taskId} item={item} />
          <DownloadFileIconButton
            fileId={file?.id!}
            filename={file?.name!}
            taskId={taskId}
          />
          <DeleteFileIconButton
            canDelete={canDelete}
            loading={loading}
            handleDelete={handleDelete}
          />
        </Space>
      </ActionsCol>
    </WrapperRow>
  );
}
