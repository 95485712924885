import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import styled from 'styled-components';

export const MessageParagraph = styled(Paragraph)`
  &&& {
    margin-bottom: 0;
    margin-top: 3px;
  }
`;

export interface IAttachmentRequiredLabelProps {
  required: boolean;
  plural: boolean;
  visible: boolean;
}

export function AttachmentRequiredLabel(props: IAttachmentRequiredLabelProps) {
  const { required, plural, visible } = props;
  if (!required) return null;
  if (!visible) return null;

  const message = plural
    ? `È richiesto l'inserimento di almeno un allegato a questa sezione.`
    : `È richiesto l'inserimento di un allegato a questa sezione.`;

  return (
    <MessageParagraph strong type="danger">
      {message}
    </MessageParagraph>
  );
}
