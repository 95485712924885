import { useGetInfoGenerali } from 'client/api/backend/procedimenti/procedimenti';
import { ProcedimentoConTask } from 'client/api/backend/schemas';
import moment from 'moment';

export interface ITaskNote {
  outcome: string | null | undefined;
  outcomeDate: Date | null | undefined;
  note: string | null | undefined;
  taskName: string | null | undefined;
  userName: string | null | undefined;
  userFiscalNumber: string | null | undefined;
}

function mapNotes(info: ProcedimentoConTask | undefined): ITaskNote[] | null {
  if (!info?.task) return null;
  const result = info.task
    .map(task =>
      task
        .infoLavorazioneTask!.filter(data => !!data.noteCompletamento)
        .map(data => ({
          outcome: data.labelEsitoCompletamento,
          outcomeDate: moment(
            data.dataCompletamento,
            'DD/MM/YYYY HH:mm:ss'
          ).toDate(),
          note: data.noteCompletamento,
          taskName: task.name,
          userName: data.nomeUtenteLavorazione,
          userFiscalNumber: data.cfUtenteLavorazione
        }))
    )
    .flat();

  return result;
}

/**
 * Ritorna le note di completamento di una procedure per tutte le sue attività
 */
export function useProcedureNotes(procedureId: string | undefined) {
  if (!procedureId) return {};

  const result = useGetInfoGenerali(procedureId);
  const notes = mapNotes(result.data?.data);

  return {
    result,
    notes
  };
}
