/**
 * Generato automaticamente.
 * Procedimenti
 */

export type GetProcedimentiSortBy =
  typeof GetProcedimentiSortBy[keyof typeof GetProcedimentiSortBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProcedimentiSortBy = {
  NUMERO: 'NUMERO',
  OGGETTO: 'OGGETTO',
  DATA_ULTIMA_MODIFICA: 'DATA_ULTIMA_MODIFICA'
} as const;
