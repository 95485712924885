import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { HistoryType } from '../../task/hooks/useTaskHistory';

const StyledActionTag = styled(Tag)`
  /* margin-left: 8px; */
  line-height: 12px;
  padding: 2px 7px;
  /* margin-left: 0; */
`;

export interface IActionTagProps {
  type: HistoryType;
}

export function ActionTag(props: IActionTagProps) {
  const { type } = props;
  switch (type) {
    case HistoryType.Start:
      return <StyledActionTag color="blue">Avvio</StyledActionTag>;
    case HistoryType.Release:
      return <StyledActionTag color="purple">Rilascio</StyledActionTag>;
    case HistoryType.Complete:
      return <StyledActionTag color="green">Completata</StyledActionTag>;
  }
}
