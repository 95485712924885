import * as React from 'react';
import clx from 'classnames';
import { Col, Row, RowProps, Typography } from 'antd';
import styled from 'styled-components';
const { Title, Text, Paragraph } = Typography;

const ParagraphStyled = styled(Paragraph)`
  margin-bottom: 0.5em;
`;
const ColStyled = styled(Col)`
  &.top-padding {
    padding-top: 14px;
  }

  // Gestiamo il caso di molteplici elementi nascosti
  &.ant-col {
    .declaration-row & {
      min-height: 0px; // before 1px
    }
  }
`;

export interface FormFieldsContainerProps extends Omit<RowProps, 'children'> {
  /**
   * Array di elementi da posizionare nelle colonne
   * */
  children: React.ReactNode;
  /**
   * Numero di colonne
   * */
  columns?: 1 | 2 | 3 | 4 | 6 | 8 | 12;

  /**
   * Permette l'inserimento di un titolo
   * */
  title?: string;
  /**
   * Sopra un titolo viene inserito uno spazio bianco di default
   * removeTopTitleSpacing permette di eliminarlo
   * */
  removeTopTitleSpacing?: boolean;
}
/**
 * Elememento contenitore di Riga per FormFieldLabel
 * */
export function FormFieldsContainer(props: FormFieldsContainerProps) {
  const { children, columns, title, removeTopTitleSpacing, ...rowsProps } =
    props;
  const span = 24 / (columns ?? 1);

  return (
    <Row gutter={24} {...rowsProps}>
      {props.title && (
        <ColStyled
          className={clx({ 'top-padding': !removeTopTitleSpacing })}
          span={24}
        >
          <ParagraphStyled type="secondary" children={props.title} />
        </ColStyled>
      )}
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) return null;
        return (
          <ColStyled key={index} span={span * (child.props.colSpan ?? 1)}>
            {child}
          </ColStyled>
        );
      })}
    </Row>
  );
}
