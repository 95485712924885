import { useGetAzioniDichiarazioni } from 'client/api/backend/attivita/attivita';
import { AzioniDichiarazione, Dichiarazione } from 'client/api/backend/schemas';
import { useMemo } from 'react';
import { normalizeDeclaration } from './normalize/normalizeDeclaration';

export function useDeclarations(taskId: string) {
  const result = useGetAzioniDichiarazioni(taskId);

  // Ordino le dichiarazioni
  const declarations = useMemo(
    () =>
      result.data?.data
        ?.sort(declarationWithActionSort)
        .map(noralizeDeclarationWithAction),
    [result.data?.data]
  );

  return {
    result,
    declarations
  };
}

export type IDeclarationsResult = ReturnType<typeof useDeclarations>;

export function declarationWithActionSort(
  a: AzioniDichiarazione,
  b: AzioniDichiarazione
) {
  return declarationSort(a.dichiarazione!, b.dichiarazione!);
}

export function noralizeDeclarationWithAction(
  data: AzioniDichiarazione
): AzioniDichiarazione {
  return {
    azioni: data.azioni,
    dichiarazione: normalizeDeclaration(data.dichiarazione)
  };
}

export function declarationSort(a: Dichiarazione, b: Dichiarazione) {
  return a!.ordinamento! - b!.ordinamento!;
}
