import {
  AzioniDichiarazione,
  AzioniDocumento,
  Dichiarazione,
  Documento
} from 'client/api/backend/schemas';
import { DeclarationConvert } from 'client/components/schema/task/declaration/converter/DeclarationConverter';
import { getFieldPrefix } from 'client/components/schema/task/declaration/validation/createDeclarationValidator';

/**
 * Restituisce i valori iniziali per i campi di una dichiarazione a partire
 * dai dati restituiti dall'API.
 */
export function getDeclarationsInitialValues(
  declarations: Dichiarazione[] | undefined
) {
  if (!declarations) return {};

  const allDeclarations = [...(declarations ?? [])];

  return Object.fromEntries(
    allDeclarations.map((declaration, index) => [
      getFieldPrefix(declaration),
      DeclarationConvert.from(declaration!, declaration!.datiJson as any)
    ])
  );
}
