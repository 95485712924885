import React from 'react';
import styled from 'styled-components';
import Text, { TextProps } from 'antd/lib/typography/Text';

const TitleWrapper = styled.div`
  color: ${props => props.theme.componentBackground};
  background-color: ${props => props.theme.primaryColor};
  border-radius: ${props => props.theme.borderRadiusBase}
    ${props => props.theme.borderRadiusBase} 0 0;
  padding: 6px 12px;
  .ant-typography {
    color: inherit;
    font-size: 18px;
  }
`;

const IconWrapper = styled.span`
  font-size: 32px;
  vertical-align: middle;
  line-height: 1em;
  margin-right: 6px;
`;

export interface CardCoverDocumentProps extends TextProps {
  icon: React.ReactNode;
  text: React.ReactNode;
}

/**
 * TODO
 */
export function CardCoverDocument(props: CardCoverDocumentProps) {
  const { icon, text, ...otherProps } = props;

  return (
    <TitleWrapper>
      <Text strong ellipsis {...otherProps}>
        <IconWrapper>{icon}</IconWrapper>
        {text}
      </Text>
    </TitleWrapper>
  );
}
