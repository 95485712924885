import { Menu } from 'antd';
import {
  Documento,
  RecuperaDocumentoPerFirmaParams
} from 'client/api/backend/schemas';
import { DocumentLogic } from 'client/components/schema/task/document/DocumentLogic';
import React from 'react';
import { DownloadDocumentSignedLink as DownloadSignedDocumentLink } from './DownloadSignedDocumentLink';

export interface IDownloadSignDocumentActionProps {
  taskId: string;
  documentId: string;
  params: RecuperaDocumentoPerFirmaParams;
  document: Documento;
}

export function DownloadSignDocumentAction(
  props: IDownloadSignDocumentActionProps
) {
  const { taskId, documentId, params, document, ...otherProps } = props;
  if (!DocumentLogic.isSigned(document, params)) return null;

  return (
    <Menu.Item {...otherProps}>
      <DownloadSignedDocumentLink
        taskId={taskId}
        documentId={documentId}
        params={params}
      />
    </Menu.Item>
  );
}
