import { useFormikAugmentedContext } from 'client/ui/form/FormikAugmentedContext';
import { useFormikFieldValidation } from 'client/ui/form/hooks/validation/useFormikFieldValidation';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import * as React from 'react';
import styled from 'styled-components';
import { IDeclarationFieldOptions } from '../DeclarationFieldSwitch';

const TagTextStyle = styled.div`
  font-size: 12px;
  color: ${props => props.theme.textColorSecondary};

  margin-bottom: 0.5em;
  h3 {
    color: ${props => props.theme.primaryColor};
    font-size: 16px;
    margin-bottom: 0;
  }
  h4 {
    color: ${props => props.theme.textColorSecondary};
    font-size: 15px;
    margin-bottom: 0;
    &.titolo-nero {
      color: ${props => props.theme.textColor};
    }
  }
  b {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
`;

export interface IDeclarationFieldTextProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

export function DeclarationFieldText(props: IDeclarationFieldTextProps) {
  const { field, options } = props;

  const prefix = options.namePrefix;

  const fieldName = prefix ? `${prefix}.${field.nome}` : field.nome;
  const { stripped } = useFormikFieldValidation(fieldName);

  if (stripped) return null;

  // console.log('campo singolo TESTO: ', props.field);
  // console.log('HTML: ', props.field.des_campo);

  return (
    <TagTextStyle
      dangerouslySetInnerHTML={{ __html: props.field.des_campo ?? '' }}
    />
  );
}
