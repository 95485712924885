import React from 'react';
import { Card, Typography } from 'antd';
import styled from 'styled-components';
const { Title, Text } = Typography;
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';

import { createGlobalStyle } from 'styled-components';
export const SelectOptionGlobalStyle = createGlobalStyle`

  body {

  // Fix Select della pagina di login
  // Testi opzione su più righe

  /* La classe 'full-text-options' deve essere assegnata alle option della select
  Esempio: 
    options={options.map(option => ({
      className: 'full-text-options',
      label: ...,
      value: ...
    }))} 
  */

  .full-text-options {
    .ant-select-item-option-content {
        flex: auto;
        overflow: unset;
        white-space: normal;
        text-overflow: unset;
      }
    }
  }
`;

// const defaultBreakpoints = {
//   xs: '0px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1400px',
// };

const StyledLoginCard = styled(Card)`
  min-width: 550px;
  ${down('md')} {
    min-width: 450px;
  }
  ${down('sm')} {
    min-width: unset;
    width: calc(100vw - 40px);
    > .ant-card-body {
      padding: 24px 12px;
    }
  }
`;

const CardTitle = styled.div`
  text-align: center;
  margin-bottom: 3em;
  margin-top: 1em;
`;

interface LoginCardProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Card stilizzata per le fasi di login ()
 */
export function LoginCard(props: LoginCardProps) {
  const { title, subTitle, children } = props;

  return (
    <StyledLoginCard bordered={false}>
      <CardTitle>
        <Title level={3}>{title}</Title>
        <Text type="secondary">{subTitle}</Text>
      </CardTitle>
      {children}
    </StyledLoginCard>
  );
}
