import * as React from 'react';
import { TaskCardMode } from 'client/ui/card/TaskCard';
import { IDocumentsResult } from 'client/components/schema/document/useDocuments';
import { LoadingCard } from '../cards/LoadingCard';
import { ErrorCard } from '../cards/ErrorCard';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { DocumentSection } from './DocumentSection';
import { AttivitaDTO, AzioniDocumento } from 'client/api/backend/schemas';
import styled from 'styled-components';

const DocumentSectionWrapper = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export interface TaskDocumentsProps {
  isTaskAssigned: boolean;
  result: IDocumentsResult;
  task: AttivitaDTO | null;
}

export function TaskDocuments(props: TaskDocumentsProps) {
  const { documents, result } = props.result;

  if (result.isError) {
    return (
      <ErrorCard
        message="Si è verificato un errore durante il caricamento dei documenti"
        description={getNetworkErrorMessage(result.error.message)}
      />
    );
  }

  if (result.isLoading) {
    return <LoadingCard mode={TaskCardMode.Document} />;
  }

  if (!documents) return null;

  return (
    <>
      {documents.map((document, index) => (
        <DocumentSectionWrapper key={index}>
          <DocumentSection
            task={props.task}
            document={document}
            key={index}
            isTaskAssigned={props.isTaskAssigned}
          />
        </DocumentSectionWrapper>
      ))}
    </>
  );
}
