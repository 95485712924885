import * as React from 'react';
import { DeclarationTitle } from 'client/ui/declaration/DeclarationTitle';
import { TaskCard, TaskCardMode } from 'client/ui/card/TaskCard';
import { useOutcomes } from 'client/components/schema/outcome/useOutcomes';
import { logger } from 'client/core/logger/logger';
import { TaskOutcomeButton } from './TaskOutcomeButton';
import { LoadingCard } from '../cards/LoadingCard';
import { ErrorCard } from '../cards/ErrorCard';
import { Space } from 'antd';
import styled from 'styled-components';
import { getResponseErrorMessage } from 'client/core/network/errors/getResponseErrorMessage';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { FormikForm } from 'client/ui/form/FormikForm';
import { EsitoDTO } from 'client/api/backend/schemas';
import { ErrorType } from 'client/core/network/mutators/apiClient';

const ButtonContainer = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: flex-end;
`;

export interface TaskResultActionsProps {
  taskId: string | undefined;
  outcomes: EsitoDTO[] | undefined;
  isError: boolean | undefined;
  error: ErrorType<unknown> | null | undefined;
  isLoading: boolean | undefined;
}

export function TaskResultActions(props: TaskResultActionsProps) {
  const { taskId, isLoading, outcomes, isError, error } = props;

  if (isLoading) {
    return <LoadingCard mode={TaskCardMode.Action} />;
  }

  if (isError && error) {
    logger.error(`[TaskResultActions] Errore durante il recupero degli esiti: `, { error: error }); // prettier-ignore
    // Se non sono stati trovati risultati non mostro la card
    if (error.response?.status === 404) {
      return null;
    }
    return (
      <ErrorCard
        mode={TaskCardMode.Action}
        message="Errore recupero degli esiti"
        description={
          getResponseErrorMessage(
            (error as any) ??
              'Si è verificato un errore durante il recupero degli esiti. Riprovare.'
          )!
        }
      />
    );
  }

  if (!taskId || !outcomes) {
    return <TaskCard mode={TaskCardMode.Action}></TaskCard>;
  }

  return (
    <TaskCard mode={TaskCardMode.Action}>
      <DeclarationTitle title="Esiti" />
      <FormikForm nested editable={true}>
        <FormFieldsContainer columns={1}>
          <FormFieldItem
            component={TextAreaInput}
            name="outcome.finalNotes"
            label="Nota di completamento"
            placeholder="Nota..."
            rows={3}
          />
        </FormFieldsContainer>
        <ButtonContainer>
          <Space>
            {outcomes.map((outcome, index) => (
              <TaskOutcomeButton
                outcome={outcome}
                taskId={taskId}
                key={index}
              />
            ))}
          </Space>
        </ButtonContainer>
      </FormikForm>
    </TaskCard>
  );
}
