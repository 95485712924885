import { UfficioType } from 'client/api/backend/schemas';
import { useRicercaUffici } from 'client/api/backend/uffici/uffici';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import React from 'react';

export interface IOfficeSelectInputProps {
  name: string;
  label: string;
  placeholder: string;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  colSpan?: number;
  /** Indica quale campo della response si vuole ottenere (e.g. descrizione, id, codice) */
  responseField: (input: UfficioType) => string | number;
}

export function OfficeSelectInput(props: IOfficeSelectInputProps) {
  const { name, label, placeholder, responseField } = props;

  const office = useRicercaUffici();

  return (
    <FormFieldItem
      size="middle"
      colSpan={props.colSpan}
      component={SelectInput}
      dropdownMatchSelectWidth={false}
      placement={props.placement}
      loading={office.isLoading}
      name={name}
      label={label}
      placeholder={placeholder}
      dropdownStyle={{ maxWidth: '80%' }}
      allowClear
      options={office.data?.data?.map(office => ({
        label: `${office.codice ?? ''} ${office.descrizione}`,
        value: responseField(office)
      }))}
    />
  );
}
