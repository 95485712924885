import { ProcedureApi } from 'client/components/schema/procedures/ProcedureApi';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import React from 'react';
import { useQuery } from 'react-query';

export interface IPhaseProcedureSelectInputProps {
  name: string;
}

/**
 * Input per la selezione dello fase di una procedura da ricercare.
 */
export function PhaseProcedureSelectInput(
  props: IPhaseProcedureSelectInputProps
) {
  const { name } = props;

  const { data: phases } = useQuery('procedure-phase-options', () =>
    ProcedureApi.listPhases()
  );

  return (
    <FormFieldItem
      size="middle"
      dropdownMatchSelectWidth={false}
      component={SelectInput}
      name={name}
      label="Fase"
      placeholder="Fase..."
      allowClear
      options={phases?.data.map(phase => ({
        // Trasformiamo in upper case solo per coerenza grafica
        // Questa API rispetto alle altre torna in Pascal Case
        label: phase.value.toUpperCase(),
        value: phase.key
      }))}
    />
  );
}
