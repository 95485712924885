import { logger } from 'client/core/logger/logger';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import { get } from 'lodash';
import mathExpressionEvaluator from 'math-expression-evaluator';

export class ComputedFieldLogic {
  static selectorRegex = /\$(.*?)\$/g;

  /**
   * Ritorna il nome del campo "risultato" della formula
   */
  static getComputedField(formula: string) {
    // Divido in due parti la formula
    const parsed = formula.split('=');
    // Recupero il campo del risultato (sx)
    const resultField = parsed[0].match(this.selectorRegex);
    // this.selectorRegex.exec(parsed[0]);
    if (!resultField) return null;
    return resultField[0].trim().replace(/\$/g, '');
  }

  /**
   * Converte la formula per poterla inserire sul campo calcolato risultato
   */
  static getComputedFieldFormula(formula: string) {
    const parsed = formula.split('=');
    return parsed[1].trim();
  }

  /**
   * Ritorna il risultato della formula in base alle scope fornito
   */
  static parseFormula(
    formula: string | undefined,
    dependency: string[] | undefined,
    prefix: string,
    scope: any
  ) {
    if (!formula) return null;
    if (!dependency) return null;

    let resultEquation = formula;

    // Genero la formula finale andando a sostituire i campi con il loro attuale valore
    let complete = true;
    for (const field of dependency) {
      const value = parseInt(
        get(scope, prefix ? `${prefix}.${field}` : field),
        10
      );
      if (value == null || isNaN(value)) {
        complete = false;
        break;
      }
      // Regex globale (/$field$/g) per sostituire tutti i campi con il loro valore
      const fieldSelector = new RegExp(`\\\$${field}\\\$`, 'g');
      resultEquation = resultEquation.replace(fieldSelector, value.toString());
    }

    // Se non tutti i campi sono stati valorizzati, ritorno null
    if (!complete) return null;

    // Faccio il parsing della formula e ritorno il risultato insieme al campo da impostare
    const result = mathExpressionEvaluator.eval(resultEquation);
    return result;
  }

  /**
   * Normalizza i campi calcolati per poterli parsare più agevolmente.
   * Prende i campi CC, ne estrae la formula (solo la parte del calcolo)
   * e la assegna al campo risultato (in key create lato FE)
   */
  static normalizeComputedFields(fields: DeclarationFieldDto[]) {
    fields.forEach(field => {
      // Se è un campo calcolato
      if (field.tp_controllo === 'CC') {
        // Recupero la formula e il campo del risultato
        const formula = field.valore;
        const resultFieldName = this.getComputedField(formula);
        const resultField = fields.find(f => f.nome === resultFieldName);

        // Se esiste, imposto le key per la gestione dei CC
        if (resultField) {
          // Se non è già computed, allora va aggiornato
          if (resultField.subtype !== 'computed') {
            resultField.formula = this.getComputedFieldFormula(formula);
            resultField.subtype = 'computed';
          }
          // Aggiorno le dipendenze
          resultField.computationDependency = resultField.computationDependency
            ? [...resultField.computationDependency, field.nome]
            : [field.nome];
        } else {
          logger.warn(`[ComputedFieldLogic] Il campo ${resultFieldName} non è stato trovato. Impossibile impostare formula CC.`); // prettier-ignore
        }
      }
    });
  }
}
