import { AzioniDichiarazione } from 'client/api/backend/schemas';
import { DeclarationActionLogic } from 'client/components/schema/declaration/actions/DeclarationActionLogic';
import { getFields } from 'client/components/schema/declaration/getFields';
import { DeclarationForm } from 'client/components/schema/task/declaration/DeclarationForm';
import { getFieldPrefix } from 'client/components/schema/task/declaration/validation/createDeclarationValidator';
import { DeclarationTitle } from 'client/ui/declaration/DeclarationTitle';
import { FormikForm } from 'client/ui/form/FormikForm';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import React, { useMemo } from 'react';

/**
 * Rimuovi una dichiarazione se tutti i suoi campi sono non visibili
 */
export function isDeclarationVisible(declaration: AzioniDichiarazione) {
  if (!declaration.dichiarazione?.jsonCampi) return false;
  // Se almeno 1 campo è visibile, allora la dichiarazione è visibile
  return Object.values(declaration.dichiarazione?.jsonCampi).some(
    (field: DeclarationFieldDto) => {
      return field.visibile !== false;
    }
  );
}

export interface IDeclarationSectionProps {
  declaration: AzioniDichiarazione;
  isTaskAssigned: boolean;
}

export function DeclarationSection(props: IDeclarationSectionProps) {
  const { declaration } = props;

  const editable =
    DeclarationActionLogic.isDeclarationEditable(declaration.azioni) &&
    props.isTaskAssigned;

  const isVisible = useMemo(
    () => isDeclarationVisible(declaration),
    [declaration]
  );

  if (!isVisible) return null;
  return (
    <>
      <DeclarationTitle
        declaration={declaration.dichiarazione!}
        title={
          declaration.dichiarazione?.titoloDichiarazione ?? 'Dichiarazione'
        }
      />
      <FormikForm nested disableEnterSubmit editable={editable}>
        <DeclarationForm
          fields={getFields(declaration.dichiarazione)}
          prefix={getFieldPrefix(declaration.dichiarazione)}
          editable={editable}
        />
      </FormikForm>
    </>
  );
}
