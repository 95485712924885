import React from 'react';
import styled from 'styled-components';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { down } from 'styled-breakpoints';

export const MenuButtonIcon = styled(ButtonIcon)`
  display: flex;
  flex: 0 0 auto;
  min-width: 0;
  justify-content: center;
  margin-right: 10px;
  color: ${props => props.theme.textColorSecondary};
  &:active,
  &:focus {
    color: ${props => props.theme.textColorSecondary};
  }
`;
