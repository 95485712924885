import React from 'react';
import styled from 'styled-components';
import { Badge, Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import clx from 'classnames';
import { LinkButton } from 'client/ui/link/LinkButton';

const StyledMenuButton = styled(LinkButton)`
  font-size: 14px;
  &.ant-btn-link {
    transition: all 0.3s;
    color: ${props => props.theme.textColorSecondary};
    &:hover {
      color: ${props => props.theme.primaryColor};
    }
  }
  &.ant-btn {
    &.selected {
      transition: all 0.3s;
      &:active,
      &:hover,
      &:focus {
        transition: all 0.3s;
        background-color: ${props => props.theme.primaryColor};
      }
    }
  }
`;

export interface MenuButtonProps extends Omit<ButtonProps, 'type'> {
  to: string;
  menuKey: string;
  currentKey: string;
}

export function MenuButton(props: MenuButtonProps) {
  // console.log('MenuButton: ', props.menuKey, props.currentKey);
  const selected = props.menuKey == props.currentKey;
  return (
    <StyledMenuButton
      to={props.to}
      size="large"
      className={clx({
        selected: selected
      })}
      type={selected ? 'primary' : 'link'}
    >
      {props.children}
    </StyledMenuButton>
  );
}
