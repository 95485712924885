import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import React, { useMemo } from 'react';
import { HiTrash } from 'react-icons/hi';
import {
  DeclarationFieldSwitch,
  IDeclarationFieldOptions
} from './DeclarationFieldSwitch';
import { FieldTableTitle, FieldTable } from './type/DeclarationFieldTable';
import clx from 'classnames';

export interface IDeclarationFieldTabularLayoutProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

/**
 * Utilizzato nelle "tabelle duplicabili" in realtà è paragonabile solo
 * a un layout "tabellare" di un insieme di elementi (subfields).
 */
export function DeclarationFieldTabularLayout(
  props: IDeclarationFieldTabularLayoutProps
) {
  const { field, options } = props;

  const fieldName = options.namePrefix;
  const formContext = useFormikFormContext();

  const columns: ColumnType<any>[] = useMemo(
    () =>
      field.headers!.flatMap((header, headerIndex) => {
        return {
          title: header.des_campo,
          dataIndex: field.subfields![headerIndex].nome,
          render: (_: any, item: any, j: number) => {
            // Leggo la sequenza di campi come una "matrice" di header.length x field.defaultRows
            const subfieldIndex =
              headerIndex + j * ((field.defaultRowsCount ?? 1) + 1);

            const subfield = field.subfields![subfieldIndex]; // Possiamo usare l'indice perché i campi sono già ordinati per ("rigaTabella"|"colonnaTabella") in normalizeDeclarationField
            return (
              <DeclarationFieldSwitch
                field={subfield}
                options={{ namePrefix: `${fieldName}` }}
              />
            );
          }
        } as ColumnType<any>;
      }),
    [(field.headers, field.subfields, fieldName)]
  );

  return (
    <FormikForm
      nested
      helpInTooltips
      editable={formContext.editable}
      disabled={formContext.disabled}
    >
      <FieldTableTitle>{field.des_campo}</FieldTableTitle>
      <FieldTable
        className={clx({ 'not-editable': !formContext.editable })}
        rowKey={(_, i) => i!}
        tableLayout="fixed"
        columns={columns}
        dataSource={new Array(field.defaultRowsCount).fill({})}
        pagination={false}
      />
    </FormikForm>
  );
}
