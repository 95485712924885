import { DatiSessioneUtente } from 'client/api/auth/schemas';
import { UserDto } from 'common/dto/UserDto';

export class UserLogic {
  static canLogout(user: UserDto | null) {
    return true;
  }

  static hasPermission(
    user: DatiSessioneUtente | undefined | null,
    permission?: string
  ) {
    if (!user) return false;
    if (!permission) return false;
    return user?.utenteLoggato?.utenteUfficis?.some(office =>
      office.ruolos?.some(role =>
        role.idAbilitazioniAssociates?.some(p => p === permission)
      )
    );
  }
}
