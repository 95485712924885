import { List } from 'antd';
import React from 'react';
import { NotificationListItem } from './detail/NotificationListItem';
import { NotificaResponseDTO } from 'client/api/backend/schemas';

interface NotificationListProps {
  list: NotificaResponseDTO[] | undefined;
  loading: boolean;
}

/**
 * Elenco delle notifiche
 */
export function NotificationList(props: NotificationListProps) {
  const { list, loading } = props;
  if (!list) return null;

  return (
    <List
      loading={loading}
      size="small"
      itemLayout="horizontal"
      dataSource={props.list}
      renderItem={item => {
        return <NotificationListItem notification={item} />;
      }}
    />
  );
}
