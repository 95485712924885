import { Button, Modal, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { AzioniDichiarazione, EsitoDTO } from 'client/api/backend/schemas';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

export function mapStyleToButtonProps(type: string | undefined): ButtonProps {
  switch (type) {
    case 'primary':
      return { type: 'primary' };
    case 'ghost_primary':
      return { type: 'primary', ghost: true };
    case 'ghost_negative':
      return { type: 'primary', ghost: true, danger: true };
    case 'negative':
      return { type: 'primary', danger: true };
    default:
      return {};
  }
}

export interface ITaskOutcomeButtonProps {
  outcome: EsitoDTO;
  taskId: string | undefined;
}

export function TaskOutcomeButton(props: ITaskOutcomeButtonProps) {
  const { outcome, taskId } = props;
  const buttonProps = mapStyleToButtonProps(outcome.stile);
  const [loading, setLoading] = useState(false);
  const formik = useFormikContext<any>();

  const handleOutcome = async () => {
    if (!taskId) return;
    if (formik.isSubmitting) return;
    setLoading(true);
    await formik.submitForm();
    setLoading(false);
  };

  const handleClick = async () => {
    if (loading) return;
    formik.setFieldValue('outcome', {
      ...formik.values.outcome,
      id: outcome.id,
      variable: outcome.nomeVariabile,
      required: outcome.noteObbligatorie
    });
    setTimeout(() => {
      formik.setFieldTouched('outcome', true, true);
    }, 0);

    Modal.confirm({
      title: `Completare la dichiarazione con esito "${outcome.nome}"?`,
      content: (
        <Typography.Text type="secondary">
          Una volta completata, la dichiarazione passerà allo stato successivo.
          L'operazione è irreversibile.
        </Typography.Text>
      ),
      onOk: handleOutcome,
      cancelText: 'Annulla',
      closable: true,
      mask: true,
      maskClosable: false,
      okText: 'Completa'
    });
  };

  if (!taskId) return null;

  return (
    <>
      <Button
        {...buttonProps}
        size="large"
        onClick={handleClick}
        loading={loading}
        // Se sta inviando ma non sta caricando, allora non è questo pulsante e lo disabilito
        // per evitare di poter premere su più esiti.
        disabled={formik.isSubmitting && !loading}
      >
        {outcome.nome}
      </Button>
    </>
  );
}
