import React from 'react';
import styled from 'styled-components';
import { Card, CardProps } from 'antd';
import { down } from 'styled-breakpoints';

const StyledResponsiveCard = styled(Card)`
  .ant-card-body {
    ${down('sm')} {
      padding: 12px;
    }
  }
`;

export interface ResponsiveCardProps extends CardProps {}

/**
 * Card Responsive
 * riduce il padding del body dalla Card (da 24px a 12px)
 */
export function ResponsiveCard(props: ResponsiveCardProps) {
  return <StyledResponsiveCard bordered={false} {...props} />;
}
