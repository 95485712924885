import { appConfig } from 'client/core/appConfig';
import {
  DeclarationFieldDto,
  WsOutParameter
} from 'common/dto/declaration/DeclarationFieldDto';
import { getIn } from 'formik';
import { fromPairs } from 'lodash';

export interface DeclarationAsyncSelectOption {
  label: string;
  value: string;
  data: { [key: string]: string };
}

export interface IResponseAssignmentMap {
  from: string;
  to: string;
}

export interface IDeclarationAsyncParametersOut {
  returnType: 'JSON' | 'XML';
  root: string | null;
  elementview: string | null;
  infield: string | null;
  outfield: string | null;
  filter: string | null;
}

export class DeclarationAsyncSelectLogic {
  static getParameter(field: DeclarationFieldDto, id: WsOutParameter) {
    return field.tipoWsSelezionato.ParametriOut.find(p => p.id === id);
  }

  static getParameters(
    field: DeclarationFieldDto
  ): IDeclarationAsyncParametersOut {
    return Object.fromEntries(
      field.tipoWsSelezionato.ParametriOut.map(p => [p.id, p.valore || null])
    ) as any;
  }

  /**
   * Ritorna la lista di options mappata con i dati richiesti dal componente
   */
  static parseListOptions(
    response: any,
    params: IDeclarationAsyncParametersOut
  ): DeclarationAsyncSelectOption[] {
    const options = params.root
      ? getIn(response, params.root.replace('/', '.'))
      : response;

    const keys = DeclarationAsyncSelectLogic.parseKeys(params.elementview);

    return options.map((option: { [key: string]: string }) => {
      const label = keys.map(key => option[key]).join(', ');
      return {
        label,
        value: label, // TODO Non abbiamo un metodo migliore per identificarli univocamente
        data: option
      };
    });
  }

  /**
   * Ritorna tutte le chiavi elencate in una lista "<key1>;<key2>;<key3>..."
   */
  static parseKeys(elementview: string | null | undefined) {
    if (!elementview) return [];
    return Array.from(
      elementview.trim().matchAll(/\<([\w\d_]+)\>/g),
      result => result[1]
    );
  }

  /**
   * Fa il parsing degli assegnamenti di outfield e ritorna un oggetto
   * le cui chiavi sono le chiavi del risultato della response, e i cui valori sono
   * il nome del campo da impostare
   */
  static parseResponseAssignment(
    params: IDeclarationAsyncParametersOut
  ): IResponseAssignmentMap[] {
    if (!params.outfield) return [];

    return Array.from(
      params.outfield.matchAll(/\$([\w\d_]+)\$=<([\w\d_]+)>;?/gm),
      ([_, to, from]) => ({ from, to })
    );
  }

  /**
   * Ritorna l'oggetto in base ai filtri indicati nel campo
   */
  static parseFilter(params: IDeclarationAsyncParametersOut) {
    if (!params.filter) return {};
    // TODO Implementare filtri
    return {};
  }

  /**
   * Ritorna l'URL
   */
  // TODO In caso sia da comporre in modo diverso l'URL della semplice
  // concatenazione, dobbiamo gestirlo qui
  static getRequestUrl(field: DeclarationFieldDto, search: string | null) {
    let url = field.tipoWsSelezionato.Url;
    url += search ? encodeURIComponent(search) : '';

    if (appConfig.useFvProxy) {
      url = url.replace(
        'hps-procedimenti.dedagroup.it',
        appConfig.useFvProxyUrl
      );
    }

    return url;
  }
}
