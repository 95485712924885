import { useFormikContext } from 'formik';
import * as React from 'react';
import { ErrorNotificationModal } from './ErrorNotificationModal';

export interface IFormErrorsNotificationProps {}

export function FormErrorsNotification(props: IFormErrorsNotificationProps) {
  const { submitCount, errors } = useFormikContext();

  return <ErrorNotificationModal submitCount={submitCount} errors={errors} />;
}
