import { AzioniGruppoAllegato } from 'client/api/backend/schemas';
import { AttachmentActionLogic } from 'client/components/schema/declaration/actions/AttachmentActionLogic';
import { getAnchorId } from 'client/ui/anchor/getAnchorId';
import { StateAnchorLink } from 'client/ui/anchor/StateAnchorLink';
import { useField } from 'formik';
import React, { useMemo } from 'react';
import { HiXCircle, HiCheckCircle } from 'react-icons/hi';

function getAttachmentAnchorName(prefix: string | undefined, groupId: string) {
  if (!prefix) return groupId;
  return `${prefix}.${groupId}`;
}

export interface ITaskAttachmentAnchorLinkProps {
  attachments: AzioniGruppoAllegato;
  /**
   * Gli allegati ai documenti hanno un prefisso perché sono
   * oggetti annidati.
   */
  prefix?: string;
}

export function TaskAttachmentAnchorLink(
  props: ITaskAttachmentAnchorLinkProps
) {
  const { attachments, prefix } = props;

  const anchorName = useMemo(() => {
    return getAttachmentAnchorName(
      prefix,
      attachments.gruppoAllegati?.idGruppoAllegato!
    );
  }, []);

  const [, meta] = useField(anchorName);

  const isEditable = AttachmentActionLogic.canUpload(attachments.azioni);

  // console.log(attachments.gruppoAllegati?.denominazioneGruppoAllegato, {
  //   meta
  // });

  if (!AttachmentActionLogic.isVisible(attachments.azioni)) return null;

  return (
    <StateAnchorLink
      href={`#${getAnchorId(attachments.gruppoAllegati?.idGruppoAllegato)}`}
      title={attachments.gruppoAllegati?.denominazioneGruppoAllegato}
      icon={
        isEditable ? (
          meta.touched && meta.error ? (
            <HiXCircle />
          ) : (
            <HiCheckCircle />
          )
        ) : undefined
      }
      status={meta.touched ? (meta.error ? 'error' : 'success') : undefined}
    />
  );
}
