import { useMemo } from 'react';
import {
  ProviderInternalDynamicFieldType,
  ProviderParameters
} from '../../provider-parameters/ProviderParameters';
import { ProviderParameterService } from '../../provider-parameters/ProviderParameterService';

export type IProviderDynamicFieldsKey =
  | 'otpParameters'
  | 'certificateParameters'
  | 'remoteSignParameters';

/**
 * Ritorna i parametri interni del provider selezionato dall'utente allo step 1,
 * e lo schema di valdiazione dinamico.
 */
export function useRemoteSignDynamicValues(
  selected: ProviderParameters | null | undefined,
  key: IProviderDynamicFieldsKey
) {
  const data = useMemo(() => {
    const originalFields = selected?.[key] ?? [];

    // Se è presente un campo OTP, aggiungo i suoi parametri alla lista
    const otpFieldIndex = originalFields.findIndex(
      p => p.tipo === ProviderInternalDynamicFieldType.Otp
    );

    // Ne creiamo una copia, in cui _potrebbero_ venire aggiunti i campi per l'OTP
    let fields = [...originalFields];

    // Se c'è un campo OTP e il provider richiede l'OTP _interno_ (da API), aggiungiamo i campi OTP
    if (otpFieldIndex > -1 && selected?.requestOtp) {
      const otpParameters = (selected?.otpParameters ?? []).filter(
        p => !originalFields.some(p2 => p2.nome === p.nome)
      );

      // Inserisci gli otpParameters _prima_ del campo OTP
      fields = [
        ...fields.slice(0, otpFieldIndex),
        ...otpParameters,
        ...fields.slice(otpFieldIndex)
      ];
    }

    // Genera lo schema di validazione dei campi _originali_ senza quelli aggiuntivi dell'OTP.
    // Questo perché la validazione del form non deve prendere in considerazione i campi aggiuntivi.
    // E.g. la scelta del tipo OTP non deve influenzare la validazione del form (il campo OTP in sé è già negli originalFields,
    // quindi se è valido, il form è valido)
    const schema = ProviderParameterService.getSchema(originalFields);

    return { fields, schema };
  }, [selected]);

  return data;
}
