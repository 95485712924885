import {
  DeclarationFieldDto,
  DeclarationFieldType
} from 'common/dto/declaration/DeclarationFieldDto';

/**
 * Normalizzazione di base del singolo campo.
 * Punto in cui concentrare le trasformazioni che non dipendono da altri campi.
 */
export function normalizeDeclarationField(
  raw: DeclarationFieldDto,
  index: number
): DeclarationFieldDto {
  let field: DeclarationFieldDto = { ...raw };

  if (field.tipo === DeclarationFieldType.Select) {
    // Nel caso di select, le opzioni sono espresse come _sottocampi_, che in
    // questo caso non rappresentano veramente dei campi ma solo le opzioni.
    field.options = (field.sottocampi ?? []).map(subfield => ({
      label: subfield.des_campo ?? subfield.nome,
      value: subfield.nome
    }));
  }

  if (field.tipo === DeclarationFieldType.Table) {
    field.headers = getTableSubfieldsByType(
      field,
      DeclarationFieldType.TableHeaders
    );

    if (field.aggiungiRiga) {
      field.subtype = 'table';
    }

    field.defaultRowsCount = Math.max(
      ...field.sottocampi.map(s => s.rigaTabella)
    );
    field.subfields = getTableSubfieldsByType(
      field,
      DeclarationFieldType.TableBody
    );

    field.subfields.forEach((subfield, i) => {
      // Il campo collegato nel caso delle tabelle non ha il senso di "valore
      // condizionale" ma indica solamente il parent.
      subfield.campo_collegato = null;

      // La label non deve essere mostrata, ma usata come placeholder
      // tranne per i campi di testo.
      subfield.placeholder = subfield.des_campo ?? undefined;
      if (subfield.tipo === DeclarationFieldType.Input) {
        subfield.des_campo = null;
      }
    });
  }

  return { ...field, uniqueName: getUniqueName(field) };
}

/**
 * Restituisce i sottocampi di un campo di tipo tabella, filtrati per tipo.
 * Ad ogni sottocampo è aggiunta la riga di appartenenza indicata dal campo "padre".
 * Trasforma i campi da una struttura matriciale, divisi tra dei sottocampi
 * "contenitor di righe" che contengono i campi di ogni singola riga, in una struttura
 * lineare, ordinata per righe|colonne.
 *
 * `(i,j) => (i * len + j)`
 */
function getTableSubfieldsByType(
  field: DeclarationFieldDto,
  type: DeclarationFieldType
) {
  const subfields =
    field.sottocampi
      .filter(s => s.tipo === type)
      ?.flatMap(f =>
        f.sottocampi.map(subField => ({
          ...subField,
          rigaTabella: f.rigaTabella
        }))
      ) ?? [];

  return subfields.sort((a, b) =>
    a.rigaTabella === b.rigaTabella
      ? a.colonnaTabella - b.colonnaTabella
      : a.rigaTabella - b.rigaTabella
  );
}

/**
 * Genera un nome univoco per quei campi che condividono lo stesso nome.
 * E.G. i radio.
 */
export function getUniqueName(field: DeclarationFieldDto) {
  return (
    field.nome + (field.tipo === DeclarationFieldType.Radio ? field.valore : '')
  );
}
