import { Typography } from 'antd';
import {
  useDocumentiManagerJnlp,
  useDocumentiManagerJnlpArguments
} from 'client/api/auth/jnlp/jnlp';
import { getGetAzioniDocumentoQueryKey } from 'client/api/backend/attivita/attivita';
import { AzioniSezione, Documento } from 'client/api/backend/schemas';
import { WebSocketKind } from 'client/components/digital-signature/DigitalSignatureProvider';
import * as React from 'react';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { JnlpService } from './JnlpService';
import { WebSocketSectionJnlpModal } from './WebSocketSectionJnlpModal';

const { Paragraph, Text } = Typography;

export interface IFastSectionJnlpWindowManagerProps {
  taskId: string;
  document: Documento;
  section: AzioniSezione;
  visible: boolean;
  hide: () => void;
}

/**
 * Gestone "FAST" della modale per la modifica delle sezioni
 */
export function FastSectionJnlpWindowManager(
  props: IFastSectionJnlpWindowManagerProps
) {
  const { taskId, document, section, visible, hide } = props;

  const getJnlp = useDocumentiManagerJnlp({});
  const getJnlpArguments = useDocumentiManagerJnlpArguments({});
  const queryClient = useQueryClient();

  const fastJnlpManager = JnlpService.useWebSocketJnlpManager({
    kind: WebSocketKind.Document,
    filename: 'documentiManager.jnlp',
    getJnlp: async () => {
      return await getJnlp.mutateAsync({
        data: {
          tipoAvvioJnlp: 'FAST',
          idTaskInstance: taskId,
          idTipoSezione: section.sezione!.idTipoSezione!,
          idDocumento: document.idDocumento!
        }
      });
    },
    getJnlpArguments: async () => {
      const result = await getJnlpArguments.mutateAsync({
        data: {
          tipoAvvioJnlp: 'FAST',
          idTaskInstance: taskId,
          idTipoSezione: section.sezione!.idTipoSezione!,
          idDocumento: document.idDocumento!
        }
      });

      return result;
    },
    maxConnectionTries: 5 * 60, // ~5 minuti
    successMessage: 'Documento modificato con successo',
    onSuccess: () => {
      queryClient.invalidateQueries(getGetAzioniDocumentoQueryKey(taskId));
      hide();
    }
  });

  useEffect(() => {
    if (!visible) {
      fastJnlpManager.setToDefault();
      return;
    }

    fastJnlpManager.jnlpProcess();
  }, [visible]);

  return (
    <WebSocketSectionJnlpModal
      visible={visible}
      fastJnlpManager={fastJnlpManager}
      hide={hide}
    />
  );
}
