/**
 * Generato automaticamente.
 * Procedimenti
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  BEResponse,
  BEResponseListFirmaResponseDTO,
  FirmaRequest,
  BEResponseListWSEsternoType,
  BEResponseListCertificato
} from '.././schemas';
import { backendApiInstance } from '../../../core/network/mutators/apiClient';
import type { ErrorType } from '../../../core/network/mutators/apiClient';

/**
 * Consente di richiedere al provider di firma la generazione ed invio del OTP
 * @summary Generazione ed invio OTP
 */
export const generaOtp = (idWsEsterno: string) => {
  return backendApiInstance<BEResponse>({
    url: `/firma-remota/otp/${idWsEsterno}`,
    method: 'post'
  });
};

export type GeneraOtpMutationResult = NonNullable<
  Awaited<ReturnType<typeof generaOtp>>
>;

export type GeneraOtpMutationError = ErrorType<unknown>;

export const useGeneraOtp = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generaOtp>>,
    TError,
    { idWsEsterno: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generaOtp>>,
    { idWsEsterno: string }
  > = props => {
    const { idWsEsterno } = props ?? {};

    return generaOtp(idWsEsterno);
  };

  return useMutation<
    Awaited<ReturnType<typeof generaOtp>>,
    TError,
    { idWsEsterno: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Consente di firma uno o più documento
 * @summary Firma remota documenti
 */
export const firma = (idWSEsterno: string, firmaRequest: FirmaRequest) => {
  return backendApiInstance<BEResponseListFirmaResponseDTO>({
    url: `/firma-remota/firma/${idWSEsterno}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: firmaRequest
  });
};

export type FirmaMutationResult = NonNullable<
  Awaited<ReturnType<typeof firma>>
>;
export type FirmaMutationBody = FirmaRequest;
export type FirmaMutationError = ErrorType<unknown>;

export const useFirma = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof firma>>,
    TError,
    { idWSEsterno: string; data: FirmaRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof firma>>,
    { idWSEsterno: string; data: FirmaRequest }
  > = props => {
    const { idWSEsterno, data } = props ?? {};

    return firma(idWSEsterno, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof firma>>,
    TError,
    { idWSEsterno: string; data: FirmaRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Ritorna la lista dei provider di firma configurati a sistema
 * @summary Lista dei provider di firma
 */
export const getListaProvider = (signal?: AbortSignal) => {
  return backendApiInstance<BEResponseListWSEsternoType>({
    url: `/firma-remota/lista-provider-firma`,
    method: 'get',
    signal
  });
};

export const getGetListaProviderQueryKey = () => [
  `/firma-remota/lista-provider-firma`
];

export type GetListaProviderQueryResult = NonNullable<
  Awaited<ReturnType<typeof getListaProvider>>
>;
export type GetListaProviderQueryError = ErrorType<unknown>;

export const useGetListaProvider = <
  TData = Awaited<ReturnType<typeof getListaProvider>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getListaProvider>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetListaProviderQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getListaProvider>>
  > = ({ signal }) => getListaProvider(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getListaProvider>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Ritorna la lista dei certificati di firma associati all'utenza fornita in header per un particolare provider
 * @summary Lista certificati di firma
 */
export const getCertificati = (idWSEsterno: string, signal?: AbortSignal) => {
  return backendApiInstance<BEResponseListCertificato>({
    url: `/firma-remota/lista-certificati/${idWSEsterno}`,
    method: 'get',
    signal
  });
};

export const getGetCertificatiQueryKey = (idWSEsterno: string) => [
  `/firma-remota/lista-certificati/${idWSEsterno}`
];

export type GetCertificatiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCertificati>>
>;
export type GetCertificatiQueryError = ErrorType<unknown>;

export const useGetCertificati = <
  TData = Awaited<ReturnType<typeof getCertificati>>,
  TError = ErrorType<unknown>
>(
  idWSEsterno: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCertificati>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCertificatiQueryKey(idWSEsterno);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCertificati>>> = ({
    signal
  }) => getCertificati(idWSEsterno, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getCertificati>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idWSEsterno,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
