import * as React from 'react';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TaskFormContainer } from '../container/TaskFormContainer';
import { TaskDeclarations } from '../declarations/TaskDeclarations';
import { TaskDocuments } from '../documents/TaskDocuments';
import { TaskResultActions } from '../actions/TaskResultActions';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { logger } from 'client/core/logger/logger';
import { Alert, message, Space } from 'antd';
import { TabTaskPortal } from './TabTaskPortal';
import { TaskStateAnchors } from '../anchor/TaskStateAnchors';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { PartialSaveButton } from './buttons/PartialSaveButton';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { TaskActionLogic, TaskActions } from 'client/logic/TaskActionLogic';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ReleaseTaskButton } from '../../list/table/actions/ReleaseTaskButton';
import { DeclarationLogic } from '../../../../components/schema/task/declaration/DeclarationLogic';
import { useHistory } from 'react-router';
import {
  useCompleta,
  useUpdateDichiarazioni
} from 'client/api/backend/attivita/attivita';
import { queryClient } from 'client/core/network/queryClient';
import { AutoValidateFormik } from './AutoValidateFormik';
import {
  TaskEditedFlagPortal,
  TaskEditFlag
} from 'client/components/schema/task/validation/TaskEditedFlag';
import { useOutcomes } from 'client/components/schema/outcome/useOutcomes';
import { useTaskData } from 'client/components/schema/task/hooks/useTaskData';
import { FormikInitialValueManager } from 'client/ui/form/FormikInitialValueManager';
import { invalidateTaskQueries } from '../declarations/invalidateTaskQueries';
import { getDocumentDeclarations } from 'client/components/schema/task/document/getDocumentDeclarations';

export interface TabTaskProps {
  task: AttivitaDTO | null;
  isAssigned: boolean;
}

export function TabTask(props: TabTaskProps) {
  const { isAssigned, task } = props;
  const taskId = task?.idTaskInstance;
  const currentUser = useCurrentUser();
  const action = TaskActionLogic.getAction(task, currentUser);
  const history = useHistory();
  const save = useUpdateDichiarazioni();
  const sendOutcome = useCompleta();

  const {
    declarations: declarationsResult,
    documents: documentsResult,
    attachments: attachmentsResult,
    declarationIds,
    validator,
    partialValidator,
    initialValues,
    initialTouched
  } = useTaskData(taskId!, TaskActionLogic.isAssigned(task, currentUser));

  const {
    outcomesConstraints,
    outcomes,
    result: outcomesResult
  } = useOutcomes(taskId);

  const { result: declarationsResponse, declarations } = declarationsResult;
  const { result: documentsResponse, documents } = documentsResult;
  const { attachments: attachmentGroups } = attachmentsResult;

  if (declarationsResponse?.error || documentsResponse?.error) {
    // TODO Gestire messaggio di errore
    return (
      <Alert
        showIcon
        type="error"
        message="Errore durante il caricamento della dichiarazione"
        description={getNetworkErrorMessage(
          declarationsResponse?.error || documentsResponse?.error,
          "Impossibile caricare le dichiarazioni dell'attività"
        )}
      />
    );
  }

  if (declarationsResponse?.isLoading || documentsResponse?.isLoading) {
    return <LoaderSpin full />;
  }

  return (
    <FormikAugmented
      enableReinitialize={false}
      validateOnChange={false}
      validationSchema={validator!}
      validationContext={{ outcomesConstraints }}
      valuesToContext
      initialValues={initialValues}
      onSubmit={async (rawValues, { resetForm }) => {
        if (!declarations) return;
        if (!taskId) return;

        // Preparo i dati per il salvataggio
        let values: any;
        try {
          values = await validator!.validate(rawValues, {
            context: { values: rawValues, outcomesConstraints }
          });
        } catch (e) {
          logger.error(`[TaskOutcomeButton] Errore durante il salvataggio del form`, {e}); // prettier-ignore
          return;
        }

        // Salvo la dichiarazione
        try {
          const data = DeclarationLogic.getBodyData(
            declarations,
            getDocumentDeclarations(documents),
            values
          );
          await save.mutateAsync({ idTaskInstance: taskId, data });
        } catch (e) {
          logger.error(`[TabTask] Si è verificato un errore durante il salvataggio dei dati`, {error: e}); // prettier-ignore
          message.error(getNetworkErrorMessage(e, `Errore durante il salvataggio`)); // prettier-ignore
          invalidateTaskQueries(queryClient, taskId);
          return;
        }

        // Chiamo l'API di esito
        try {
          await sendOutcome.mutateAsync({
            idTaskInstance: taskId,
            data: {
              nomeVariabileEsito: values.outcome.variable,
              esitoCompletamento: values.outcome.id,
              noteCompletamento: values.outcome.finalNotes
            }
          });
          message.success(`Attività esitata con successo`);
          // Aggiorno gli initialValues senza dover invalidare la query così
          // da poter cambiare pagina senza attivare il FormikPreventNavigation
          // (altrimenti il formik viene considerato dirty e mostra l'alert)
          resetForm(values);
          // Dopo l'esito torno alla home
          history.push('/home');
        } catch (e) {
          logger.error(`[TaskOutcomeButton] Errore durante l'esito`, {outcome: values.outcome, error: e}); // prettier-ignore
          message.error(getNetworkErrorMessage(e, `Si è verificato un errore. Riprovare...`)); // prettier-ignore
          invalidateTaskQueries(queryClient, taskId);
          return;
        }
      }}
    >
      <FormikForm layout="vertical" showVisualFeedback>
        <FormikInitialValueManager
          initialValues={initialValues}
          declarationIds={declarationIds}
        />
        <AutoValidateFormik touched={initialTouched} validator={validator} />
        {/* Portal collegato all'header. I bottoni qui verranno renderizzati come extra dell'header ma avranno il context del formik */}
        <TabTaskPortal>
          {action === TaskActions.ExecRelease && (
            <Space>
              <ReleaseTaskButton taskId={taskId} redirect="/home" />
              <PartialSaveButton
                taskId={taskId!}
                documents={documents}
                declarations={declarations}
                validator={partialValidator}
              />
            </Space>
          )}
        </TabTaskPortal>
        <TaskEditedFlagPortal>
          <TaskEditFlag />
        </TaskEditedFlagPortal>
        <TaskFormContainer
          anchors={
            <TaskStateAnchors
              declarations={declarations}
              documents={documents}
              attachmentGroups={attachmentGroups}
            />
          }
        >
          <TaskDeclarations
            task={task}
            result={declarationsResult}
            isTaskAssigned={isAssigned}
            attachments={attachmentsResult}
          />
          <TaskDocuments
            isTaskAssigned={isAssigned}
            result={documentsResult}
            task={task}
          />
          {TaskActions.ExecRelease === action && (
            <TaskResultActions
              taskId={taskId}
              isError={outcomesResult?.isError}
              error={outcomesResult?.error}
              isLoading={outcomesResult?.isLoading}
              outcomes={outcomes}
            />
          )}
        </TaskFormContainer>
      </FormikForm>
    </FormikAugmented>
  );
}
