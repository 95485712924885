import { base64 } from 'client/core/encoding/base64';

const STORAGE_KEY = 'pfeAuth';

interface IAuthStorage {
  isLogged: boolean;
  isPrelogged: boolean;
  strategy: string | null;
}

export const AuthStorage = {
  get(): IAuthStorage {
    const data = window.localStorage.getItem(STORAGE_KEY);
    return {
      isLogged: false,
      isPrelogged: false,
      strategy: null,
      ...(data && JSON.parse(base64.decode(data)))
    };
  },

  /**
   * Carichiamo i dati iniziali dal `localStorage` in caso siano
   * presenti.
   */
  initialState(): IAuthStorage {
    return this.get();
  },

  /**
   * Salviamo lo stato dell'utente corrente sul `localStorage`
   */
  saveState(state: IAuthStorage) {
    window.localStorage.setItem(
      STORAGE_KEY,
      base64.encode(JSON.stringify(state))
    );
  }
};
