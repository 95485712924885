import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { logger } from 'client/core/logger/logger';
import { Col, Row } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import {
  hasUserSelectedOfficeAndRole,
  useAuth,
  useCurrentUser
} from 'client/components/auth/AuthModule';
import { LoginForm } from 'client/components/auth/login/LoginForm';
import styled from 'styled-components';
import { LoginSelectRoleForm } from 'client/components/auth/login-select-role/LoginSelectRoleForm';

const RowCenter = styled(Row)`
  width: 100%;
  margin-top: 5em;
`;

interface Props extends RouteComponentProps {}

/**
 * Disponibile soltanto se l'utente non ha ancora effettuato il login.
 * In caso di successo, lo reindirizza alla pagina corretta.
 */
export const LoginPage = (props: Props) => {
  const { logged, prelogged } = useAuth();
  const currentUser = useCurrentUser();

  // Calcolo l'origine
  const { from } = (props.location.state as any) || { from: { pathname: '/' } };

  if (logged) {
    logger.log(`Redirect utente dopo login: ->`, from, currentUser);
    return <Redirect to={from} />;
  }

  return (
    <PageLayout>
      <RowCenter justify="center" align="middle">
        <Col>{prelogged ? <LoginSelectRoleForm /> : <LoginForm />}</Col>
      </RowCenter>
    </PageLayout>
  );
};
