import { IDocumentFile } from 'client/ui/form/input/UploadInput';
import React, { useState } from 'react';
import { TbEdit } from 'react-icons/tb';
import { EditFileModal } from './components/EditFileModal';
import { Icon24px } from './FileRow';

export interface IEditFileIconButtonProps {
  canEdit: boolean;
  item: IDocumentFile;
  taskId: string;
}

/**
 * Pulsante per l'apertura del Modal di modifica informazioni aggiuntive del file
 */
export function EditFileButton(props: IEditFileIconButtonProps) {
  const { canEdit, item, taskId } = props;
  const [visible, setVisible] = useState(false);
  if (!canEdit) return null;

  const handleEdit = () => {
    setVisible(true);
  };

  return (
    <>
      <a>
        <Icon24px icon={<TbEdit />} onClick={handleEdit} />
      </a>
      {/* Modal di modifica informazioni aggiuntive del file */}
      <EditFileModal
        taskId={taskId}
        visible={visible}
        hide={() => setVisible(false)}
        item={item}
      />
    </>
  );
}
