import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { RadioGroupInput } from 'client/ui/form/input/RadioGroupInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import React from 'react';
import {
  ProviderInternalDynamicField,
  ProviderInternalDynamicFieldType
} from '../../../provider-parameters/ProviderParameters';
import { RemoteSignOtpField } from '../RemoteSignOtpField';
import { Divider } from 'antd';
import styled from 'styled-components';

const BoxedOptionsWrapper = styled.div`
  background-color: #fff;
  padding: 12px;
  padding-top: 0;
  border-radius: 4px;
  border: #c2c7cc 1px solid;
  margin-bottom: -4px;
`;
const BoxedOptionsDivider = styled(Divider)`
  margin-bottom: 5px;
  margin-top: 0;
`;

export interface IRemoteSignDynamicFieldSwitchProps {
  field: ProviderInternalDynamicField;
}

export function RemoteSignDynamicFieldSwitch(
  props: Readonly<IRemoteSignDynamicFieldSwitchProps>
) {
  const { field } = props;

  switch (field.tipo) {
    case ProviderInternalDynamicFieldType.String:
      return (
        <FormFieldItem
          label={field.descrizione}
          name={field.nome}
          component={TextInput}
          autoComplete="off"
        />
      );
    case ProviderInternalDynamicFieldType.Password:
      return (
        <FormFieldItem
          label={field.descrizione}
          name={field.nome}
          component={TextInput}
          type={'password'}
          autoComplete="off"
        />
      );

    case ProviderInternalDynamicFieldType.Otp:
      return <RemoteSignOtpField name={field.nome} label={field.descrizione} />;
    case ProviderInternalDynamicFieldType.Select:
      // TODO
      return (
        <BoxedOptionsWrapper>
          {/* <label>{field.descrizione}</label>
          <div
            style={{
              border: '1px solid grey',
              borderRadius: '8px',
              padding: '6px',
              marginBottom: '12px'
            }}
          > */}
          <BoxedOptionsDivider>{field.descrizione}</BoxedOptionsDivider>
          <FormFieldItem
            className="boxed-options"
            label={null}
            name={field.nome}
            component={RadioGroupInput}
            options={field.opzioni.map(o => ({
              label: o.descrizione,
              value: o.valore
            }))}
          />
          {/* </div> */}
        </BoxedOptionsWrapper>
      );

    default:
      return null;
  }
}
