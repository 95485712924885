import React, { useCallback } from 'react';
import { FormikValues, useField, useFormikContext } from 'formik';
import Select, { SelectProps, OptionProps, SelectValue } from 'antd/lib/select';
import { useFormikFormContext } from '../FormikFormContext';

// Da mantenere sincronizzata con `OptionCoreData`
export interface SelectOption {
  key?: string | number;
  disabled?: boolean;
  value: string | number;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  label: React.ReactNode;
}

export interface SelectInputProps<T extends FormikValues>
  extends Omit<SelectProps<any>, 'name' | 'options'> {
  name: keyof T & string;
  onChange?: (value: SelectValue) => void;
  options?: SelectOption[];
  renderOption?: (option: SelectOption) => React.ReactNode;
  children?: React.ReactNode;
}

/**
 * Campo per input di tipo select, con opzioni fornibili direttamente.
 */
export function SelectInput<T extends FormikValues>(
  props: SelectInputProps<T>
) {
  const { name, options, children, onChange, renderOption, ...otherProps } =
    props;
  const [field, meta, helper] = useField(name);
  const { disabled } = useFormikFormContext();
  const handleChange = useCallback(
    (value: SelectValue) => {
      helper.setValue(value ?? null);
      if (onChange) onChange(value);
      setTimeout(() => {
        helper.setTouched(true);
      }, 0);
    },
    [helper, onChange]
  );
  const handleBlur = useCallback(() => helper.setTouched(true), [helper]);

  return (
    <Select
      {...otherProps}
      {...field}
      disabled={props.disabled || disabled}
      onChange={handleChange}
      onBlur={handleBlur}
    >
      {options &&
        options.map(option => (
          <Select.Option key={option.value} {...option}>
            {renderOption ? renderOption(option) : option.label}
          </Select.Option>
        ))}
      {children}
    </Select>
  );
}

SelectInput.renderFieldValue = (
  value: string | number,
  props: SelectInputProps<any>
) => {
  return props.options?.find(option => option.value === value)?.label ?? value;
};
