import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { RadioInput } from 'client/ui/form/input/RadioInput';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import * as React from 'react';
import { IDeclarationFieldOptions } from '../DeclarationFieldSwitch';

export interface IDeclarationFieldRadioProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

export function DeclarationFieldRadio(props: IDeclarationFieldRadioProps) {
  const { field, options } = props;
  const prefix = options.namePrefix;

  const fieldName = prefix ? `${prefix}.${field.nome}` : field.nome;
  return (
    <FormFieldItem
      label={undefined}
      name={fieldName}
      component={RadioInput}
      title={field.des_campo!}
      radioValue={field.valore!}
    />
  );
}
