import React from 'react';
import { FormikValues } from 'formik';
import { FormItem, SingleFormItemProps } from '../FormItem';
import { CheckboxInput, CheckboxInputProps } from './CheckboxInput';
import { createGlobalStyle } from 'styled-components';

export const CheckboxInputGlobalStyle = createGlobalStyle`

  body {

    //  CheckboxInput.ts
    .ant-checkbox-wrapper {
      &.checkbox-not-editable {
        cursor: default;
        input,
        .ant-checkbox-checked {
          cursor: default;
        }
        .ant-checkbox-inner,
        .ant-checkbox-inner:hover {
          border: 1px solid rgba(23, 50, 77, 0.1);
          cursor: default;
        }
      }
    }
  }
`;

interface Props<T extends FormikValues> extends CheckboxInputProps<T> {
  itemProps?: SingleFormItemProps<T>;
  label?: React.ReactNode;
  title?: React.ReactNode;
}

/**
 * Form Item per input di tipo checkbox.
 */
export function CheckboxInputItem<T extends FormikValues>(props: Props<T>) {
  const { name, label, itemProps, ...otherProps } = props;

  return (
    <FormItem {...itemProps} name={name} label={label}>
      <CheckboxInput {...otherProps} name={name}>
        {props.title}
      </CheckboxInput>
    </FormItem>
  );
}
