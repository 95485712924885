import { message } from 'antd';
import {
  getGetAttivitaQueryKey,
  getGetAzioniDocumentoQueryKey
} from 'client/api/backend/attivita/attivita';
import {
  AttivitaDTO,
  BEResponseListFirmaResponseDTO,
  Certificato,
  FirmaRequest
} from 'client/api/backend/schemas';
import { queryClient } from 'client/core/network/queryClient';
import { groupBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  ProviderParameters,
  RemoteSignType
} from '../../provider-parameters/ProviderParameters';
import { IRemoteSignErrorBag } from '../../reducer/RemoteSignActions';
import { RemoteSignStep } from '../../reducer/RemoteSignReducer';
import { useRemoteSignApi } from './useRemoteSignApi';

export interface IUseRemoteSignParams {
  provider: ProviderParameters | null | undefined;
  signType: RemoteSignType | null | undefined;
  certificate: Certificato | null | undefined;
  documentId?: string;
  taskId?: string;
  /**
   * Se fornito, ignora documentId e taskId e firma tutti i documenti (firma _massiva_)
   */
  tasks?: AttivitaDTO[];
  setCurrentStep: (step: RemoteSignStep) => void;
  setErrors: (errorBag: IRemoteSignErrorBag) => void;
  flIntermedio?: boolean;
  flCompletare?: boolean;
}

export function useRemoteSign(params: IUseRemoteSignParams) {
  const {
    documentId,
    taskId,
    tasks,
    provider,
    signType,
    certificate,
    flCompletare,
    flIntermedio,
    setCurrentStep,
    setErrors
  } = params;

  const requestData = useMemo<FirmaRequest>(() => {
    if (!signType) return { firmeRequest: [] };

    // Se è una firma massiva, ignora documentId e taskId
    if (tasks) {
      return {
        firmeRequest: tasks.map(task => ({
          idTaskInstance: task.idTaskInstance!,
          flIntermedio: Boolean(flIntermedio),
          flCompletare: Boolean(flCompletare),
          tipo: signType,
          idCertificato: certificate?.id
        }))
      };
    }

    if (taskId) {
      return {
        firmeRequest: [
          {
            idDocumento: documentId,
            idTaskInstance: taskId,
            flIntermedio: Boolean(flIntermedio),
            flCompletare: Boolean(flCompletare),
            tipo: signType,
            idCertificato: certificate?.id
          }
        ]
      };
    }

    return { firmeRequest: [] };
  }, [documentId, taskId, signType, certificate, flCompletare, flIntermedio]);

  const handleRemoteSign = useRemoteSignApi<BEResponseListFirmaResponseDTO>({
    method: 'POST',
    fields: provider?.remoteSignParameters ?? [],
    apiUrl: `/firma-remota/firma/${provider?.webserviceId}`,
    skip: !provider,
    body: requestData
  });

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        const result = await handleRemoteSign(values);

        // Se ritorna 1 solo elemento, non proseguo ma mostro direttamente l'errore.
        if (result?.data?.length === 1) {
          result?.data?.forEach(response => {
            if (response.error) {
              throw new Error(response.errorMessage);
            }
          });
        } else {
          // Se ci sono errori, li raccolgo in un array così da mostrarli
          const resultWithError = result?.data?.filter(
            response => response.error
          );

          const grouped = groupBy(resultWithError, 'idTaskInstance');

          const errorBag = Object.keys(grouped).map(taskId => ({
            taskId,
            messages: grouped[taskId].map(response => response.errorMessage!)
          }));

          setErrors(errorBag);

          console.log({ result, resultWithError, grouped, errorBag });
        }

        if (tasks) {
          queryClient.invalidateQueries(getGetAttivitaQueryKey());
        } else if (taskId) {
          queryClient.invalidateQueries(getGetAzioniDocumentoQueryKey(taskId));
        }

        setCurrentStep(RemoteSignStep.Signing);
      } catch (e: any) {
        console.error(`[RemoteSignCertificateStep] Si è verificato un errore durante il processo di firma`, {error: e}); // prettier-ignore
        const errorMessage = e?.response?.data?.esito?.errore.messaggioDiErrore ?? e.message; // prettier-ignore
        message.error(`Si è verificato un errore durante il processo di firma: ${errorMessage}`); // prettier-ignore
        return;
      }
    },
    [handleRemoteSign, setCurrentStep]
  );

  return handleSubmit;
}
