import {
  AzioniDichiarazione,
  AzioniDocumento
} from 'client/api/backend/schemas';

/**
 * L'API delle dichiarazioni ritorna _tutte_ le dichiarazioni, comprese quelle contenute nei documenti.
 * Utilizziamo un filtro per rimuovere quelle già presenti nei documenti così da non riproporle due volte.
 */
export function filterDeclarations(
  declarations: AzioniDichiarazione[] | undefined,
  documents: AzioniDocumento[] | undefined
) {
  if (!declarations || !documents) return declarations;

  // Genero la lista degli ID delle dichiarazioni presenti nei documenti
  const documentDeclarations = documents
    .map(doc =>
      doc.documento?.dichiarazioni?.map(
        d => d.dichiarazione?.idTipoDichiarazione
      )
    )
    .flat()
    .filter(item => !!item) as string[];

  // Rimuovo le dichiarazioni duplicate
  return declarations.filter(declaration => {
    if (!declaration.dichiarazione?.idTipoDichiarazione) return true;
    return !documentDeclarations.includes(
      declaration.dichiarazione?.idTipoDichiarazione
    );
  });
}
