import { message, Tooltip } from 'antd';
import { useAssegna } from 'client/api/backend/attivita/attivita';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { logger } from 'client/core/logger/logger';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { UserLogic } from 'common/schema/user/UserLogic';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { IconTable } from './IconTable';
import { IconAssignToMe } from 'client/ui/icon/ApplicationIcon';

export interface IAssignTaskButtonProps {
  task: AttivitaDTO;
}

export function AssignTaskButton(props: IAssignTaskButtonProps) {
  const { task } = props;

  const queryClient = useQueryClient();
  const assignFunction = useAssegna();
  const user = useCurrentUser();
  const history = useHistory();

  // Se non ha il permesso di assegnarsi la task, non mostro il pulsante
  if (!UserLogic.hasPermission(user, task.abilitazioneLavorazione)) {
    return null;
  }

  return (
    <Tooltip
      overlayClassName="button-icon-tooltip"
      title="Prendi in carico"
      placement="bottomRight"
    >
      <ButtonConfirm
        title="Vuoi prendere in carico questa attività?"
        okText="Prendi in carico"
        okType="primary"
        okDanger={false}
        size="large"
        type="link"
        popIcon={<IconWrapper icon={<IconAssignToMe />} />}
        icon={<IconTable icon={<IconAssignToMe />} />}
        loading={assignFunction.isLoading}
        disabled={!task.idTaskInstance}
        onConfirm={async () => {
          if (!task.idTaskInstance) return;
          try {
            await assignFunction.mutateAsync({
              idTaskInstance: task.idTaskInstance
            });
            queryClient.invalidateQueries();
            message.success('Attività assegnata');
            history.push(`/task/list/${task.idTaskInstance}`);
          } catch (e) {
            logger.error({ error: e });
            message.error(`Si è verificato un errore durante l'assegnazione. Riprovare...`); // prettier-ignore
          }
        }}
      />
    </Tooltip>
  );
}
