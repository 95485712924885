import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { sanitizeColumnsCount } from 'client/ui/form/field/row/sanitizeColumnsCount';
import { useFormFieldReset } from 'client/ui/form/field/useFormFieldReset';
import {
  DeclarationFieldDto,
  DeclarationFieldType
} from 'common/dto/declaration/DeclarationFieldDto';
import * as React from 'react';
import { useEffect } from 'react';
import { HiTrash } from 'react-icons/hi';
import styled from 'styled-components';
import {
  DeclarationFieldSwitch,
  IDeclarationFieldOptions
} from '../DeclarationFieldSwitch';
import { DeclarationFieldTabularLayout } from '../DeclarationFieldTabularLayout';
import { useDeclarationFields } from '../useDeclarationFields';

const containerPadding = '12px';

const RepeatableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;
const RepeatableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const AddButtonContainer = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${props => props.theme.borderColorBase};
  border-radius: 4px;
  margin-top: 12px;
`;

export interface IDeclarationFieldRepeatableProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
  editable?: boolean;
}

const RepeatableRow = styled.div`
  border: 1px solid ${props => props.theme.borderColorBase};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  & + & {
    margin-top: ${containerPadding};
  }
`;

const RepeatableMain = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  padding: 12px;
  padding-bottom: 0;
  /* text-align: right; */
`;

const RepeatableActions = styled.div`
  flex: 0 0 auto;
  min-width: 0;
  display: flex;
  border-left: 1px solid ${props => props.theme.borderColorBase};
  border-radius: 0 4px 4px 0;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
`;

/**
 * Si occupa di renderizzare un campo di tipo "Array" ovvero un campo
 * "ripetibile" secondo la nomenclatura delle dichiarazioni.
 *
 * La struttura è ricorsiva e permette di:
 * 1) Gestire l'aggiunta e la rimozione di N righe.
 * 2) Renderizzare ogni riga come una "dichiarazione ricorsiva".
 */
export function DeclarationFieldRepeatable(
  props: IDeclarationFieldRepeatableProps
) {
  const { field, options, editable } = props;

  const fieldName = options.namePrefix
    ? `${options.namePrefix}.${field.nome}`
    : field.nome;

  const { stripped } = useFormFieldReset(fieldName);
  const editor = useRelationEditor(fieldName);

  if (stripped) return null;

  return (
    <RepeatableWrapper>
      <RepeatableContainer>
        {(editor.items.length === 0 ? [{}] : editor.items).map(
          (item, index) => (
            <RepeatableRow key={index}>
              <DeclarationFieldRepeatableRow
                field={field}
                fieldName={fieldName}
                item={item}
                index={index}
              />
              {editable && (
                <RepeatableActions>
                  {/* {index > 0 && ( */}
                  <ButtonIcon
                    danger
                    icon={<HiTrash />}
                    onClick={() => editor.remove(item)}
                    tooltip={
                      index < 1 ? "Pulisci l'elemento" : 'Elimina l’elemento'
                    }
                    tooltipProps={{ placement: 'topRight' }}
                  />
                  {/* )} */}
                </RepeatableActions>
              )}
            </RepeatableRow>
          )
        )}
      </RepeatableContainer>
      {editable && (
        <AddButtonContainer>
          <Button
            block
            icon={<PlusOutlined />}
            type="link"
            onClick={() => {
              // Se vuoto, aggiungo due elementi, dato che uno è sempre
              // rendeizzato anche se non è presente.
              if (editor.count === 0) {
                editor.addItems([{}, {}]);
              } else {
                editor.add({});
              }
            }}
          >
            Aggiungi
          </Button>
        </AddButtonContainer>
      )}
    </RepeatableWrapper>
  );
}

interface IDeclarationFieldRepeatableRowProps {
  field: DeclarationFieldDto;
  fieldName: string;
  item: any;
  index: number;
}

/**
 * Ogni singola riga è simile a una dichiarazione a se stante, con un
 * `namePrefix` differente. Di base è una piccola copia di `DeclarationForm`.
 */
function DeclarationFieldRepeatableRow(
  props: IDeclarationFieldRepeatableRowProps
) {
  const { field, fieldName, item, index } = props;
  const rows = useDeclarationFields(field.subfields!);

  const namePrefix = `${fieldName}.${index}`;

  // Se si tratta di un campo tabulare, allora devo renderizzare
  // la tabella prima di renderizzare i sottocampi
  if (field.tipo === DeclarationFieldType.Table) {
    return (
      <RepeatableMain>
        <DeclarationFieldTabularLayout
          field={props.field}
          options={{ namePrefix }}
        />
      </RepeatableMain>
    );
  }

  return (
    <RepeatableMain>
      {rows.map((columns, index) => (
        <FormFieldsContainer
          key={index}
          columns={sanitizeColumnsCount(columns.length)}
          className="declaration-row"
        >
          {columns.map((column, index) => {
            return (
              <ErrorBoundary description="" key={index}>
                <DeclarationFieldSwitch
                  field={column}
                  key={index}
                  options={{ namePrefix }}
                />
              </ErrorBoundary>
            );
          })}
        </FormFieldsContainer>
      ))}
    </RepeatableMain>
  );
}
