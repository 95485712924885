import * as React from 'react';
import { Divider } from 'antd';
import { AsyncSelectMeta } from './AsyncSelectInput';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const AsyncSelectMetaStyle = styled.div`
  padding: 4px 8px;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &--icon {
    flex: 0 0 auto;
    font-size: 16px;
    padding-left: 4px;
    padding-right: 16px;
  }

  &--text {
    flex: 1 1 auto;
  }
`;

const AsyncSelectMetaDivider = styled(Divider)`
  margin: 4px 0;
`;

export interface IAsyncSelectInputMetaProps {
  meta?: AsyncSelectMeta;
}

/**
 * Dati aggiuntivi per la select asincrona
 */
export function AsyncSelectInputMeta(props: IAsyncSelectInputMetaProps) {
  const { meta } = props;

  if (!meta) return null;

  return (
    <>
      <AsyncSelectMetaDivider />
      <AsyncSelectMetaStyle>
        <div className="async-select-input-meta--icon">
          <InfoCircleOutlined />
        </div>
        <div className="async-select-input-meta--text">
          Mostrati {meta.shown} di {meta.total}.
          {meta.shown < meta.total && (
            <>
              <br />
              Filtrare la ricerca digitando per migliorare i risultati.
            </>
          )}
        </div>
      </AsyncSelectMetaStyle>
    </>
  );
}
