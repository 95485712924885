import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';

export interface PaginationUrlOptions {
  init?: object | null;
}

/**
 * Fornisce l'oggetto query indicato nella querystring dell'URL.
 * Insieme a `setPaginationUrlQuery` permette la gestione delle query da URL
 * anziché come stato del componente, garantendo il mantenimento dei filtri
 * attraverso la navigazione della cronologia.
 * @param options opzioni aggiuntive (se presenti nella querystring, verranno sovrascritte)
 */
export function getPaginationUrlQuery(
  querystring: string,
  options?: any | null
) {
  return {
    ...(options ?? {}),
    ...qs.parse(querystring, { ignoreQueryPrefix: true })
  };
}

/**
 * Imposta la querystring dell'URL con le informazioni passate come query.
 * Insieme a `getPaginationUrlQuery` permette la gestione delle query da URL
 * anziché come stato del componente, garantendo il mantenimento dei filtri
 * attraverso la navigazione della cronologia.
 * @param input query che verrà inserita nell'URL
 */
export function setPaginationUrlQuery<T>(
  props: Pick<RouteComponentProps<any>, 'history'>,
  input: T
) {
  props.history.push(
    props.history.location.pathname +
      qs.stringify(input, {
        addQueryPrefix: true,
        skipNulls: true
      })
  );
}
