import { FileDoneOutlined } from '@ant-design/icons';
import { Menu, message } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { WebSocketKind } from 'client/components/digital-signature/DigitalSignatureProvider';
import { JnlpService } from 'client/components/schema/task/jnlp/JnlpService';
import {
  IStandardSignJnlpWindowManagerHandle,
  StandardSignJnlpWindowManager
} from 'client/components/schema/task/jnlp/StandardSignJnlpWindowManager';
import { appConfig } from 'client/core/appConfig';
import { TaskActionLogic } from 'client/logic/TaskActionLogic';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import React, { useCallback, useEffect } from 'react';

export interface IMassiveStandardSignMenuItemProps {
  tasks: AttivitaDTO[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MassiveStandardSignMenuItem(
  props: IMassiveStandardSignMenuItemProps
) {
  const { tasks, setLoading, ...menuProps } = props;
  const currentUser = useCurrentUser();
  const [objectUrl, setObjectUrl] = React.useState<string | null>(null);
  const signManager = JnlpService.useDocumentMassiveSignManager();

  useEffect(() => {
    return () => {
      if (objectUrl) {
        window.URL.revokeObjectURL(objectUrl);
      }
    };
  }, [objectUrl]);

  const jnlpWindowRef =
    React.useRef<IStandardSignJnlpWindowManagerHandle>(null);

  const openModal = useCallback(
    (objectUrl: string) => {
      // Apriamo la modal
      jnlpWindowRef.current?.open(objectUrl);
    },
    [jnlpWindowRef.current?.open]
  );

  const disabled = tasks.some(
    task => !TaskActionLogic.isAssigned(task, currentUser)
  );

  return (
    <>
      <Menu.Item
        key="release"
        disabled={disabled}
        onClick={async () => {
          try {
            let result = await signManager.getJnlp(
              tasks.map(t => t.idTaskInstance!)
            );

            if (!result) {
              throw new Error('Impossibile effettuare il download');
            }

            if (appConfig.useFvProxy) {
              result = JnlpService.setFvProxy(result, WebSocketKind.Sign);
            }

            const resultObjectUrl = JnlpService.getObjectUrl(result);

            // Rimuovo l'URL precedente ed imposto il nuovo
            if (objectUrl) {
              JnlpService.revokeObjectUrl(objectUrl);
            }
            setObjectUrl(resultObjectUrl);

            // Apro il file
            JnlpService.downloadFile(resultObjectUrl, 'firmaManager.jnlp');

            // Apriamo la modal
            openModal(resultObjectUrl!);
          } catch (e: any) {
            if (
              e.response.data.status === 404 &&
              e.response.data.error === 'Not Found'
            ) {
              message.error(`Le attività selezionate non presentano nessun documento da firmare.`); // prettier-ignore
            } else {
              message.error(`Si è verificato un errore durante l'apertura dell'applicativo.`); // prettier-ignore
            }
            console.error(e);
          }
        }}
        {...menuProps}
      >
        <IconWrapper icon={<FileDoneOutlined />} /> Firma con dispositivo
      </Menu.Item>
      <StandardSignJnlpWindowManager ref={jnlpWindowRef} />
    </>
  );
}
