import {
  Dichiarazione,
  DocumentoSenzaAzioni,
  GruppoAllegati
} from 'client/api/backend/schemas';
import { DeclarationLogic } from 'client/components/schema/task/declaration/DeclarationLogic';
import { DocumentLogic } from 'client/components/schema/task/document/DocumentLogic';
import { StateAnchor } from 'client/ui/anchor/StateAnchor';
import React from 'react';
import { ProcedureAttachmentAnchorLink } from './ProcedureAttachmentAnchorLink';
import { ProcedureDeclarationAnchorLink } from './ProcedureDeclarationAnchorLink';
import { ProcedureDocumentAnchorLink } from './ProcedureDocumentAnchorLink';

export interface IProcedureStateAnchorsProps {
  documents: DocumentoSenzaAzioni[] | undefined;
  declarations: Dichiarazione[] | undefined;
  attachmentGroups: GruppoAllegati[] | undefined;
}

export function ProcedureStateAnchors(props: IProcedureStateAnchorsProps) {
  const { documents, declarations, attachmentGroups } = props;
  return (
    <StateAnchor offsetTop={234}>
      {declarations?.map((declaration, index) => (
        <ProcedureDeclarationAnchorLink
          declaration={declaration}
          key={DeclarationLogic.getKey(declaration)}
        />
      ))}
      {attachmentGroups?.map(attachments => (
        <ProcedureAttachmentAnchorLink attachments={attachments} />
      ))}

      {documents?.map((document, index) => (
        <div key={index}>
          <ProcedureDocumentAnchorLink
            document={document}
            key={DocumentLogic.getKey(document)}
          />
          {document.dichiarazioni?.map((declaration, index) => (
            <ProcedureDeclarationAnchorLink
              declaration={declaration}
              key={DeclarationLogic.getKey(declaration)}
            />
          ))}
          {document?.gruppoAllegati?.map(attachments => (
            <ProcedureAttachmentAnchorLink attachments={attachments} />
          ))}
        </div>
      ))}
    </StateAnchor>
  );
}
