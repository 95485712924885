import { Menu } from 'antd';
import { InviaDocumentoFirmatoParams } from 'client/api/backend/schemas';
import React, { useState } from 'react';
import { UploadSignDocumentModal } from './UploadSignDocumentModal';

export interface IUploadSignDoumentActionProps {
  taskId: string;
  documentId: string;
  params: InviaDocumentoFirmatoParams;
}

export function UploadSignDoumentAction(props: IUploadSignDoumentActionProps) {
  const { taskId, documentId, params, ...otherProps } = props;
  const [visible, setVisible] = useState(false);

  return (
    <>
      <UploadSignDocumentModal
        taskId={taskId}
        documentId={documentId}
        visible={visible}
        params={params}
        hide={() => {
          setVisible(false);
        }}
      />
      <Menu.Item
        {...otherProps}
        onClick={() => {
          setVisible(true);
        }}
      >
        Carica documento firmato
      </Menu.Item>
    </>
  );
}
