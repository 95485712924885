import React from 'react';
import styled from 'styled-components';
import { Space, Typography } from 'antd';
const { Title, Paragraph } = Typography;
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { ProcedureListCard } from './detail/ProcedureListCard';
import { useGetTipiProcedimentoPerUtente } from 'client/api/backend/procedimenti/procedimenti';

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

export interface PProcedureTypeListPageProps extends RouteComponentProps {}

/**
 * Pagina Home / dettaglio Tipi di Procedimento.
 */
export function ProcedureTypeListPage(props: PProcedureTypeListPageProps) {
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Tipologie di Procedimenti'
  });

  return (
    <CorePageLayout>
      <PageHeading
        breadcrumb={<BreadcrumbsContainer />}
        title={'Tipologie di Procedimenti'}
      />
      <PageContent>
        <FullWidthSpace direction="vertical" size={24}>
          <ProcedureListCard />
        </FullWidthSpace>
      </PageContent>
    </CorePageLayout>
  );
}
