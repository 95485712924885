import {
  AzioniDichiarazione,
  AzioniDocumento
} from 'client/api/backend/schemas';
import { outcomeValidator } from 'client/routes/task/single/actions/outcomeValidator';
import { yup } from 'common/validation/initYup';
import { createDocumentsValidator } from '../document/validation/createDocumentsValidator';
import { createDeclarationsValidator } from '../declaration/validation/createDeclarationValidator';
import { createAttachmentGroupValidator } from '../../attachments/validator/createAttachmentGroupsValidator';

/**
 * Genera il validatore per una task a partire dal set di dichiarazioni contenuto in un task
 */
export function createTaskValidator(data: {
  declarations: AzioniDichiarazione[] | null | undefined;
  documents: AzioniDocumento[] | null | undefined;
  attachments: AzioniDocumento[] | null | undefined;
}) {
  const { declarations, documents, attachments } = data;
  if (!declarations) return null;
  if (!documents) return null;

  // Crea il validatore di tutte le dichiarazioni unendo i valdatori dei singoli field
  const declarationValidators = createDeclarationsValidator(declarations);
  const documentValidator = createDocumentsValidator(documents);
  const attachmentsValidator = createAttachmentGroupValidator(attachments);

  return yup
    .object({
      ...declarationValidators,
      ...documentValidator,
      ...attachmentsValidator,
      ...outcomeValidator
    })
    .noUnknown()
    .required();
}

/**
 * Rende ricorsivamente non required i vari elementi del validatore
 */
export function getPartialValidator(data: {
  declarations: AzioniDichiarazione[] | null | undefined;
  documents: AzioniDocumento[] | null | undefined;
  attachments: AzioniDocumento[] | null | undefined;
}) {
  const { declarations, documents, attachments } = data;
  if (!declarations) return null;
  if (!documents) return null;

  // Crea il validatore di tutte le dichiarazioni unendo i valdatori dei singoli field
  const declarationValidators = createDeclarationsValidator(declarations, true);
  const documentValidator = createDocumentsValidator(documents, true);
  const attachmentsValidator = createAttachmentGroupValidator(attachments, true); // prettier-ignore

  return yup
    .object({
      ...declarationValidators,
      ...documentValidator,
      ...attachmentsValidator
    })
    .noUnknown()
    .required();
}
