import React, { useState } from 'react';

export interface MenuItemsProps {
  menuKey: string;
  to: string;
  children: string;
}

/**
 * Centralizzato elementi del menu principale per gestire la versione mobile e desktop.
 */
export function getMainMenuItems(): MenuItemsProps[] {
  return [
    {
      menuKey: 'home',
      to: '/home',
      children: 'Home'
    },
    {
      menuKey: 'procedure',
      to: '/procedure/list',
      children: 'Procedimenti'
    },
    {
      menuKey: 'task',
      to: '/task/list',
      children: 'Attività'
    }
  ];
}
