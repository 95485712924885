import { DeclarationAction } from 'client/components/schema/declaration/actions/DeclarationActions';
import { IOutcomeConstraints } from 'client/components/schema/outcome/useOutcomes';
import {
  DeclarationFieldDto,
  DeclarationFieldType
} from 'common/dto/declaration/DeclarationFieldDto';
import { yup } from 'common/validation/initYup';
import { minBy } from 'lodash';

declare module 'yup' {
  interface StringSchema {
    /**
     * Permette a una stringa di essere validata condizionalmente
     */
    conditionals(
      field: DeclarationFieldDto,
      declarationId: string,
      isEditable: boolean
    ): StringSchema;
  }

  interface NumberSchema {
    /**
     * Permette a un numero di essere validato condizionalmente
     */
    conditionals(
      field: DeclarationFieldDto,
      declarationId: string,
      isEditable: boolean
    ): NumberSchema;
  }

  interface DateSchema {
    /**
     * Permette a una data di essere validata condizionalmente
     */
    conditionals(
      field: DeclarationFieldDto,
      declarationId: string,
      isEditable: boolean
    ): DateSchema;
  }

  interface BooleanSchema {
    /**
     * Permette a un booleano di essere validato condizionalmente
     */
    conditionals(
      field: DeclarationFieldDto,
      declarationId: string,
      isEditable: boolean
    ): BooleanSchema;
  }

  interface NotRequiredArraySchema<T> {
    /**
     * Permette a un array di essere validato condizionalmente
     */
    conditionals(
      field: DeclarationFieldDto,
      declarationId: string,
      isEditable: boolean
    ): NotRequiredArraySchema<T>;
  }
}

/**
 * Crea la validazione `required` in base al tipo di schema
 */
function makeRequired(
  schema: yup.AnySchema<any>,
  fieldType: DeclarationFieldType
) {
  // Per i booleani
  if (schema.type === 'boolean' && fieldType !== DeclarationFieldType.Checkbox)
    return schema.required().oneOf([true], 'Il campo ${path} è obbligatorio');

  // Per i campi Radio input
  if (fieldType === DeclarationFieldType.Radio) {
    return schema.required('Il campo è obbligatorio');
  }
  // Tutti gli altri
  return schema.required();
}

yup.addMethod(
  yup.mixed,
  'conditionals',
  function (
    this: yup.AnySchema,
    field: DeclarationFieldDto,
    declarationId: string,
    isEditable: boolean
  ) {
    if (!isEditable) {
      return this.notRequired();
    }

    // Altrimenti impostiamo la condizione con Yup
    return this.when(
      [
        '$values',
        '$outcomesConstraints',
        field.campo_collegato ?? 'this-is-not-an-id'
      ],
      // @ts-ignore Problema col tipo della funzione
      // https://github.com/jquense/yup/issues/1529
      (
        values: any,
        constraints: IOutcomeConstraints,
        linkedValue: any,
        schema: yup.AnySchema<any>
      ) => {
        // Controllo se l'outcome mi richiede che sia required
        let outcomeId = values?.outcome?.id;

        // Se l'outcome non è selezionato, allora utilizzo di default quello con priorità maggiore
        if (!outcomeId) {
          outcomeId = getDefaultOutcomeKey(constraints);
        }

        const actions = (constraints[outcomeId]?.declarations?.[
          declarationId
        ] ?? []) as DeclarationAction[];

        /**
         * Se il campo è forzato specificatamente a non visibile dal BE,
         * lo nascondiamo dallo switch e lo rendiamo non richiesto.
         * In questo modo, a differenza di strip, non perdiamo i dati precedentemente salvati.
         */
        if (field.visibile === false) {
          return schema.notRequired();
        }

        // Se non c'è il campo collegato, allora dipende dal controllo
        if (!field.campo_collegato) {
          if (field.controllo === 'O') {
            // Se non ci sono azioni sulla dichiarazione, è da considerarsi come opzionale
            if (actions.length === 0) return schema.notRequired();
            return makeRequired(schema, field.tipo);
          }
          return schema;
        }

        const isVisible = isFieldVisible(field, linkedValue);
        if (isVisible && field.controllo === 'O') {
          // Se non ci sono azioni sulla dichiarazione, è da considerarsi come opzionale
          if (actions.length === 0) return schema.notRequired();
          return makeRequired(schema, field.tipo);
        }
        if (!isVisible) return schema.strip();
        return schema;
      }
    );
  }
);

function isFieldVisible(field: DeclarationFieldDto, linkedValue: any): boolean {
  // I booleani sono indicati nel `val_campo_collegato` come "1".
  // TODO: Da rivedere nelle checkbox
  const normalizedLinkedValue =
    linkedValue === true ? field.val_campo_collegato : linkedValue;

  if (normalizedLinkedValue !== field.val_campo_collegato) {
    return false;
  }

  // Se il campo è _specificatamente_ impostato come nascosto, allora non lo mostriamo
  return field.visibile !== false;
}

/**
 * L'esito di deafult è quello con priorità minore (= maggiore importanza)
 */
function getDefaultOutcomeKey(constraints: IOutcomeConstraints) {
  const priorityOutcome = minBy(
    Object.keys(constraints).map(key => ({
      key,
      priority: constraints[key].priority
    })),
    c => c.priority
  );
  return priorityOutcome?.key;
}
