import React from 'react';
import { Popconfirm } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { Icon24px } from 'client/routes/task/single/documents/file/FileRow';

export interface IconConfirmProps extends PopconfirmProps {
  icon?: React.ReactElement;
  okDanger?: boolean;
  popIcon?: React.ReactElement;
  handleClick?: () => void;
}

/**
 * Popup di conferma con icona
 */
export function IconConfirm(props: IconConfirmProps) {
  const { placement, icon, okDanger, popIcon, handleClick, ...otherProps } =
    props;

  return (
    <Popconfirm
      placement={placement ? placement : 'top'}
      {...otherProps}
      {...(popIcon ? { icon: popIcon } : {})}
      okButtonProps={{
        type: 'primary',
        danger: okDanger == null ? true : okDanger
      }}
    >
      <a>
        <Icon24px icon={icon} onClick={handleClick} />
      </a>
    </Popconfirm>
  );
}
