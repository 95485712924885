import * as React from 'react';
import { TaskCard } from 'client/ui/card/TaskCard';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { LoadingCard } from '../cards/LoadingCard';
import { ErrorCard } from '../cards/ErrorCard';
import { DeclarationSection } from './DeclarationSection';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { IDeclarationsResult } from 'client/components/schema/declaration/useDeclarations';
import { diff } from 'deep-diff';
import { IAttachmentResult } from 'client/components/schema/attachments/useAttachments';
import { AttachmentGroupSection } from '../documents/AttachmentGroupSection';
import { AttivitaDTO } from 'client/api/backend/schemas';

export const DeclarationWrapper = styled.div`
  & + & {
    margin-top: 24px;
  }
`;

export interface TaskDeclarationsProps {
  task: AttivitaDTO | null;
  result: IDeclarationsResult;
  attachments: IAttachmentResult;
  isTaskAssigned: boolean;
}

// const declaration: DeclarationFieldDto[] = require('client/routes/task/fixtures/dichiarazioni-F31.json');

export function TaskDeclarations(props: TaskDeclarationsProps) {
  const { result, declarations } = props.result;

  const formik = useFormikContext<any>();

  // Calcola se ci sono differenze tra gli initial values e il formik
  // Utile in sviluppo per vedere quando e come il formik diventa dirty
  if (process.env.NODE_ENV === 'development') {
    const differenze = diff(formik.values, formik.initialValues);

    console.log(`[TaskDeclaration] Differenze tra formik e initial values: `, {
      formik: formik.values,
      ini: formik.initialValues,
      differenze
    });
  }

  if (result.isError) {
    return (
      <ErrorCard
        message={
          'Si è verificato un errore durante il recupero della dichiarazione.'
        }
        description={getNetworkErrorMessage(result.error ?? '')}
      />
    );
  }

  if (result.isLoading || !declarations) {
    return <LoadingCard />;
  }

  // Se non ci sono dichiarazioni né allegati, non mostriamo nulla
  if (declarations.length === 0 && props.attachments.attachments.length === 0) {
    return null;
  }

  return (
    <TaskCard>
      {declarations.map((declaration, index) => (
        <DeclarationWrapper key={index}>
          <DeclarationSection
            declaration={declaration}
            key={index}
            isTaskAssigned={props.isTaskAssigned}
          />
        </DeclarationWrapper>
      ))}
      {/*  Sezione Gruppo allegati */}
      {props.attachments.attachments.map((group, index) => (
        <AttachmentGroupSection
          key={index}
          group={group}
          task={props.task}
          name={null}
        />
      ))}
    </TaskCard>
  );
}
