import { List } from 'antd';
import { Allegato } from 'client/api/backend/schemas';
import { AttachmentsTypeName } from 'client/routes/task/single/documents/AttachmentSection';
import { ReadonlyFileRow } from 'client/routes/task/single/documents/file/ReadonlyFileRow';
import { IDocumentFile } from 'client/ui/form/input/UploadInput';
import React from 'react';

export interface IProcedureAttachmentSectionProps {
  attachment: Allegato;
  procedureId: string;
}

export function ProcedureAttachmentSection(
  props: IProcedureAttachmentSectionProps
) {
  const { attachment, procedureId } = props;

  const files: IDocumentFile[] =
    attachment.files?.map(file => ({
      description: file.descrizione,
      file: {
        id: file.idFile,
        name: file.nomeFile ?? 'documento.pdf',
        size: file.dimensione!,
        ownerFiscalId: file.idUtenteCreazione!,
        office: file.idUfficioCreazione!
      }
    })) ?? [];

  // Nascondo la lista se non ci sono files
  if (files.length === 0) return null;

  return (
    <>
      <AttachmentsTypeName>
        {attachment.denominazioneAllegato}
      </AttachmentsTypeName>
      <List
        size="small"
        bordered
        dataSource={files}
        renderItem={item => (
          <List.Item>
            <ReadonlyFileRow item={item} procedureId={procedureId} />
          </List.Item>
        )}
      />
    </>
  );
}
