import * as React from 'react';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useAuth, AuthActions, useCurrentUser } from '../AuthModule';
import { useDispatch } from 'react-redux';
import { UserLogic } from 'common/schema/user/UserLogic';
import { LogoutOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { logger } from 'client/core/logger/logger';
import { resolveApiPath } from 'client/core/appConfig';
import { Menu } from 'antd';
import styled from 'styled-components';
import { useCambiaUfficioRuolo } from 'client/api/auth/sessione-controller/sessione-controller';

export interface ILogoutRoleButtonProps {}

/**
 * Gestisce il logout dell'utente corrente dall'ufficio, in modo tale
 * che sia possibile cambiarlo.
 */
export function LogoutRoleButton(props: ILogoutRoleButtonProps) {
  const auth = useAuth();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const logoutRole = useCambiaUfficioRuolo();

  if (!auth.logged) return null;

  const handleLogoutRole = async () => {
    await logoutRole.mutateAsync();
    await dispatch(AuthActions.logout());
  };

  return (
    <Menu.Item {...props} key="logout-role" icon={<UserSwitchOutlined />}>
      <a onClick={handleLogoutRole}>Cambia Ufficio o Ruolo</a>
    </Menu.Item>
  );
}
