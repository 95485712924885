import {
  getGetNotificheQueryKey,
  getNotifiche,
  useGetNotifiche
} from 'client/api/backend/notifiche/notifiche';
import {
  GetNotificheParams,
  GetNotificheSortDirection,
  GetNotificheSortField
} from 'client/api/backend/schemas';
import { useInterval } from 'client/core/hooks/useInterval';
import { queryClient } from 'client/core/network/queryClient';
import { useCallback, useEffect, useState } from 'react';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_NOTIFICATION_FLAG_MS_DELAY = 120 * 1000;
const NOTIFICATION_FLAG_QUERY = {
  flVista: false,
  sortField: GetNotificheSortField.DATA_NOTIFICA,
  sortDirection: GetNotificheSortDirection.DESC,
  firstResult: 0,
  maxResults: 1
};

/**
 * Hook che ritorna le notifiche utente.
 * Le notifiche vengono riaggiornate ogni `delay` millisecondi.
 * Se stop è true, le notifiche non vengono aggiornate
 */
export function useNotification(query: GetNotificheParams) {
  const apiResult = useGetNotifiche(query, {
    query: {
      keepPreviousData: true
    }
  });
  const notificationUpdate = useNotificationUpdate();

  // Invalido la key e refetch delle notifiche ad intervalli regolari
  const refetch = useCallback(() => {
    const queryKey = getGetNotificheQueryKey(query);
    queryClient.invalidateQueries(queryKey);
  }, [query]);

  const notifications = apiResult.data?.data?.list;

  const pageSize = query.maxResults ?? DEFAULT_PAGE_SIZE;
  const pageNumber =
    Math.floor((query.firstResult ?? 0) / query.maxResults!) + 1;

  const meta = {
    pageSize,
    pageNumber,
    total: apiResult.data?.data?.totalRecs
  };

  return {
    apiResult,
    notifications,
    notificationUpdate,
    meta,
    refetch
  };
}

/**
 * Gestisce il meccanismo che indica quando c'è almeno una nuova notifica non letta.
 * Salva le informazioni nel localStorage così che siano sempre disponibili.
 */
function useNotificationUpdate() {
  //  Seleziono solo l'ultima notifica creata
  const [unread, setUnread] = useState(false);

  const apiResult = useGetNotifiche(NOTIFICATION_FLAG_QUERY);
  const notification = apiResult.data?.data?.list?.[0];
  //const unread = localStorage.getItem(READ_NOTIFICATION_STORAGE_KEY) === '1';

  // Resetto il flag di unread per non mostrare più il badge
  const resetUnreadFlag = useCallback(async () => {
    const result = await getNotifiche(NOTIFICATION_FLAG_QUERY);
    setUnread(!!result.data?.list?.[0]);
  }, [localStorage]);

  // Invalido la key e refetch delle notifiche ad intervalli regolari
  useInterval(() => {
    apiResult.refetch();
  }, DEFAULT_NOTIFICATION_FLAG_MS_DELAY);

  // Ad ogni aggiornamento della notifica, aggiorno coerentemente anche il flag
  useEffect(() => {
    setUnread(!!notification);
  }, [notification]);

  return {
    unreadFlag: unread,
    resetUnreadFlag
  };
}
