import { Alert } from 'antd';
import {
  useGetUtentiCandidati,
  useGetUtentiCandidatiMassivo
} from 'client/api/backend/attivita/attivita';
import { UtenteType } from 'client/api/backend/schemas';
import { logger } from 'client/core/logger/logger';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import React, { useEffect, useState } from 'react';

function mapOptions(users: UtenteType[] | undefined) {
  if (!users) return [];
  return users.map(user => ({
    value: user.codiceFiscale,
    label: `${user.denominazione} (${user.codiceFiscale})`
  }));
}

export interface IAssegneeUserAsyncSelectProps {
  name: string;
  taskId: string | string[];
  disabled?: boolean;
}

export function AssegneeUserAsyncSelect(props: IAssegneeUserAsyncSelectProps) {
  const { name, disabled, taskId } = props;

  const usersRequest = useGetUtentiCandidatiMassivo();

  const [users, setUsers] = useState<UtenteType[]>([]);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const users = await usersRequest.mutateAsync({
          data: {
            idTaskInstanceList: Array.isArray(taskId) ? taskId : [taskId]
          }
        });
        setUsers(users.data ?? []);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [taskId]);

  if (error) {
    logger.error(`[AssegneeUserAsyncSelect] Error: ${error}`);
    return (
      <Alert
        message="Error"
        description="Impossibile recuperare la lista degli utenti"
        showIcon
        type="error"
      />
    );
  }

  return (
    <FormFieldItem
      label="Utente"
      name={name}
      component={SelectInput}
      loading={loading}
      options={mapOptions(users)}
      disabled={disabled}
      showSearch
      filterOption={(input: string, option: any) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
    />
  );
}
