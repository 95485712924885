import { Certificato, WSEsternoType } from 'client/api/backend/schemas';
import { omit } from 'lodash';
import { useCallback, useReducer } from 'react';
import { getAllOtpFieldsNames } from '../provider-parameters/getAllOtpFieldsNames';
import {
  ProviderParameters,
  RemoteSignType
} from '../provider-parameters/ProviderParameters';
import { ProviderParameterService } from '../provider-parameters/ProviderParameterService';
import {
  IRemoteSignAction,
  IRemoteSignErrorBag,
  RemoteSignAction
} from './RemoteSignActions';

export interface IRemoteSignState {
  provider?: ProviderParameters | null;
  signType?: RemoteSignType | null;
  // Certificato selezionato
  certificate?: Certificato | null;
  // Certificati disponibili
  certificates?: Certificato[];
  dynamicValues?: {
    [key: string]: string;
  };
  currentStep?: RemoteSignStep;
  errorBag?: IRemoteSignErrorBag;
}

export enum RemoteSignStep {
  /** Primo step in cui si sceglie il provider e si compila il form per i certificati */
  Provider = 'provider',
  /** Secondo step, opzionale, in cui si sceglie il certificato e si compila il form per la firma */
  Certificate = 'certificate',
  /** Step finale in cui si mostra il risultato della firma */
  Signing = 'signing'
}

function remoteSignReducer(
  state: IRemoteSignState,
  action: IRemoteSignAction
): IRemoteSignState {
  switch (action.type) {
    case RemoteSignAction.SetProvider:
      // Non passo lo stato per reimpostare tutti i valori
      return {
        currentStep: RemoteSignStep.Provider,
        provider: action.payload.provider
      };
    case RemoteSignAction.SetCertificate:
      return {
        ...state,
        certificate: action.payload.certificate
      };
    case RemoteSignAction.SetCertificates:
      return {
        ...state,
        certificates: action.payload.certificates
      };
    case RemoteSignAction.SetSignType:
      return {
        ...state,
        signType: action.payload.signType
      };
    case RemoteSignAction.SetDynamicValues:
      return {
        ...state,
        dynamicValues: action.payload.dynamicValues
      };
    case RemoteSignAction.SetCurrentStep:
      return {
        ...state,
        currentStep: action.payload.currentStep
      };
    case RemoteSignAction.Reset:
      return { currentStep: RemoteSignStep.Provider };
    case RemoteSignAction.SetError:
      return { ...state, errorBag: action.payload.errorBag };
    default:
      return state;
  }
}

export function useRemoteSignReducer() {
  const [state, dispatch] = useReducer(remoteSignReducer, {
    currentStep: RemoteSignStep.Provider
  });

  const setCurrentStep = useCallback((target: RemoteSignStep) => {
    return dispatch({
      type: RemoteSignAction.SetCurrentStep,
      payload: { currentStep: target }
    });
  }, []);

  const setCertificate = useCallback(
    (certificate: Certificato | null | undefined) => {
      return dispatch({
        type: RemoteSignAction.SetCertificate,
        payload: { certificate }
      });
    },
    []
  );

  const setCertificates = useCallback((certificates: Certificato[]) => {
    return dispatch({
      type: RemoteSignAction.SetCertificates,
      payload: { certificates }
    });
  }, []);

  const setProvider = useCallback(
    (provider: WSEsternoType | null | undefined) => {
      if (!provider) {
        return dispatch({
          type: RemoteSignAction.SetProvider,
          payload: { provider: null }
        });
      }
      const parsed = ProviderParameterService.parse({
        name: provider.denominazione,
        id: provider.idWSEsterno,
        params: provider.parametri
      });

      return dispatch({
        type: RemoteSignAction.SetProvider,
        payload: { provider: parsed }
      });
    },
    []
  );

  const setSignType = useCallback(
    (signType: RemoteSignType | null | undefined) => {
      return dispatch({
        type: RemoteSignAction.SetSignType,
        payload: { signType }
      });
    },
    []
  );

  const setDynamicValues = useCallback(
    (dynamicValues: { [key: string]: string }) => {
      const otpFieldNames = getAllOtpFieldsNames(state.provider);

      // Rimuovo i campi otp dallo stato, in quanto non vanno salvati
      const updatedDynamicValues = omit(dynamicValues, otpFieldNames);

      return dispatch({
        type: RemoteSignAction.SetDynamicValues,
        payload: { dynamicValues: updatedDynamicValues }
      });
    },
    [state.provider]
  );

  const setErrors = useCallback((errorBag: IRemoteSignErrorBag) => {
    return dispatch({
      type: RemoteSignAction.SetError,
      payload: { errorBag }
    });
  }, []);

  return {
    state,
    dispatch,
    handlers: {
      setCurrentStep,
      setCertificate,
      setCertificates,
      setProvider,
      setSignType,
      setDynamicValues,
      setErrors
    }
  };
}

export type IRemoteSignHandlers = ReturnType<
  typeof useRemoteSignReducer
>['handlers'];
