import { Button } from 'antd';
import {
  Documento,
  GetDocumentoFirmatoParams
} from 'client/api/backend/schemas';
import { DocumentLogic } from 'client/components/schema/task/document/DocumentLogic';
import { appConfig } from 'client/core/appConfig';
import { joinPath } from 'client/core/network/joinPath';
import QueryString from 'qs';
import React from 'react';

export interface IDocumentViewSignedContentProps {
  taskId: string;
  params: GetDocumentoFirmatoParams;
  document: Documento | undefined;
  buttonText: string;
}

export function DocumentViewSignedContent(
  props: IDocumentViewSignedContentProps
) {
  const { taskId, params, document, buttonText } = props;
  if (!document || !DocumentLogic.isSigned(document, params)) return null;
  return (
    <a
      target="_blank"
      href={joinPath([
        appConfig.remotePath,
        appConfig.apiBackendPath,
        '/attivita/' +
          taskId +
          '/documenti/' +
          document.idDocumento +
          `/contenutoFirmato` +
          '?' +
          QueryString.stringify(params)
      ])}
    >
      <Button size="large" type="primary" children={buttonText} />
    </a>
  );
}
