import { FaseDTO } from 'client/components/schema/procedures/faseDTO';
import { apiClient } from '../../../core/network/mutators/apiClient';

export const ProcedureApi = {
  /** Lista delle fasi disponibili delle pratiche */
  listPhases: () =>
    apiClient.get<FaseDTO[]>(
      `/api/suape-gestione-praticaBDU/services/tipo/statoPratica/options/`
    )
};
