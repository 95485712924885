import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Badge, Drawer, Pagination, Space } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { MdNotifications } from 'react-icons/md';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { NotificationList } from 'client/components/schema/notification/NotificationList';
import { useNotification } from 'client/routes/notification/useNotification';
import {
  GetNotificheSortDirection,
  GetNotificheSortField
} from 'client/api/backend/schemas';
import { down } from 'styled-breakpoints';
import { NotificationFilterSwitch } from 'client/routes/notification/NotificationFilterSwitch';

// TODO Sostituire con il componente dedicato
const MenuButtonIcon = styled(ButtonIcon)`
  display: flex;
  flex: 0 0 auto;
  min-width: 0;
  justify-content: center;
  margin-right: 20px;
  color: ${props => props.theme.textColorSecondary};
  &:active,
  &:focus {
    color: ${props => props.theme.textColorSecondary};
  }
`;

const NotificationsDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 600px !important;
    max-width: 70%;
    ${down('sm')} {
      width: 100% !important;
      max-width: 100%;
    }
  }

  .ant-drawer-header {
    padding: 10px;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-title {
    line-height: 1.3;
  }
`;

export interface NotificationsButtonProps extends Omit<ButtonProps, 'type'> {}

export function NotificationsButton(props: NotificationsButtonProps) {
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  // true: lette; false: da leggere; undefined: tutte
  const [filterToRead, setFilterToRead] = useState<boolean | undefined>(
    undefined
  );
  const PAGE_SIZE = 10;

  const query = {
    firstResult: (page - 1) * PAGE_SIZE,
    maxResults: PAGE_SIZE,
    sortField: GetNotificheSortField.DATA_NOTIFICA,
    sortDirection: GetNotificheSortDirection.DESC,
    flVista: filterToRead
  };

  const { apiResult, notifications, notificationUpdate, meta, refetch } =
    useNotification(query);

  // Evito di azzerarlo quando cambio pagina
  useEffect(() => {
    if (meta.total == null) return;
    setTotal(meta.total);
  }, [meta.total]);

  const handleOpen = () => {
    refetch();
    setVisible(true);
  };

  const handleClose = () => {
    notificationUpdate.resetUnreadFlag();
    setVisible(false);
    setPage(1);
  };

  return (
    <>
      <MenuButtonIcon
        icon={
          <Badge offset={[-2, 2]} dot={notificationUpdate.unreadFlag}>
            <MdNotifications size={22} />
          </Badge>
        }
        onClick={handleOpen}
      />

      <NotificationsDrawer
        title="Notifiche"
        placement="right"
        onClose={handleClose}
        visible={visible}
        extra={
          <Space>
            <NotificationFilterSwitch
              defaultChecked={filterToRead === false ? true : false}
              onChange={value => {
                // Seleziona solo quelle da leggere, altrimenti tutte
                setFilterToRead(value ? false : undefined);
                setPage(1);
              }}
            />

            {/* <Button>Segna tutte come lette</Button> */}
            <Pagination
              simple
              size="small"
              defaultCurrent={1}
              total={total}
              current={page}
              pageSize={PAGE_SIZE}
              onChange={page => {
                setPage(page);
              }}
            />
          </Space>
        }
        key="notificaion-drawer"
      >
        <NotificationList list={notifications} loading={apiResult.isLoading} />
      </NotificationsDrawer>
    </>
  );
}
