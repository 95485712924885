/**
 * Tipi di firma ammessi per la firma remota
 */
export enum RemoteSignType {
  Pades = 'PAdES',
  Cades = 'CAdES'
}

export enum ProviderInternalDynamicFieldType {
  String = 'string',
  Password = 'password',
  Otp = 'otp',
  Select = 'combo'
}

interface BaseProviderInternalField {
  nome: string;
  descrizione: string;
  tipo: ProviderInternalDynamicFieldType;
}

export interface ProviderInternalStringField extends BaseProviderInternalField {
  tipo: ProviderInternalDynamicFieldType.String;
}

export interface ProviderInternalPasswordField
  extends BaseProviderInternalField {
  tipo: ProviderInternalDynamicFieldType.Password;
}

export interface ProviderInternalOtpField extends BaseProviderInternalField {
  tipo: ProviderInternalDynamicFieldType.Otp;
}

export interface ProviderInternalSelectField extends BaseProviderInternalField {
  tipo: ProviderInternalDynamicFieldType.Select;
  opzioni: ProviderInternalSelectFieldOption[];
}

export type ProviderInternalSelectFieldOption = {
  valore: string;
  descrizione: string;
};

export type ProviderInternalDynamicField =
  | ProviderInternalStringField
  | ProviderInternalPasswordField
  | ProviderInternalOtpField
  | ProviderInternalSelectField;

export interface ProviderParameters {
  name: string;
  webserviceId: string;
  multiCertificate: boolean;
  requestOtp: boolean;
  signType: RemoteSignType[];
  otpAuthType: string | undefined;
  certificateParameters: ProviderInternalDynamicField[];
  remoteSignParameters: ProviderInternalDynamicField[];
  otpParameters: ProviderInternalDynamicField[];
}

/**
 * Mappatura dei codici dei parametri del provider,
 * necessari nel parsing.
 */
export enum ParameterKey {
  multiCertificate = 'multiCertificato',
  requestOtp = 'richiediOtp',
  signType = 'tipoFirma',
  otpAuthType = 'typeOtpAuth',
  certificateParameters = 'parametriInputListaCertificati',
  remoteSignParameters = 'parametriInputFirmaRemota',
  otpParameters = 'parametriInputOtp'
}
