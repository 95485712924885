import React, { useState } from 'react';
import styled from 'styled-components';
import { UserMenu } from './right-button/UserMenu';
import { Space } from 'antd';
import { NotificationsButton } from './right-button/NotificationsButton';
import { MainMenuMobile } from './MainMenuMobile';

const RightMenuWrapper = styled.div`
  max-width: 100%;
  min-width: 0;
  display: flex;
  justify-content: end;
`;

export interface RightSideMenuProps {
  isMobile: boolean | null;
}

export function RightMenu(props: RightSideMenuProps) {
  return (
    <RightMenuWrapper>
      {props.isMobile && <MainMenuMobile />}
      <NotificationsButton />
      <UserMenu />
    </RightMenuWrapper>
  );
}
