/**
 * Generato automaticamente.
 * Procedimenti
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  EsitoRispostaWS,
  PutDatiSessioneUtenteParams,
  LoginResponse,
  LoginRequest,
  DatiSessioneUtente
} from '.././schemas';
import { authApiInstance } from '../../../core/network/mutators/apiClient';
import type { ErrorType } from '../../../core/network/mutators/apiClient';

export const putDatiSessioneUtente = (params: PutDatiSessioneUtenteParams) => {
  return authApiInstance<EsitoRispostaWS>({
    url: `/session/seleziona-ufficio-ruolo`,
    method: 'put',
    params
  });
};

export type PutDatiSessioneUtenteMutationResult = NonNullable<
  Awaited<ReturnType<typeof putDatiSessioneUtente>>
>;

export type PutDatiSessioneUtenteMutationError = ErrorType<unknown>;

export const usePutDatiSessioneUtente = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putDatiSessioneUtente>>,
    TError,
    { params: PutDatiSessioneUtenteParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putDatiSessioneUtente>>,
    { params: PutDatiSessioneUtenteParams }
  > = props => {
    const { params } = props ?? {};

    return putDatiSessioneUtente(params);
  };

  return useMutation<
    Awaited<ReturnType<typeof putDatiSessioneUtente>>,
    TError,
    { params: PutDatiSessioneUtenteParams },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ricarica i dati di sessione utente senza scollegare l'utente. Necessario ad esempio per consentire all'utente un cambio di ruolo o di ufficio
 */
export const cambiaUfficioRuolo = () => {
  return authApiInstance<EsitoRispostaWS>({
    url: `/session/reload`,
    method: 'put'
  });
};

export type CambiaUfficioRuoloMutationResult = NonNullable<
  Awaited<ReturnType<typeof cambiaUfficioRuolo>>
>;

export type CambiaUfficioRuoloMutationError = ErrorType<unknown>;

export const useCambiaUfficioRuolo = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cambiaUfficioRuolo>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cambiaUfficioRuolo>>,
    TVariables
  > = () => {
    return cambiaUfficioRuolo();
  };

  return useMutation<
    Awaited<ReturnType<typeof cambiaUfficioRuolo>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};
export const login = (loginRequest: LoginRequest) => {
  return authApiInstance<LoginResponse>({
    url: `/session/login`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: loginRequest
  });
};

export type LoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof login>>
>;
export type LoginMutationBody = LoginRequest;
export type LoginMutationError = ErrorType<unknown>;

export const useLogin = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof login>>,
    { data: LoginRequest }
  > = props => {
    const { data } = props ?? {};

    return login(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const getDatiSessioneUtente = (signal?: AbortSignal) => {
  return authApiInstance<DatiSessioneUtente>({
    url: `/session`,
    method: 'get',
    signal
  });
};

export const getGetDatiSessioneUtenteQueryKey = () => [`/session`];

export type GetDatiSessioneUtenteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDatiSessioneUtente>>
>;
export type GetDatiSessioneUtenteQueryError = ErrorType<unknown>;

export const useGetDatiSessioneUtente = <
  TData = Awaited<ReturnType<typeof getDatiSessioneUtente>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDatiSessioneUtente>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDatiSessioneUtenteQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDatiSessioneUtente>>
  > = ({ signal }) => getDatiSessioneUtente(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getDatiSessioneUtente>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
