import * as React from 'react';
import { TabsProps, Tabs } from 'antd';
import styled from 'styled-components';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down, up } from 'styled-breakpoints';

const HeadingTabs = styled(Tabs)`
  .ant-tabs-nav {
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        padding: 0 24px;
        ${down('md')} {
          padding: 0 12px;
        }
      }
      + .ant-tabs-tab {
        margin: 0 0 0 8px;
      }
    }
  }
  .ant-tabs-extra-content {
    /* align-self: stretch; */
    ${up('sm')} {
      align-self: stretch;
    }
  }
  .ant-tabs-tab {
    .ant-page-header-footer & {
      ${down('sm')} {
        font-size: 20px;
      }
    }
  }
`;

export interface TabsPageHeadingProps extends TabsProps {}

export function TabsPageHeading(props: TabsPageHeadingProps) {
  return <HeadingTabs {...props} />;
}
