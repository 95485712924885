import { FileSearchOutlined } from '@ant-design/icons';
import { Button, ButtonProps, message, Upload, UploadProps } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { Allegato } from 'client/api/backend/schemas';
import { logger } from 'client/core/logger/logger';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { FormikValues, useField } from 'formik';
import React from 'react';
import { useFormikFormContext } from '../FormikFormContext';

export const B_TO_MB = 1024 * 1024;

export interface IDocumentFile {
  description?: string;
  file?: IDocumentFileInfo;
  integralPart?: boolean;
  omittedInPublish?: boolean;
}

export interface IDocumentFileInfo {
  id: string | undefined;
  name: string;
  size: number;
  ownerFiscalId: string;
  office: string | undefined;
}

export interface IUploadInputProps<T extends FormikValues>
  extends Omit<UploadProps, 'name'> {
  name: keyof T & string;
  buttonProps: ButtonProps;
  options: {
    dimMaxFile?: number;
    formatiAccettati?: string[];
    flFirmaObbligatoria?: boolean;
  };
}

/**
 * Componente per la gestione dell'upload del file.
 * Non invia il file al server, ma lo salva temporaneamente nel formik.
 */
export function UploadInput<T extends FormikValues>(
  props: IUploadInputProps<T>
) {
  const { name, buttonProps, options: attachment, ...uploadProps } = props;
  const [field, , helper] = useField<RcFile | undefined>(name);
  const { disabled } = useFormikFormContext();

  return (
    <Upload
      {...field}
      fileList={field.value ? [field.value] : []}
      {...uploadProps}
      disabled={disabled || props.disabled}
      onChange={(data: UploadChangeParam<UploadFile<any>>) => {
        if (data.file.status === 'removed') {
          helper.setValue(undefined);
          return;
        }
      }}
      beforeUpload={async (inputFile: RcFile) => {
        if (
          attachment.dimMaxFile &&
          attachment.dimMaxFile > 0 &&
          inputFile.size > attachment.dimMaxFile * B_TO_MB
        ) {
          logger.warn(`Il file ${inputFile.name} è troppo grande`);
          message.error(`Il file ${inputFile.name} è troppo grande. (Dimensione massima: ${attachment.dimMaxFile} MB)`); // prettier-ignore
          return false;
        }
        helper.setValue(inputFile);
        return false;
      }}
      maxCount={1}
      accept={attachment.formatiAccettati?.join(',')}
    >
      <Button {...buttonProps} disabled={disabled || props.disabled}>
        <IconWrapper icon={<FileSearchOutlined />} />
        Seleziona file{attachment.flFirmaObbligatoria ? ' firmato' : ''}
      </Button>
    </Upload>
  );
}
