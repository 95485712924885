import { DatiSessioneUtente } from 'client/api/auth/schemas';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { useField } from 'formik';
import React from 'react';
import { getOfficeLabel } from './LoginSelectRoleInput';

export function parseFullRoleList(user?: DatiSessioneUtente) {
  return (
    user?.utenteLoggato?.utenteUfficis
      ?.flatMap(
        office =>
          office.ruolos
            ?.map(role => ({
              office: getOfficeLabel(office),
              officeId: office.idUfficio!,
              role: role.denominazione,
              roleId: role.idRuolo!
            }))
            .filter(item => !!item)!
      )
      // Ordino per nome dell'ufficio e poi per nome del ruolo
      .sort(
        (role1, role2) =>
          role1.office.localeCompare(role2.office) ??
          role1.role.localeCompare(role2.role)
      ) ?? []
  );
}

export type officeRoleOptions = ReturnType<typeof parseFullRoleList>;

export interface ILoginSimpleSelectRoleFormProps {
  options: officeRoleOptions;
}

export function LoginSimpleSelectRoleForm(
  props: ILoginSimpleSelectRoleFormProps
) {
  const { options } = props;

  const [, , officeHelpers] = useField('codUfficio');
  const [, , roleHelpers] = useField('codRuolo');

  return (
    <SelectInputItem
      name="office_role"
      size="large"
      placeholder="Ruolo"
      options={options.map(option => ({
        className: 'full-text-options',
        label: `${option.office} - ${option.role}`,
        value: `${option.officeId}#&#${option.roleId}`
      }))}
      onChange={selected => {
        const values = selected?.toString().split('#&#');
        if (!values) return;
        officeHelpers.setValue(values[0]);
        roleHelpers.setValue(values[1]);
      }}
    />
  );
}
