import { isArray, mapValues } from 'lodash';
import { useMemo } from 'react';

export function useInitialTouched(values: { [key: string]: any }): {
  [key: string]: any;
} {
  const initialTouched = useMemo(() => {
    console.log('useInitialTouched', values);
    return createInitialTouched(values);
  }, [values]);

  return initialTouched;
}

export function createInitialTouched(values: { [key: string]: any }): {
  [key: string]: any;
} {
  const result = mapValues(values, value => {
    if (!value || (isArray(value) && value.length === 0)) return false;

    if (typeof value === 'object') {
      //  Se è un oggetto vuoto ed è a livello 0 (una dichiarazione) allora non la tocco
      if (Object.keys(value).length === 0) return false;

      //  Altrimenti chiamo ricorsivamente la funzionione
      return createInitialTouched(value);
    }

    // Tutti i valori sono altrimenti da toccare
    return true;
  });

  // Rimuovo tutti i path che non sono stati toccati
  removeFalseOrEmptyKeys(result);

  return result;
}

/**
 * Rimuove ricorsivamente tutte le chiavi "false" o gli oggetti vuoti.
 * Rimuove inoltre tutti gli oggetti che contengono solo chiavi "false".
 *
 * In questo modo rimangono solo i path che portano a valori "true".
 */
function removeFalseOrEmptyKeys(values: any) {
  Object.keys(values).forEach(key => {
    if (values[key] === false) {
      delete values[key];
    }
    if (typeof values[key] === 'object') {
      if (Object.keys(values[key]).length > 0) {
        removeFalseOrEmptyKeys(values[key]);
      }
      if (Object.keys(values[key]).length === 0) {
        delete values[key];
      }
    }
  });
}
