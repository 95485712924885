import React, { useState } from 'react';
import { Formik } from 'formik';
import { Card, Button, Typography, message } from 'antd';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useDispatch } from 'react-redux';
import { AuthActions, useCurrentUser } from '../AuthModule';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import styled from 'styled-components';
import { usePutDatiSessioneUtente } from 'client/api/auth/sessione-controller/sessione-controller';
import { PutDatiSessioneUtenteParams } from 'client/api/auth/schemas';
import { yup } from 'common/validation/initYup';
import { logger } from 'client/core/logger/logger';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { LoginSelectRoleInput } from './LoginSelectRoleInput';
import {
  LoginSimpleSelectRoleForm,
  parseFullRoleList
} from './LoginSimpleSelectRoleInput';
import { LoginCard } from '../login/LoginCard';

interface Props {}

const selectRoleValidator = yup
  .object({
    codRuolo: yup.string().required().label('Ruolo'),
    codUfficio: yup.string().required().label('Ufficio')
  })
  .noUnknown();

/**
 * Form di selezione Ufficio e Ruolo per confermare la sessione.
 * Senza queste due selezioni, di fatto è come non essere loggati.
 */
export function LoginSelectRoleForm(props: Props) {
  const dispatch = useDispatch();

  const selectRoleAndOffice = usePutDatiSessioneUtente();

  const currentUser = useCurrentUser()!;

  const officeRoles = parseFullRoleList(currentUser);

  return (
    <Formik<PutDatiSessioneUtenteParams>
      initialValues={{} as any}
      validate={schemaToValidator(selectRoleValidator)}
      onSubmit={async (values, helpers) => {
        try {
          await selectRoleAndOffice.mutateAsync({ params: values });
          await dispatch(AuthActions.officeAndRoleSelected());
        } catch (e: any) {
          logger.error(`Si è verificato un errore durante il login`, {
            error: e
          });
          if (e.response.status === 400) {
            message.error('Si è verificato un errore durante il login. Riprovare.'); // prettier-ignore
          } else {
            message.error(getNetworkErrorMessage(e, 'Si è verificato un errore durante il login. Riprovare.')); // prettier-ignore
          }
        }
      }}
    >
      <LoaderSpin spinning={!officeRoles} transparent>
        <LoginCard title="Login" subTitle="Seleziona Ufficio e Ruolo">
          <FormikForm layout="vertical">
            {officeRoles.length > 6 ? (
              <LoginSelectRoleInput />
            ) : (
              <LoginSimpleSelectRoleForm options={officeRoles} />
            )}
            <Button htmlType="submit" size="large" type="primary" block>
              Applica
            </Button>
          </FormikForm>
        </LoginCard>
      </LoaderSpin>
    </Formik>
  );
}
