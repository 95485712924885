import { message } from 'antd';
import { useFirma, useFirmaArguments } from 'client/api/auth/jnlp/jnlp';
import {
  getAzioniDocumento,
  getGetAzioniDocumentoQueryKey
} from 'client/api/backend/attivita/attivita';
import {
  Documento,
  RecuperaDocumentoPerFirmaParams
} from 'client/api/backend/schemas';
import { WebSocketKind } from 'client/components/digital-signature/DigitalSignatureProvider';
import React, { useEffect } from 'react';
import { useQueries, useQueryClient } from 'react-query';
import { JnlpService } from './JnlpService';
import { WebSocketSignJnlpModal } from './WebSocketSignJnlpModal';

export interface IFastSignJnlpWindowManagerProps {
  visible: boolean;
  taskId: string;
  document: Documento;
  params: RecuperaDocumentoPerFirmaParams;
  hide: () => void;
}

/**
 * Manager modal per firma **singola** "FAST"
 */
export function FastSignJnlpWindowManager(
  props: IFastSignJnlpWindowManagerProps
) {
  const { visible, taskId, document, params } = props;

  const documentId = document.idDocumento;

  const signManager = JnlpService.useDocumentSignManager();

  const queryClient = useQueryClient();

  const fastJnlpManager = JnlpService.useWebSocketJnlpManager({
    kind: WebSocketKind.Sign,
    filename: 'firmaManager.jnlp',
    getJnlp: async () => {
      return await signManager.getJnlp(taskId, documentId, params);
    },
    getJnlpArguments: async () => {
      const result = await signManager.getJnlpArguments(
        taskId,
        documentId,
        params
      );

      return result;
    },
    maxConnectionTries: 5 * 60, // ~5 minuti
    successMessage: 'Documento firmato con successo',
    onSuccess: () => {
      queryClient.invalidateQueries(getGetAzioniDocumentoQueryKey(taskId));
      props.hide();
    }
  });

  useEffect(() => {
    if (!visible) {
      fastJnlpManager.setToDefault();
      return;
    }

    if (!documentId) {
      message.error('Il documento non è disponibile');
      props.hide();
      return;
    }

    fastJnlpManager.jnlpProcess();
  }, [visible]);

  return (
    <WebSocketSignJnlpModal
      visible={visible}
      fastJnlpManager={fastJnlpManager}
      hide={props.hide}
    />
  );
}
