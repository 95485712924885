import * as React from 'react';
import { TableData } from 'client/ui/table/TableData';
import {
  getColumnsTaskListTable,
  columnsTaskListTableMobile
} from 'client/routes/task/list/table/columnsTaskListTable';
import { useGetAttivita } from 'client/api/backend/attivita/attivita';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { useCurrentUser } from 'client/components/auth/AuthModule';

export interface HomeTaskListTableProps {}

/**
 * Elenco delle attività nella home page
 */
export function HomeTaskListTable(props: HomeTaskListTableProps) {
  const isMobile = useBreakpoint(down('md'));
  const user = useCurrentUser();

  const tasks = useGetAttivita({
    sortBy: 'CREATED',
    sortDirection: 'DESC',
    firstResult: 0,
    maxResults: 10
  });

  return (
    <TableData
      loading={tasks.isLoading || tasks.isFetching}
      columns={
        isMobile ? columnsTaskListTableMobile : getColumnsTaskListTable(user)
      }
      dataSource={tasks.data?.data?.attivitas ?? []}
      pagination={false}
      showHeader={false}
    />
  );
}
