import React from 'react';
import { Space } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { IconExec } from 'client/ui/icon/ApplicationIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';

export interface IExecTaskButtonProps {
  task: AttivitaDTO;
}

export function ExecTaskButton(props: IExecTaskButtonProps) {
  const { task } = props;
  return (
    <ButtonIcon
      icon={<IconWrapper icon={<IconExec />} />}
      to={`/task/list/${task.idTaskInstance}`}
      tooltip="Esegui"
      tooltipProps={{ placement: 'bottomRight' }}
    />
  );
}
