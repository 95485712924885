import * as React from 'react';
import styled from 'styled-components';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';
import { IconWrapper } from '../icon/IconWrapper';
import { Tooltip } from 'antd';

const NameToIcon = styled.span`
  .anticon {
    margin-right: 0;
  }
`;

export interface NameResponsiveProps {
  name: string;
  icon: React.ReactNode;
}

export function NameResponsive(props: NameResponsiveProps) {
  const { name, icon } = props;
  const isMobile = useBreakpoint(down('sm'));
  const content = !icon ? (
    name
  ) : isMobile ? (
    <Tooltip title={name}>
      <IconWrapper icon={icon} />
    </Tooltip>
  ) : (
    name
  );

  return <NameToIcon>{content}</NameToIcon>;
}
