import { mainTheme } from './mainTheme';
import { lessThemeConverted } from './lessThemeConverted';
import { themeOverride } from './themeOverride';

/**
 * Thema personalizzabile dell'applicativo
 *
 * Permette di sovrascivere le variabili utilizzate nell'applicativo
 */

export const theme = { ...lessThemeConverted, ...mainTheme, ...themeOverride };
