import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import { omit } from 'lodash';
import { getUniqueName } from './normalizeDeclarationField';

export type DeclarationFieldTreeNode = {
  field: Omit<DeclarationFieldDto, 'sottocampi'>;
  children: DeclarationFieldTreeNode[];
};

let sortByRow = (a: DeclarationFieldTreeNode, b: DeclarationFieldTreeNode) =>
  a.field.riga - b.field.riga || a.field.posizione - b.field.posizione;

/**
 * A partire dall'elenco completo dei campi (anche con strutture duplicate)
 * ottieniamo l'albero dei campi per poterli ristrutturare in seguito (ad esempio,
 * campi annidati ripetibili)
 */
export function getDeclarationFieldsTree(fields: DeclarationFieldDto[]) {
  let fieldsMap: Map<string, DeclarationFieldTreeNode> = new Map();
  for (const field of fields) {
    fieldsMap.set(field.uniqueName, {
      field: omit(field, ['sottocampi']),
      children: []
    });
  }

  // Coda dei nodi da processare
  let fieldsQueue: DeclarationFieldDto[] = [...fields];

  // Elenco dei nodi già visitati per evitare i casi di annidamenti esponenzionali
  // Ad esempio in r1->r2->r3 il nodo r2 sarà presente due volte,
  // sia come r1->r2->r3 che come r2->r3
  let visited: Set<string> = new Set();

  // Nodo corrente visitato. Ricorsivo.
  let current: DeclarationFieldDto | undefined;

  while ((current = fieldsQueue.shift()) != null) {
    if (!current.sottocampi) continue;

    for (const child of current.sottocampi) {
      // Facciamo side-effect sui sottocampi perché il nome non è stato generato
      // in `normalizeDeclaration` (lì li generiamo solo per i nodi root)
      child.uniqueName = getUniqueName(child);

      if (visited.has(child.uniqueName)) continue;

      let childNode = fieldsMap.get(child.uniqueName);
      if (!childNode) continue;

      // Aggiungiamo il campo ai children del padre
      fieldsMap.get(current.uniqueName)!.children.push(childNode);

      // Aggiungiamo il campo alla coda
      fieldsQueue.push(child);
      visited.add(child.uniqueName);
    }

    fieldsMap.get(current.uniqueName)?.children.sort(sortByRow);
  }

  // Se un campo è presente almeno una volta come sottocampo lo eliminiamo
  visited.forEach(name => {
    fieldsMap.delete(name);
  });

  const tree = Array.from(fieldsMap.values()).sort(sortByRow);
  applyIndentationLevel(tree);

  return tree;
}

function applyIndentationLevel(tree: DeclarationFieldTreeNode[], level = 0) {
  for (const node of tree) {
    node.field.indentation = level;
    applyIndentationLevel(node.children, level + 1);
  }
}
