import { joinPath } from './network/joinPath';

const window$: any = window;

function normalizePublicPath(path?: string | null) {
  if (!path) return '/';
  if (!path.endsWith('/')) return path + '/';
}

export const appConfig = {
  publicPath: normalizePublicPath(
    window$.PUBLIC_PATH || process.env.PUBLIC_PATH
  ),
  remotePath: window$.REMOTE_PATH || process.env.REMOTE_PATH,
  rootNavigationPath:
    window$.ROOT_NAVIGATION_PATH || process.env.ROOT_NAVIGATION_PATH,
  // apiRestUrl: api$.rest_url,
  apiAuthPath: window$.API_AUTH_PATH || process.env.API_AUTH_PATH,
  apiBackendPath: window$.API_BACKEND_PATH || process.env.API_BACKEND_PATH,
  useFvProxy: window$.API_USE_FV_PROXY || process.env.API_USE_FV_PROXY,
  useFvProxyUrl:
    window$.API_USE_FV_PROXY_URL || process.env.API_USE_FV_PROXY_URL,
  loginStrategies: (
    window$.LOGIN_STRATEGIES ||
    process.env.LOGIN_STRATEGIES ||
    ''
  ).split(',') as string[],
  showTestUserMenu: process.env.NODE_ENV === 'development', // process.env.SHOW_TEST_USER_MENU
  jnlpKind: window$.JNLP_KIND || process.env.JNLP_KIND
};

/**
 * Permette di utilizzare URL importati da webpack anche in caso di
 * sottocartelle.
 */
export function resolvePublicPath(path: string) {
  return joinPath([appConfig.publicPath, path]);
}

/**
 * Permette di ottenere il link alle API risolvendo sottocartelle e URL
 * delle API.
 */
export function resolveApiPath(path: string) {
  return joinPath([appConfig.remotePath, path]);
}
