import * as React from 'react';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { Tabs } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { TabNotes } from 'client/routes/task/single/tab/TabNotes';
import { TabHistory } from 'client/routes/task/single/tab/TabHistory';
import { TabsPageHeading } from 'client/ui/tabs/TabsPageHeading';
import { TabInfo } from 'client/routes/task/single/tab/TabInfo';
import { useProcedure } from './useProcedure';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { useState } from 'react';
import { TabProcedure } from './tab/TabProcedure';
import { TabTasks } from './tab/TabTasks';
import { PageTitle } from 'client/ui/layout/page/heading/PageTitle';
import { NameResponsive } from 'client/ui/tabs/NameToIconResponsive';
import {
  TbFileText,
  TbHistory,
  TbInfoCircle,
  TbMessage,
  TbEdit
} from 'react-icons/tb';

const { TabPane } = Tabs;

interface Params {
  id: string;
}

export interface ProcedurePageProps extends RouteComponentProps<Params> {}

export function ProcedurePage(props: ProcedurePageProps) {
  const [visibleTab, setVisibleTab] = useState('metadata');

  const procedureId = props.match.params.id;
  const procedureResult = useProcedure(procedureId);

  const procedureContent = procedureResult.content.result;
  const procedure = procedureResult.procedure.result;

  useBreadcrumbItem({ path: `/procedure/list`, title: `Elenco Procedimenti` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: `${procedure?.tipologiaProcedimento?.nomeTipo} - ${procedure?.nomeFaseCorrente}`
  });

  if (procedureResult.content.isError || procedureResult.procedure.isError) {
    return (
      <NetworkAlertPage
        message={'Si è verificato un errore durante il caricamento'}
        error={getNetworkErrorMessage(
          procedureResult.content.error ?? procedureResult.procedure.error ?? ''
        )}
      />
    );
  }

  if (
    procedureResult.content.isLoading ||
    procedureResult.procedure.isLoading ||
    !procedureContent ||
    !procedure
  ) {
    return <LoadingPage full />;
  }

  return (
    <CorePageLayout>
      <PageHeading
        breadcrumb={<BreadcrumbsContainer />}
        title={
          <PageTitle procedure={procedure} name={procedure?.nomeFaseCorrente} />
        }
        footer={
          <TabsPageHeading
            defaultActiveKey={'metadata'}
            onChange={key => setVisibleTab(key)}
          >
            <TabPane
              key="metadata"
              tab={
                <NameResponsive
                  name="Dichiarazioni e Documenti"
                  icon={<TbFileText />}
                />
              }
            />
            <TabPane
              key="tasks"
              tab={<NameResponsive name="Attività" icon={<TbEdit />} />}
            />
            <TabPane
              key="info"
              tab={
                <NameResponsive name="Informazioni" icon={<TbInfoCircle />} />
              }
            />
            <TabPane
              key="history"
              tab={<NameResponsive name="Cronologia" icon={<TbHistory />} />}
            />
            <TabPane
              key="notes"
              tab={<NameResponsive name="Note" icon={<TbMessage />} />}
            />
          </TabsPageHeading>
        }
      />
      {/* PageContent */}
      <PageContent noPadding={visibleTab == 'tasks'}>
        {visibleTab == 'metadata' && (
          <TabProcedure
            procedure={procedureContent}
            initialValues={procedureResult.initialValues}
          />
        )}
        {visibleTab == 'notes' && <TabNotes procedureId={procedure.id!} />}
        {visibleTab == 'history' && <TabHistory procedureId={procedure.id!} />}
        {visibleTab == 'info' && <TabInfo procedure={procedure} />}
        {visibleTab == 'tasks' && <TabTasks procedure={procedure} />}
      </PageContent>
    </CorePageLayout>
  );
}
