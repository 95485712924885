import * as React from 'react';
import styled from 'styled-components';
import { ISODate } from '../../../../ui/display/date/ISODate';
import { HistoryType } from '../../task/hooks/useTaskHistory';
import { ActionTag } from './ActionTag';

const HistoryTaskInfoLineWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${props => props.theme.textColorSecondary};
  font-size: ${props => props.theme.fontSizeBase};
  transition: color 0.3s;
`;
const DateSpan = styled.span`
  margin-right: 8px;
  line-height: 1.2;
`;

export interface HistoryItemTaskInfoProps {
  date: Date;
  type: HistoryType;
}
/**
 * // TODO
 */
export function HistoryItemTaskInfo(props: HistoryItemTaskInfoProps) {
  return (
    <HistoryTaskInfoLineWrapper>
      <DateSpan>
        <ISODate date={props.date} format="DD/MM/YYYY HH:mm" />
      </DateSpan>
      {props.type && <ActionTag type={props.type} />}
    </HistoryTaskInfoLineWrapper>
  );
}
