import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import * as React from 'react';

const INDENTATION_SIZE_PX = 24;

export interface IDeclarationFieldContainerProps {
  field: DeclarationFieldDto;
  children?: React.ReactNode;
}

/**
 * Container per gestire l'indentazione dei campi.
 */
export function DeclarationFieldContainer(
  props: IDeclarationFieldContainerProps
) {
  const { field, children } = props;
  const indentation = field.indentation ?? 0;
  return (
    <div style={{ marginLeft: INDENTATION_SIZE_PX * indentation }}>
      {children}
    </div>
  );
}
