import { AzioniDichiarazione, Dichiarazione } from 'client/api/backend/schemas';
import { DeclarationActionLogic } from 'client/components/schema/declaration/actions/DeclarationActionLogic';
import { getAnchorId } from 'client/ui/anchor/getAnchorId';
import { StateAnchorLink } from 'client/ui/anchor/StateAnchorLink';
import { useField } from 'formik';
import * as React from 'react';
import { HiCheckCircle, HiXCircle } from 'react-icons/hi';

export interface ITaskDeclarationAnchorLinkProps {
  declaration: AzioniDichiarazione;
}

export function TaskDeclarationAnchorLink(
  props: ITaskDeclarationAnchorLinkProps
) {
  const { declaration } = props;
  const [, meta] = useField(declaration.dichiarazione!.idTipoDichiarazione!);

  const isEditable = DeclarationActionLogic.isDeclarationEditable(
    declaration.azioni
  );

  return (
    <StateAnchorLink
      href={`#${getAnchorId(declaration.dichiarazione!.idTipoDichiarazione!)}`}
      title={declaration.dichiarazione!.titoloDichiarazione}
      icon={
        isEditable ? (
          meta.touched && meta.error ? (
            <HiXCircle />
          ) : (
            <HiCheckCircle />
          )
        ) : undefined
      }
      status={meta.touched ? (meta.error ? 'error' : 'success') : undefined}
    />
  );
}
