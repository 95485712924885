import { useGetAttivita } from 'client/api/backend/attivita/attivita';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

export interface ITestApiPageProps extends RouteComponentProps {}

export function TestApiPage(props: ITestApiPageProps) {
  const tasks = useGetAttivita(
    {
      maxResults: 20,
      firstResult: 0
    },
    {}
  );

  return (
    <CorePageLayout>
      <LoaderSpin spinning={tasks.isLoading}>
        {tasks.data?.data?.attivitas?.map(task => {
          return <div>{task.name}</div>;
        })}
      </LoaderSpin>
    </CorePageLayout>
  );
}
