import { AzioneDTO } from 'client/api/backend/schemas';
import { DeclarationAction } from './DeclarationActions';

export class DeclarationActionLogic {
  static isDeclarationEditable(actions?: AzioneDTO[]) {
    if (!actions) return false;
    return actions.some(
      a =>
        a.idAzione === DeclarationAction.Edit ||
        a.idAzione === DeclarationAction.CustomEdit
    );
  }
}
