import { FormFieldsContainerProps } from './FormFieldsContainer';

export function sanitizeColumnsCount(
  columns: number | undefined
): FormFieldsContainerProps['columns'] {
  /**
   * Numero di colonne
   * */
  //  columns?: 1 | 2 | 3 | 4 | 6 | 8 | 12;

  if (!columns) return 1;
  if (columns === 5 || columns === 7) return (columns + 1) as any;
  if (columns > 8) return 8;
  return columns as any;
}
