import { useFormikContext } from 'formik';
import { pick } from 'lodash';
import React, { useCallback, useEffect } from 'react';

export interface IFormikInitialValueManagerProps {
  initialValues: any;
  declarationIds: string[];
}

/**
 * Permette l'aggiornamento asincrono del formik.
 * Quando si compie un'operazione come l'upload di un file, e il conseguente
 * invalid della query, si aggiorna l'intero formik con i nuovi dati aggiornati
 * da BE (che diventano gli initialValues) e poi si "sovrascrivono" i dati delle
 * dichiarazioni precedenti. In questo modo si mantengono eventuali modifiche
 * non salvate nei campi della dichiarazione.
 */
export function FormikInitialValueManager(
  props: IFormikInitialValueManagerProps
) {
  const { initialValues, declarationIds } = props;
  const formik = useFormikContext<{ [key: string]: any }>();

  // Quando cambiano gli initial values (e.g. all'aggiornamento della query)
  // mantieni intatti i valori delle dichiarazioni e aggiorna solo i documenti (sezioni, file, allegati etc)
  useEffect(() => {
    const oldValues = { ...formik.values };

    formik.resetForm({ values: initialValues });

    // Prendo solo le dichiarazioni
    const updatedValues = pick(oldValues, declarationIds);

    formik.setValues(() => ({
      ...initialValues,
      ...updatedValues
    }));
  }, [initialValues]);

  return <></>;
}
