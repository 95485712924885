import 'reflect-metadata';

// Polyfill
import 'ts-polyfill/lib/es2015-core';
import 'ts-polyfill/lib/es2015-collection';
import 'ts-polyfill/lib/es2015-iterable';
import 'ts-polyfill/lib/es2015-promise';
import 'ts-polyfill/lib/es2015-reflect';
import 'ts-polyfill/lib/es2016-array-include';
import 'ts-polyfill/lib/es2017-object';
import 'core-js/es/symbol';

// Locale
import it_IT from 'antd/lib/locale-provider/it_IT';
import 'common/validation/initYup';
import moment from 'moment';
import 'moment/locale/it';
moment.locale('it');

import './fonts/InterFontFace.less';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'client/core/store/configureReduxStore';
import { Routes } from './routes/Routes';
import { ConfigProvider, message } from 'antd';
import { NetworkProvider } from './core/network/context/NetworkContext';
import { networkState } from './core/network/context/configureNetworkState';
import { BreadcrumbProvider } from './core/router/breadcrumb/BreadcrumbContext';
import { appConfig } from './core/appConfig';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './core/network/queryClient';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from './ui/theme/theme';
import { SelectOptionGlobalStyle } from './components/auth/login/LoginCard';
import { CollapseGlobalStyle } from './components/schema/history/detail/PhasesContainer';
import { ButtonTooltipsGlobalStyle } from './ui/button/ButtonIcon';
import { CheckboxInputGlobalStyle } from './ui/form/input/CheckboxInputItem';
import { RadioInputGlobalStyle } from './ui/form/input/RadioGroupInputItem';
import { RichTextInputGlobalStyle } from './ui/form/input/RichTextInputItem';
import { TextAreaInputGlobalStyle } from './ui/form/input/TextAreaInputItem';
import { MessageGlobalConfiguration } from './components/messages/MessageGlobalConfiguration';

export function App() {
  return (
    <BrowserRouter basename={appConfig.rootNavigationPath}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {/* Importazione GlobalStyle */}
          <SelectOptionGlobalStyle />
          <CollapseGlobalStyle />
          <ButtonTooltipsGlobalStyle />
          <CheckboxInputGlobalStyle />
          <RadioInputGlobalStyle />
          <RichTextInputGlobalStyle />
          <TextAreaInputGlobalStyle />

          {/* Configurazione message di antd */}
          <MessageGlobalConfiguration />

          <ConfigProvider locale={it_IT}>
            <QueryClientProvider client={queryClient}>
              <NetworkProvider value={networkState}>
                <BreadcrumbProvider>
                  <Routes />
                </BreadcrumbProvider>
              </NetworkProvider>
            </QueryClientProvider>
          </ConfigProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
