import { Dichiarazione, GruppoAllegati } from 'client/api/backend/schemas';
import { TaskCard } from 'client/ui/card/TaskCard';
import React from 'react';
import { ProcedureDeclarationSection } from './ProcedureDeclarationSection';
import { DeclarationWrapper } from '../../../task/single/declarations/TaskDeclarations';
import { ProcedureAttachmentGroupSection } from '../attachment/ProcedureAttachmentGroupSection';

export interface IProcedureDeclarationsProps {
  procedureId: string;
  declarations: Dichiarazione[] | null | undefined;
  attachmentGroups: GruppoAllegati[] | null | undefined;
}

export function ProcedureDeclarations(props: IProcedureDeclarationsProps) {
  const { declarations, attachmentGroups, procedureId } = props;

  // Se non ci sono dichiarazioni né allegati, non mostriamo nulla
  if (
    (!declarations || declarations.length === 0) &&
    (!attachmentGroups || attachmentGroups.length === 0)
  ) {
    return null;
  }

  return (
    <TaskCard>
      {declarations?.map((declaration, index) => (
        <DeclarationWrapper key={index}>
          <ProcedureDeclarationSection declaration={declaration} />
        </DeclarationWrapper>
      ))}
      {/*  Sezione Gruppo allegati */}
      {attachmentGroups?.map((group, index) => (
        <ProcedureAttachmentGroupSection
          group={group}
          key={index}
          procedureId={procedureId}
        />
      ))}
    </TaskCard>
  );
}
