import React, { useCallback } from 'react';
import styled from 'styled-components';
import { ButtonProps, message, Typography } from 'antd';
import { ProcedureButtonBox } from './ProcedureButtonBox';
import { ProcedureIcon } from 'client/components/schema/variables/icons/ProcedureIcon';
import { TipologiaProcedimentoDTO } from 'client/api/backend/schemas';
import { DefaultProcedureInfo } from 'client/components/schema/variables/colors/colorNames';
import { useAvviaProcedimento } from 'client/api/backend/procedimenti/procedimenti';
import { queryClient } from 'client/core/network/queryClient';
import { getGetAttivitaQueryKey } from 'client/api/backend/attivita/attivita';
import { useHistory } from 'react-router';
import { TaskActionLogic } from 'client/logic/TaskActionLogic';
import { useCurrentUser } from 'client/components/auth/AuthModule';

export interface ProcedureButtonProps {
  procedure: TipologiaProcedimentoDTO;
  buttonText?: string;
}

// const fixNullRenderingValue = (code: string | null | undefined) =>
//   code === 'null' ? null : code;

/**
 * Box per le procedure
 */
// TODO Aggiungere descrizione
export function ProcedureButton(props: ProcedureButtonProps) {
  const { procedure, ...rest } = props;

  const history = useHistory();
  const user = useCurrentUser();

  const startProcedure = useAvviaProcedimento({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getGetAttivitaQueryKey()
        });
      }
    }
  });

  const handleStart = useCallback(async () => {
    try {
      const result = await startProcedure.mutateAsync({
        idTipoProcedimento: procedure.id!
      });
      message.success(`Procedimento avviato con successo`);
      // Faccio il redirect all'attività appena avviata se assegnata a me.
      if (!TaskActionLogic.isAssigned(result.data, user)) return;
      const redirectTo = `/task/list/${result.data?.idTaskInstance}`;
      history.push(redirectTo);
    } catch (e) {
      console.error(e);
      message.error(
        `Si è verificato un errore durante l'avvio del procedimento, si prega di riprovare.`
      );
    }
  }, [procedure]);

  return (
    <ProcedureButtonBox
      title={procedure.nomeTipo ?? 'N.A.'}
      color={procedure.coloreRendering ?? DefaultProcedureInfo.Color}
      icon={
        <ProcedureIcon
          name={procedure.iconaRendering ?? DefaultProcedureInfo.Icon}
        />
      }
      size="middle"
      onConfirm={handleStart}
      confirmMessage={
        <span>
          Avviare il procedimento <b>{procedure.nomeTipo ?? 'N.A.'}</b>?
        </span>
      }
      loading={startProcedure.isLoading}
      description={'' /*procedure.descrizione*/}
      {...rest}
    />
  );
}
