import React from 'react';
import { AttivitaDTO } from 'client/api/backend/schemas';
import styled from 'styled-components';
import { ProcedureTableTag } from 'client/ui/tag/ProcedureTableTag';
import { Space, Typography } from 'antd';
const { Text, Paragraph } = Typography;
import { TaskActionButton } from './actions/TaskActionButton';
import { TaskStateTag } from './TaskStateTag';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';
import { TaskName } from 'client/ui/tag/TaskName';
import { TaskRowProcedure } from 'client/ui/table/mobile/TaskRowProcedure';
import { TaskDateFrom } from 'client/ui/table/mobile/TaskDateFrom';
import { ItemListMobile } from 'client/ui/table/mobile/ItemListMobile';
import { TableCellEllipsis } from 'client/ui/table/TableCellEllipsis';

export interface ITasksProcedureDetailListItemMobileProps {
  record: AttivitaDTO | undefined;
}
/**
 * Visualizzazione a lista per Mobile dell'attività
 */
export function TasksProcedureDetailListItemMobile(
  props: ITasksProcedureDetailListItemMobileProps
) {
  const { record } = props;
  if (!record) return null;
  return (
    <ItemListMobile actions={<TaskActionButton task={record} />}>
      <Space style={{ width: '100%' }} direction="vertical" size={2}>
        <TaskRowProcedure>
          <Text>
            Numero: <Text strong>{record.procedimento?.numero}</Text>
          </Text>
          <TaskStateTag state={record.stato} />
        </TaskRowProcedure>

        <Paragraph>
          Resp:{' '}
          <Text strong>
            {record.ultimaLavorazioneTask?.nomeUtenteLavorazione}
          </Text>{' '}
          ({record.ultimaLavorazioneTask?.nomeUfficioAssegnazione})
        </Paragraph>

        <TaskName>{record.name}</TaskName>

        {record.ultimaLavorazioneTask?.dataInizioLavorazione && (
          <TaskDateFrom
            date={record.ultimaLavorazioneTask?.dataInizioLavorazione}
            format={'DD/MM/YYYY HH:mm'}
          />
        )}
      </Space>
    </ItemListMobile>
  );
}
