import { useGetInfoGenerali } from 'client/api/backend/procedimenti/procedimenti';
import {
  InfoLavorazioneTask,
  InfoLavorazioneTaskResponse,
  ProcedimentoConTask
} from 'client/api/backend/schemas';
import moment from 'moment';

export enum HistoryType {
  Release = 'Release',
  Complete = 'Complete',
  Start = 'Start'
}

export interface IHistorySubphase {
  type: HistoryType;
  date: Date;
  note: string | null | undefined;
  user: string | null | undefined;
  userFiscalNumber: string | null | undefined;
}

export interface IHistoryPhase {
  name: string;
  from: Date | undefined | null;
  to: Date | undefined | null;
  subphases: IHistorySubphase[];
}

/**
 * Estrae la lista degli elementi della cronologia da un singolo infoLavorazione
 * (che contiene informazioni per più lavorazioni contemporaneamente)
 */
function getHistoryInfo(input: InfoLavorazioneTask) {
  const result = [];

  if (input.dataCompletamento) {
    result.push({
      user: input.nomeUtenteLavorazione,
      userFiscalNumber: input.cfUtenteLavorazione,
      date: input.dataCompletamento,
      type: HistoryType.Complete,
      note: input.noteCompletamento
    });
  }
  if (input.dataRilascio) {
    result.push({
      user: input.nomeUtenteLavorazione,
      userFiscalNumber: input.cfUtenteLavorazione,
      date: input.dataRilascio,
      type: HistoryType.Release
    });
  }
  if (input.dataInizioLavorazione) {
    result.push({
      user: input.nomeUtenteLavorazione,
      userFiscalNumber: input.cfUtenteLavorazione,
      date: input.dataInizioLavorazione,
      type: HistoryType.Start
    });
  }
  return result;
}

/**
 * Spacchetta e costruisce l'array di sottofasi, ordinandole
 */
function getSubphases(input: InfoLavorazioneTaskResponse[] | null | undefined) {
  return (
    input
      ?.map(phase => {
        const historyInfo = getHistoryInfo(phase);
        if (!historyInfo) return null;

        return historyInfo.map(historyItem => ({
          type: historyItem.type,
          date: moment(historyItem.date, 'DD/MM/YYYY HH:mm:SS').toDate(),
          note: historyItem.note,
          user: historyItem.user,
          userFiscalNumber: historyItem.userFiscalNumber
        }));
      })
      .flat()
      .filter(item => item !== null)
      // Ordina le date in ordine dalla più recente alla più vecchia
      .sort(
        (a, b) => b!.date.getTime() - a!.date.getTime()
      ) as IHistorySubphase[]
  );
}

/**
 * Dato il procedimento costruisce l'oggetto per msotrare la cronologia
 */
function mapTaskHistory(
  input: ProcedimentoConTask | null | undefined
): IHistoryPhase[] {
  if (!input?.task) return [];
  // Ogni task è una fase "main"
  const phases = input.task
    .map(task => {
      // All'interno ci sono le sottofasi
      // (N.B. sono in ordine cronologico dalla più recente alla meno recente)
      const subphases = getSubphases(task.infoLavorazioneTask);

      // Se non ci sono sottofasi, non lo considero
      if (subphases.length === 0) return null;

      // Recupero la data d'inizio
      const fromDate = subphases[subphases.length - 1]?.date;
      // Prendo la data di fine se è stata completata
      const lastPhase = subphases[0];
      const lastDate =
        lastPhase?.type === HistoryType.Complete ? lastPhase.date : null;

      return {
        name: task.name || 'Attività',
        from: fromDate,
        to: lastDate,
        subphases
      };
    })
    .filter(phase => !!phase)
    .sort((a, b) => b!.from.getTime() - a!.from.getTime()) as IHistoryPhase[];

  return phases;
}

/**
 * Hook per recuperare la cronologia degli eventi su un'attività
 * mappandoli nel formato per creazione della struttura dei componenti
 */
export function useTaskHistory(procedureId: string | undefined) {
  if (!procedureId) return {};
  const result = useGetInfoGenerali(procedureId);
  const history = result.data?.data;

  return {
    result,
    history: mapTaskHistory(history)
  };
}
