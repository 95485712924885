/**
 * Generato automaticamente.
 * Procedimenti
 */

export type AttivitaDTOStato =
  typeof AttivitaDTOStato[keyof typeof AttivitaDTOStato];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttivitaDTOStato = {
  PENDENTE: 'PENDENTE',
  IN_CORSO: 'IN_CORSO'
} as const;
