import {
  AzioniDocumento,
  AzioniSezione,
  Documento,
  DocumentoSenzaAzioni
} from 'client/api/backend/schemas';
import { FormikContextType } from 'formik';
import {
  DocumentAction,
  DocumentSectionAction,
  DocumentSignActions
} from '../../declaration/actions/DeclarationActions';

export enum SectionEditStatus {
  All = 'all',
  Some = 'some',
  None = 'none'
}
export class DocumentLogic {
  /**
   * Ritorna una key univoca per react
   */
  static getKey(document: Documento | DocumentoSenzaAzioni | null | undefined) {
    return `${document!.idTipoDocumento!}-${document!.idDocumento!}`;
  }

  static isToSign(document: Pick<AzioniDocumento, 'azioni'>) {
    return !!document.azioni?.some(a =>
      DocumentSignActions.includes(a.idAzione as DocumentAction)
    );
  }

  static isSectionsEditable(document: AzioniDocumento) {
    return document.documento?.sezioni?.some(section =>
      this.isSectionEditable(section)
    );
  }

  static isSectionEditable(section: AzioniSezione) {
    return section.azioni?.some(a => a.idAzione === DocumentSectionAction.Edit);
  }

  static isSectionCompiled(section: AzioniSezione) {
    return section.sezione?.idSezioneDocumento != null;
  }

  static sectionEditStatus(document: AzioniDocumento) {
    const editableSections = document.documento?.sezioni?.filter(section =>
      this.isSectionEditable(section)
    );

    if (editableSections?.every(s => !!s.sezione?.idSezioneDocumento))
      return SectionEditStatus.All;
    else if (editableSections?.some(s => !!s.sezione?.idSezioneDocumento))
      return SectionEditStatus.Some;
    return SectionEditStatus.None;
  }

  /** Ritorna true se almeno una sezione `fieldname` è segnata come touched */
  static isSectionTouched(formik: FormikContextType<any>, fieldname: string) {
    const sections = formik.getFieldMeta(fieldname);

    if (!sections.value) return false;

    // Per ogni sezione recupero i meta del idSezioneDocumento
    for (const key of Object.keys(sections.value as any)) {
      const documentId = formik.getFieldMeta(
        `${fieldname}.${key}.idSezioneDocumento`
      );

      if (documentId.touched) return true;
    }

    return false;
  }

  /**
   * Ritorna vero se è presente il documento firmato
   */
  static isSigned(document: Documento, params: { flIntermedio: boolean }) {
    return document.files?.some(
      file =>
        file.flFirmato === true && file.flIntermedio === params.flIntermedio
    );
  }
}
