import React from 'react';
import clx from 'classnames';

export interface IconWrapperProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  icon?: React.ReactNode;
}
/**
 * Wrapper per le icone custom o react-icons per associare gli stili base di Ant
 */
export function IconWrapper(props: IconWrapperProps) {
  const { icon, children, className, ...otherProps } = props;

  return (
    <span role="img" className={clx('anticon', className)} {...otherProps}>
      {icon ?? children}
    </span>
  );
}
