import { Button, Space } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { TaskActions, TaskActionLogic } from 'client/logic/TaskActionLogic';
import { assertNever } from 'common/utils/assertNever';
import React from 'react';
import styled from 'styled-components';
import { AssignTaskButton } from './AssignTaskButton';
import { AssignToOtherButton } from './AssignToOtherButton';
import { ExecTaskButton } from './ExecTaskButton';
import { ReleaseTaskIconButton } from './ReleaseTaskIconButton';
import { ViewTaskButton } from './ViewTaskButton';

const SpaceButtonsWrapper = styled(Space)`
  margin-top: -10px;
  margin-bottom: -10px;
`;

export interface ITaskActionButtonProps {
  task: AttivitaDTO;
}

export function TaskActionButton(props: ITaskActionButtonProps) {
  const { task } = props;
  const currentUser = useCurrentUser();

  if (!currentUser) return null;

  const action = TaskActionLogic.getAction(task, currentUser);

  switch (action) {
    case TaskActions.Assign:
      return (
        <SpaceButtonsWrapper size={0}>
          <AssignTaskButton task={task} />
          <AssignToOtherButton taskId={task.idTaskInstance} />
        </SpaceButtonsWrapper>
      );
    case TaskActions.ExecRelease:
      return (
        <SpaceButtonsWrapper size={0}>
          <ReleaseTaskIconButton taskId={task.idTaskInstance} />
          <ExecTaskButton task={task} />
        </SpaceButtonsWrapper>
      );
    case TaskActions.View:
      return (
        <SpaceButtonsWrapper size={0}>
          <ViewTaskButton task={task} />
          <AssignToOtherButton taskId={task.idTaskInstance} />
        </SpaceButtonsWrapper>
      );
    case null:
      return null;
  }
  assertNever(action);
}
