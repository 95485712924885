import { Space } from 'antd';
import { AzioniDocumento } from 'client/api/backend/schemas';
import * as React from 'react';
import { DocumentActionButton } from './DocumentActionButton';

export interface IDocumentActionsBarProps {
  taskId: string;
  document: AzioniDocumento;
}

export function DocumentActionsBar(props: IDocumentActionsBarProps) {
  const { document, taskId } = props;
  return (
    <Space wrap align="end">
      {document.azioni?.map((action, index) => (
        <DocumentActionButton
          actions={document.azioni!}
          key={action.idAzione}
          taskId={taskId}
          action={action}
          document={document}
        />
      ))}
    </Space>
  );
}
