import { apiClient } from 'client/core/network/mutators/apiClient';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { SelectOption } from 'client/ui/form/input/SelectInput';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import {
  DeclarationAsyncSelectLogic,
  DeclarationAsyncSelectOption
} from 'common/schema/declaration/DeclarationAsyncSelectLogic';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { IDeclarationFieldOptions } from '../DeclarationFieldSwitch';
import { parseResponseXml } from '../normalize/parseResponseXml';

export interface IDeclarationFieldAsyncSelectProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

function useDeclarationFieldAsync(field: DeclarationFieldDto) {
  const parameters = DeclarationAsyncSelectLogic.getParameters(field);

  const [assignments, setAssignments] = useState(
    DeclarationAsyncSelectLogic.parseResponseAssignment(parameters)
  );

  useEffect(() => {
    setAssignments(
      DeclarationAsyncSelectLogic.parseResponseAssignment(parameters)
    );
  }, [field]);

  return {
    assignments,
    parameters
  };
}

const MIN_CHARS = 2;

export function DeclarationFieldAsyncSelect(
  props: IDeclarationFieldAsyncSelectProps
) {
  const { field, options } = props;
  const prefix = options.namePrefix;

  const fieldName = prefix ? `${prefix}.${field.nome}` : field.nome;

  const formik = useFormikContext();
  const { assignments, parameters } = useDeclarationFieldAsync(field);

  /**
   * Aggiorna i campi del form al cambio della select
   */
  const handleChange = useCallback(
    (option: DeclarationAsyncSelectOption | undefined) => {
      if (!assignments || !option) return;
      for (const mapper of assignments) {
        const fieldName = prefix ? `${prefix}.${mapper.to}` : mapper.to;
        formik.setFieldValue(fieldName, option.data[mapper.from]);
        setTimeout(() => {
          formik.setFieldTouched(fieldName, true, true);
        }, 0);
      }
    },
    [formik, assignments]
  );

  return (
    <FormFieldItem
      name={fieldName}
      label={field.des_campo}
      showSearch
      refreshOnSearch
      component={AsyncSelectInput}
      query={{
        apiFn: (data: any) =>
          apiClient.get<any>(
            DeclarationAsyncSelectLogic.getRequestUrl(field, data.search)
          ),
        options: (formik: any, search: string | undefined) => ({
          data: { search },
          skip: !search || search.length < MIN_CHARS
        })
      }}
      /** Ristruttura la risposta del WS con i dati che servono */
      responseTransform={(response: any) => {
        const rawOptions =
          parameters.returnType === 'XML'
            ? parseResponseXml(response)
            : response;

        return DeclarationAsyncSelectLogic.parseListOptions(
          rawOptions,
          parameters
        );
      }}
      /** Trasforma il parsing della risposta nelle varie opzioni della select */
      optionTransform={(
        option: DeclarationAsyncSelectOption
      ): SelectOption => ({
        label: option.label,
        value: option.value
      })}
      onAfterSelect={handleChange}
      placeholder={`Digita per iniziare a cercare..`}
    />
  );
}
