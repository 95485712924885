import { useGetProcedimenti } from 'client/api/backend/procedimenti/procedimenti';
import { GetProcedimentiParams } from 'client/api/backend/schemas';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { useLocalPageSize } from 'client/core/hooks/useLocalPageSize';
import { isEqualWith } from 'lodash';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

export function useProcedureList(defaultQuery: GetProcedimentiParams) {
  const location = useLocation();
  const history = useHistory();
  const query = getPaginationUrlQuery(location.search, defaultQuery);

  const { pageSize } = useLocalPageSize(query.pageSize, 10);

  query.pageSize = pageSize;

  const setQuery = useCallback(
    (input: GetProcedimentiParams) =>
      setPaginationUrlQuery<GetProcedimentiParams>({ history }, input),
    [history]
  );

  const apiResult = useGetProcedimenti(query, {
    query: {
      keepPreviousData: true
    }
  });

  // Ritorna vero se la query è diversa dal default, e quindi si arriva da un link già filtrato.
  const isFiltered = !isEqualWith(query, defaultQuery, (value, other) => {
    if (typeof value === 'object' || Array.isArray(value)) return undefined;
    return value == other;
  });

  return {
    query,
    setQuery,
    procedures: apiResult.data?.data?.procedimentos || [],
    meta: { total: apiResult.data?.data?.totale || 0 },
    apiResult,
    isFiltered
  };
}
