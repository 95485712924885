import React from 'react';
import { AttivitaDTO } from 'client/api/backend/schemas';
import styled from 'styled-components';
import { ProcedureTableTag } from 'client/ui/tag/ProcedureTableTag';
import { Space, Typography } from 'antd';
const { Text, Paragraph } = Typography;
import { TaskActionButton } from './actions/TaskActionButton';
import { TaskStateTag } from './TaskStateTag';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';
import { TaskName } from 'client/ui/tag/TaskName';
import { TaskRowProcedure } from 'client/ui/table/mobile/TaskRowProcedure';
import { TaskDateFrom } from 'client/ui/table/mobile/TaskDateFrom';
import { ItemListMobile } from 'client/ui/table/mobile/ItemListMobile';

export interface TaskListItemMobileProps {
  record: AttivitaDTO | undefined;
}
/**
 * Visualizzazione a lista per Mobile dell'attività
 */
export function TaskListItemMobile(props: TaskListItemMobileProps) {
  const { record } = props;
  if (!record) return null;
  return (
    <ItemListMobile actions={<TaskActionButton task={record} />}>
      <Space style={{ width: '100%' }} direction="vertical" size={2}>
        <TaskRowProcedure>
          <ProcedureTableTag
            name={record.procedimento?.tipologiaProcedimento?.nomeTipo}
            color={record.procedimento?.tipologiaProcedimento?.coloreRendering}
            icon={record.procedimento?.tipologiaProcedimento?.iconaRendering}
          />
          <TaskStateTag state={record.stato} />
        </TaskRowProcedure>

        {record.procedimento?.oggetto ? (
          <Paragraph
            ellipsis={{
              rows: 2,
              expandable: true
            }}
          >
            {record.procedimento?.oggetto} -{' '}
            <Text strong>{record.procedimento?.numero}</Text>
          </Paragraph>
        ) : (
          <Text strong>{record.procedimento?.numero}</Text>
        )}

        <TaskName>{record.name}</TaskName>

        {record.ultimaLavorazioneTask?.dataInizioLavorazione && (
          <TaskDateFrom
            date={record.ultimaLavorazioneTask?.dataInizioLavorazione}
            format={'DD/MM/YYYY HH:mm'}
          />
        )}
      </Space>
    </ItemListMobile>
  );
}
