import * as React from 'react';
import clx from 'classnames';
import { Tag, Tooltip } from 'antd';
import { HiArrowSmRight, HiArrowRight } from 'react-icons/hi';
import styled from 'styled-components';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { ISODate } from '../../../../ui/display/date/ISODate';
import { ISORelativeDate } from '../../../../ui/display/date/ISORelativeDate';

const PhaseTimeInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${props => props.theme.textColorSecondary};
  font-size: ${props => props.theme.fontSizeSm};
  transition: color 0.3s;
  padding-top: 2px;
`;
const Content = styled.span`
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
`;
const ContentBold = styled(Content)`
  font-weight: 600;
`;
const Arrow = styled(IconWrapper)`
  padding: 0 8px;
`;
const DateTag = styled(Tag)`
  line-height: 12px;
  padding: 2px 7px;
`;

export interface PhaseTimeInfoProps {
  /** Data Inizio Fase */
  from?: Date | null;
  /** Data Fine Fase
   * Se non presente, la fase è ancora in corso
   */
  to?: Date | null;
  /** Formato di visualizzazione della data,
   * di default è 'DD/MM/YYYY HH:mm'
   */
  format?: string;
}
/**
 * Linea con le informazioni di inizio e fine Fase
 */
export function PhaseTimeInfo(props: PhaseTimeInfoProps) {
  const format = props.format ?? 'DD/MM/YYYY HH:mm';
  return (
    <PhaseTimeInfoWrapper>
      <Content>
        <ISODate date={props.from} format={format} />
      </Content>
      <Content>
        <Arrow icon={<HiArrowRight />} />
        {props.to ? (
          <ISODate date={props.to} format={format} />
        ) : (
          <span>In corso...</span>
        )}
      </Content>

      <ContentBold>
        <Arrow icon={<HiArrowRight />} />
        <ISORelativeDate date={props.from} to={props.to} />
        {/* Alternativa con Tag
        <DateTag
          color="purple"
          children={<ISORelativeDate from={props.from} date={props.to} />}
        /> */}
      </ContentBold>
    </PhaseTimeInfoWrapper>
  );
}
