import { EditFilled, EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { AzioniDocumento, AzioniSezione } from 'client/api/backend/schemas';
import { DocumentLogic } from 'client/components/schema/task/document/DocumentLogic';
import { getAnchorId } from 'client/ui/anchor/getAnchorId';
import { StateAnchorLink } from 'client/ui/anchor/StateAnchorLink';
import { FieldMetaProps, useField, useFormikContext } from 'formik';
import * as React from 'react';
import { HiCheckCircle, HiDocumentText, HiXCircle } from 'react-icons/hi';

export interface ITaskDocumentAnchorLinkProps {
  document: AzioniDocumento;
}

export function TaskDocumentAnchorLink(props: ITaskDocumentAnchorLinkProps) {
  const { document } = props;
  const fieldName = `${document.documento!.idTipoDocumento!}.sections`;
  const [, meta] = useField(fieldName);
  const formik = useFormikContext();

  const isEditable = DocumentLogic.isSectionsEditable(document);

  const isSectionTouched = DocumentLogic.isSectionTouched(formik, fieldName);

  return (
    <StateAnchorLink
      href={`#${getAnchorId(document.documento!.idTipoDocumento!)}`}
      prefixIcon={<HiDocumentText />}
      title={document.documento!.nomeTipoDocumento}
      icon={
        isEditable ? (
          isSectionTouched && meta.error ? (
            <HiXCircle />
          ) : (
            <HiCheckCircle />
          )
        ) : undefined
      }
      status={isSectionTouched ? (meta.error ? 'error' : 'success') : undefined}
    />
  );
}
