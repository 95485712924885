/**
 * Generato automaticamente.
 * Procedimenti
 */

export type GetProcedimentiSortDirection =
  typeof GetProcedimentiSortDirection[keyof typeof GetProcedimentiSortDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProcedimentiSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC'
} as const;
