import { DocumentoSenzaAzioni } from 'client/api/backend/schemas';
import React from 'react';
import { ProcedureDocumentSection } from './ProcedureDocumentSection';

export interface IProcedureDocumentsProps {
  documents: DocumentoSenzaAzioni[] | undefined;
  procedureId: string;
}

export function ProcedureDocuments(props: IProcedureDocumentsProps) {
  const { documents, procedureId } = props;

  if (!documents || documents.length === 0) return null;

  return (
    <>
      {documents.map((document, index) => (
        <ProcedureDocumentSection
          key={index}
          document={document}
          procedureId={procedureId}
        />
      ))}
    </>
  );
}
