import { useLocalStorageMeta } from './useLocalStorageMeta';

const LOCAL_STORAGE_KEY = 'procedimenti-page-size-user-preference';

/**
 * Hook per la gestione della dimensione della pagina di una tabella,
 * che permette il salvataggio dell'ultima preferenza usata nel local storage
 */
export function useLocalPageSize(
  current: number | null | undefined,
  defaultValue: number = 10
): {
  pageSize: number;
} {
  const { value } = useLocalStorageMeta(LOCAL_STORAGE_KEY, current?.toString());

  const result = value ? parseInt(value, 10) : defaultValue;

  return { pageSize: isNaN(result) ? defaultValue : result };
}
