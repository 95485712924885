import { AxiosError } from 'axios';
import { QueryClient } from 'react-query';
import { shouldRetryRequest } from './interceptors/retryInterceptor';

/**
 * Client di gestione delle query BE di react-query.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      /**
       * Di default, react-query provvede a ricaricare le query quando si
       * fa il focus della finestra. Per ora lo teniamo come opt-in.
       * https://react-query.tanstack.com/guides/important-defaults
       */
      refetchOnWindowFocus: false,
      /**
       * Gestiamo il retry tramite le nostre regole personalizzate. Evitiamo
       * il retry per i 401.
       */
      retry: (count, error: any) => {
        return count < 3 && shouldRetryRequest(error);
      }
    }
  }
});
