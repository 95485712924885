import { Divider, Progress, Space, Button, message } from 'antd';
import { AttivitaDTO } from 'client/api/backend/schemas';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { useFormikContext } from 'formik';
import React from 'react';
import { CompleteTasksMassiveAlert } from './CompleteTasksMassiveAlert';
import { ITaskInError, ITaskResult } from './CompleteTasksMassiveModal';

export interface ICompleteTasksMassiveFormProps {
  tasks: AttivitaDTO[];
  taskResults: ITaskResult[];
  progress: number;
  hide: () => void;
  isRunning: React.MutableRefObject<boolean>;
}

export const LOADING_COMPLETE_MESSAGE_KEY = 'complete-task-massive-menu';

/**
 * Form per il completamento massivo delle attività
 */
export function CompleteTasksMassiveForm(
  props: ICompleteTasksMassiveFormProps
) {
  const { taskResults, tasks, progress, isRunning } = props;
  const formik = useFormikContext();

  // Estraggo le attività che hanno dato errore
  const tasksInError = taskResults.filter(
    result => result.esito === 'error'
  ) as ITaskInError[];

  // Se l'utente chiude il modal durante l'azione massiva,
  // interrompo l'operazione e mostro un messaggio di avviso
  const handleClose = () => {
    if (formik.isSubmitting) {
      message.loading({
        content: 'Annullamento in corso...',
        key: LOADING_COMPLETE_MESSAGE_KEY
      });
      isRunning.current = false;
      return;
    }
    props.hide();
  };

  // Se almeno una attività ha dato errore a fine azione massiva, mostro l'alert
  const showErrors = tasksInError.length > 0 && !formik.isSubmitting;

  // Disabilito il pulsante di stop se non ci sono attività rimanenti e se
  // ci sono più di una attività selezionate
  // ! L'annullamento prevede l'interruzione delle prossime attività, quella in esecuzione
  // ! non viene interrotta
  const isStopDisabled =
    progress >= tasks.length - 1 &&
    (tasks.length > 1 || formik.isSubmitting) &&
    !showErrors;

  return (
    <FormikForm layout="vertical" nested editable hideSuccess>
      {showErrors ? (
        <>
          <CompleteTasksMassiveAlert
            taskResults={taskResults}
            tasksInError={tasksInError}
          />
          <Divider />
        </>
      ) : (
        <>
          <FormFieldItem
            component={TextAreaInput}
            disabled={formik.isSubmitting}
            name="finalNotes"
            label="Nota di completamento"
            placeholder="Nota..."
            rows={3}
          />
          <Progress percent={Math.floor((progress / tasks.length) * 100)} />
          <Divider />
        </>
      )}

      <Space>
        <Button
          disabled={isStopDisabled}
          onClick={e => {
            handleClose();
            formik.resetForm();
            e.stopPropagation();
          }}
        >
          {formik.isSubmitting ? 'Annulla' : 'Chiudi'}
        </Button>
        {isRunning.current && !showErrors && (
          <FormikSendButton resetOnSubmit>Completa</FormikSendButton>
        )}
      </Space>
    </FormikForm>
  );
}
