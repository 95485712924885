import { DownOutlined, FilterFilled } from '@ant-design/icons';
import { Button, ButtonProps, Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

const SearchDiv = styled.div`
  .anticon {
    margin-right: 0;
    svg {
      transition: all 0.3s;
    }
    &.anticon-down {
      font-size: 14px;
      vertical-align: middle;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

export interface SearchTitleTabsProps {
  /** Se true, l'icona ruota per indicare l'apertura del collapse */
  opened?: boolean;
  /** Se true, mostra un icona ruota per indicare che è attiva una ricerca
   * e si stanno visualizzando dati filtrati */
  filtered?: boolean;

  children?: React.ReactNode;
}

/**
 * Button per la ricerca da posizionare come Tab
 * Utilizzato per oprire il pannello con il form di ricerca (Collapse)
 */
export function SearchTitleTabs(props: SearchTitleTabsProps) {
  const { opened, children, filtered } = props;
  return (
    <ButtonWrapper>
      <SearchDiv>
        {filtered && (
          <Tooltip title="Filtri di ricerca attivi">
            <FilterFilled />{' '}
          </Tooltip>
        )}
        {children ?? 'Ricerca Estesa'}{' '}
        <DownOutlined rotate={opened ? 180 : 0} />
      </SearchDiv>
    </ButtonWrapper>
  );
}
