import { Button } from 'antd';
import {
  AzioneDTO,
  AzioniDocumento,
  VisualizzaParams
} from 'client/api/backend/schemas';
import { appConfig } from 'client/core/appConfig';
import { joinPath } from 'client/core/network/joinPath';
import QueryString from 'qs';
import * as React from 'react';

export interface IDocumentActionViewButtonProps {
  taskId: string;
  document: AzioniDocumento;
  params: VisualizzaParams;
  buttonText: string;
}

export function DocumentActionViewButton(
  props: IDocumentActionViewButtonProps
) {
  const { taskId, params, document, buttonText } = props;
  return (
    <a
      target="_blank"
      href={joinPath([
        appConfig.remotePath,
        appConfig.apiBackendPath,
        '/attivita/' +
          taskId +
          '/documenti/' +
          document.documento!.idDocumento! +
          `/visualizza` +
          '?' +
          QueryString.stringify(params)
      ])}
    >
      <Button size="large" type="primary" children={buttonText} />
    </a>
  );
}
