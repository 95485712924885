import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { yup } from 'common/validation/initYup';
import React, { useState } from 'react';
import { Button, message, Space, Typography } from 'antd';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import {
  useRiassegna,
  useRiassegnazioneMassiva
} from 'client/api/backend/attivita/attivita';
import { queryClient } from 'client/core/network/queryClient';
import { logger } from 'client/core/logger/logger';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { UserLogic } from 'common/schema/user/UserLogic';
import { AssegneeUserAsyncSelect } from '../../search/AssegneeUserAsyncSelect';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { AttivitaDTO, BEResponseString } from 'client/api/backend/schemas';
const { Text } = Typography;

const assignToOtherValidator = yup
  .object({
    userFiscalId: yup.string().required().label('Utente')
  })
  .required()
  .noUnknown();

/** Ritorna una lista con tutti i messaggi d'errore formattati */
function parseErrors(apiResult: BEResponseString[], tasks?: AttivitaDTO[]) {
  const result = apiResult
    .filter(r => !!r.esito?.errore?.codice)
    .map(error => {
      const task = tasks?.find(task => task.idTaskInstance === error.data);
      if (!task) {
        return (
          error.esito?.errore?.messaggioDiErrore ??
          "Errore durante l'assegnazione"
        );
      }

      return `${task?.procedimento?.tipologiaProcedimento?.nomeTipo} n.${
        task?.procedimento?.numero
      }: ${
        error.esito?.errore?.messaggioDiErrore ??
        "Errore durante l'assegnazione"
      }`;
    });
  return result;
}

export interface IAssignToOtherModalProps {
  taskId: string | string[] | undefined;
  tasks?: AttivitaDTO[];
  visible: boolean;
  hide: () => void;
}

export function AssignToOtherModal(props: IAssignToOtherModalProps) {
  const { taskId, tasks } = props;
  const { visible } = props;
  const [loading, setLoading] = useState(false);
  const currentUser = useCurrentUser();

  const LOADING_MESSAGE_KEY = 'assign-to-other-modal-message';

  const massiveAssign = useRiassegnazioneMassiva();
  const assignToOthers = useRiassegna();

  if (!UserLogic.hasPermission(currentUser, 'assegna_task')) {
    return null;
  }

  return (
    <ModalDialog
      title={'Assegna Attività'}
      visible={visible}
      onCancel={e => {
        if (loading) return;
        props.hide();
      }}
      footer={null}
      maskClosable={!loading}
    >
      <FormikAugmented
        enableReinitialize
        validationSchema={assignToOtherValidator}
        validationContext={{}}
        initialValues={{ userFiscalId: undefined }}
        onSubmit={async (rawValues, helper) => {
          if (!taskId || taskId.length === 0) return;
          const values = await assignToOtherValidator.validate(rawValues);
          try {
            setLoading(true);
            message.loading({ content: 'Assegnamento in corso...', key: LOADING_MESSAGE_KEY }); // prettier-ignore
            //  Se sono più elementi utilizzo l'assegnazione massiva, altrimenti la singola
            if (Array.isArray(taskId)) {
              const result = await massiveAssign.mutateAsync({
                data: {
                  codiceFiscale: values.userFiscalId,
                  idTaskInstanceList: taskId
                }
              });

              const errors = parseErrors(result, tasks);
              message.destroy(LOADING_MESSAGE_KEY);

              const successes = result.length - errors.length;
              if (successes > 0) {
                message.success(`${successes} attività assegnat${successes > 1 ? 'e' : 'a'} con successo`); // prettier-ignore
              }
              errors.forEach(error => { message.error(error, 5); }); // prettier-ignore
            } else {
              await assignToOthers.mutateAsync({
                idTaskInstance: taskId,
                data: { codiceFiscale: values.userFiscalId }
              });

              message.success('Attività assegnata con successo');
            }

            queryClient.invalidateQueries();
          } catch (e) {
            logger.error(`[AssignToOtherModal] Errore durante l'assegnazione: `, {error: e}); // prettier-ignore
            message.error(getNetworkErrorMessage(e, "Errore durante l'assegnazione")); // prettier-ignore
          } finally {
            setLoading(false);
            helper.resetForm();
          }
          props.hide();
        }}
      >
        {formik => (
          <FormikForm nested layout="vertical" editable>
            <Text type="secondary">
              Seleziona l'utente a cui assegnare l'attività.
            </Text>
            {taskId && (
              <AssegneeUserAsyncSelect
                name="userFiscalId"
                disabled={loading}
                // TODO assegnee user massivo?
                taskId={taskId}
              />
            )}
            <Space>
              <Button
                disabled={loading}
                onClick={e => {
                  formik.resetForm();
                  props.hide();
                  e.stopPropagation();
                }}
              >
                Annulla
              </Button>
              <FormikSendButton>Assegna</FormikSendButton>
            </Space>
          </FormikForm>
        )}
      </FormikAugmented>
    </ModalDialog>
  );
}
