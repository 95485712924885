/**
 * Generato automaticamente.
 * Procedimenti
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  BEResponseObject,
  BEResponseBoolean,
  RiassegnaTaskRequest,
  BEResponseCollectionAzioniDichiarazione,
  BEResponseDatiSpecificiRisposta,
  CreazioneDichiarazioneDto,
  BEResponseErroreWS,
  CompletaDTO,
  BEResponseString,
  UpdateAllegatoParams,
  BEResponseListUtenteType,
  RicercaUtentiCandidatiMassivoRequest,
  RilascioMassivoRequest,
  RiassegnaTaskMassivoRequest,
  AttivitaMassivaRequest,
  PostSezioneDocumentoBody,
  GetDocumentoFirmatoParams,
  BEResponseInteger,
  InviaDocumentoFirmatoBody,
  InviaDocumentoFirmatoParams,
  InsertAllegatoBody,
  InsertAllegatoParams,
  BEResponseRicercaAttivitaResponseDTO,
  GetAttivitaParams,
  BEResponseListInfoLavorazioneTaskResponse,
  BEResponseListEsitoDTO,
  BEResponseCollectionAzioniDocumento,
  VisualizzaParams,
  VisualizzaOldParams,
  RecuperaDocumentoPerFirmaParams,
  BEResponseListFileDocumentoExt,
  BEResponseListAzioniGruppoAllegato,
  BEResponseListStatoAttivitaDTO,
  BEResponseNumber,
  GetAttivitaCountParams,
  BEResponseListAzioniFirmaDocumentoResponse,
  GetAzioniFirmaDocumentiParams
} from '.././schemas';
import { backendApiInstance } from '../../../core/network/mutators/apiClient';
import type { ErrorType } from '../../../core/network/mutators/apiClient';

/**
 * Rilascia un'attività dato il suo id e ritorna l'esito dell'operazione
 * @summary Azione di rilascio
 */
export const rilascia = (idTaskInstance: string) => {
  return backendApiInstance<BEResponseObject>({
    url: `/attivita/${idTaskInstance}/rilascia`,
    method: 'put'
  });
};

export type RilasciaMutationResult = NonNullable<
  Awaited<ReturnType<typeof rilascia>>
>;

export type RilasciaMutationError = ErrorType<unknown>;

export const useRilascia = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rilascia>>,
    TError,
    { idTaskInstance: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rilascia>>,
    { idTaskInstance: string }
  > = props => {
    const { idTaskInstance } = props ?? {};

    return rilascia(idTaskInstance);
  };

  return useMutation<
    Awaited<ReturnType<typeof rilascia>>,
    TError,
    { idTaskInstance: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Assegna un'attività a un altro utente dato il suo id e ritorna l'esito dell'operazione
 * @summary Azione di assegnazione a un altro utente
 */
export const riassegna = (
  idTaskInstance: string,
  riassegnaTaskRequest: RiassegnaTaskRequest
) => {
  return backendApiInstance<BEResponseBoolean>({
    url: `/attivita/${idTaskInstance}/riassegna`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: riassegnaTaskRequest
  });
};

export type RiassegnaMutationResult = NonNullable<
  Awaited<ReturnType<typeof riassegna>>
>;
export type RiassegnaMutationBody = RiassegnaTaskRequest;
export type RiassegnaMutationError = ErrorType<unknown>;

export const useRiassegna = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof riassegna>>,
    TError,
    { idTaskInstance: string; data: RiassegnaTaskRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof riassegna>>,
    { idTaskInstance: string; data: RiassegnaTaskRequest }
  > = props => {
    const { idTaskInstance, data } = props ?? {};

    return riassegna(idTaskInstance, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof riassegna>>,
    TError,
    { idTaskInstance: string; data: RiassegnaTaskRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Ritorna una lista di dichiarazione dato un idTask passato
 * @summary Ricerca dichiarazioni
 */
export const getAzioniDichiarazioni = (
  idTaskInstance: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseCollectionAzioniDichiarazione>({
    url: `/attivita/${idTaskInstance}/dichiarazioni`,
    method: 'get',
    signal
  });
};

export const getGetAzioniDichiarazioniQueryKey = (idTaskInstance: string) => [
  `/attivita/${idTaskInstance}/dichiarazioni`
];

export type GetAzioniDichiarazioniQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAzioniDichiarazioni>>
>;
export type GetAzioniDichiarazioniQueryError = ErrorType<unknown>;

export const useGetAzioniDichiarazioni = <
  TData = Awaited<ReturnType<typeof getAzioniDichiarazioni>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAzioniDichiarazioni>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAzioniDichiarazioniQueryKey(idTaskInstance);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAzioniDichiarazioni>>
  > = ({ signal }) => getAzioniDichiarazioni(idTaskInstance, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAzioniDichiarazioni>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idTaskInstance,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Aggiorna tutte le dichiarazioni
 * @summary Update massivo
 */
export const updateDichiarazioni = (
  idTaskInstance: string,
  creazioneDichiarazioneDto: CreazioneDichiarazioneDto[]
) => {
  return backendApiInstance<BEResponseDatiSpecificiRisposta>({
    url: `/attivita/${idTaskInstance}/dichiarazioni`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: creazioneDichiarazioneDto
  });
};

export type UpdateDichiarazioniMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDichiarazioni>>
>;
export type UpdateDichiarazioniMutationBody = CreazioneDichiarazioneDto[];
export type UpdateDichiarazioniMutationError = ErrorType<unknown>;

export const useUpdateDichiarazioni = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDichiarazioni>>,
    TError,
    { idTaskInstance: string; data: CreazioneDichiarazioneDto[] },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateDichiarazioni>>,
    { idTaskInstance: string; data: CreazioneDichiarazioneDto[] }
  > = props => {
    const { idTaskInstance, data } = props ?? {};

    return updateDichiarazioni(idTaskInstance, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateDichiarazioni>>,
    TError,
    { idTaskInstance: string; data: CreazioneDichiarazioneDto[] },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Completa un'attività dato il suo id e ritorna l'esito dell'operazione
 * @summary Azione di completamento
 */
export const completa = (idTaskInstance: string, completaDTO: CompletaDTO) => {
  return backendApiInstance<BEResponseErroreWS>({
    url: `/attivita/${idTaskInstance}/completa`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: completaDTO
  });
};

export type CompletaMutationResult = NonNullable<
  Awaited<ReturnType<typeof completa>>
>;
export type CompletaMutationBody = CompletaDTO;
export type CompletaMutationError = ErrorType<unknown>;

export const useCompleta = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completa>>,
    TError,
    { idTaskInstance: string; data: CompletaDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof completa>>,
    { idTaskInstance: string; data: CompletaDTO }
  > = props => {
    const { idTaskInstance, data } = props ?? {};

    return completa(idTaskInstance, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof completa>>,
    TError,
    { idTaskInstance: string; data: CompletaDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Assegna un'attività dato il suo id e ritorna l'esito dell'operazione
 * @summary Azione di assegnazione
 */
export const assegna = (idTaskInstance: string) => {
  return backendApiInstance<BEResponseObject>({
    url: `/attivita/${idTaskInstance}/assegna`,
    method: 'put'
  });
};

export type AssegnaMutationResult = NonNullable<
  Awaited<ReturnType<typeof assegna>>
>;

export type AssegnaMutationError = ErrorType<unknown>;

export const useAssegna = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assegna>>,
    TError,
    { idTaskInstance: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assegna>>,
    { idTaskInstance: string }
  > = props => {
    const { idTaskInstance } = props ?? {};

    return assegna(idTaskInstance);
  };

  return useMutation<
    Awaited<ReturnType<typeof assegna>>,
    TError,
    { idTaskInstance: string },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Modifica sul database alcuni dati del record associato all'allegato
 * @summary Modifica dati allegato
 */
export const updateAllegato = (
  idTaskInstance: string,
  idAllegato: string,
  params: UpdateAllegatoParams
) => {
  return backendApiInstance<BEResponseString>({
    url: `/attivita/${idTaskInstance}/allegati/${idAllegato}`,
    method: 'put',
    params
  });
};

export type UpdateAllegatoMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAllegato>>
>;

export type UpdateAllegatoMutationError = ErrorType<unknown>;

export const useUpdateAllegato = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAllegato>>,
    TError,
    {
      idTaskInstance: string;
      idAllegato: string;
      params: UpdateAllegatoParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAllegato>>,
    { idTaskInstance: string; idAllegato: string; params: UpdateAllegatoParams }
  > = props => {
    const { idTaskInstance, idAllegato, params } = props ?? {};

    return updateAllegato(idTaskInstance, idAllegato, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateAllegato>>,
    TError,
    {
      idTaskInstance: string;
      idAllegato: string;
      params: UpdateAllegatoParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Effettua la cancellazione di un allegato
 * @summary Cancellazione allegato
 */
export const deleteAllegato = (idTaskInstance: string, idAllegato: string) => {
  return backendApiInstance<BEResponseString>({
    url: `/attivita/${idTaskInstance}/allegati/${idAllegato}`,
    method: 'delete'
  });
};

export type DeleteAllegatoMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAllegato>>
>;

export type DeleteAllegatoMutationError = ErrorType<unknown>;

export const useDeleteAllegato = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAllegato>>,
    TError,
    { idTaskInstance: string; idAllegato: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAllegato>>,
    { idTaskInstance: string; idAllegato: string }
  > = props => {
    const { idTaskInstance, idAllegato } = props ?? {};

    return deleteAllegato(idTaskInstance, idAllegato);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteAllegato>>,
    TError,
    { idTaskInstance: string; idAllegato: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getUtentiCandidatiMassivo = (
  ricercaUtentiCandidatiMassivoRequest: RicercaUtentiCandidatiMassivoRequest
) => {
  return backendApiInstance<BEResponseListUtenteType>({
    url: `/attivita/utentiCandidati`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: ricercaUtentiCandidatiMassivoRequest
  });
};

export type GetUtentiCandidatiMassivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof getUtentiCandidatiMassivo>>
>;
export type GetUtentiCandidatiMassivoMutationBody =
  RicercaUtentiCandidatiMassivoRequest;
export type GetUtentiCandidatiMassivoMutationError = ErrorType<unknown>;

export const useGetUtentiCandidatiMassivo = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getUtentiCandidatiMassivo>>,
    TError,
    { data: RicercaUtentiCandidatiMassivoRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getUtentiCandidatiMassivo>>,
    { data: RicercaUtentiCandidatiMassivoRequest }
  > = props => {
    const { data } = props ?? {};

    return getUtentiCandidatiMassivo(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof getUtentiCandidatiMassivo>>,
    TError,
    { data: RicercaUtentiCandidatiMassivoRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Rilascia un'attività dato il suo id e ritorna l'esito dell'operazione
 * @summary Azione di rilascio Massivo
 */
export const rilascia1 = (rilascioMassivoRequest: RilascioMassivoRequest) => {
  return backendApiInstance<BEResponseString[]>({
    url: `/attivita/rilascia`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: rilascioMassivoRequest
  });
};

export type Rilascia1MutationResult = NonNullable<
  Awaited<ReturnType<typeof rilascia1>>
>;
export type Rilascia1MutationBody = RilascioMassivoRequest;
export type Rilascia1MutationError = ErrorType<unknown>;

export const useRilascia1 = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rilascia1>>,
    TError,
    { data: RilascioMassivoRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rilascia1>>,
    { data: RilascioMassivoRequest }
  > = props => {
    const { data } = props ?? {};

    return rilascia1(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof rilascia1>>,
    TError,
    { data: RilascioMassivoRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Assegna più attività a un altro utente dato il suo id e ritorna l'esito dell'operazione
 * @summary Azione di assegnazione massiva a un altro utente
 */
export const riassegnazioneMassiva = (
  riassegnaTaskMassivoRequest: RiassegnaTaskMassivoRequest
) => {
  return backendApiInstance<BEResponseString[]>({
    url: `/attivita/riassegna`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: riassegnaTaskMassivoRequest
  });
};

export type RiassegnazioneMassivaMutationResult = NonNullable<
  Awaited<ReturnType<typeof riassegnazioneMassiva>>
>;
export type RiassegnazioneMassivaMutationBody = RiassegnaTaskMassivoRequest;
export type RiassegnazioneMassivaMutationError = ErrorType<unknown>;

export const useRiassegnazioneMassiva = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof riassegnazioneMassiva>>,
    TError,
    { data: RiassegnaTaskMassivoRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof riassegnazioneMassiva>>,
    { data: RiassegnaTaskMassivoRequest }
  > = props => {
    const { data } = props ?? {};

    return riassegnazioneMassiva(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof riassegnazioneMassiva>>,
    TError,
    { data: RiassegnaTaskMassivoRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const assegnazioneMassiva = (
  attivitaMassivaRequest: AttivitaMassivaRequest
) => {
  return backendApiInstance<BEResponseString[]>({
    url: `/attivita/assegna`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: attivitaMassivaRequest
  });
};

export type AssegnazioneMassivaMutationResult = NonNullable<
  Awaited<ReturnType<typeof assegnazioneMassiva>>
>;
export type AssegnazioneMassivaMutationBody = AttivitaMassivaRequest;
export type AssegnazioneMassivaMutationError = ErrorType<unknown>;

export const useAssegnazioneMassiva = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assegnazioneMassiva>>,
    TError,
    { data: AttivitaMassivaRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assegnazioneMassiva>>,
    { data: AttivitaMassivaRequest }
  > = props => {
    const { data } = props ?? {};

    return assegnazioneMassiva(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof assegnazioneMassiva>>,
    TError,
    { data: AttivitaMassivaRequest },
    TContext
  >(mutationFn, mutationOptions);
};
export const postSezioneDocumento = (
  idTaskInstance: string,
  idDocumento: string,
  idTipoSezione: string,
  postSezioneDocumentoBody: PostSezioneDocumentoBody
) => {
  const formData = new FormData();
  formData.append('file', postSezioneDocumentoBody.file);

  return backendApiInstance<BEResponseObject>({
    url: `/attivita/${idTaskInstance}/documento/${idDocumento}/sezioniDocumento/${idTipoSezione}`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
};

export type PostSezioneDocumentoMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSezioneDocumento>>
>;
export type PostSezioneDocumentoMutationBody = PostSezioneDocumentoBody;
export type PostSezioneDocumentoMutationError = ErrorType<unknown>;

export const usePostSezioneDocumento = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSezioneDocumento>>,
    TError,
    {
      idTaskInstance: string;
      idDocumento: string;
      idTipoSezione: string;
      data: PostSezioneDocumentoBody;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSezioneDocumento>>,
    {
      idTaskInstance: string;
      idDocumento: string;
      idTipoSezione: string;
      data: PostSezioneDocumentoBody;
    }
  > = props => {
    const { idTaskInstance, idDocumento, idTipoSezione, data } = props ?? {};

    return postSezioneDocumento(
      idTaskInstance,
      idDocumento,
      idTipoSezione,
      data
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof postSezioneDocumento>>,
    TError,
    {
      idTaskInstance: string;
      idDocumento: string;
      idTipoSezione: string;
      data: PostSezioneDocumentoBody;
    },
    TContext
  >(mutationFn, mutationOptions);
};
export const getDocumentoFirmato = (
  idTaskInstance: string,
  idDocumento: string,
  params: GetDocumentoFirmatoParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<string[]>({
    url: `/attivita/${idTaskInstance}/documenti/${idDocumento}/contenutoFirmato`,
    method: 'get',
    params,
    signal
  });
};

export const getGetDocumentoFirmatoQueryKey = (
  idTaskInstance: string,
  idDocumento: string,
  params: GetDocumentoFirmatoParams
) => [
  `/attivita/${idTaskInstance}/documenti/${idDocumento}/contenutoFirmato`,
  ...(params ? [params] : [])
];

export type GetDocumentoFirmatoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentoFirmato>>
>;
export type GetDocumentoFirmatoQueryError = ErrorType<unknown>;

export const useGetDocumentoFirmato = <
  TData = Awaited<ReturnType<typeof getDocumentoFirmato>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  idDocumento: string,
  params: GetDocumentoFirmatoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentoFirmato>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentoFirmatoQueryKey(idTaskInstance, idDocumento, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDocumentoFirmato>>
  > = ({ signal }) =>
    getDocumentoFirmato(idTaskInstance, idDocumento, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getDocumentoFirmato>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(idTaskInstance && idDocumento),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const inviaDocumentoFirmato = (
  idTaskInstance: string,
  idDocumento: string,
  inviaDocumentoFirmatoBody: InviaDocumentoFirmatoBody,
  params: InviaDocumentoFirmatoParams
) => {
  const formData = new FormData();
  formData.append('file', inviaDocumentoFirmatoBody.file);

  return backendApiInstance<BEResponseInteger>({
    url: `/attivita/${idTaskInstance}/documenti/${idDocumento}/contenutoFirmato`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params
  });
};

export type InviaDocumentoFirmatoMutationResult = NonNullable<
  Awaited<ReturnType<typeof inviaDocumentoFirmato>>
>;
export type InviaDocumentoFirmatoMutationBody = InviaDocumentoFirmatoBody;
export type InviaDocumentoFirmatoMutationError = ErrorType<unknown>;

export const useInviaDocumentoFirmato = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof inviaDocumentoFirmato>>,
    TError,
    {
      idTaskInstance: string;
      idDocumento: string;
      data: InviaDocumentoFirmatoBody;
      params: InviaDocumentoFirmatoParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof inviaDocumentoFirmato>>,
    {
      idTaskInstance: string;
      idDocumento: string;
      data: InviaDocumentoFirmatoBody;
      params: InviaDocumentoFirmatoParams;
    }
  > = props => {
    const { idTaskInstance, idDocumento, data, params } = props ?? {};

    return inviaDocumentoFirmato(idTaskInstance, idDocumento, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof inviaDocumentoFirmato>>,
    TError,
    {
      idTaskInstance: string;
      idDocumento: string;
      data: InviaDocumentoFirmatoBody;
      params: InviaDocumentoFirmatoParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Effettua l'upload di un allegato in formato binario
 * @summary Upload allegato
 */
export const insertAllegato = (
  idTaskInstance: string,
  idTipoAllegato: string,
  insertAllegatoBody: InsertAllegatoBody,
  params: InsertAllegatoParams
) => {
  const formData = new FormData();
  formData.append('file', insertAllegatoBody.file);

  return backendApiInstance<BEResponseString>({
    url: `/attivita/${idTaskInstance}/allegati/${idTipoAllegato}/contenutoBinario`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
    params
  });
};

export type InsertAllegatoMutationResult = NonNullable<
  Awaited<ReturnType<typeof insertAllegato>>
>;
export type InsertAllegatoMutationBody = InsertAllegatoBody;
export type InsertAllegatoMutationError = ErrorType<unknown>;

export const useInsertAllegato = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof insertAllegato>>,
    TError,
    {
      idTaskInstance: string;
      idTipoAllegato: string;
      data: InsertAllegatoBody;
      params: InsertAllegatoParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof insertAllegato>>,
    {
      idTaskInstance: string;
      idTipoAllegato: string;
      data: InsertAllegatoBody;
      params: InsertAllegatoParams;
    }
  > = props => {
    const { idTaskInstance, idTipoAllegato, data, params } = props ?? {};

    return insertAllegato(idTaskInstance, idTipoAllegato, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof insertAllegato>>,
    TError,
    {
      idTaskInstance: string;
      idTipoAllegato: string;
      data: InsertAllegatoBody;
      params: InsertAllegatoParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Ritorna una lista paginata di attività secondo i criteri di ricerca
 * @summary Ricerca attività
 */
export const getAttivita = (
  params?: GetAttivitaParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseRicercaAttivitaResponseDTO>({
    url: `/attivita`,
    method: 'get',
    params,
    signal
  });
};

export const getGetAttivitaQueryKey = (params?: GetAttivitaParams) => [
  `/attivita`,
  ...(params ? [params] : [])
];

export type GetAttivitaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAttivita>>
>;
export type GetAttivitaQueryError = ErrorType<unknown>;

export const useGetAttivita = <
  TData = Awaited<ReturnType<typeof getAttivita>>,
  TError = ErrorType<unknown>
>(
  params?: GetAttivitaParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAttivita>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAttivitaQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAttivita>>> = ({
    signal
  }) => getAttivita(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAttivita>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Ritorna la cardinalità della lista di esisti di tipo EsitoDTO di un'attività
 * @summary Esiti [count]
 */
export const esitiCount = (idTask: string, signal?: AbortSignal) => {
  return backendApiInstance<BEResponseInteger>({
    url: `/attivita/${idTask}/esiti/count`,
    method: 'get',
    signal
  });
};

export const getEsitiCountQueryKey = (idTask: string) => [
  `/attivita/${idTask}/esiti/count`
];

export type EsitiCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof esitiCount>>
>;
export type EsitiCountQueryError = ErrorType<unknown>;

export const useEsitiCount = <
  TData = Awaited<ReturnType<typeof esitiCount>>,
  TError = ErrorType<unknown>
>(
  idTask: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof esitiCount>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEsitiCountQueryKey(idTask);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof esitiCount>>> = ({
    signal
  }) => esitiCount(idTask, signal);

  const query = useQuery<Awaited<ReturnType<typeof esitiCount>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!idTask, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getAzioniSuDichiarazioneCount = (
  idTask: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseInteger>({
    url: `/attivita/${idTask}/dichiarazioni/count`,
    method: 'get',
    signal
  });
};

export const getGetAzioniSuDichiarazioneCountQueryKey = (idTask: string) => [
  `/attivita/${idTask}/dichiarazioni/count`
];

export type GetAzioniSuDichiarazioneCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAzioniSuDichiarazioneCount>>
>;
export type GetAzioniSuDichiarazioneCountQueryError = ErrorType<unknown>;

export const useGetAzioniSuDichiarazioneCount = <
  TData = Awaited<ReturnType<typeof getAzioniSuDichiarazioneCount>>,
  TError = ErrorType<unknown>
>(
  idTask: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAzioniSuDichiarazioneCount>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAzioniSuDichiarazioneCountQueryKey(idTask);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAzioniSuDichiarazioneCount>>
  > = ({ signal }) => getAzioniSuDichiarazioneCount(idTask, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAzioniSuDichiarazioneCount>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idTask,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getUtentiCandidati = (
  idTaskInstance: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseListUtenteType>({
    url: `/attivita/${idTaskInstance}/utentiCandidati`,
    method: 'get',
    signal
  });
};

export const getGetUtentiCandidatiQueryKey = (idTaskInstance: string) => [
  `/attivita/${idTaskInstance}/utentiCandidati`
];

export type GetUtentiCandidatiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUtentiCandidati>>
>;
export type GetUtentiCandidatiQueryError = ErrorType<unknown>;

export const useGetUtentiCandidati = <
  TData = Awaited<ReturnType<typeof getUtentiCandidati>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUtentiCandidati>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUtentiCandidatiQueryKey(idTaskInstance);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUtentiCandidati>>
  > = ({ signal }) => getUtentiCandidati(idTaskInstance, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getUtentiCandidati>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idTaskInstance,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getInfoLavorazioneTask = (
  idTaskInstance: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseListInfoLavorazioneTaskResponse>({
    url: `/attivita/${idTaskInstance}/infoLavorazioneTask`,
    method: 'get',
    signal
  });
};

export const getGetInfoLavorazioneTaskQueryKey = (idTaskInstance: string) => [
  `/attivita/${idTaskInstance}/infoLavorazioneTask`
];

export type GetInfoLavorazioneTaskQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInfoLavorazioneTask>>
>;
export type GetInfoLavorazioneTaskQueryError = ErrorType<unknown>;

export const useGetInfoLavorazioneTask = <
  TData = Awaited<ReturnType<typeof getInfoLavorazioneTask>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInfoLavorazioneTask>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInfoLavorazioneTaskQueryKey(idTaskInstance);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInfoLavorazioneTask>>
  > = ({ signal }) => getInfoLavorazioneTask(idTaskInstance, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getInfoLavorazioneTask>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idTaskInstance,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Ritorna una lista di esisti di tipo EsitoDTO di un'attività
 * @summary Esiti
 */
export const getEsiti = (idTaskInstance: string, signal?: AbortSignal) => {
  return backendApiInstance<BEResponseListEsitoDTO>({
    url: `/attivita/${idTaskInstance}/esiti`,
    method: 'get',
    signal
  });
};

export const getGetEsitiQueryKey = (idTaskInstance: string) => [
  `/attivita/${idTaskInstance}/esiti`
];

export type GetEsitiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEsiti>>
>;
export type GetEsitiQueryError = ErrorType<unknown>;

export const useGetEsiti = <
  TData = Awaited<ReturnType<typeof getEsiti>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEsiti>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetEsitiQueryKey(idTaskInstance);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEsiti>>> = ({
    signal
  }) => getEsiti(idTaskInstance, signal);

  const query = useQuery<Awaited<ReturnType<typeof getEsiti>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!idTaskInstance, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getContenutoSezioneDocumento = (
  idTaskInstance: string,
  idDocumento: string,
  idTipoSezione: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<string[]>({
    url: `/attivita/${idTaskInstance}/documento/${idDocumento}/sezioniDocumento/${idTipoSezione}/contenuto`,
    method: 'get',
    signal
  });
};

export const getGetContenutoSezioneDocumentoQueryKey = (
  idTaskInstance: string,
  idDocumento: string,
  idTipoSezione: string
) => [
  `/attivita/${idTaskInstance}/documento/${idDocumento}/sezioniDocumento/${idTipoSezione}/contenuto`
];

export type GetContenutoSezioneDocumentoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContenutoSezioneDocumento>>
>;
export type GetContenutoSezioneDocumentoQueryError = ErrorType<unknown>;

export const useGetContenutoSezioneDocumento = <
  TData = Awaited<ReturnType<typeof getContenutoSezioneDocumento>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  idDocumento: string,
  idTipoSezione: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getContenutoSezioneDocumento>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetContenutoSezioneDocumentoQueryKey(
      idTaskInstance,
      idDocumento,
      idTipoSezione
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getContenutoSezioneDocumento>>
  > = ({ signal }) =>
    getContenutoSezioneDocumento(
      idTaskInstance,
      idDocumento,
      idTipoSezione,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getContenutoSezioneDocumento>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(idTaskInstance && idDocumento && idTipoSezione),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getAzioniDocumento = (
  idTaskInstance: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseCollectionAzioniDocumento>({
    url: `/attivita/${idTaskInstance}/documenti`,
    method: 'get',
    signal
  });
};

export const getGetAzioniDocumentoQueryKey = (idTaskInstance: string) => [
  `/attivita/${idTaskInstance}/documenti`
];

export type GetAzioniDocumentoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAzioniDocumento>>
>;
export type GetAzioniDocumentoQueryError = ErrorType<unknown>;

export const useGetAzioniDocumento = <
  TData = Awaited<ReturnType<typeof getAzioniDocumento>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAzioniDocumento>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAzioniDocumentoQueryKey(idTaskInstance);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAzioniDocumento>>
  > = ({ signal }) => getAzioniDocumento(idTaskInstance, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAzioniDocumento>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idTaskInstance,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * produzione del Documento in modo da consentire la verifica di completezza di dichiarazioni e sezioni
 * @summary Visualizza Documento
 */
export const visualizza = (
  idTaskInstance: string,
  idDocumento: string,
  params: VisualizzaParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<string[]>({
    url: `/attivita/${idTaskInstance}/documenti/${idDocumento}/visualizza`,
    method: 'get',
    params,
    signal
  });
};

export const getVisualizzaQueryKey = (
  idTaskInstance: string,
  idDocumento: string,
  params: VisualizzaParams
) => [
  `/attivita/${idTaskInstance}/documenti/${idDocumento}/visualizza`,
  ...(params ? [params] : [])
];

export type VisualizzaQueryResult = NonNullable<
  Awaited<ReturnType<typeof visualizza>>
>;
export type VisualizzaQueryError = ErrorType<unknown>;

export const useVisualizza = <
  TData = Awaited<ReturnType<typeof visualizza>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  idDocumento: string,
  params: VisualizzaParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof visualizza>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getVisualizzaQueryKey(idTaskInstance, idDocumento, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof visualizza>>> = ({
    signal
  }) => visualizza(idTaskInstance, idDocumento, params, signal);

  const query = useQuery<Awaited<ReturnType<typeof visualizza>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(idTaskInstance && idDocumento), ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * produzione del Documento in modo da consentire la verifica di completezza di dichiarazioni e sezioni
 * @summary Visualizza Documento
 */
export const visualizzaOld = (
  idTaskInstance: string,
  idDocumento: string,
  params: VisualizzaOldParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<string[]>({
    url: `/attivita/${idTaskInstance}/documenti/${idDocumento}/visualizzaOld`,
    method: 'get',
    params,
    signal
  });
};

export const getVisualizzaOldQueryKey = (
  idTaskInstance: string,
  idDocumento: string,
  params: VisualizzaOldParams
) => [
  `/attivita/${idTaskInstance}/documenti/${idDocumento}/visualizzaOld`,
  ...(params ? [params] : [])
];

export type VisualizzaOldQueryResult = NonNullable<
  Awaited<ReturnType<typeof visualizzaOld>>
>;
export type VisualizzaOldQueryError = ErrorType<unknown>;

export const useVisualizzaOld = <
  TData = Awaited<ReturnType<typeof visualizzaOld>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  idDocumento: string,
  params: VisualizzaOldParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof visualizzaOld>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getVisualizzaOldQueryKey(idTaskInstance, idDocumento, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof visualizzaOld>>> = ({
    signal
  }) => visualizzaOld(idTaskInstance, idDocumento, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof visualizzaOld>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(idTaskInstance && idDocumento),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Recupero di un documento per firmarlo o per visualizzarlo firmato
 * @summary Recupera documento per firma
 */
export const recuperaDocumentoPerFirma = (
  idTaskInstance: string,
  idDocumento: string,
  params: RecuperaDocumentoPerFirmaParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<string[]>({
    url: `/attivita/${idTaskInstance}/documenti/${idDocumento}/firma`,
    method: 'get',
    params,
    signal
  });
};

export const getRecuperaDocumentoPerFirmaQueryKey = (
  idTaskInstance: string,
  idDocumento: string,
  params: RecuperaDocumentoPerFirmaParams
) => [
  `/attivita/${idTaskInstance}/documenti/${idDocumento}/firma`,
  ...(params ? [params] : [])
];

export type RecuperaDocumentoPerFirmaQueryResult = NonNullable<
  Awaited<ReturnType<typeof recuperaDocumentoPerFirma>>
>;
export type RecuperaDocumentoPerFirmaQueryError = ErrorType<unknown>;

export const useRecuperaDocumentoPerFirma = <
  TData = Awaited<ReturnType<typeof recuperaDocumentoPerFirma>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  idDocumento: string,
  params: RecuperaDocumentoPerFirmaParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof recuperaDocumentoPerFirma>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getRecuperaDocumentoPerFirmaQueryKey(idTaskInstance, idDocumento, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof recuperaDocumentoPerFirma>>
  > = ({ signal }) =>
    recuperaDocumentoPerFirma(idTaskInstance, idDocumento, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof recuperaDocumentoPerFirma>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(idTaskInstance && idDocumento),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Restituisce la lista dei documenti da firmare associati ad un idTaskInstance
 * @summary Lista documenti
 */
export const getFileDocumentiByTaskInstance = (
  idTaskInstance: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseListFileDocumentoExt>({
    url: `/attivita/${idTaskInstance}/documenti/elenco`,
    method: 'get',
    signal
  });
};

export const getGetFileDocumentiByTaskInstanceQueryKey = (
  idTaskInstance: string
) => [`/attivita/${idTaskInstance}/documenti/elenco`];

export type GetFileDocumentiByTaskInstanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFileDocumentiByTaskInstance>>
>;
export type GetFileDocumentiByTaskInstanceQueryError = ErrorType<unknown>;

export const useGetFileDocumentiByTaskInstance = <
  TData = Awaited<ReturnType<typeof getFileDocumentiByTaskInstance>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFileDocumentiByTaskInstance>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetFileDocumentiByTaskInstanceQueryKey(idTaskInstance);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFileDocumentiByTaskInstance>>
  > = ({ signal }) => getFileDocumentiByTaskInstance(idTaskInstance, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getFileDocumentiByTaskInstance>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idTaskInstance,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Elenco delle azioni su Allegati di procedimento previste in un dato task, incluse le informazioni relative agli eventuali file fisici eventualmente conferiti e non cancellati logicamente.
 * @summary Azioni su allegati
 */
export const getAzioniGruppoAllegati = (
  idTaskInstance: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseListAzioniGruppoAllegato>({
    url: `/attivita/${idTaskInstance}/allegati`,
    method: 'get',
    signal
  });
};

export const getGetAzioniGruppoAllegatiQueryKey = (idTaskInstance: string) => [
  `/attivita/${idTaskInstance}/allegati`
];

export type GetAzioniGruppoAllegatiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAzioniGruppoAllegati>>
>;
export type GetAzioniGruppoAllegatiQueryError = ErrorType<unknown>;

export const useGetAzioniGruppoAllegati = <
  TData = Awaited<ReturnType<typeof getAzioniGruppoAllegati>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAzioniGruppoAllegati>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAzioniGruppoAllegatiQueryKey(idTaskInstance);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAzioniGruppoAllegati>>
  > = ({ signal }) => getAzioniGruppoAllegati(idTaskInstance, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAzioniGruppoAllegati>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!idTaskInstance,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Restituisce un allegato in formato binario
 * @summary Download allegato
 */
export const getAllegato = (
  idTaskInstance: string,
  idAllegato: string,
  signal?: AbortSignal
) => {
  return backendApiInstance<string[]>({
    url: `/attivita/${idTaskInstance}/allegati/${idAllegato}/contenutoBinario`,
    method: 'get',
    signal
  });
};

export const getGetAllegatoQueryKey = (
  idTaskInstance: string,
  idAllegato: string
) => [`/attivita/${idTaskInstance}/allegati/${idAllegato}/contenutoBinario`];

export type GetAllegatoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllegato>>
>;
export type GetAllegatoQueryError = ErrorType<unknown>;

export const useGetAllegato = <
  TData = Awaited<ReturnType<typeof getAllegato>>,
  TError = ErrorType<unknown>
>(
  idTaskInstance: string,
  idAllegato: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllegato>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllegatoQueryKey(idTaskInstance, idAllegato);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllegato>>> = ({
    signal
  }) => getAllegato(idTaskInstance, idAllegato, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAllegato>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(idTaskInstance && idAllegato),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Ritorna una lista con tutti i possibili stati di un'attività, di tipo StatoAttivitaDTO
 * @summary Stati attività
 */
export const getStati = (signal?: AbortSignal) => {
  return backendApiInstance<BEResponseListStatoAttivitaDTO>({
    url: `/attivita/stati`,
    method: 'get',
    signal
  });
};

export const getGetStatiQueryKey = () => [`/attivita/stati`];

export type GetStatiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStati>>
>;
export type GetStatiQueryError = ErrorType<unknown>;

export const useGetStati = <
  TData = Awaited<ReturnType<typeof getStati>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getStati>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStatiQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStati>>> = ({
    signal
  }) => getStati(signal);

  const query = useQuery<Awaited<ReturnType<typeof getStati>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Ritorna la cardinalità della lista paginata di attività secondo i criteri di ricerca
 * @summary Ricerca attività [count]
 */
export const getAttivitaCount = (
  params?: GetAttivitaCountParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseNumber>({
    url: `/attivita/count`,
    method: 'get',
    params,
    signal
  });
};

export const getGetAttivitaCountQueryKey = (
  params?: GetAttivitaCountParams
) => [`/attivita/count`, ...(params ? [params] : [])];

export type GetAttivitaCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAttivitaCount>>
>;
export type GetAttivitaCountQueryError = ErrorType<unknown>;

export const useGetAttivitaCount = <
  TData = Awaited<ReturnType<typeof getAttivitaCount>>,
  TError = ErrorType<unknown>
>(
  params?: GetAttivitaCountParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAttivitaCount>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAttivitaCountQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAttivitaCount>>
  > = ({ signal }) => getAttivitaCount(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAttivitaCount>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

export const getAzioniFirmaDocumenti = (
  params: GetAzioniFirmaDocumentiParams,
  signal?: AbortSignal
) => {
  return backendApiInstance<BEResponseListAzioniFirmaDocumentoResponse>({
    url: `/attivita/azioniFirmaDocumenti`,
    method: 'get',
    params,
    signal
  });
};

export const getGetAzioniFirmaDocumentiQueryKey = (
  params: GetAzioniFirmaDocumentiParams
) => [`/attivita/azioniFirmaDocumenti`, ...(params ? [params] : [])];

export type GetAzioniFirmaDocumentiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAzioniFirmaDocumenti>>
>;
export type GetAzioniFirmaDocumentiQueryError = ErrorType<unknown>;

export const useGetAzioniFirmaDocumenti = <
  TData = Awaited<ReturnType<typeof getAzioniFirmaDocumenti>>,
  TError = ErrorType<unknown>
>(
  params: GetAzioniFirmaDocumentiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAzioniFirmaDocumenti>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAzioniFirmaDocumentiQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAzioniFirmaDocumenti>>
  > = ({ signal }) => getAzioniFirmaDocumenti(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAzioniFirmaDocumenti>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};
