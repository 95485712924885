import * as React from 'react';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { Tabs } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { TabHistory } from './tab/TabHistory';
import { TabTask } from './tab/TabTask';
import { TabNotes } from './tab/TabNotes';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { TabsPageHeading } from 'client/ui/tabs/TabsPageHeading';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { TabTaskPortalContainer } from './tab/TabTaskPortal';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { useTask } from 'client/components/schema/task/hooks/useTask';
import { TaskActionLogic } from 'client/logic/TaskActionLogic';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { PageTitle } from 'client/ui/layout/page/heading/PageTitle';
const { TabPane } = Tabs;
import { TbHistory, TbMessage, TbEdit } from 'react-icons/tb';
import { NameResponsive } from 'client/ui/tabs/NameToIconResponsive';
import { CSSProperties, useState } from 'react';

function getVisibilityStyle(tab: string, key: string) {
  return tab === key
    ? undefined
    : ({ display: 'none', visibility: 'hidden' } as CSSProperties);
}

interface Params {
  id: string;
}

export interface TaskPageProps extends RouteComponentProps<Params> {}

export function TaskPage(props: TaskPageProps) {
  const [visibleTab, setVisibleTab] = useState('task');
  const currentUser = useCurrentUser();

  const taskId = props.match.params.id;
  const { task, loading, error, notFound } = useTask(taskId);

  useBreadcrumbItem({ path: `/task/list`, title: `Elenco Attività` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: `${task?.name} - ${task?.procedimento?.nomeFaseCorrente}`
  });

  if (loading) {
    return <LoadingPage full />;
  }

  if (notFound) {
    return (
      <AlertPage
        showIcon
        type="error"
        message="Attività non trovata"
        description={`Nessuna attività trovata con l'ID specificato.`}
      />
    );
  }

  if (error) {
    return (
      <NetworkAlertPage
        message={"Si è verificato un errore durante l'accesso all'attività"}
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <>
        <PageHeading
          breadcrumb={<BreadcrumbsContainer />}
          title={<PageTitle procedure={task?.procedimento} name={task?.name} />}
          extra={<TabTaskPortalContainer />}
          footer={
            <TabsPageHeading
              defaultActiveKey={visibleTab ?? 'task'}
              onChange={key => setVisibleTab(key)}
            >
              <TabPane
                key="task"
                tab={<NameResponsive name="Attività" icon={<TbEdit />} />}
              />
              <TabPane
                key="notes"
                tab={<NameResponsive name="Note" icon={<TbMessage />} />}
              />
              <TabPane
                key="history"
                tab={<NameResponsive name="Cronologia" icon={<TbHistory />} />}
              />
            </TabsPageHeading>
          }
        />
        {/* PageContent */}
        <PageContent>
          <div style={getVisibilityStyle(visibleTab, 'task')}>
            <TabTask
              task={task}
              isAssigned={TaskActionLogic.isAssigned(task, currentUser)}
            />
          </div>
          <div style={getVisibilityStyle(visibleTab, 'notes')}>
            <TabNotes procedureId={task?.procedimento?.id} />
          </div>
          <div style={getVisibilityStyle(visibleTab, 'history')}>
            <TabHistory procedureId={task?.procedimento?.id} />
          </div>
        </PageContent>
      </>
    </CorePageLayout>
  );
}
