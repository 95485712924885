import { Menu, message } from 'antd';
import {
  Documento,
  RecuperaDocumentoPerFirmaParams
} from 'client/api/backend/schemas';
import { WebSocketKind } from 'client/components/digital-signature/DigitalSignatureProvider';
import { JnlpService } from 'client/components/schema/task/jnlp/JnlpService';
import {
  ISignJnlpWindowManagerHandle,
  SignJnlpWindowManager
} from 'client/components/schema/task/jnlp/SignJnlpWindowManager';
import { appConfig } from 'client/core/appConfig';
import React, { useCallback, useEffect } from 'react';

export interface ISignManagerDownloadActionProps {
  taskId: string;
  document: Documento;
  params: RecuperaDocumentoPerFirmaParams;
  // openModal: (objectUrl: string, document: Documento) => void;
}

export function SignManagerDownloadAction(
  props: ISignManagerDownloadActionProps
) {
  const { taskId, document, params, ...otherProps } = props;
  const documentId = document.idDocumento;
  const [objectUrl, setObjectUrl] = React.useState<string | null>(null);
  const signManager = JnlpService.useDocumentSignManager();

  const jnlpWindowRef = React.useRef<ISignJnlpWindowManagerHandle>(null);

  const openModal = useCallback(
    (objectUrl: string, document: Documento) => {
      // Apriamo la modal
      jnlpWindowRef.current?.open(objectUrl, document);
    },
    [jnlpWindowRef.current?.open]
  );

  useEffect(() => {
    return () => {
      if (objectUrl) {
        JnlpService.revokeObjectUrl(objectUrl);
      }
    };
  }, [objectUrl]);

  return (
    <>
      <Menu.Item
        {...otherProps}
        onClick={async () => {
          try {
            let result = await signManager.getJnlp(taskId, documentId, params);

            if (!result) {
              throw new Error('Impossibile effettuare il download');
            }

            if (appConfig.useFvProxy) {
              result = JnlpService.setFvProxy(result, WebSocketKind.Sign);
            }

            const resultObjectUrl = JnlpService.getObjectUrl(result);

            // Rimuovo l'URL precedente ed imposto il nuovo
            if (objectUrl) {
              JnlpService.revokeObjectUrl(objectUrl);
            }
            setObjectUrl(resultObjectUrl);

            // Apro il file
            JnlpService.downloadFile(resultObjectUrl, 'firmaManager.jnlp');

            // Apriamo la modal
            openModal(resultObjectUrl!, document);
          } catch (e) {
            message.error(`Si è verificato un errore durante l'apertura dell'applicativo.`); // prettier-ignore
            console.error(e);
          }
        }}
      >
        Firma Manager
      </Menu.Item>
      <SignJnlpWindowManager
        ref={jnlpWindowRef}
        taskId={props.taskId}
        document={document}
      />
    </>
  );
}
