/**
 * Generato automaticamente.
 * Procedimenti
 */

export type GetAttivitaSortDirection =
  typeof GetAttivitaSortDirection[keyof typeof GetAttivitaSortDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAttivitaSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC'
} as const;
