import { Allegato, AzioniGruppoAllegato } from 'client/api/backend/schemas';
import { arrayToObject } from 'client/core/utils/arrayToObject';
import { yup } from 'common/validation/initYup';
import { merge } from 'lodash';
import { AttachmentActionLogic } from '../../declaration/actions/AttachmentActionLogic';

const MB_TO_B = 1024 * 1024;

/**
 * Dato un documento, genera i validatori dei gruppi di allegati.
 */
export function createAttachmentGroupValidator(
  attachmentGroups?: AzioniGruppoAllegato[] | null | undefined,
  partial?: boolean
) {
  if (!attachmentGroups) return yup.object().strip();
  return arrayToObject(
    attachmentGroups.map(group => {
      if (!group.gruppoAllegati?.idGruppoAllegato) return yup.object().strip();
      return {
        [group.gruppoAllegati.idGruppoAllegato]: yup
          .object(
            createAttachmentsValidator(
              AttachmentActionLogic.isAttachmentGroupEditable(group.azioni),
              partial,
              group.gruppoAllegati?.allegati ?? []
            )
          )
          .required()
          .noUnknown()
      };
    })
  );
}

/**
 * Dato il gruppo di allegati genera il validatore per ogni allegato
 */
function createAttachmentsValidator(
  isGroupEditable: boolean,
  partial?: boolean,
  attachments?: Allegato[]
) {
  if (!attachments) return yup.object().strip();

  return arrayToObject(
    attachments?.map(attachment => {
      let schema = yup
        .array()
        .of(getFileValidator(attachment))
        .label(attachment.denominazioneAllegato ?? "l'allegato");

      // Non è obbligatorio inserire un file se il gruppo allegati non è editabile
      if (!isGroupEditable || partial) {
        return {
          [attachment.idTipoAllegato!]: schema
        };
      }

      if (attachment.flAllegatoObbligatorio) {
        schema = schema.min(
          attachment.flAllegatoObbligatorio ? 1 : 0,
          'È obbligatorio inserire almeno un file per ${label}'
        );
      }

      if (attachment.numMaxFile) {
        schema = schema.max(attachment.numMaxFile);
      }
      return {
        [attachment.idTipoAllegato!]: schema
      };
    })
  );
}

/**
 * Ritorna il validatore del file:
 * - Una descrizione
 * - Un oggetto con nome e dimensione per i check e per tenere traccia dei documenti caricati
 */
function getFileValidator(attachment: Allegato) {
  return yup.object({
    description: yup.string().notRequired().nullable().label(`Descrizione`),
    file: yup
      .object({
        name: yup.string().required(),
        size: yup
          .number()
          .required()
          .min(1, 'Impossibile caricare file vuoto')
          .max(
            attachment.dimMaxFile && attachment.dimMaxFile != 0
              ? attachment.dimMaxFile * MB_TO_B
              : 256 * MB_TO_B, // Un default massimo di 256 MB
            `Il file fornito supera la dimensione massima permessa`
          )
      })
      .notRequired()
  });
}
