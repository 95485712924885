import { useGetEsiti } from 'client/api/backend/attivita/attivita';
import {
  EsitoDTO,
  TipoDichiarazioneDTO,
  TipoDocumentoDTO,
  TipoGruppoAllegatoDTO,
  TipoSezioneDTO
} from 'client/api/backend/schemas';
import { fromPairs } from 'lodash';
import { useEffect, useState } from 'react';
import {
  AttachmentsAction,
  DeclarationAction,
  DocumentAction,
  DocumentSectionAction
} from '../declaration/actions/DeclarationActions';

export function useOutcomes(taskId: string | undefined) {
  const result = useGetEsiti(taskId!, { query: { enabled: Boolean(taskId) } });
  const [outcomes, setOutcomes] = useState<EsitoDTO[]>([]);
  const [constraints, setConstraints] = useState<IOutcomeConstraints>({});

  useEffect(() => {
    // Ordina gli esiti per priorità crescente (priorità bassa = maggiore importanza)
    const newOutcomes =
      result?.data?.data?.sort(
        (a, b) => (a.priorita ?? 0) - (b.priorita ?? 0)
      ) ?? [];

    // Per ogni outcome, elenco le azioni obbligatorie
    // sui suoi documenti e sulle sue sezioni
    const newConstraints = fromPairs(
      newOutcomes.map(outcome => {
        const documents = getDocuments(outcome.tipiDocumenti);
        const sections = getSections(outcome.tipiSezioni);
        const attachments = getAttachments(outcome.tipiGruppiAllegati);
        const declarations = getDeclarations(outcome.tipiDichiarazioni);
        return [
          outcome.id,
          {
            documents,
            sections,
            attachments,
            declarations,
            priority: outcome.priorita ?? 0
          }
        ];
      })
    );

    setOutcomes(newOutcomes);
    setConstraints(newConstraints);
  }, [result.data?.data]);

  if (!taskId) return {};

  return {
    // Ritorna gli esiti ordinati per priorità
    outcomes,
    outcomesConstraints: constraints,
    result
  };
}

export interface IOutcomeConstraints {
  [key: string]: {
    priority: number;
    declarations: { [id: string]: DeclarationAction[] };
    documents: { [id: string]: DocumentAction[] };
    sections: { [id: string]: DocumentSectionAction[] };
    attachments: { [id: string]: AttachmentsAction[] };
  };
}

/** Genera la struttura dell'oggetto documento con `{[idDoc]: Azioni[]}`  */
function getDocuments(documentTypes: TipoDocumentoDTO[] | undefined) {
  if (!documentTypes) return {};

  return fromPairs(
    documentTypes.map(document => [
      document.idTipoDocumento!,
      document.azioni?.map(action => action.idAzione!) ?? []
    ])
  );
}

/** Genera la struttura dell'oggetto sezione con `{[idSezione]: Azioni[]}` */
function getSections(sectionTypes: TipoSezioneDTO[] | undefined) {
  if (!sectionTypes) return {};

  return fromPairs(
    sectionTypes.map(section => [
      section.idTipoSezione,
      section.azioni?.map(action => action.idAzione!) ?? []
    ])
  );
}

/** Genera la struttura dell'oggetto dichiaraze con `{[idDichiarazione]: Azioni[]}` */
function getDeclarations(declarationTypes: TipoDichiarazioneDTO[] | undefined) {
  if (!declarationTypes) return {};

  return fromPairs(
    declarationTypes.map(declaration => [
      declaration.idTipoDichiarazione,
      declaration.azioni?.map(action => action.idAzione!) ?? []
    ])
  );
}

/** Genera la struttura dell'oggetto gruppo allegati con `{[idAllegato]: Azioni[]}` */
function getAttachments(attachmentTypes: TipoGruppoAllegatoDTO[] | undefined) {
  if (!attachmentTypes) return {};

  return fromPairs(
    attachmentTypes.map(attachment => [
      attachment.idTipoGruppoAllegato,
      attachment.azioni?.map(action => action.idAzione!) ?? []
    ])
  );
}
