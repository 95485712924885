import {
  AzioniSezione,
  AzioneDTO,
  AzioniDocumento
} from 'client/api/backend/schemas';
import { JnlpService } from 'client/components/schema/task/jnlp/JnlpService';
import React from 'react';
import { SectionFastActionButton } from './SectionFastActionButton';
import { SectionStandardActionButton } from './SectionStandardActionButton';

export interface ISectionActionButtonProps {
  section: AzioniSezione;
  action: AzioneDTO;
  taskId: string;
  document: AzioniDocumento;
}

/**
 * Pulsante per l'azione sulla singola sezione.
 */
// TODO: Unificare in un unico pulsante con Dropdown
// TODO/HI: Gestire ulteriori azioni (per ora c'è solo modifica)
export function SectionActionButton(props: ISectionActionButtonProps) {
  if (JnlpService.isStandardJnlp()) {
    return <SectionStandardActionButton {...props} />;
  } else {
    return <SectionFastActionButton {...props} />;
  }
}
