import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div<OnPageAlertContainerProps>`
  padding: 48px 32px;
  max-width: 900px;
  margin: auto;
`;

export interface OnPageAlertContainerProps {
  className?: string;
  children: React.ReactNode;
}

export function OnPageAlertContainer(props: OnPageAlertContainerProps) {
  return <Container className={props.className}>{props.children}</Container>;
}
