import { Tooltip } from 'antd';
import React from 'react';
import { MdOutlinePostAdd } from 'react-icons/md';
import { Icon24px } from '../FileRow';

export function IntegralPartIcon() {
  return (
    <Tooltip title="Parte Integrante">
      <Icon24px icon={<MdOutlinePostAdd />} />
    </Tooltip>
  );
}
