import { Button, ButtonProps } from 'antd';
import { useFormikContext } from 'formik';
import * as React from 'react';

export interface IFormikSendButtonProps extends ButtonProps {
  resetOnSubmit?: boolean;
}

export function FormikSendButton(props: IFormikSendButtonProps) {
  const formik = useFormikContext();
  return (
    <Button
      type="primary"
      disabled={formik.isSubmitting}
      loading={formik.isSubmitting}
      {...props}
      onClick={async e => {
        await formik.submitForm();
        if (props.resetOnSubmit) formik.resetForm();
        e.stopPropagation();
      }}
    />
  );
}
