import * as React from 'react';
import { Note } from 'client/components/schema/note/detail/Note';
import { UserInfoLine } from 'client/components/schema/note/detail/UserInfoLine';
import { TaskInfoLine } from 'client/components/schema/note/detail/TaskInfoLine';
import { ITaskNote } from 'client/routes/procedure/single/useProcedureNotes';
import { useCurrentUser } from 'client/components/auth/AuthModule';

export interface NoteItemProps {
  note: ITaskNote;
}
/**
 * La singola nota dell'utente relativa ad una attività
 */
export function NoteItem(props: NoteItemProps) {
  const { note } = props;

  const currentUser = useCurrentUser();

  const isSameUser =
    currentUser?.utenteLoggato?.codiceFiscale === note.userFiscalNumber;

  return (
    <Note
      isCurrentUser={isSameUser}
      name={note.userName}
      beforeContent={<UserInfoLine name={note.userName!} />}
      afterContent={
        <TaskInfoLine
          date={note.outcomeDate!}
          action={note.outcome!}
          task={note.taskName!}
        />
      }
    >
      {note.note}
    </Note>
  );
}
