import { yup } from 'common/validation/initYup';

export const outcomeValidator = {
  outcome: yup
    .object({
      id: yup.string().required(),
      variable: yup.string().required(),
      required: yup.boolean().notRequired(),
      finalNotes: yup
        .string()
        .when('required', (flag, schema) =>
          flag
            ? schema
                .required('È obbligatorio compilare le note di validazione')
                .min(1, 'Le note non possono essere vuote')
            : schema.notRequired()
        )
        .label('Note di validazione')
    })
    .required()
    .noUnknown()
};
