import React from 'react';
import { Breadcrumbs } from 'client/core/router/breadcrumb/Breadcrumbs';
import styled from 'styled-components';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';

const BreadcrumbsWrapper = styled.div`
  position: relative;
  margin-bottom: 6px;
`;

export interface BreadcrumbsContainerProps {}

/*
 * Contiene il breadcrumb e gli stili per il posizionamento nel Layout
 */
export function BreadcrumbsContainer(props: BreadcrumbsContainerProps) {
  const isMobile = useBreakpoint(down('sm'));
  // Nella versione  mobile nonmostriamo i breadcrumbs
  if (isMobile) return null;

  return (
    <BreadcrumbsWrapper>
      <Breadcrumbs />
    </BreadcrumbsWrapper>
  );
}
