import React from 'react';
import styled from 'styled-components';

const Border = styled.div`
  background-color: ${props => props.theme.primaryColor};
  height: 10px;
  border-radius: ${props => props.theme.borderRadiusBase}
    ${props => props.theme.borderRadiusBase} 0 0;
`;

export interface CardCoverActionProps {}

/**
 * TODO
 */
export function CardCoverAction(props: CardCoverActionProps) {
  return <Border />;
}
