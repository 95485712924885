import React, { useEffect, useState } from 'react';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { Alert, Button, Space, Tabs } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import {
  columnsTaskListTableMobile,
  getColumnsTaskListTable
} from './table/columnsTaskListTable';
import { TabsPageHeading } from 'client/ui/tabs/TabsPageHeading';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { TaskSearchForm } from './search/TaskSearchForm';
import { SearchCollapseContainer } from '../../../ui/collapse/SearchCollapseContainer';
import { SearchOutlined } from '@ant-design/icons';
import { SearchTitleTabs } from 'client/ui/tabs/SearchTitleTabs';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { getAttivitaParamsValidator } from 'client/components/schema/task/validation/getAttivitaParamsValidator';
import { TableData } from 'client/ui/table/TableData';
import { SorterResult } from 'antd/lib/table/interface';
import { isEqualWith, omit } from 'lodash';
import { setPaginationUrlQuery } from 'client/components/tables/pagination/paginationUrlQuery';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import {
  GetAttivitaSortBy,
  GetAttivitaSortDirection,
  GetAttivitaStatoAttivita,
  AttivitaDTO
} from 'client/api/backend/schemas';
import { useTaskList } from './table/useTaskList';
import { TaskMassiveActionBar } from './table/TaskMassiveActionBar';
import { NameResponsive } from 'client/ui/tabs/NameToIconResponsive';
import { TbSearch, TbStack3, TbClock, TbListCheck } from 'react-icons/tb';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { requestModalConfirmation } from './table/requestModalConfirmation';
import { useCurrentUser } from 'client/components/auth/AuthModule';

const { TabPane } = Tabs;

const DEFAULT_PAGE_SIZE = 10;

/**
 * Ci sono dei filtri attivi se la query è diversa
 * dalla default query a meno della paginazione
 */
function isFilterActive(query: any, deafultQuery: any) {
  const mainQuery = omit(query, ['firstResult', 'maxResults']);
  const mainDefaultQuery = omit(deafultQuery, ['firstResult', 'maxResults']);

  const filtered = !isEqualWith(mainQuery, mainDefaultQuery, (value, other) => {
    if (typeof value === 'object' || Array.isArray(value)) return undefined;
    return value == other;
  });

  return filtered;
}

export interface TaskListPageProps extends RouteComponentProps {}

export function TaskListPage(props: TaskListPageProps) {
  const [selected, setSelected] = useState<string[]>([]);
  const isMobile = useBreakpoint(down('md'));
  const user = useCurrentUser();

  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Elenco Attività'
  });

  const toggleOpenSearchCollapse = () => {
    setSearchCollapseOpened(!searchCollapseOpened);
  };

  const defaultQuery = {
    firstResult: 0,
    sortBy: GetAttivitaSortBy.CREATED,
    sortDirection: GetAttivitaSortDirection.DESC
  };

  const {
    query,
    setQuery,
    tasks,
    meta: { total, pageSize, pageNumber },
    apiResult
  } = useTaskList(defaultQuery, { defaultPageSize: DEFAULT_PAGE_SIZE });

  const filtered = isFilterActive(query, defaultQuery);

  const [searchCollapseOpened, setSearchCollapseOpened] = useState(filtered);
  const [searchActive, setSearchActive] = useState(filtered);

  // Al ricaricamento dei tasks aggiorno la selezione includendo solo i task che esistono
  useEffect(() => {
    setSelected(selected =>
      selected.filter(id => tasks?.some(t => t.idTaskInstance === id))
    );
  }, [tasks]);

  return (
    <CorePageLayout>
      <>
        <PageHeading
          breadcrumb={<BreadcrumbsContainer />}
          title="Elenco Attività"
          footer={
            <TabsPageHeading
              defaultActiveKey={filtered ? 'search' : 'All'}
              onTabClick={key => {
                if (key === 'search') {
                  toggleOpenSearchCollapse();
                }
              }}
              onChange={key => {
                if (searchCollapseOpened) {
                  setSearchCollapseOpened(false);
                }
                // Se premo sulla tab del filtro dello stato
                if (
                  [
                    GetAttivitaStatoAttivita.PENDENTE,
                    GetAttivitaStatoAttivita.IN_CORSO
                  ].includes(key as GetAttivitaStatoAttivita)
                ) {
                  // Se è uno stato specifico, lo filtro
                  setPaginationUrlQuery(props, {
                    ...query,
                    statoAttivita: key as GetAttivitaStatoAttivita,
                    firstResult: 0
                  });
                }
                // Altrimenti, se è generale (All, Ricerca) e non è già non impostato, lo rimuovo
                // (in questo modo evito di rimuoverlo se già non c'è per non re-rendirizzare)
                else if (query.statoAttivita != null) {
                  const { statoAttivita, ...otherValues } = query;
                  setPaginationUrlQuery(props, {
                    ...otherValues,
                    firstResult: 0
                  });
                }
              }}
              tabBarExtraContent={
                <TaskMassiveActionBar tasks={tasks} selected={selected} />
              }
            >
              <TabPane
                key="All"
                tab={<NameResponsive name="Tutti" icon={<TbStack3 />} />}
              />
              <TabPane
                key={GetAttivitaStatoAttivita.PENDENTE}
                tab={<NameResponsive name="Pendenti" icon={<TbClock />} />}
              />
              <TabPane
                key={GetAttivitaStatoAttivita.IN_CORSO}
                tab={<NameResponsive name="In corso" icon={<TbListCheck />} />}
              />
              <TabPane
                tab={
                  <SearchTitleTabs
                    opened={searchCollapseOpened}
                    filtered={searchActive}
                    children={
                      <NameResponsive
                        name="Ricerca Estesa"
                        icon={<TbSearch />}
                      />
                    }
                  />
                }
                key="search"
              />
            </TabsPageHeading>
          }
        />
        <PageContent noPadding>
          <FormikAugmented
            enableReinitialize
            initialValues={query}
            validationSchema={getAttivitaParamsValidator}
            onSubmit={async rawValues => {
              const values = await getAttivitaParamsValidator.validate({
                ...rawValues,
                // Resetto il first result per tornare alla pagina 1 dopo la query
                firstResult: defaultQuery.firstResult
              });
              setQuery({
                ...values,
                sortBy: query.sortBy,
                sortDirection: query.sortDirection
              });
              setSearchActive(true);
            }}
            validationContext={{}}
          >
            <SearchCollapseContainer
              opened={searchCollapseOpened}
              isMobile={isMobile}
              button={
                <Space direction={isMobile ? 'horizontal' : 'vertical'}>
                  <Button
                    block
                    onClick={() => {
                      setQuery(defaultQuery);
                      setSearchActive(false);
                    }}
                  >
                    Pulisci
                  </Button>
                  <FormikSendButton
                    block
                    type="primary"
                    icon={<SearchOutlined />}
                    children="Cerca"
                  />
                </Space>
              }
            >
              <TaskSearchForm />
            </SearchCollapseContainer>
          </FormikAugmented>
          {apiResult.isError && (
            <Alert
              type="error"
              showIcon
              style={{ marginBottom: '24px' }}
              message={getNetworkErrorMessage(
                apiResult.error,
                "Impossibile caricare l'elenco delle attività."
              )}
            />
          )}
          {/* Tabella Elenco Attività */}
          <TableData
            rowKey="idTaskInstance"
            columns={
              isMobile
                ? columnsTaskListTableMobile
                : getColumnsTaskListTable(user)
            }
            showHeader={isMobile ? false : true}
            dataSource={tasks}
            loading={apiResult.isLoading || apiResult.isFetching}
            pagination={{
              pageSize: pageSize,
              current: pageNumber,
              total: total
            }}
            onChange={async (pagination, filters, sorter) => {
              const { field, order } = sorter as SorterResult<AttivitaDTO>;

              // Se ho selezionato delle attività, chiedo conferma prima di cambiare pagina
              // perche in tal caso andranno deselezionate per garantire coerenza nell'utilizzo
              // dell'applicativo
              if (selected.length > 0) {
                const result = await requestModalConfirmation({
                  title: 'Sei sicuro di voler cambiare pagina?',
                  okText: 'Conferma',
                  cancelText: 'Annulla',
                  children: (
                    <p>Tutte le attività selezionate verranno deselezionate.</p>
                  )
                });
                if (!result) return;

                setSelected([]);
              }

              const nextPageSize = pagination.pageSize ?? pageSize;

              const offset = pagination.current
                ? (pagination.current - 1) * nextPageSize
                : query.firstResult;

              const newQuery = {
                ...query,
                firstResult: offset,
                maxResults: nextPageSize,
                sortBy:
                  order && field
                    ? (field.toString().toUpperCase() as GetAttivitaSortBy)
                    : GetAttivitaSortBy.CREATED,
                sortDirection:
                  order === 'ascend'
                    ? GetAttivitaSortDirection.ASC
                    : GetAttivitaSortDirection.DESC
              };

              const updated = await getAttivitaParamsValidator.validate(
                newQuery
              );

              setPaginationUrlQuery(props, updated);
            }}
            rowSelection={{
              hideSelectAll: true,
              selectedRowKeys: selected,
              onSelect: (record, selection) => {
                if (selection) {
                  setSelected([...selected, record.idTaskInstance!]);
                } else {
                  setSelected(
                    selected.filter(id => id !== record.idTaskInstance)
                  );
                }
              }
            }}
          />
        </PageContent>
      </>
    </CorePageLayout>
  );
}
