import React from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import { CorePageLayout } from '../layout/CorePageLayout';
import { OnPageAlertContainer } from './OnPageAlertContainer';

export interface AlertPageProps extends AlertProps {}

/**
 * Pagina che contiene un solo messaggio di avviso/errore.
 */
export function AlertPage(props: AlertPageProps) {
  const { ...otherProps } = props;

  return (
    <CorePageLayout>
      <OnPageAlertContainer>
        <Alert {...otherProps} />
      </OnPageAlertContainer>
    </CorePageLayout>
  );
}
