import { useEffect, useState } from 'react';

export function useLocalStorageMeta(key: string, current?: string | null) {
  const [value, setValue] = useState<string | undefined | null>();

  useEffect(() => {
    if (!value && !current) {
      const newValue = localStorage.getItem(key);
      setValue(newValue);
    }

    if (current && value != current) {
      localStorage.setItem(key, current);
      setValue(current);
    }
  }, [key, current]);

  return { value };
}
