/**
 * Generato automaticamente.
 * Procedimenti
 */

export type GetAttivitaStatoAttivita =
  typeof GetAttivitaStatoAttivita[keyof typeof GetAttivitaStatoAttivita];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAttivitaStatoAttivita = {
  PENDENTE: 'PENDENTE',
  IN_CORSO: 'IN_CORSO'
} as const;
