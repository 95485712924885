import { RcFile } from 'antd/lib/upload';
import {
  BEResponseString,
  InviaDocumentoFirmatoParams
} from 'client/api/backend/schemas';
import { backendApiInstance } from 'client/core/network/mutators/apiClient';
import { queryClient } from 'client/core/network/queryClient';

export async function uploadSignedFile(input: {
  taskId: string;
  documentId: string;
  params?: InviaDocumentoFirmatoParams;
  file: RcFile;
}) {
  const { taskId, documentId, params, file } = input;
  const data = new FormData();
  // Solo `append` è IE11 compatible
  data.append('file', file);

  const result = await backendApiInstance<BEResponseString>({
    url: `/attivita/${taskId}/documenti/${documentId}/contenutoFirmato`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
    params
  });

  return result;
}
