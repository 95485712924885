import { Button, message } from 'antd';
import {
  getGetAttivitaQueryKey,
  useUpdateDichiarazioni
} from 'client/api/backend/attivita/attivita';
import {
  AzioniDichiarazione,
  AzioniDocumento
} from 'client/api/backend/schemas';
import { getDocumentDeclarations } from 'client/components/schema/task/document/getDocumentDeclarations';
import { logger } from 'client/core/logger/logger';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { queryClient } from 'client/core/network/queryClient';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { DeclarationLogic } from '../../../../../components/schema/task/declaration/DeclarationLogic';
import { invalidateTaskQueries } from '../../declarations/invalidateTaskQueries';

export interface IPartialSaveButtonProps {
  taskId: string;
  declarations: AzioniDichiarazione[] | undefined;
  documents: AzioniDocumento[] | undefined;
  validator: any | null;
}

/**
 * Pulsante per il salvataggio del task (non richiede la validazione completa)
 */
export function PartialSaveButton(props: IPartialSaveButtonProps) {
  const { declarations, documents, validator } = props;
  const [loading, setLoading] = useState(false);

  const formik = useFormikContext();
  const save = useUpdateDichiarazioni();

  const handleSave = useCallback(async () => {
    if (!validator || !declarations) return;
    setLoading(true);
    try {
      const values = await validator.validate(formik.values);
      const data = DeclarationLogic.getBodyData(
        declarations,
        getDocumentDeclarations(documents),
        values
      );

      await save.mutateAsync({ idTaskInstance: props.taskId, data });
      invalidateTaskQueries(queryClient, props.taskId);
      // Invalido la getAttività per aggiornare l'oggetto
      queryClient.invalidateQueries(
        getGetAttivitaQueryKey({ taskId: props.taskId })
      );
      message.success(`Salvataggio effettuato`);
    } catch (e) {
      logger.error(`[TabTask] Si è verificato un errore durante il salvataggio dei dati`, {error: e}); // prettier-ignore
      message.error(getNetworkErrorMessage(e, `Errore durante il salvataggio`)); // prettier-ignore
    } finally {
      setLoading(false);
    }
  }, [declarations, validator, formik.values]);

  return (
    <Button size="large" type="primary" onClick={handleSave} loading={loading}>
      Salva
    </Button>
  );
}
