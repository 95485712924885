import { RcFile } from 'antd/lib/upload';
import {
  getGetAzioniDocumentoQueryKey,
  getGetAzioniGruppoAllegatiQueryKey
} from 'client/api/backend/attivita/attivita';
import {
  BEResponseString,
  InsertAllegatoParams
} from 'client/api/backend/schemas';
import { backendApiInstance } from 'client/core/network/mutators/apiClient';
import { queryClient } from 'client/core/network/queryClient';

/**
 * Carica il file sul server e invalida le query di allegati e documenti
 * così da ricaricare la lista coi dati aggiornati.
 */
export async function uploadFile(input: {
  taskId: string;
  attachmentId: string;
  file: RcFile;
  params?: InsertAllegatoParams;
}) {
  const { taskId, attachmentId, params, file } = input;
  const data = new FormData();
  // Solo `append` è IE11 compatible
  data.append('file', file);

  const result = await backendApiInstance<BEResponseString>({
    url: `/attivita/${taskId}/allegati/${attachmentId}/contenutoBinario`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
    params
  });

  queryClient.invalidateQueries(getGetAzioniDocumentoQueryKey(taskId));
  queryClient.invalidateQueries(getGetAzioniGruppoAllegatiQueryKey(taskId));

  return result;
}
