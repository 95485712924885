import React from 'react';
import clx from 'classnames';

import styled, { css } from 'styled-components';

const WrapperLabel = styled.label`
  &&&:hover {
    cursor: default;
    .ant-radio,
    .ant-checkbox,
    .ant-checkbox-inner {
      cursor: default;
      border-color: #c2c7cc;
    }
    .ant-radio-inner {
      cursor: default;
    }
  }
`;
const StaticRadio = styled.span`
  &:hover {
    cursor: default;
    border-color: #c2c7cc;
  }
  .ant-radio-inner:hover {
    cursor: default;
    border-color: #c2c7cc;
  }
  &.ant-radio-checked {
    .ant-radio-inner {
      /* background-color: ${props => props.theme.textColorSecondary}; */
      border-color: ${props => props.theme.textColorSecondary};
      &::after {
        background-color: ${props => props.theme.textColorSecondary};
      }
    }
  }
`;
const StaticCheckbox = styled.span`
  &:hover {
    cursor: default;
    border-color: #c2c7cc;
  }
  .ant-checkbox-inner:hover {
    cursor: default;
    border-color: #c2c7cc;
  }
  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${props => props.theme.textColorSecondary};
      border-color: ${props => props.theme.textColorSecondary};
    }

    &::after {
    }
  }
`;

export interface RadioInputRenderValueProps {
  value?: boolean | undefined;
  title: React.ReactNode;
  type: 'radio' | 'checkbox';
}

/**
 * Gestisce la renderizzazione dei RadioInput e dei CheckboxInput
 * in base al valore del campo
 * in modalità consultazione (non-editabile)
 *
 */
export function RadioCheckboxInputRenderValue(
  props: RadioInputRenderValueProps
) {
  const { value, title, type } = props;

  const icon =
    type === 'radio' ? (
      <StaticRadio
        className={clx('ant-radio', { 'ant-radio-checked': !!value })}
      >
        <span className="ant-radio-inner"></span>
      </StaticRadio>
    ) : (
      <StaticCheckbox
        className={clx('ant-checkbox', { 'ant-checkbox-checked': !!value })}
      >
        <span className="ant-checkbox-inner"></span>
      </StaticCheckbox>
    );

  return (
    <WrapperLabel className={`ant-${type}-wrapper`}>
      {icon}
      <span>{title}</span>
    </WrapperLabel>
  );
}
