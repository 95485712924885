import React from 'react';
import styled from 'styled-components';

export const UploadInputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 10px;
  .upload-button {
    flex: 0 0 auto;
    min-width: 0;
    overflow-x: visible;
    .ant-btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin-right: -1px;
    }
    // correggo visualizzazione messaggi di errore e lista file
    // più lunghi del pulsante
    .ant-col,
    .ant-form-item-control-input {
      position: unset;
      z-index: 2;
    }
    .ant-upload-list-item-info {
      /* display: inline-block; */
    }
    .ant-upload-list,
    .ant-form-item-explain {
      // Posizionamento del nome file e pulsante di rimozione
      // dopo la selezione del file da caricare
      .ant-upload-list-text-container {
        position: relative;
        &::before {
          display: table;
          width: 100%;
          height: 26px;
          content: ' ';
        }

        .ant-upload-list-item {
          margin-top: 4px;
          position: absolute;
          top: 0;
        }
      }
    }
    // end correggo visualizzazione
  }
  .description-field {
    flex: 1 1 auto;
    min-width: 0;
    .ant-input,
    .ant-input-affix-wrapper {
      border-radius: 0;
      margin-right: -1px;

      max-height: 40px;
    }
  }
  .send-button {
    flex: 0 0 auto;
    min-width: 0;
    margin-top: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }

  // Inserimento dell'InputSwitch
  // nel campo di input testo (come suffix)
  .in-field-switch {
    margin-top: -1px;
  }
`;
