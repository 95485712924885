import { Alert, Button, Form, Select } from 'antd';
import React, { useMemo } from 'react';
import { RemoteSignStep } from '../reducer/RemoteSignReducer';
import { useRemoteSignContext } from '../RemoteSignContext';
import { RemoteSignForm } from './components/dynamic/forms/RemoteSignForm';

export interface IRemoteSignCertificateStepProps {
  handleSubmit: (values: any) => Promise<void>;
}

export function RemoteSignCertificateStep(
  props: Readonly<IRemoteSignCertificateStepProps>
) {
  const { handleSubmit } = props;

  const {
    state: { certificate, certificates },
    handlers: { setCurrentStep, setCertificate }
  } = useRemoteSignContext();

  const options = useMemo(
    () => certificates?.map(c => ({ label: c.descrizione, value: c.id })) ?? [],
    [certificates]
  );

  if (!certificates || certificates.length === 0) {
    return (
      <>
        <Alert
          type="warning"
          description="Non è stato possibile trovare nessun certificato per il provider scelto. Si prega di cambiare provider o riprovare più tardi."
          showIcon
          message="Nessun certificato disponibile"
        />
        <Button
          onClick={() => {
            setCurrentStep(RemoteSignStep.Provider);
          }}
        >
          Indietro
        </Button>
      </>
    );
  }

  if (certificates.length === 1) {
    return (
      <>
        {/* <label>Certificato</label>
        <span>{certificates[0].descrizione}</span> */}
        <div className="ant-form-vertical">
          <Form.Item
            label="Certificato"
            className="form-field-item not-editable ghost"
          >
            {certificates[0].descrizione}
          </Form.Item>
        </div>

        <RemoteSignForm handleSubmit={handleSubmit} />
      </>
    );
  }

  return (
    <>
      <Form.Item label="Seleziona certificato">
        <Select
          style={{ width: '100%' }}
          options={options}
          placeholder="Certificato..."
          onChange={(value: string) => {
            const selected = certificates.find(c => c.id === value);

            setCertificate(selected);
          }}
        />
      </Form.Item>

      {certificate && <RemoteSignForm handleSubmit={handleSubmit} />}
    </>
  );
}
