import { Affix, PageHeader, PageHeaderProps } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { theme } from 'client/ui/theme/theme';
import { PageContent } from '../content/PageContent';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up, down, between, only } from 'styled-breakpoints';

const HeadingWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.componentBackground};
  box-shadow: 0 1px 1px 0 rgba(100, 116, 139, 0.06),
    0 1px 2px 0 rgba(100, 116, 139, 0.1);
`;

const StyledPageHeader = styled(PageHeader)`
  width: 100%;
  background-color: ${props => props.theme.componentBackground};
  ${down('sm')} {
    padding-left: 12px;
    padding-right: 12px;
  }

  .ant-page-header-heading-title {
    font-size: 32px;
    font-weight: 700;
    min-height: 40px;
    ${down('md')} {
      font-size: 28px;
      min-height: 32px;
    }
  }
  .ant-page-header-heading {
    .ant-page-header-heading-left {
      margin: 0;
    }
    .ant-page-header-heading-extra {
      margin: 0;
      height: auto;
    }
  }
  .ant-page-header-footer {
    margin-top: 6px;
  }
`;

export interface PageHeadingProps extends PageHeaderProps {}

/**
 * Intestazione della singola pagina
 */
export function PageHeading(props: PageHeadingProps) {
  return (
    <Affix offsetTop={Number(theme.layoutHeaderHeight.replace(/\D/g, ''))}>
      <HeadingWrapper>
        <PageContent noPadding>
          <StyledPageHeader {...props} />
        </PageContent>
      </HeadingWrapper>
    </Affix>
  );
}
