import { AzioniDichiarazione } from 'client/api/backend/schemas';
import { useMemo } from 'react';
import { EditDeclarationActions } from '../../declaration/actions/DeclarationActions';
import { filterActions } from './utils/filterActions';

export function filterDeclarationsActions(
  declarations: AzioniDichiarazione[] | undefined,
  isAssigned: boolean | undefined
) {
  if (!declarations) return [];

  return declarations.map(declaration => {
    if (!declaration.dichiarazione) return declaration;
    return {
      ...declaration,
      azioni: filterActions(
        declaration.azioni,
        isAssigned,
        EditDeclarationActions
      )
    };
  });
}

export function useDeclarationsActions(
  declarations: AzioniDichiarazione[] | undefined,
  isAssigned: boolean | undefined
) {
  const result = useMemo(() => {
    return filterDeclarationsActions(declarations, isAssigned);
  }, [declarations, isAssigned]);

  return result;
}
