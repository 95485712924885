import { message } from 'antd';
import { useFirma, useFirmaArguments } from 'client/api/auth/jnlp/jnlp';
import { ArgumentsResponse } from 'client/api/auth/schemas';
import {
  EsitoRispostaWS,
  RecuperaDocumentoPerFirmaParams
} from 'client/api/backend/schemas';
import {
  DigitalSignatureProvider,
  WebSocketKind
} from 'client/components/digital-signature/DigitalSignatureProvider';
import { appConfig } from 'client/core/appConfig';
import { base64 } from 'client/core/encoding/base64';
import { useCallback, useEffect, useRef, useState } from 'react';
import { jnlpSubject } from './JnlpSubject';

export const URL_SIGN_AUTH_TOKEN_REGEX =
  /(<argument>POST##<\/argument>\s+<argument>)(.*)(<\/argument>)/gm;

export const URL_SECTION_AUTH_TOKEN_REGEX =
  /(<argument>POST<\/argument>\s+<argument>)(.*)(<\/argument>)/gm;

export class JnlpService {
  /**
   * Ritorna vero se la configurazione richiede l'avvio "STANDARD".
   * False se richiede l'avvio "FAST".
   */
  static isStandardJnlp() {
    return appConfig.jnlpKind === 'STANDARD';
  }

  static getObjectUrl(input: string) {
    return window.URL.createObjectURL(new Blob([input]));
  }

  static setFvProxy(input: string, kind: WebSocketKind) {
    console.warn(`⛔️ Attenzione! Link modificato manualmente per lo sviluppo.`); // prettier-ignore
    const authRegex =
      kind === WebSocketKind.Sign
        ? URL_SIGN_AUTH_TOKEN_REGEX
        : URL_SECTION_AUTH_TOKEN_REGEX;
    return input.replace(authRegex, (_, pre, token, post) => {
      return `${pre}${this.replaceFvProxyAuthToken(token)}${post}`;
    });
  }

  static replaceFvProxyAuthToken(token: string) {
    const decoded = base64.decode(token.replace('##', ''));
    const replaced = this.replaceFvProxyUrl(decoded);
    console.log('replaced', { original: decoded, replaced });

    return base64.encode(replaced) + '##';
  }

  static replaceFvProxyUrl(input: string) {
    return input.replace(
      'hps-procedimenti.dedagroup.it',
      appConfig.useFvProxyUrl
    );
  }

  static downloadFile(objectUrl: string, filename: string) {
    // Apro il file
    let a = window.document.createElement('a');
    a.setAttribute('target', '_blank');
    a.download = filename;
    a.href = objectUrl;
    a.click();
  }

  static revokeObjectUrl(objectUrl: string) {
    window.URL.revokeObjectURL(objectUrl);
  }

  static useDocumentSignManager() {
    const getSignManager = useFirma({});
    const getArguments = useFirmaArguments({});

    const getJnlp = useCallback(
      async (
        taskId: string,
        documentId: string | undefined,
        params: RecuperaDocumentoPerFirmaParams
      ) => {
        return await getSignManager.mutateAsync({
          data: {
            tipoAvvioJnlp: appConfig.jnlpKind,
            tasks: [
              {
                idTaskInstance: taskId,
                flCompletare: false,
                taskDocuments: [
                  {
                    idDocument: documentId,
                    flIntermedio: params.flIntermedio
                  }
                ]
              }
            ]
          }
        });
      },
      [getSignManager]
    );

    const getJnlpArguments = useCallback(
      async (
        taskId: string,
        documentId: string | undefined,
        params: RecuperaDocumentoPerFirmaParams
      ) => {
        return await getArguments.mutateAsync({
          data: {
            tipoAvvioJnlp: appConfig.jnlpKind,
            tasks: [
              {
                idTaskInstance: taskId,
                flCompletare: false,
                taskDocuments: [
                  {
                    idDocument: documentId,
                    flIntermedio: params.flIntermedio
                  }
                ]
              }
            ]
          }
        });
      },
      []
    );

    return { getJnlp, getJnlpArguments };
  }

  static useDocumentMassiveSignManager() {
    const getSignManager = useFirma({});
    const getArguments = useFirmaArguments({});

    const getJnlp = useCallback(async (taskIds: string[]) => {
      return await getSignManager.mutateAsync({
        data: {
          tipoAvvioJnlp: appConfig.jnlpKind,
          tipoFirmaEnum: 'PADES',
          tasks: taskIds.map(taskId => ({
            idTaskInstance: taskId,
            flCompletare: true
          }))
        }
      });
    }, []);

    const getJnlpArguments = useCallback(async (taskIds: string[]) => {
      return await getArguments.mutateAsync({
        data: {
          tipoAvvioJnlp: appConfig.jnlpKind,
          tipoFirmaEnum: 'PADES',
          tasks: taskIds.map(taskId => ({
            idTaskInstance: taskId,
            flCompletare: true
          }))
        }
      });
    }, []);

    return { getJnlp, getJnlpArguments };
  }

  static useWebSocketJnlpManager(options: {
    getJnlp: () => Promise<string>;
    getJnlpArguments: () => Promise<ArgumentsResponse>;
    // Tentativi per la connessione al WebSocket (~1 al secondo)
    maxConnectionTries: number;
    successMessage: string;
    onSuccess: () => void;
    filename: string;
    kind: WebSocketKind;
  }) {
    const [loading, setLoading] = useState(false);
    const [objectUrl, setObjectUrl] = useState<string | null>(null);
    const [awaitConnection, setAwaitConnection] = useState(false);
    const [needRetry, setNeedRetry] = useState(false);
    const [httpsCertificateUrl, setHttpsCertificateUrl] = useState<
      string | null | undefined
    >(null);
    const pooling = useRef(true);

    // Gestisco il subscribe per fermare il pooling quando la modal viene chiusa
    useEffect(() => {
      const listener = jnlpSubject.subscribe(event => {
        if (event.type === 'end') {
          pooling.current = false;
        }
      });

      return () => {
        listener.unsubscribe();
      };
    }, []);

    // Gestisce la chiamata al websocket
    const jnlpProcess = useCallback(async () => {
      setLoading(true);

      try {
        // Flusso standard di firma se il websocket è connesso
        // Recuperiamo gli arguments (compreso gli url per il WS)
        const {
          wsUrl,
          wssUrl,
          httpsCertificateUrl,
          arguments: jnlpArguments
        } = await options.getJnlpArguments(); // TODO rimuovere any una volta aggiornata l'API

        setHttpsCertificateUrl(httpsCertificateUrl);

        const webSocketUrls = {
          ws: wsUrl!,
          wss: wssUrl!
        };

        if (jnlpArguments && appConfig.useFvProxy) {
          // Aggiorno il proxy sul primo argument (/contenuto)
          jnlpArguments[0] = this.replaceFvProxyUrl(jnlpArguments[0]);
          // Aggiorno il proxy sul secondo argument (/sezioniDocumento/:id)
          jnlpArguments[1] = this.replaceFvProxyUrl(jnlpArguments[1]);
          // Aggiorno il proxy sul terzo argument (JSESSIONID)
          jnlpArguments[3] = this.replaceFvProxyAuthToken(jnlpArguments[3]);
        }

        let provider = null;
        try {
          provider = await DigitalSignatureProvider.open(
            webSocketUrls,
            options.kind
          );
        } catch (e: any) {
          console.warn(`[FastSignJnlpWindowManager] Si è verificato un errore durante la connessione al WebSocket: ${e.message ?? e}`); // prettier-ignore
        }

        // Se non c'è connessione al WebSocket, scarichiamo il jnlp
        if (!provider?.isConnected() && !objectUrl) {
          let result = await options.getJnlp();

          if (!result) {
            throw new Error('Impossibile effettuare il download');
          }

          if (appConfig.useFvProxy) {
            result = this.setFvProxy(result, options.kind);
          }

          const resultObjectUrl = JnlpService.getObjectUrl(result);

          // Rimuovo l'URL precedente ed imposto il nuovo
          if (objectUrl) {
            this.revokeObjectUrl(objectUrl);
          }
          setObjectUrl(resultObjectUrl);

          // Scarico il file jnlp
          this.downloadFile(resultObjectUrl, options.filename);
        }

        // Attendo la connessione pet ~5 minuti ritentando circa ogni secondo
        if (!provider?.isConnected()) {
          setAwaitConnection(true);
          for (let i = 0; i < options.maxConnectionTries; i++) {
            if (!pooling.current) {
              pooling.current = true;
              console.log("[FastSignJnlpWindowManager] Connessione al WebSocket interrotta!"); // prettier-ignore
              return;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
            console.log("[FastSignJnlpWindowManager] Tentativo di connessione al WebSocket..."); // prettier-ignore
            try {
              provider = await DigitalSignatureProvider.open(
                webSocketUrls,
                options.kind
              );
            } catch (e: any) {
              console.warn(`[FastSignJnlpWindowManager] Si è verificato un errore durante la connessione al WebSocket: ${e.message ?? e}`); // prettier-ignore
            }
            if (provider?.isConnected()) {
              console.log("[FastSignJnlpWindowManager] Connessione al WebSocket riuscita!"); // prettier-ignore
              break;
            }
          }
          setAwaitConnection(false);

          // Se non sono riuscito a connettermi mostro un messaggio di errore ed esco
          if (!provider?.isConnected()) {
            throw new Error("Impossibile connettersi al software. Riprovare."); // prettier-ignore
          }
        }

        console.log("[FastSignJnlpWindowManager] Invio richiesta di al websocket..."); // prettier-ignore
        // Inviamo un messagge attraverso il websocket
        // Attendiamo la risposta dal websocket (in caso di errore lancia un'eccezione)
        const result: EsitoRispostaWS = await provider.sendRequest({
          arguments: jnlpArguments
        });

        console.log({ result });

        // Se la risposta è positiva mostriamo success e chiudiamo la modal
        // Non ci sono check dato che sendRequest lancia eccezione se la risposta è negativa
        message.success(options.successMessage);
        setLoading(false);
        setNeedRetry(false);
        options.onSuccess();
      } catch (e: any) {
        // Annullamento inserimento PIN
        if (e.code === 'ANNULLATO') {
          message.info(`Operazione annullata`);
        } else if (
          e?.response?.data?.status === 404 &&
          e?.response?.data?.error === 'Not Found'
        ) {
          message.error(`Le attività selezionate non presentano nessun documento da firmare.`); // prettier-ignore
        } else {
          message.error(`Si è verificato un errore durante il processo di firma${e?.message ? `: ${e?.message}` : ''}`); // prettier-ignore
        }
        console.log(`[FastSignJnlpWindowManager] Si è verificato un errore durante il processo di firma`, {error: e}); // prettier-ignore

        setNeedRetry(true);
      } finally {
        setLoading(false);
      }
    }, [objectUrl, options]);

    const setToDefault = useCallback(() => {
      setLoading(false);
      setObjectUrl(null);
      setAwaitConnection(false);
      setNeedRetry(false);
    }, []);

    return {
      filename: options.filename,
      loading,
      objectUrl,
      awaitConnection,
      needRetry,
      httpsCertificateUrl,
      jnlpProcess,
      setToDefault
    };
  }
}

export type IWebSocketJnlpManager = ReturnType<
  typeof JnlpService.useWebSocketJnlpManager
>;
