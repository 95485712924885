import {
  getGetAzioniDichiarazioniQueryKey,
  getGetAzioniDocumentoQueryKey
} from 'client/api/backend/attivita/attivita';
import { QueryClient } from 'react-query';

/**
 * Invalida le query di azioni dichiarazioni e azioni documento
 */
export function invalidateTaskQueries(
  queryClient: QueryClient,
  taskId: string
) {
  queryClient.invalidateQueries(getGetAzioniDichiarazioniQueryKey(taskId));
  queryClient.invalidateQueries(getGetAzioniDocumentoQueryKey(taskId));
}
