import * as yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    /**
     * Valida la stringa come codice fiscale.
     */
    fiscalNumber<T extends string>(): StringSchema<T>;
  }
}

yup.addMethod(yup.string, 'fiscalNumber', function (this: yup.StringSchema) {
  return this.uppercase().test(
    'fiscal-number-check',
    'Il codice fiscale non è valido',
    fiscalNumber => {
      if (!fiscalNumber) return true;
      // Check sul formato
      if (!fiscalNumberRegex.test(fiscalNumber)) return false;
      // Check cifra di controllo
      return checkFiscalNumberControlChar(fiscalNumber);
    }
  );
});

const fiscalNumberRegex =
  /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;

// source: https://it.wikipedia.org/wiki/Codice_fiscale
function checkFiscalNumberControlChar(fiscalNumber: string) {
  const chars = fiscalNumber.split('');
  // Suddivido i caratteri in base alla loro posizione (pari o dispari)
  const odd: string[] = [];
  const even: string[] = [];
  chars.slice(0, 15).forEach((char, index) => {
    (index + 1) % 2 === 0 ? even.push(char) : odd.push(char);
  });
  // Calcolo la somma dei caratteri dispari (convertiti in numero tramite l'apposita tabella)
  const oddSum = odd.reduce(
    (acc, curr) => acc + oddConverter[curr as ConverterKey],
    0
  );
  // Calcolo la somma dei caratteri pari (convertiti in numero tramite l'apposita tabella)
  const evenSum = even.reduce(
    (acc, curr) => acc + evenConverter[curr as ConverterKey],
    0
  );

  // Calcolo il numero di controllo come il resto della somma dei valori precedenti
  const resultNumber = (oddSum + evenSum) % 26;
  // Calcolo il carattere di controllo (0: A, 1: B, ..., 25: Z)
  const offset = 'A'.charCodeAt(0);
  const resultChar = String.fromCharCode(offset + resultNumber);

  // Il CF è corretto se il carattere di controllo corrisponde all'ultimo carattere del codice fiscale
  return resultChar === fiscalNumber[15];
}

type ConverterKey = keyof typeof oddConverter;

const oddConverter = {
  '0': 1,
  '1': 0,
  '2': 5,
  '3': 7,
  '4': 9,
  '5': 13,
  '6': 15,
  '7': 17,
  '8': 19,
  '9': 21,
  A: 1,
  B: 0,
  C: 5,
  D: 7,
  E: 9,
  F: 13,
  G: 15,
  H: 17,
  I: 19,
  J: 21,
  K: 2,
  L: 4,
  M: 18,
  N: 20,
  O: 11,
  P: 3,
  Q: 6,
  R: 8,
  S: 12,
  T: 14,
  U: 16,
  V: 10,
  W: 22,
  X: 25,
  Y: 24,
  Z: 23
};

const evenConverter = {
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  E: 4,
  F: 5,
  G: 6,
  H: 7,
  I: 8,
  J: 9,
  K: 10,
  L: 11,
  M: 12,
  N: 13,
  O: 14,
  P: 15,
  Q: 16,
  R: 17,
  S: 18,
  T: 19,
  U: 20,
  V: 21,
  W: 22,
  X: 23,
  Y: 24,
  Z: 25
};
