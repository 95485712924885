import { message } from 'antd';
import React from 'react';
import { ProviderParameters } from '../../../../provider-parameters/ProviderParameters';
import { RemoteSignStep } from '../../../../reducer/RemoteSignReducer';
import { useRemoteSignContext } from '../../../../RemoteSignContext';
import { useCertificateList } from '../../../hooks/useCertificateList';
import { RemoteSignDynamicForm } from '../RemoteSignDynamicForm';

export interface IRemoteSignCertificateListFormProps {
  provider: ProviderParameters | null | undefined;
}

/**
 * Form dinamico per il recupero della lista dei certificati disponibili di
 * un provider per la _FIRMA_ dinamica.
 */
export function RemoteSignCertificateListForm(
  props: IRemoteSignCertificateListFormProps
) {
  const { provider } = props;

  const certificateListApi = useCertificateList({
    provider
  });

  const {
    handlers: {
      setCertificates,
      setCertificate,
      setDynamicValues,
      setCurrentStep
    }
  } = useRemoteSignContext();

  return (
    <RemoteSignDynamicForm
      paramsKey="certificateParameters"
      textButton={provider?.multiCertificate ? 'Avanti' : 'Firma'}
      afterSubmit={async dynamicValues => {
        try {
          // Altrimenti recupero la lista dei certificati
          // e la salvo così da mostrarla nello step successivo.

          const certificateResponse = await certificateListApi(dynamicValues);

          if (certificateResponse?.esito?.errore) {
            throw new Error(certificateResponse.esito.errore.messaggioDiErrore); // prettier-ignore
          }

          if (certificateResponse?.data) {
            if (certificateResponse.data.length === 0) {
              message.error(`Non sono stati trovati certificati per il provider selezionato`); // prettier-ignore
              throw new Error('Non sono stati trovati certificati per il provider selezionato'); // prettier-ignore
            }

            setCertificates(certificateResponse.data);
            // Se ce ne è uno solo, lo seleziono automaticamente
            if (certificateResponse.data.length === 1) {
              setCertificate(certificateResponse.data[0]);
            }
            setDynamicValues(dynamicValues);
            setCurrentStep(RemoteSignStep.Certificate);
          }
        } catch (e: any) {
          console.error('Error in RemoteSignProviderStep: ', e);
          const errorMessage = e?.response?.data?.esito?.errore.messaggioDiErrore ?? e.message; // prettier-ignore
          message.error(`Si è verificato un errore durante il recupero dei certificati. ${errorMessage}`); // prettier-ignore
        }
      }}
    />
  );
}
