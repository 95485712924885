import { GruppoAllegati } from 'client/api/backend/schemas';
import { AttachmentLogic } from 'client/components/schema/attachments/AttachmentLogic';
import { AttachmentSection } from 'client/routes/task/single/documents/AttachmentSection';
import { DeclarationTitle } from 'client/ui/declaration/DeclarationTitle';
import React from 'react';
import { ProcedureAttachmentSection } from './ProcedureAttachmentSection';

export interface IProcedureAttachmentGroupSectionProps {
  group: GruppoAllegati;
  procedureId: string;
}

export function ProcedureAttachmentGroupSection(
  props: IProcedureAttachmentGroupSectionProps
) {
  const { group, procedureId } = props;

  // Se non ci sono file negli allegati, nascondi il gruppo
  if (!AttachmentLogic.hasAnyFile(group)) return null;

  return (
    <>
      <DeclarationTitle
        title={group.denominazioneGruppoAllegato ?? 'Allegati'}
        attachments={group}
      />
      {group.allegati?.map((attachment, index) => (
        <ProcedureAttachmentSection
          attachment={attachment}
          key={index}
          procedureId={procedureId}
        />
      ))}
    </>
  );
}
