import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import { groupBy } from 'lodash';

/**
 * Ordina in righe e prepara i campi per il rendering
 */
export function useDeclarationFields(fields: DeclarationFieldDto[]) {
  return Object.values(
    groupBy(fields, f =>
      // Dobbiamo dividere la riga (anche se è la stessa, ad esempio "3")
      // nel caso nella stessa riga ci siano SIA campi ripetibili che non
      // ripetibili.
      // In questo caso, creiamo una riga "virtuale" successiva a quella
      // ripetibile per i campi non ripetibili.
      //
      //  [R3.1][R3.2] <- ripetibile
      //  [R3.3      ] <- non ripetibile
      f.subtype === 'repeatable' ? `${f.riga}-0` : `${f.riga}-1`
    )
  );
}
