import * as React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';
import { SearchFormContainer } from './SearchFormContainer';

const CollapseWrapper = styled.div<{ isMobile?: boolean | null }>`
  margin-bottom: ${props => (props.isMobile ? '2px' : '24px')};
  margin-top: 2px;
`;
const CollapseHiddenTitle = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: none;
  }
`;
const CollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    background-color: ${props => props.theme.componentBackground};
    border-radius: 0 0 4px 4px;
    .ant-form-item.form-field-item.help-in-tooltips {
      margin-bottom: 6px;
    }
  }
`;

export interface SearchCollapseContainerProps {
  opened: boolean;
  children: React.ReactNode;
  button: React.ReactNode;

  isMobile?: boolean | null;
}

/*
 * Collepse dedicato per mostrare i form di ricerca nelle pagine elenco
 *
 */
export function SearchCollapseContainer(props: SearchCollapseContainerProps) {
  return (
    <CollapseWrapper isMobile={props.isMobile}>
      <CollapseHiddenTitle activeKey={[props.opened ? 'search' : '']} ghost>
        <CollapsePanel header="Search Extend Collapse" key="search">
          <SearchFormContainer button={props.button}>
            {props.children}
          </SearchFormContainer>
        </CollapsePanel>
      </CollapseHiddenTitle>
    </CollapseWrapper>
  );
}
