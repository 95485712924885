import React from 'react';
import styled from 'styled-components';

const SpaceItem = styled.div`
  width: 100%;
  height: 1px;
`;

export interface VerticalSpaceProps {
  /**
   * Numero di pixel di margin-bottom css
   */
  size?: number;
}

/**
 * Spazio distanziatore verticale.
 */
export function VerticalSpace(props: VerticalSpaceProps) {
  const size = props.size ? props.size - 1 : 23;

  return <SpaceItem style={{ marginBottom: size + 'px' }} />;
}
