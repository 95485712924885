import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuButton } from './main-button/MenuButton';
import { Menu, Space } from 'antd';
import { useCurrentMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { LinkButton } from 'client/ui/link/LinkButton';
import { Link } from 'react-router-dom';
import { getMainMenuItems } from './main-button/getMainMenuItems';

const MenuWrapper = styled(Space)`
  padding: 0 24px;
`;

export interface MenuItemsProps {}

export interface MainMenuProps {}

export function MainMenu(props: MainMenuProps) {
  const currentPathKey = useCurrentMenuKey()[0];

  const items = getMainMenuItems();

  return (
    <MenuWrapper>
      {items.map(item => (
        <MenuButton
          key={item.menuKey}
          menuKey={item.menuKey}
          currentKey={currentPathKey}
          to={item.to}
          children={item.children}
        />
      ))}
    </MenuWrapper>
  );
}
