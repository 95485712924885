import {
  AzioniDocumento,
  AzioniDichiarazione
} from 'client/api/backend/schemas';

export function getDocumentDeclarations(
  documents: AzioniDocumento[] | null | undefined
): AzioniDichiarazione[] {
  if (!documents) return [];
  return documents
    .flatMap(document => document.documento?.dichiarazioni)
    .filter(d => !!d) as AzioniDichiarazione[];
}
