import * as React from 'react';
import { HiCheckCircle } from 'react-icons/hi';
import { Anchor, AnchorLinkProps, Typography } from 'antd';
const { Title, Paragraph } = Typography;
import styled from 'styled-components';
import { IconWrapper } from '../icon/IconWrapper';
import { useState } from 'react';
import { EllipsisConfig } from 'antd/lib/typography/Base';

const AnchorLinkStyled = styled(Anchor.Link)`
  padding: 4px 0 4px 12px;
  max-width: 100%;

  .ant-anchor-link-title {
    background-color: #fff;
    border-radius: ${props => props.theme.borderRadiusBase};
    padding: 3px 3px 3px 8px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TitleLink = styled(Paragraph)`
  &.ant-typography {
    flex: 1 1 auto;
    min-width: 0px;
    margin: 0;
    color: inherit;
  }
`;
const Icon = styled(IconWrapper)`
  flex: 0 0 auto;
  min-width: 0px;
  padding-left: 6px;
  font-size: 24px;
  color: ${props => props.theme.borderColorSplit};
`;
const StateIcon = styled(Icon)<{ status?: 'success' | 'error' }>`
  color: ${props =>
    props.status === 'success'
      ? props.theme.successColor
      : props.status === 'error'
      ? props.theme.errorColor
      : props.theme.borderColorSplit};
`;

const IconBefore = styled(IconWrapper)`
  flex: 0 0 auto;
  min-width: 24px;
  height: 30px;
  padding: 3px 4px;
  margin: -3px 6px -3px -8px;
  font-size: 18px;
  color: ${props => props.theme.componentBackground};
  background-color: ${props => props.theme.primaryColor};
  border-radius: 4px 0 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

export interface StateAnchorLinkProps extends AnchorLinkProps {
  icon?: React.ReactNode;
  status?: 'success' | 'error';
  prefixIcon?: React.ReactNode;
}

export function StateAnchorLink(props: StateAnchorLinkProps) {
  const [ellipsed, setEllipsed] = useState(false);
  const { title, icon, status, prefixIcon, ...othersProps } = props;
  return (
    <AnchorLinkStyled
      {...othersProps}
      title={
        <TitleWrapper>
          {prefixIcon && <IconBefore icon={prefixIcon} />}
          <TitleLink
            ellipsis={{
              onEllipsis: e => setEllipsed(e),
              tooltip: ellipsed ? title : false
            }}
          >
            {title}
          </TitleLink>
          {icon && <StateIcon status={status} icon={icon} />}
        </TitleWrapper>
      }
    />
  );
}
