import * as React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Procedimento } from 'client/api/backend/schemas';
import { ProcedureListItemMobile } from './ProcedureListItemMobile';

/**
 * Colonne della tabella di ricerca delle procedure per Mobile.
 */
export const columnsProcedureListMobile: ColumnsType<Procedimento> = [
  {
    title: 'Procedimento',
    dataIndex: 'procedimento.tipologiaProcedimento',
    key: 'procedimento.tipologiaProcedimento',
    render: (_, record) => <ProcedureListItemMobile record={record} />
  }
];
