import { useMemo } from 'react';
import { useAttachments } from '../../attachments/useAttachments';
import { useDeclarations } from '../../declaration/useDeclarations';
import { useDocuments } from '../../document/useDocuments';
import { useAttachmentsActions } from './useAttachmentsActions';
import { useDeclarationsActions } from './useDeclarationsActions';
import { useDocumentsActions } from './useDocumentsActions';
import { useTaskValidation } from '../validation/useTaskValidation';
import { filterDeclarations } from './utils/filterDeclarations';

// Hook che ritorna tutti i campi di un'attività: dichiarazione, documenti...
export function useTaskData(taskId: string, isAssigned: boolean | undefined) {
  const declarationsData = useDeclarations(taskId);
  const attachmentsData = useAttachments(taskId);
  const documentsData = useDocuments(taskId);

  // Rimuovo le azioni di edit se necessario
  const declarations = useDeclarationsActions(
    declarationsData.declarations,
    isAssigned
  );

  const documents = useDocumentsActions(documentsData.documents, isAssigned);

  const attachments = useAttachmentsActions(
    attachmentsData.attachments,
    isAssigned,
    documents
  );

  const { validator, partialValidator, initialValues, initialTouched } =
    useTaskValidation({
      declarations,

      documents,
      attachments,
      isLoading:
        declarationsData.result.isLoading ||
        documentsData.result.isLoading ||
        attachmentsData.result.isLoading
    });

  // Rimuovi le dichiarazioni duplicate (presenti già nei documenti)
  const uniqueDeclarations = useMemo(
    () => filterDeclarations(declarations, documents),
    [declarations, documents]
  );

  // ID delle dichiarazioni così da poterle filtrare dagli initialValues.
  const declarationIds = useMemo(
    () =>
      (uniqueDeclarations
        ?.map(d => d.dichiarazione?.idTipoDichiarazione)
        .filter(Boolean) ?? []) as string[],
    [uniqueDeclarations]
  );

  const documentDeclarationIds = useMemo(
    () =>
      (documents?.flatMap(
        d =>
          d.documento?.dichiarazioni
            ?.map(dec => dec.dichiarazione?.idTipoDichiarazione)
            .filter(Boolean) ?? []
      ) ?? []) as string[],
    [documents]
  );

  const resultDeclarationIds = useMemo(
    () => [...declarationIds, ...documentDeclarationIds],
    [declarationIds, documentDeclarationIds]
  );

  return {
    declarations: {
      declarations: uniqueDeclarations,
      result: declarationsData.result
    },
    declarationIds: resultDeclarationIds,
    documents: { documents: documents, result: documentsData.result },
    attachments: {
      attachments: attachments,
      result: attachmentsData.result
    },
    validator,
    partialValidator,
    initialValues,
    initialTouched
  };
}
