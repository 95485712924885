import { ModalDialogProps } from 'client/ui/modal/ModalDialog';
import React from 'react';
import { RemoteSignModalDialog } from './modal-dialog/RemoteSignModalDialog';
import { useRemoteSignContext } from './RemoteSignContext';
import { useRemoteSign } from './steps/hooks/useRemoteSign';

export interface IRemoteSignActionModalProps
  extends Omit<ModalDialogProps, 'children'> {
  documentId: string;
  taskId: string;
  flags: { flIntermedio: boolean; flCompletare: boolean };
}

/**
 * Modal con steps di firma remota del documento.
 */
export function RemoteSignActionModal(
  props: Readonly<IRemoteSignActionModalProps>
) {
  const { documentId, taskId, flags } = props;
  const { state, dispatch, handlers } = useRemoteSignContext();

  // Funzione di submit che gestisce la firma remota del documento
  const handleRemoteSignSubmit = useRemoteSign({
    documentId,
    taskId,
    certificate: state.certificate,
    provider: state.provider,
    signType: state.signType,
    setCurrentStep: handlers.setCurrentStep,
    setErrors: handlers.setErrors,
    flCompletare: flags.flCompletare,
    flIntermedio: flags.flIntermedio
  });

  return (
    <RemoteSignModalDialog
      {...props}
      handleRemoteSignSubmit={handleRemoteSignSubmit}
      currentStep={state.currentStep}
      dispatch={dispatch}
    />
  );
}
