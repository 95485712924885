import React from 'react';
import { HiArrowRight, HiEye, HiOutlineEye } from 'react-icons/hi';
import { MdDone } from 'react-icons/md';
import { RiUserShared2Line } from 'react-icons/ri';
import {
  TbHandStop,
  TbHandOff,
  TbEye,
  TbBallpen,
  TbBallpenOff
} from 'react-icons/tb';

/**
 * Icona funzione applicativa: Esegui
 */
export function IconExec(props: {}) {
  return <HiArrowRight />;
}
/**
 * Icona funzione applicativa: Vedi Dettaglio
 */
export function IconView(props: {}) {
  return <TbEye />;
}
/**
 * Icona funzione applicativa: Prendi in carico (Assegna a me)
 */
export function IconAssignToMe(props: {}) {
  return <TbBallpen />;
}
/**
 * Icona funzione applicativa: Completa
 */
export function IconComplete(props: {}) {
  return <MdDone />;
}
/**
 * Icona funzione applicativa: Rilascia
 */
export function IconRelaese(props: {}) {
  return <TbBallpenOff />;
}
/**
 * Icona funzione applicativa: Assegna (ad Altro utente)
 */
export function IconAssignToOther(props: {}) {
  return <RiUserShared2Line />;
}
