import * as React from 'react';
import { Alert, Card, Empty, Typography } from 'antd';
import { NoteItem } from '../../../../components/schema/note/NoteItem';
import { useProcedureNotes } from 'client/routes/procedure/single/useProcedureNotes';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { ResponsiveCard } from 'client/ui/card/ResponsiveCard';

export interface TabNotesProps {
  procedureId?: string;
}

/**
 * // TODO
 */
export function TabNotes(props: TabNotesProps) {
  const { procedureId } = props;

  const { result, notes } = useProcedureNotes(procedureId);

  if (result?.isError) {
    return (
      <Alert
        showIcon
        type="error"
        message="Impossibile caricare le note"
        description={getNetworkErrorMessage(
          result.error,
          'Si è verificato un errore'
        )}
      />
    );
  }

  if (result?.isLoading || !notes) {
    return (
      <ResponsiveCard bordered={false} style={{ padding: '12px' }}>
        <LoaderSpin full />
      </ResponsiveCard>
    );
  }

  if (notes.length === 0) {
    return (
      <ResponsiveCard bordered={false}>
        <Empty
          description={
            <Typography.Text type="secondary">
              Non ci sono ancora note per questo procedimento
            </Typography.Text>
          }
        />
      </ResponsiveCard>
    );
  }

  return (
    <ResponsiveCard bordered={false}>
      {notes.map((note, i) => (
        <NoteItem key={i} note={note} />
      ))}
    </ResponsiveCard>
  );
}
