import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const ContentWrapper = styled.div`
  max-width: ${props => props.theme.contentMaxWidth};
  margin: 0 auto;
  padding: 24px;
  ${down('sm')} {
    padding: 12px;
  }

  &.no-padding {
    padding: 0;
  }
`;

export interface PageContentProps {
  noPadding?: boolean;
  children: React.ReactNode;
}

/**
 * Contiene la pagina, che sia una tabella oppure gli elementi
 * di un dettaglio.
 */
export function PageContent(props: PageContentProps) {
  return (
    <ContentWrapper className={clx({ 'no-padding': props.noPadding })}>
      {props.children}
    </ContentWrapper>
  );
}
