import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { yup } from 'common/validation/initYup';
import React, { useState } from 'react';
import { Button, Divider, message, Space, Tooltip, Typography } from 'antd';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { SwitchInput } from 'client/ui/form/input/SwitchInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { getResponseErrorMessage } from 'client/core/network/errors/getResponseErrorMessage';
import { logger } from 'client/core/logger/logger';
import { IDocumentFile } from 'client/ui/form/input/UploadInput';
import {
  useUpdateAllegato,
  getGetAzioniDocumentoQueryKey,
  getGetAzioniGruppoAllegatiQueryKey
} from 'client/api/backend/attivita/attivita';
import { queryClient } from 'client/core/network/queryClient';
import { UploadInputWrapper } from '../UploadInputWrapper';
import { AddFileSwitchesWrapper } from '../AddFileSwitchesWrapper';
import {
  editFileValidator,
  IEditFileData
} from '../validator/updateFileValidator';

const { Text } = Typography;

export interface IEditFileModalProps {
  taskId: string;
  visible: boolean;
  hide: () => void;
  item: IDocumentFile;
}

export function EditFileModal(props: IEditFileModalProps) {
  const { item, taskId, visible } = props;
  const { description, integralPart, file, omittedInPublish } = item;
  const [loading, setLoading] = useState(false);
  const editFile = useUpdateAllegato();

  return (
    <ModalDialog
      title={'Modifica Informazioni File'}
      visible={visible}
      onCancel={e => {
        if (loading) return;
        props.hide();
      }}
      footer={null}
    >
      <FormikAugmented
        enableReinitialize
        validationSchema={editFileValidator}
        validationContext={{}}
        initialValues={{
          description: description ?? '',
          integralPart: integralPart ?? false,
          omittedInPublish: integralPart && omittedInPublish ? true : false
        }}
        onSubmit={async (rawValues: IEditFileData) => {
          if (!taskId || taskId.length === 0) {
            console.warn(`[EditFileModal] l\'id del task non è presente`);
            return;
          }
          if (!file) {
            console.warn(`[EditFileModal] File mancante`);
            return;
          }
          const values = await editFileValidator.validate(rawValues);
          setLoading(true);

          try {
            // NB si chiama idAllegato ma ci va l'id del file
            await editFile.mutateAsync({
              idAllegato: file?.id!,
              idTaskInstance: taskId,
              params: {
                descrizione: values.description,
                flParteIntegrante: values.integralPart,
                flPubblicabile: !values.omittedInPublish
              }
            });

            // Invalida le query per gli allegati e i documenti per ricaricare le liste.
            queryClient.invalidateQueries(getGetAzioniDocumentoQueryKey(taskId)); // prettier-ignore
            queryClient.invalidateQueries(
              getGetAzioniGruppoAllegatiQueryKey(taskId)
            );

            message.success(`File modificato correttamente`);
            props.hide();
          } catch (e: any) {
            logger.error(`[EditFileModal] Errore durante la modifica del file`, {error: e}); // prettier-ignore
            message.error(getResponseErrorMessage(e, 'Errore durante la modifica del file')); // prettier-ignore
            throw e;
          } finally {
            setLoading(false);
          }
        }}
      >
        {formik => (
          <FormikForm nested editable helpInTooltips hideSuccess>
            <Text type="secondary">
              Modifica la descrizione del file e/o il flag "Parte Integrante"
              con relativo flag "Omesso dalla Pubblicazione"
            </Text>
            <UploadInputWrapper>
              <FormFieldItem
                formItemClassName="description-field"
                label={null}
                placeholder="Descrizione"
                name="description"
                component={TextInput}
                disabled={loading}
                suffix={<AddFileSwitchesWrapper loading={loading} />}
              />
            </UploadInputWrapper>
            <Divider />
            <Space>
              <Button
                disabled={loading}
                onClick={e => {
                  formik.resetForm();
                  props.hide();
                  e.stopPropagation();
                }}
              >
                Annulla
              </Button>
              <FormikSendButton>Salva</FormikSendButton>
            </Space>
          </FormikForm>
        )}
      </FormikAugmented>
    </ModalDialog>
  );
}
