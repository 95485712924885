import React from 'react';
import {
  RiBankFill,
  RiBuilding2Fill,
  RiCommunityFill,
  RiGovernmentFill,
  RiHome2Fill,
  RiHospitalFill,
  RiHotelFill,
  RiStoreFill,
  RiAdvertisementFill,
  RiArchiveDrawerFill,
  RiArchiveFill,
  RiAtFill,
  RiAttachmentFill,
  RiAwardFill,
  RiBarChart2Fill,
  RiBookmarkFill,
  RiBriefcaseFill,
  RiBubbleChartFill,
  RiCalculatorFill,
  RiCalendarTodoFill,
  RiCustomerService2Fill,
  RiFlagFill,
  RiGlobalFill,
  RiHonourFill,
  RiInboxArchiveFill,
  RiInboxFill,
  RiLineChartFill,
  RiMailFill,
  RiMedal2Fill,
  RiMedalFill,
  RiPrinterFill,
  RiProfileFill,
  RiProjectorFill,
  RiStackFill,
  RiChatCheckFill,
  RiDiscussFill,
  RiArtboardFill,
  RiBallPenFill,
  RiBrush3Fill,
  RiBrushFill,
  RiCompasses2Fill,
  RiEdit2Fill,
  RiEdit2Line,
  RiEditCircleFill,
  RiEditFill,
  RiEditLine,
  RiEditBoxFill,
  RiEditBoxLine,
  RiFocus2Line,
  RiFocus3Fill,
  RiGridFill,
  RiLayoutGridFill,
  RiMagicFill,
  RiMarkPenFill,
  RiPenNibFill,
  RiPenNibLine,
  RiPencilFill,
  RiPencilLine,
  RiPencilRuler2Line,
  RiQuillPenLine,
  RiRulerLine,
  RiScreenshotFill,
  RiShape2Fill,
  RiToolsFill,
  RiCpuLine,
  RiDashboard3Fill,
  RiDatabase2Fill,
  RiDatabaseFill,
  RiFingerprint2Line,
  RiPhoneLine,
  RiRadarFill,
  RiSave2Fill,
  RiServerFill,
  RiSimCard2Line,
  RiArticleLine,
  RiArticleFill,
  RiBook2Fill,
  RiBillLine,
  RiBook3Fill,
  RiBook3Line,
  RiBookFill,
  RiBookLine,
  RiBookMarkFill,
  RiBookMarkLine,
  RiBookOpenFill,
  RiBookletLine,
  RiClipboardLine,
  RiContactsBook2Fill,
  RiContactsBook2Line,
  RiDraftFill,
  RiDraftLine,
  RiFile2Fill,
  RiFile2Line,
  RiFile3Fill,
  RiFile3Line,
  RiFileAddFill,
  RiFileAddLine,
  RiFileChart2Fill,
  RiFileChartFill,
  RiFileCloudFill,
  RiFileCodeFill,
  RiFileCopy2Fill,
  RiFileCopy2Line,
  RiFileDamageFill,
  RiFileDownloadFill,
  RiFileDownloadLine,
  RiFileEditFill,
  RiFileEditLine,
  RiFileForbidFill,
  RiFileForbidLine,
  RiFileHistoryFill,
  RiFileHistoryLine,
  RiFileInfoFill,
  RiFileInfoLine,
  RiFileLine,
  RiFileList2Fill,
  RiFileList2Line,
  RiFileList3Fill,
  RiFileList3Line,
  RiFileListFill,
  RiFileListLine,
  RiFileLockFill,
  RiFileLockLine,
  RiFileMarkFill,
  RiFileMarkLine,
  RiFilePaper2Fill,
  RiFilePaper2Line,
  RiFileShield2Fill,
  RiFileShield2Line,
  RiFileTextFill,
  RiFileTextLine,
  RiFileUserFill,
  RiFileUserLine,
  RiFolder3Fill,
  RiFolder3Line,
  RiFolder5Fill,
  RiFolder5Line,
  RiFolderChart2Fill,
  RiFolderChartFill,
  RiFolderDownloadFill,
  RiFolderForbidFill,
  RiFolderInfoFill,
  RiFolderKeyholeFill,
  RiFolderLockFill,
  RiFolderMusicFill,
  RiFolderShield2Fill,
  RiFolderUserFill,
  RiFolderZipFill,
  RiAttachment2,
  RiDoubleQuotesL,
  RiFlowChart,
  RiTable2,
  Ri24HoursFill,
  RiAuctionFill,
  RiAuctionLine,
  RiBankCardFill,
  RiBankCardLine,
  RiCoinsLine,
  RiFundsFill,
  RiFundsLine,
  RiHandCoinFill,
  RiHandHeartFill,
  RiMoneyEuroBoxFill,
  RiMoneyEuroCircleFill,
  RiRedPacketFill,
  RiRedPacketLine,
  RiTrophyFill,
  RiVipCrown2Fill,
  RiWallet3Fill,
  RiFlaskFill,
  RiVirusLine,
  RiBus2Fill,
  RiCarFill,
  RiCompass3Fill,
  RiCupFill,
  RiGlobeFill,
  RiHotelBedFill,
  RiLifebuoyLine,
  RiLuggageDepositLine,
  RiMapPinLine,
  RiPlaneFill,
  RiRestaurant2Line,
  RiPushpinFill,
  RiPushpinLine
} from 'react-icons/ri';

export const iconNames = [
  { name: 'RiBankFill', icon: <RiBankFill /> },
  { name: 'RiBuilding2Fill', icon: <RiBuilding2Fill /> },
  { name: 'RiCommunityFill', icon: <RiCommunityFill /> },
  { name: 'RiGovernmentFill', icon: <RiGovernmentFill /> },
  { name: 'RiHome2Fill', icon: <RiHome2Fill /> },
  { name: 'RiHospitalFill', icon: <RiHospitalFill /> },
  { name: 'RiHotelFill', icon: <RiHotelFill /> },
  { name: 'RiStoreFill', icon: <RiStoreFill /> },
  { name: 'RiAdvertisementFill', icon: <RiAdvertisementFill /> },
  { name: 'RiArchiveDrawerFill', icon: <RiArchiveDrawerFill /> },
  { name: 'RiArchiveFill', icon: <RiArchiveFill /> },
  { name: 'RiAtFill', icon: <RiAtFill /> },
  { name: 'RiAttachmentFill', icon: <RiAttachmentFill /> },
  { name: 'RiAwardFill', icon: <RiAwardFill /> },
  { name: 'RiBarChart2Fill', icon: <RiBarChart2Fill /> },
  { name: 'RiBookmarkFill', icon: <RiBookmarkFill /> },
  { name: 'RiBriefcaseFill', icon: <RiBriefcaseFill /> },
  { name: 'RiBubbleChartFill', icon: <RiBubbleChartFill /> },
  { name: 'RiCalculatorFill', icon: <RiCalculatorFill /> },
  { name: 'RiCalendarTodoFill', icon: <RiCalendarTodoFill /> },
  { name: 'RiCustomerService2Fill', icon: <RiCustomerService2Fill /> },
  { name: 'RiFlagFill', icon: <RiFlagFill /> },
  { name: 'RiGlobalFill', icon: <RiGlobalFill /> },
  { name: 'RiHonourFill', icon: <RiHonourFill /> },
  { name: 'RiInboxArchiveFill', icon: <RiInboxArchiveFill /> },
  { name: 'RiInboxFill', icon: <RiInboxFill /> },
  { name: 'RiLineChartFill', icon: <RiLineChartFill /> },
  { name: 'RiMailFill', icon: <RiMailFill /> },
  { name: 'RiMedal2Fill', icon: <RiMedal2Fill /> },
  { name: 'RiMedalFill', icon: <RiMedalFill /> },
  { name: 'RiPrinterFill', icon: <RiPrinterFill /> },
  { name: 'RiProfileFill', icon: <RiProfileFill /> },
  { name: 'RiProjectorFill', icon: <RiProjectorFill /> },
  { name: 'RiStackFill', icon: <RiStackFill /> },
  { name: 'RiChatCheckFill', icon: <RiChatCheckFill /> },
  { name: 'RiDiscussFill', icon: <RiDiscussFill /> },
  { name: 'RiArtboardFill', icon: <RiArtboardFill /> },
  { name: 'RiBallPenFill', icon: <RiBallPenFill /> },
  { name: 'RiBrush3Fill', icon: <RiBrush3Fill /> },
  { name: 'RiBrushFill', icon: <RiBrushFill /> },
  { name: 'RiCompasses2Fill', icon: <RiCompasses2Fill /> },
  { name: 'RiEdit2Fill', icon: <RiEdit2Fill /> },
  { name: 'RiEdit2Line', icon: <RiEdit2Line /> },
  { name: 'RiEditCircleFill', icon: <RiEditCircleFill /> },
  { name: 'RiEditFill', icon: <RiEditFill /> },
  { name: 'RiEditLine', icon: <RiEditLine /> },
  { name: 'RiEditBoxFill', icon: <RiEditBoxFill /> },
  { name: 'RiEditBoxLine', icon: <RiEditBoxLine /> },
  { name: 'RiFocus2Line', icon: <RiFocus2Line /> },
  { name: 'RiFocus3Fill', icon: <RiFocus3Fill /> },
  { name: 'RiGridFill', icon: <RiGridFill /> },
  { name: 'RiLayoutGridFill', icon: <RiLayoutGridFill /> },
  { name: 'RiMagicFill', icon: <RiMagicFill /> },
  { name: 'RiMarkPenFill', icon: <RiMarkPenFill /> },
  { name: 'RiPenNibFill', icon: <RiPenNibFill /> },
  { name: 'RiPenNibLine', icon: <RiPenNibLine /> },
  { name: 'RiPencilFill', icon: <RiPencilFill /> },
  { name: 'RiPencilLine', icon: <RiPencilLine /> },
  { name: 'RiPencilRuler2Line', icon: <RiPencilRuler2Line /> },
  { name: 'RiQuillPenLine', icon: <RiQuillPenLine /> },
  { name: 'RiRulerLine', icon: <RiRulerLine /> },
  { name: 'RiScreenshotFill', icon: <RiScreenshotFill /> },
  { name: 'RiShape2Fill', icon: <RiShape2Fill /> },
  { name: 'RiToolsFill', icon: <RiToolsFill /> },
  { name: 'RiCpuLine', icon: <RiCpuLine /> },
  { name: 'RiDashboard3Fill', icon: <RiDashboard3Fill /> },
  { name: 'RiDatabase2Fill', icon: <RiDatabase2Fill /> },
  { name: 'RiDatabaseFill', icon: <RiDatabaseFill /> },
  { name: 'RiFingerprint2Line', icon: <RiFingerprint2Line /> },
  { name: 'RiPhoneLine', icon: <RiPhoneLine /> },
  { name: 'RiRadarFill', icon: <RiRadarFill /> },
  { name: 'RiSave2Fill', icon: <RiSave2Fill /> },
  { name: 'RiServerFill', icon: <RiServerFill /> },
  { name: 'RiSimCard2Line', icon: <RiSimCard2Line /> },
  { name: 'RiArticleLine', icon: <RiArticleLine /> },
  { name: 'RiArticleFill', icon: <RiArticleFill /> },
  { name: 'RiBook2Fill', icon: <RiBook2Fill /> },
  { name: 'RiBillLine', icon: <RiBillLine /> },
  { name: 'RiBook3Fill', icon: <RiBook3Fill /> },
  { name: 'RiBook3Line', icon: <RiBook3Line /> },
  { name: 'RiBookFill', icon: <RiBookFill /> },
  { name: 'RiBookLine', icon: <RiBookLine /> },
  { name: 'RiBookMarkFill', icon: <RiBookMarkFill /> },
  { name: 'RiBookMarkLine', icon: <RiBookMarkLine /> },
  { name: 'RiBookOpenFill', icon: <RiBookOpenFill /> },
  { name: 'RiBookletLine', icon: <RiBookletLine /> },
  { name: 'RiClipboardLine', icon: <RiClipboardLine /> },
  { name: 'RiContactsBook2Fill', icon: <RiContactsBook2Fill /> },
  { name: 'RiContactsBook2Line', icon: <RiContactsBook2Line /> },
  { name: 'RiDraftFill', icon: <RiDraftFill /> },
  { name: 'RiDraftLine', icon: <RiDraftLine /> },
  { name: 'RiFile2Fill', icon: <RiFile2Fill /> },
  { name: 'RiFile2Line', icon: <RiFile2Line /> },
  { name: 'RiFile3Fill', icon: <RiFile3Fill /> },
  { name: 'RiFile3Line', icon: <RiFile3Line /> },
  { name: 'RiFileAddFill', icon: <RiFileAddFill /> },
  { name: 'RiFileAddLine', icon: <RiFileAddLine /> },
  { name: 'RiFileChart2Fill', icon: <RiFileChart2Fill /> },
  { name: 'RiFileChartFill', icon: <RiFileChartFill /> },
  { name: 'RiFileCloudFill', icon: <RiFileCloudFill /> },
  { name: 'RiFileCodeFill', icon: <RiFileCodeFill /> },
  { name: 'RiFileCopy2Fill', icon: <RiFileCopy2Fill /> },
  { name: 'RiFileCopy2Line', icon: <RiFileCopy2Line /> },
  { name: 'RiFileDamageFill', icon: <RiFileDamageFill /> },
  { name: 'RiFileDownloadFill', icon: <RiFileDownloadFill /> },
  { name: 'RiFileDownloadLine', icon: <RiFileDownloadLine /> },
  { name: 'RiFileEditFill', icon: <RiFileEditFill /> },
  { name: 'RiFileEditLine', icon: <RiFileEditLine /> },
  { name: 'RiFileForbidFill', icon: <RiFileForbidFill /> },
  { name: 'RiFileForbidLine', icon: <RiFileForbidLine /> },
  { name: 'RiFileHistoryFill', icon: <RiFileHistoryFill /> },
  { name: 'RiFileHistoryLine', icon: <RiFileHistoryLine /> },
  { name: 'RiFileInfoFill', icon: <RiFileInfoFill /> },
  { name: 'RiFileInfoLine', icon: <RiFileInfoLine /> },
  { name: 'RiFileLine', icon: <RiFileLine /> },
  { name: 'RiFileList2Fill', icon: <RiFileList2Fill /> },
  { name: 'RiFileList2Line', icon: <RiFileList2Line /> },
  { name: 'RiFileList3Fill', icon: <RiFileList3Fill /> },
  { name: 'RiFileList3Line', icon: <RiFileList3Line /> },
  { name: 'RiFileListFill', icon: <RiFileListFill /> },
  { name: 'RiFileListLine', icon: <RiFileListLine /> },
  { name: 'RiFileLockFill', icon: <RiFileLockFill /> },
  { name: 'RiFileLockLine', icon: <RiFileLockLine /> },
  { name: 'RiFileMarkFill', icon: <RiFileMarkFill /> },
  { name: 'RiFileMarkLine', icon: <RiFileMarkLine /> },
  { name: 'RiFilePaper2Fill', icon: <RiFilePaper2Fill /> },
  { name: 'RiFilePaper2Line', icon: <RiFilePaper2Line /> },
  { name: 'RiFileShield2Fill', icon: <RiFileShield2Fill /> },
  { name: 'RiFileShield2Line', icon: <RiFileShield2Line /> },
  { name: 'RiFileTextFill', icon: <RiFileTextFill /> },
  { name: 'RiFileTextLine', icon: <RiFileTextLine /> },
  { name: 'RiFileUserFill', icon: <RiFileUserFill /> },
  { name: 'RiFileUserLine', icon: <RiFileUserLine /> },
  { name: 'RiFolder3Fill', icon: <RiFolder3Fill /> },
  { name: 'RiFolder3Line', icon: <RiFolder3Line /> },
  { name: 'RiFolder5Fill', icon: <RiFolder5Fill /> },
  { name: 'RiFolder5Line', icon: <RiFolder5Line /> },
  { name: 'RiFolderChart2Fill', icon: <RiFolderChart2Fill /> },
  { name: 'RiFolderChartFill', icon: <RiFolderChartFill /> },
  { name: 'RiFolderDownloadFill', icon: <RiFolderDownloadFill /> },
  { name: 'RiFolderForbidFill', icon: <RiFolderForbidFill /> },
  { name: 'RiFolderInfoFill', icon: <RiFolderInfoFill /> },
  { name: 'RiFolderKeyholeFill', icon: <RiFolderKeyholeFill /> },
  { name: 'RiFolderLockFill', icon: <RiFolderLockFill /> },
  { name: 'RiFolderMusicFill', icon: <RiFolderMusicFill /> },
  { name: 'RiFolderShield2Fill', icon: <RiFolderShield2Fill /> },
  { name: 'RiFolderUserFill', icon: <RiFolderUserFill /> },
  { name: 'RiFolderZipFill', icon: <RiFolderZipFill /> },
  { name: 'RiAttachment2', icon: <RiAttachment2 /> },
  { name: 'RiDoubleQuotesL', icon: <RiDoubleQuotesL /> },
  { name: 'RiFlowChart', icon: <RiFlowChart /> },
  { name: 'RiTable2', icon: <RiTable2 /> },
  { name: 'Ri24HoursFill', icon: <Ri24HoursFill /> },
  { name: 'RiAuctionFill', icon: <RiAuctionFill /> },
  { name: 'RiAuctionLine', icon: <RiAuctionLine /> },
  { name: 'RiBankCardFill', icon: <RiBankCardFill /> },
  { name: 'RiBankCardLine', icon: <RiBankCardLine /> },
  { name: 'RiCoinsLine', icon: <RiCoinsLine /> },
  { name: 'RiFundsFill', icon: <RiFundsFill /> },
  { name: 'RiFundsLine', icon: <RiFundsLine /> },
  { name: 'RiHandCoinFill', icon: <RiHandCoinFill /> },
  { name: 'RiHandHeartFill', icon: <RiHandHeartFill /> },
  { name: 'RiMoneyEuroBoxFill', icon: <RiMoneyEuroBoxFill /> },
  { name: 'RiMoneyEuroCircleFill', icon: <RiMoneyEuroCircleFill /> },
  { name: 'RiRedPacketFill', icon: <RiRedPacketFill /> },
  { name: 'RiRedPacketLine', icon: <RiRedPacketLine /> },
  { name: 'RiTrophyFill', icon: <RiTrophyFill /> },
  { name: 'RiVipCrown2Fill', icon: <RiVipCrown2Fill /> },
  { name: 'RiWallet3Fill', icon: <RiWallet3Fill /> },
  { name: 'RiFlaskFill', icon: <RiFlaskFill /> },
  { name: 'RiVirusLine', icon: <RiVirusLine /> },
  { name: 'RiBus2Fill', icon: <RiBus2Fill /> },
  { name: 'RiCarFill', icon: <RiCarFill /> },
  { name: 'RiCompass3Fill', icon: <RiCompass3Fill /> },
  { name: 'RiCupFill', icon: <RiCupFill /> },
  { name: 'RiGlobeFill', icon: <RiGlobeFill /> },
  { name: 'RiHotelBedFill', icon: <RiHotelBedFill /> },
  { name: 'RiLifebuoyLine', icon: <RiLifebuoyLine /> },
  { name: 'RiLuggageDepositLine', icon: <RiLuggageDepositLine /> },
  { name: 'RiMapPinLine', icon: <RiMapPinLine /> },
  { name: 'RiPlaneFill', icon: <RiPlaneFill /> },
  { name: 'RiRestaurant2Line', icon: <RiRestaurant2Line /> },
  { name: 'RiPushpinFill', icon: <RiPushpinFill /> },
  { name: 'RiPushpinLine', icon: <RiPushpinLine /> }
];
