import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { CheckboxInputItem } from 'client/ui/form/input/CheckboxInputItem';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import * as React from 'react';
import { IDeclarationFieldOptions } from '../DeclarationFieldSwitch';

export interface IDeclarationFieldCheckboxProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

// TODO Gestire raggruppamento_check
export function DeclarationFieldCheckbox(
  props: IDeclarationFieldCheckboxProps
) {
  const { field, options } = props;
  const prefix = options.namePrefix;

  const fieldName = prefix ? `${prefix}.${field.nome}` : field.nome;

  return (
    <FormFieldItem
      label={null}
      component={CheckboxInput}
      name={fieldName}
      children={props.field.des_campo}
    />
  );
}
