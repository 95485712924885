/**
 * Generato automaticamente.
 * Procedimenti
 */
import { useMutation } from 'react-query';
import type { UseMutationOptions, MutationFunction } from 'react-query';
import type {
  JNLPFirmaRequest,
  ArgumentsResponse,
  JNLPDocumentiRequest
} from '.././schemas';
import { authApiInstance } from '../../../core/network/mutators/apiClient';
import type { ErrorType } from '../../../core/network/mutators/apiClient';

/**
 * Effettua il download del file .jnlp per il jar di FirmaManager
 * @summary Firma Manager JNLP
 */
export const firma = (jNLPFirmaRequest: JNLPFirmaRequest) => {
  return authApiInstance<string>({
    url: `/jnlp/firma-manager`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: jNLPFirmaRequest
  });
};

export type FirmaMutationResult = NonNullable<
  Awaited<ReturnType<typeof firma>>
>;
export type FirmaMutationBody = JNLPFirmaRequest;
export type FirmaMutationError = ErrorType<unknown>;

export const useFirma = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof firma>>,
    TError,
    { data: JNLPFirmaRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof firma>>,
    { data: JNLPFirmaRequest }
  > = props => {
    const { data } = props ?? {};

    return firma(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof firma>>,
    TError,
    { data: JNLPFirmaRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Genera la lista di arguments da passare al websocket di FirmaManager
 * @summary Firma Manager JNLP
 */
export const firmaArguments = (jNLPFirmaRequest: JNLPFirmaRequest) => {
  return authApiInstance<ArgumentsResponse>({
    url: `/jnlp/firma-manager-arguments`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: jNLPFirmaRequest
  });
};

export type FirmaArgumentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof firmaArguments>>
>;
export type FirmaArgumentsMutationBody = JNLPFirmaRequest;
export type FirmaArgumentsMutationError = ErrorType<unknown>;

export const useFirmaArguments = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof firmaArguments>>,
    TError,
    { data: JNLPFirmaRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof firmaArguments>>,
    { data: JNLPFirmaRequest }
  > = props => {
    const { data } = props ?? {};

    return firmaArguments(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof firmaArguments>>,
    TError,
    { data: JNLPFirmaRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Effettua il download del file .jnlp per il jar di DocumentiManager
 * @summary Documenti Manager JNLP
 */
export const documentiManagerJnlp = (
  jNLPDocumentiRequest: JNLPDocumentiRequest
) => {
  return authApiInstance<string>({
    url: `/jnlp/documenti-manager`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: jNLPDocumentiRequest
  });
};

export type DocumentiManagerJnlpMutationResult = NonNullable<
  Awaited<ReturnType<typeof documentiManagerJnlp>>
>;
export type DocumentiManagerJnlpMutationBody = JNLPDocumentiRequest;
export type DocumentiManagerJnlpMutationError = ErrorType<unknown>;

export const useDocumentiManagerJnlp = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentiManagerJnlp>>,
    TError,
    { data: JNLPDocumentiRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof documentiManagerJnlp>>,
    { data: JNLPDocumentiRequest }
  > = props => {
    const { data } = props ?? {};

    return documentiManagerJnlp(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof documentiManagerJnlp>>,
    TError,
    { data: JNLPDocumentiRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Genera la lista di arguments da passare al websocket di DocumentiManager
 * @summary Documenti Manager JNLP
 */
export const documentiManagerJnlpArguments = (
  jNLPDocumentiRequest: JNLPDocumentiRequest
) => {
  return authApiInstance<ArgumentsResponse>({
    url: `/jnlp/documenti-manager-arguments`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: jNLPDocumentiRequest
  });
};

export type DocumentiManagerJnlpArgumentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof documentiManagerJnlpArguments>>
>;
export type DocumentiManagerJnlpArgumentsMutationBody = JNLPDocumentiRequest;
export type DocumentiManagerJnlpArgumentsMutationError = ErrorType<unknown>;

export const useDocumentiManagerJnlpArguments = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof documentiManagerJnlpArguments>>,
    TError,
    { data: JNLPDocumentiRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof documentiManagerJnlpArguments>>,
    { data: JNLPDocumentiRequest }
  > = props => {
    const { data } = props ?? {};

    return documentiManagerJnlpArguments(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof documentiManagerJnlpArguments>>,
    TError,
    { data: JNLPDocumentiRequest },
    TContext
  >(mutationFn, mutationOptions);
};
