import { Menu } from 'antd';
import {
  Documento,
  RecuperaDocumentoPerFirmaParams
} from 'client/api/backend/schemas';
import { FastSignJnlpWindowManager } from 'client/components/schema/task/jnlp/FastSignJnlpWindowManager';
import React, { useState } from 'react';

export interface IDocumentActionFastSignProps {
  taskId: string;
  document: Documento;
  params: RecuperaDocumentoPerFirmaParams;
}

export function DocumentActionFastSign(props: IDocumentActionFastSignProps) {
  const { taskId, document, params, ...otherProps } = props;

  const [visible, setVisible] = useState(false);

  return (
    <>
      <Menu.Item
        {...otherProps}
        onClick={() => {
          setVisible(true);
        }}
      >
        Firma Manager
      </Menu.Item>
      <FastSignJnlpWindowManager
        taskId={taskId}
        document={document}
        visible={visible}
        params={params}
        hide={() => setVisible(false)}
      />
    </>
  );
}
