import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import { MessageParagraph } from './AttachmentRequiredLabel';

export interface IAttachmentRequiredSignedLabelProps {
  plural: boolean;
  signed: boolean | undefined | null;
  visible: boolean;
}

export function AttachmentRequiredSignedLabel(
  props: IAttachmentRequiredSignedLabelProps
) {
  const { signed, visible } = props;

  if (!signed) return null;
  if (!visible) return null;

  const message = props.plural
    ? `I documenti allegati devono essere firmati.`
    : `Il documento allegato deve essere firmato.`;
  return (
    <MessageParagraph strong type="warning">
      {message}
    </MessageParagraph>
  );
}
