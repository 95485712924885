import { message } from 'antd';
import { useRilascia } from 'client/api/backend/attivita/attivita';
import { logger } from 'client/core/logger/logger';
import { queryClient } from 'client/core/network/queryClient';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import { IconRelaese } from 'client/ui/icon/ApplicationIcon';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

export interface IReleaseTaskButtonProps {
  taskId: string | undefined;
  /** URL di redirect. Se non passato, non avviene nulla */
  redirect?: string;
}

export function ReleaseTaskButton(props: IReleaseTaskButtonProps) {
  const { taskId, redirect } = props;
  const queryClient = useQueryClient();
  const releaseFunction = useRilascia();
  const history = useHistory();

  return (
    <ButtonConfirm
      title="Vuoi rilasciare questa attività?"
      okText="Rilascia"
      okType="primary"
      okDanger={false}
      size="large"
      type="default"
      children="Rilascia"
      placement="bottomLeft"
      popIcon={<IconWrapper icon={<IconRelaese />} />}
      loading={releaseFunction.isLoading}
      disabled={!taskId}
      onConfirm={async () => {
        if (!taskId) return;
        try {
          await releaseFunction.mutateAsync({
            idTaskInstance: taskId
          });
          queryClient.invalidateQueries();
          message.success('Attività rilasciata');
          // Se fornito, faccio il redirect
          if (redirect) history.push(redirect);
        } catch (e) {
          logger.error({ error: e });
          message.error(`Si è verificato un errore durante il rilascio. Riprovare...`); // prettier-ignore
        }
      }}
    />
  );
}
