import { AttivitaDTOStato } from 'client/api/backend/schemas';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TaskStateTag } from '../table/TaskStateTag';
import React from 'react';

export interface IStateSelectIputProps {
  name: string;
}

export function StateSelectIputItem(props: IStateSelectIputProps) {
  const { name } = props;
  return (
    <FormFieldItem
      size="middle"
      component={SelectInput}
      name={name}
      label="Stato"
      placeholder="stato..."
      allowClear
      options={[
        {
          label: <TaskStateTag state={AttivitaDTOStato.PENDENTE} />,
          value: AttivitaDTOStato.PENDENTE
        },
        {
          label: <TaskStateTag state={AttivitaDTOStato.IN_CORSO} />,
          value: AttivitaDTOStato.IN_CORSO
        }
      ]}
    />
  );
}
