import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { DeclarationFieldDto } from 'common/dto/declaration/DeclarationFieldDto';
import React from 'react';
import { IDeclarationFieldOptions } from '../DeclarationFieldSwitch';

export interface IDeclarationFieldSelectProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
}

/**
 * Componente per la gestione dei campi di tipo select
 */
export function DeclarationFieldSelect(props: IDeclarationFieldSelectProps) {
  const { field, options } = props;
  const prefix = options.namePrefix;

  const fieldName = prefix ? `${prefix}.${field.nome}` : field.nome;

  return (
    <FormFieldItem
      name={fieldName}
      label={field.des_campo}
      component={SelectInput}
      options={field.options ?? []}
    />
  );
}
