import { Col, Space, Typography } from 'antd';
import { IDocumentFile } from 'client/ui/form/input/UploadInput';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import React from 'react';
import { ImAttachment } from 'react-icons/im';
import { DeleteFileIconButton } from './DeleteFileIconButton';
import { DownloadFileIconButton } from './DownloadFileIconButton';
import { ActionsCol, FileNameText, WrapperRow } from './FileRow';

const { Text } = Typography;

export interface IReadonlyFileRowProps {
  item: IDocumentFile;
  /** Se fornito, richiama l'API di download relativa alle procedure */
  procedureId?: string;
}

export function ReadonlyFileRow(props: IReadonlyFileRowProps) {
  const { item, procedureId } = props;
  const { file, description } = item;

  return (
    <WrapperRow>
      <Col flex="1 1 auto">
        <Text>
          <FileNameText>
            <IconWrapper icon={<ImAttachment />} /> {file?.name}
          </FileNameText>
          {description && <span> | {description}</span>}
        </Text>
      </Col>
      <ActionsCol>
        <Space size={16}>
          <DownloadFileIconButton
            fileId={file?.id!}
            filename={file?.name!}
            procedureId={procedureId}
          />
        </Space>
      </ActionsCol>
    </WrapperRow>
  );
}
