import { Tooltip } from 'antd';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { FaCircle } from 'react-icons/fa';
import styled from 'styled-components';

const FlagContainer = styled.div`
  vertical-align: 2px;
  display: flex;
  width: 14px;
  height: 14px;
  align-items: center;
  justify-content: center;
  .anticon {
    display: flex;
  }
`;

const IconWarning = styled(IconWrapper)`
  font-size: 14px;
  color: ${props => props.theme.warningColor};
  opacity: 0;
  transition: opacity 0.15s linear 0.075s, font-size 0.3s linear;
  &.unsaved {
    opacity: 1;
    font-size: 10px;
  }
`;

const TASK_EDITED_FLAG_PORTAL = 'header-flag--edited-portal';

export interface ITaskEditedFlagPortalProps {
  children: React.ReactNode;
}

export function TaskEditFlag() {
  const formik = useFormikContext();

  return (
    <Tooltip
      title="Ci sono modifiche non salvate"
      trigger={formik.dirty ? 'hover' : []}
    >
      <IconWarning
        icon={<FaCircle />}
        className={formik.dirty ? 'unsaved' : ''}
      />
    </Tooltip>
  );
}

/**
 * Permette di posizionare il flag che indica la modifica di una dichiarazione,
 * mantenendo il `Context` di React anche se annidato nell'albero del DOM.
 */
export function TaskEditedFlagPortal(props: ITaskEditedFlagPortalProps) {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const portal = document.getElementById(TASK_EDITED_FLAG_PORTAL)!;
    portal.appendChild(el.current);

    return () => {
      portal.removeChild(el.current);
    };
  }, [props.children]);

  return ReactDOM.createPortal(props.children, el.current);
}

export const TaskEditedFlagContainer = () => (
  <FlagContainer id={TASK_EDITED_FLAG_PORTAL} />
);
