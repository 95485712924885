import { AxiosInstance } from 'axios';
import { store } from 'client/core/store/configureReduxStore';
import { AuthActions } from 'client/components/auth/AuthModule';
import { message } from 'antd';
import { logger } from 'client/core/logger/logger';
import { appConfig } from 'client/core/appConfig';

function isSessionForbidden(response: any, config: any) {
  return response.status === 403 && config.url.startsWith('/session');
}

/**
 * Gestiamo tramite interceptor la scadenza della sessione e l'eventual redirect
 * alla pagina di Login (tramite react-router)
 */
export function authInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(undefined, error => {
    const { config, response } = error;
    const originalConfig = config;

    console.log({ response, config, error });

    if (
      response == null ||
      (response.status !== 401 && !isSessionForbidden(response, config))
    ) {
      return Promise.reject(error);
    }

    /**
     * Se si è loggati mostriamo un messaggio di avviso che identifica il fatto
     * che la sessione è scaduta.
     */
    const { isLogged, current: currentUser } = store.getState().auth;
    logger.log(`[Auth/refresh] Utente ${currentUser?.utenteLoggato?.email ?? '<NA>'} - sessione scaduta - ${isLogged}`); // prettier-ignore
    store.dispatch(AuthActions.logout());

    if (currentUser) {
      message.error(
        `La sessione è scaduta. Si prega di effettuare nuovamente l'accesso.`
      );
    }

    /**
     * Effettuiamo il redirect per l'autenticazione
     */
    return Promise.reject(error);
  });
}
