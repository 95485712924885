import { UploadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { InviaDocumentoFirmatoParams } from 'client/api/backend/schemas';
import { logger } from 'client/core/logger/logger';
import { getResponseErrorMessage } from 'client/core/network/errors/getResponseErrorMessage';
import { uploadSignedFile } from 'client/components/schema/document/uploadSignedFile';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { UploadInput } from 'client/ui/form/input/UploadInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { yup } from 'common/validation/initYup';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { UploadInputWrapper } from '../../file/UploadInputWrapper';

const ModalUploadSignDocumentForm = styled(ModalDialog)`
  .ant-modal-body {
    padding: 40px 0 50px 0;
    background-color: ${props => props.theme.bodyBackground};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 4px 4px;
  }
`;

const B_TO_MB = 1024 * 1024;

export interface IUploadSignDocumentModalProps {
  taskId: string;
  documentId: string;
  visible: boolean;
  params: InviaDocumentoFirmatoParams;
  hide: () => void;
}

export function UploadSignDocumentModal(props: IUploadSignDocumentModalProps) {
  const { taskId, documentId, visible, params } = props;

  const [loading, setLoading] = useState(false);

  const handleUpload = useCallback(
    async (file: RcFile) => {
      if (!file) return;
      setLoading(true);
      try {
        // Usiamo una funzione custom perché quella autogenerata
        // non gestisce correttamente i multipart/form-data.
        const result = await uploadSignedFile({
          taskId,
          documentId,
          file,
          params
        });

        message.success(`File caricato correttamente`);
      } catch (e: any) {
        logger.error(`[UploadInput] Errore durante l'upload del file`, {error: e}); // prettier-ignore
        message.error(getResponseErrorMessage(e, 'Errore durante il caricamento del file')); // prettier-ignore
        throw e;
      } finally {
        setLoading(false);
        props.hide();
      }
    },
    [taskId, documentId]
  );

  if (!visible) return null;

  return (
    <ModalUploadSignDocumentForm
      size="small"
      title={'Carica documento firmato'}
      visible={visible}
      onCancel={() => !loading && props.hide()}
      footer={null}
      mask
      maskClosable={false}
    >
      <FormikAugmented
        validationSchema={uploadFileValidator}
        validationContext={{}}
        initialValues={{}}
        enableReinitialize
        onSubmit={async (rawValues, { resetForm }) => {
          const values = await uploadFileValidator.validate(rawValues);

          // Evito l'upload di file vuoti
          if (values.file.size === 0) {
            message.error('Impossibile caricare un file vuoto');
            return;
          }

          // Carico il file
          try {
            await handleUpload(values.file);
            resetForm();
          } catch (e) {
            return;
          }
        }}
      >
        <FormikForm nested editable>
          <UploadInputWrapper>
            <FormFieldItem
              formItemClassName="upload-button"
              label={null}
              name="file"
              component={UploadInput}
              buttonProps={{ size: 'large' }}
              options={{ flFirmaObbligatoria: true, formatiAccettati: ['pdf'] }}
              disabled={loading}
            />
            <FormikSendButton
              className="send-button"
              size="large"
              icon={<UploadOutlined />}
              loading={loading}
            >
              Carica
            </FormikSendButton>
          </UploadInputWrapper>
        </FormikForm>
      </FormikAugmented>
    </ModalUploadSignDocumentForm>
  );
}

const uploadFileValidator = yup
  .object({
    file: yup.mixed<RcFile>().required()
  })
  .required()
  .noUnknown();
