import React from 'react';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import styled from 'styled-components';

export const IconTable = styled(IconWrapper)`
  &.anticon {
    font-size: 20px;
    vertical-align: middle;
    line-height: 1;
  }
`;
