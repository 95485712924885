import * as React from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const FormRow = styled.div`
  display: flex;
  ${down('md')} {
    flex-direction: column;
  }
`;
const ColForm = styled.div`
  margin-top: 6px;
  flex: 1 1 auto;
  min-width: 0;
`;
const ColButton = styled.div`
  margin-bottom: 6px;
  flex: 0 0 auto;
  min-width: 0;
  padding-left: 24px;
  align-self: flex-end;
  ${down('md')} {
    flex: 1 1 auto;
    margin-top: 18px;
  }
`;

export interface SearchFormContainerProps {
  children: React.ReactNode;
  button: React.ReactNode;
}

export function SearchFormContainer(props: SearchFormContainerProps) {
  return (
    <FormRow>
      <ColForm>{props.children}</ColForm>
      <ColButton>{props.button}</ColButton>
    </FormRow>
  );
}
