import { RecuperaDocumentoPerFirmaParams } from 'client/api/backend/schemas';
import { appConfig } from 'client/core/appConfig';
import { joinPath } from 'client/core/network/joinPath';
import QueryString from 'qs';
import React from 'react';

export interface IDownloadSignedDocumentLinkProps {
  taskId: string;
  documentId: string;
  params: RecuperaDocumentoPerFirmaParams;
}

export function DownloadDocumentSignedLink(
  props: IDownloadSignedDocumentLinkProps
) {
  const { taskId, documentId, params } = props;
  return (
    <a
      target="_blank"
      href={joinPath([
        appConfig.remotePath,
        appConfig.apiBackendPath,
        '/attivita/' +
          taskId +
          '/documenti/' +
          documentId +
          `/firma` +
          '?' +
          QueryString.stringify(params)
      ])}
    >
      Scarica documento da firmare
    </a>
  );
}
