import {
  DeclarationFieldDto,
  DeclarationFieldType
} from 'common/dto/declaration/DeclarationFieldDto';
import { assertNever } from 'common/utils/assertNever';
import * as React from 'react';
import { DeclarationFieldContainer } from './DeclarationFieldContainer';
import { DeclarationFieldTabularLayout } from './DeclarationFieldTabularLayout';
import { DeclarationFieldAsyncSelect } from './type/DeclarationFieldAsyncSelect';
import { DeclarationFieldCheckbox } from './type/DeclarationFieldCheckbox';
import { DeclarationFieldComputed } from './type/DeclarationFieldComputed';
import { DeclarationFieldInput } from './type/DeclarationFieldInput';
import { DeclarationFieldRadio } from './type/DeclarationFieldRadio';
import { DeclarationFieldRepeatable } from './type/DeclarationFieldRepeatable';
import { DeclarationFieldSelect } from './type/DeclarationFieldSelect';
import { DeclarationFieldTable } from './type/DeclarationFieldTable';
import { DeclarationFieldText } from './type/DeclarationFieldText';
import { DeclarationFieldTextArea } from './type/DeclarationFieldTextArea';

export interface IDeclarationFieldOptions {
  namePrefix: string | null | undefined;
}

export interface IDeclarationFieldSwitchProps {
  field: DeclarationFieldDto;
  options: IDeclarationFieldOptions;
  editable?: boolean;
}

export function DeclarationFieldSwitch(props: IDeclarationFieldSwitchProps) {
  return (
    <DeclarationFieldContainer field={props.field}>
      <DeclarationFieldSwitchInternal {...props} />
    </DeclarationFieldContainer>
  );
}

/**
 * Versione interna che gestisce esclusivamente lo switch fra gli input.
 */
function DeclarationFieldSwitchInternal(props: IDeclarationFieldSwitchProps) {
  // Se il campo è specificatamente nascosto, non lo renderizzo
  if (props.field.visibile === false) {
    return null;
  }

  switch (props.field.subtype) {
    case 'repeatable':
      return (
        <DeclarationFieldRepeatable
          field={props.field}
          options={props.options}
          editable={props.editable}
        />
      );
    case 'table':
      return (
        <DeclarationFieldTable field={props.field} options={props.options} />
      );
  }

  switch (props.field.tipo) {
    case DeclarationFieldType.Text:
      return (
        <DeclarationFieldText field={props.field} options={props.options} />
      );
    case DeclarationFieldType.TextArea:
      return (
        <DeclarationFieldTextArea field={props.field} options={props.options} />
      );
    case DeclarationFieldType.Input:
    case DeclarationFieldType.Date:
      if (props.field.subtype === 'computed') {
        return <DeclarationFieldComputed {...props} />;
      }
      return (
        <DeclarationFieldInput field={props.field} options={props.options} />
      );
    case DeclarationFieldType.Radio:
      return (
        <DeclarationFieldRadio field={props.field} options={props.options} />
      );
    case DeclarationFieldType.Checkbox:
      return (
        <DeclarationFieldCheckbox field={props.field} options={props.options} />
      );
    case DeclarationFieldType.Select:
      return (
        <DeclarationFieldSelect field={props.field} options={props.options} />
      );
    case DeclarationFieldType.AsyncSelect:
      return (
        <DeclarationFieldAsyncSelect
          field={props.field}
          options={props.options}
        />
      );
    case DeclarationFieldType.Table:
      return (
        <DeclarationFieldTabularLayout
          field={props.field}
          options={props.options}
        />
      );
    case DeclarationFieldType.TableHeaders:
    case DeclarationFieldType.TableBody:
      return null; // TODO
    default:
      assertNever(
        props.field.tipo,
        new Error(
          `Non è possibile visualizzare il campo di tipo "${props.field.tipo}" (nome: ${props.field.nome})`
        )
      );
  }
}
