import * as React from 'react';
import { StateAnchor } from 'client/ui/anchor/StateAnchor';
import {
  AzioniDichiarazione,
  AzioniDocumento,
  AzioniGruppoAllegato
} from 'client/api/backend/schemas';
import { TaskDeclarationAnchorLink } from './TaskDeclarationAnchorLink';
import { TaskDocumentAnchorLink } from './TaskDocumentAnchorLink';
import { DeclarationLogic } from '../../../../components/schema/task/declaration/DeclarationLogic';
import { DocumentLogic } from 'client/components/schema/task/document/DocumentLogic';
import { TaskAttachmentAnchorLink } from './TaskAttachmentAnchorLink';

export interface TaskStateAnchorsProps {
  declarations?: AzioniDichiarazione[];
  documents?: AzioniDocumento[];
  attachmentGroups?: AzioniGruppoAllegato[];
}

export function TaskStateAnchors(props: TaskStateAnchorsProps) {
  return (
    <StateAnchor offsetTop={234}>
      {props.declarations?.map((declaration, index) => (
        <TaskDeclarationAnchorLink
          declaration={declaration}
          key={DeclarationLogic.getKey(declaration.dichiarazione!)}
        />
      ))}
      {props.attachmentGroups?.map((attachments, index) => (
        <TaskAttachmentAnchorLink attachments={attachments} key={index} />
      ))}
      {props.documents?.map((document, index) => (
        <div key={index}>
          <TaskDocumentAnchorLink
            document={document}
            key={DocumentLogic.getKey(document.documento)}
          />
          {document.documento?.dichiarazioni?.map((declaration, index) => (
            <TaskDeclarationAnchorLink
              declaration={declaration}
              key={DeclarationLogic.getKey(declaration.dichiarazione!)}
            />
          ))}
          {document.documento?.gruppoAllegati?.map((attachments, index) => (
            <TaskAttachmentAnchorLink
              attachments={attachments}
              key={index}
              prefix={document.documento?.idTipoDocumento}
            />
          ))}
        </div>
      ))}
    </StateAnchor>
  );
}
